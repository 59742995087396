// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        coupons: [],
        discounts: [],
        credits: null,
        couponsHistory: [],
        isLoadMore: false,
        page: 1,
        limit: 12,
        tabIndex: 0,
        addCouponData: null
    }
}

export const state = getDefaultState()