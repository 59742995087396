<script setup>
// components
import FreeKwhPackage from '@/components/FreeKwhPackage.vue'
import FreeKwhPackages from '@/components/FreeKwhPackages.vue'
import FreeKwhLoader from '@/loaders/FreeKwhLoader.vue'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

defineProps({
    id: {
        required: false,
        default: null
    },
    packages: {
        required: true,
        type: Array,
        default: () => {
            return []
        }
    }
})
</script>

<template>
    <section class="section">
        <v-row v-if="!packages">
            <v-col>
                <FreeKwhLoader class="mt-5" />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <FreeKwhPackage v-if="packages.length <= 1"
                                :driver-id="id ? [id] : null"
                                :free-kwh="packages"
                />
                <FreeKwhPackages v-if="packages.length > 1"
                                 :driver-id="[id]"
                                 :free-kwh="packages"
                />
            </v-col>
        </v-row>
    </section>
</template>