<script setup>
import { onBeforeMount, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'

// components
import Table from '@/components/Table.vue'
import ExportBtn from '@/components/ExportBtn.vue'
import Popup from '@/components/modals/Popup.vue'
import FilterByToggle from '@/components/inputs/FilterByToggle.vue'
import ChargingForm from '@/components/chargingForm/ChargingForm.vue'
import DriverFilters from '@/pages/drivers/components/DriverFilters.vue'
import MenuActionsMobile from '@/components/inputs/MenuActionsMobile.vue'

// composables
import useWindowWidth from '@/composables/windowWidth'
import useDrivers from '@/composables/drivers'

// utils
import { roundToPrecision } from '@/utils'
import { useStore } from 'vuex'

const store = useStore()
const { dispatch, state } = store

// translations
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const router = useRouter()

const {
    windowWidth
} = useWindowWidth()

const {
    packages,
    tariffs,
    chargingDriverId,
    isPopupActive,
    isFiltersVisible,
    isCustomVisible,
    isExportLoading,
    selected,
    selectedRow,
    filtersSelected,
    popupData,
    options,
    actions,
    headers,
    drivers,
    pagination,
    countSelectedItems,
    getItems,
    getBatchActions,
    getActions,
    getSingleDeleteAction,
    isActionDisabled,
    handleAction,
    deleteDriver,
    handleDownload,
    getDefaultFilters,
    setFilter,
    clearFilters,
    sorting,
    goToDetail,
    addDriver,
    selectedItems,
    highlightElement,
    setWindowWidth
} = useDrivers()

onMounted(async () => {
    await dispatch('drivers/getDriverTariffs')
})

onBeforeMount(() => {
    getItems()
    // if only 1 driver is available, redirect to account settings
    // if (state.account?.drivers_count < 2) {
    //     router.push({ path: 'account' })
    // }
})

watch(options, () => {
    getItems()
})

</script>

<template>
    <div class="drivers">
        <header class="drivers__header">
            <div class="drivers__header-head">
                <h1 class="home-page-title drivers__header--title">
                    {{ t('drivers.DriversManagement') }}
                </h1>
            </div>
            <v-row class="drivers__options">
                <v-col
                    class="py-0"
                    cols="5"
                >
                    <v-btn
                        v-if="windowWidth < 600"
                        :ripple="false"
                        class="active-button px-0 justify-start"
                        color="var(--v-color-gray)"
                        icon
                        variant="plain"
                        @click="addDriver"
                    >
                        <v-icon class="active-icon">
                            mdi-account-plus
                        </v-icon>
                    </v-btn>
                    <v-btn
                        v-else
                        :ripple="false"
                        class="active-button px-0"
                        color="primary"
                        variant="text"
                        data-testid="addDriverButton"
                        text
                        @click="addDriver"
                    >
                        <v-icon class="pr-3">
                            mdi-account-plus
                        </v-icon>
                        {{ t('drivers.addDriver') }}
                    </v-btn>
                </v-col>
                <v-col
                    class="py-0"
                    cols="7"
                >
                    <div class="options-wrapper">
                        <ExportBtn
                            :loading="isExportLoading"
                            @handleDownload="handleDownload"
                        />
                        <FilterByToggle
                            :active="isFiltersVisible"
                            color="primary"
                            @change="isFiltersVisible = !isFiltersVisible"
                        />
                    </div>
                </v-col>
            </v-row>
        </header>
        <DriverFilters
            v-show="isFiltersVisible"
            :default-filters="getDefaultFilters()"
            :filters-selected="filtersSelected"
            @clearFilters="clearFilters"
            @getItems="getItems"
            @setFilter="setFilter"
        />
        <main class="drivers__main">
            <div class="drivers__table">
                <div
                    v-if="!!countSelectedItems"
                    class="drivers__table-actions"
                >
                    <span class="mr-6">
                        {{ countSelectedItems }} {{ t('drivers.itemsAreSelected') }}
                    </span>
                    <div
                        v-for="{ icon, title } in getBatchActions()"
                        :key="title"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    v-show="!!countSelectedItems"
                                    :key="title"
                                    class="actions__button"
                                    color="var(--v-color-gray-darken2)"
                                    data-testid="batchDeleteButton"
                                    variant="text"
                                    v-bind="props"
                                    @click="handleAction(title)"
                                >
                                    <v-icon>{{ icon }}</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ title }}</span>
                        </v-tooltip>
                </div>
            </div>
            <Popup
                :active="isPopupActive"
                :popup-data="popupData.question"
                @accept="deleteDriver"
                @cancel="isPopupActive = false"
            />
            <ChargingForm
                v-if="chargingDriverId"
                :driver="chargingDriverId"
                @close="chargingDriverId = null"
            />
            <Table
                :headers="headers"
                :is-descending="options.sortDesc ? options.sortDesc : true"
                :items="drivers"
                :pagination="pagination"
                :server-items="pagination.itemsLength"
                :show-select="true"
                :sort-column-name="options.sortBy[0]"
                @updateSelected="v => selected = v"
                @countSelectedItems="selectedItems"
                @options="v => options = v"
                @sorting="sorting"
            >
                <template
                    v-if="windowWidth > 600 && countSelectedItems === 0"
                    v-slot:actions="props"
                >
                    <MenuActionsMobile
                        v-if="!props.item.deletedAt && !isActionDisabled(props.item.id)"
                        :id="props.item.id"
                        :actions="getSingleDeleteAction()"
                        :is-disabled="isActionDisabled(props.item.id)"
                        :is-styled="true"
                        text=""
                        @action="handleAction"
                    />
                </template>
	            <template
		            v-if="windowWidth < 600"
		            v-slot:name="props"
	            >
                    <span class="active-link"
                          @click="router.push(`/drivers/${props.item.id}`)"
                    >
	                    {{ props.item.name }}
                    </span>
	            </template>
                <template
                    v-else
                    v-slot:name="props"
                >
                    <span data-testid="dListName">{{ props.item.name }}</span>
                </template>
                <template v-slot:status="props">
                    <span data-testid="dListStatus">{{ props.item.status }}</span>
                </template>
                <template v-slot:email="props">
                    <span data-testid="dListMail">{{ props.item.email }}</span>
                </template>
                <template v-slot:phone="props">
                    <span data-testid="dListPhone">{{ props.item.phone }}</span>
                </template>
                <template v-slot:vehicle="props">
                    <span data-testid="dListVehicle">{{ props.item.vehicle }}</span>
                </template>
                <template v-slot:priceProgram="props">
                    <span data-testid="dListProgram">{{ props.item.priceProgram }}</span>
                </template>
                <template v-slot:freeKWh="props">
                    <span data-testid="dListFreeKWh">
                        <template v-if="props.item.freeKWh.freekwh_amount">
                            {{
                            roundToPrecision((props.item.freeKWh.freekwh_amount - props.item.freeKWh.used_amount), 2)
                            }} / {{ roundToPrecision(props.item.freeKWh.freekwh_amount, 2) }} kWh
                        </template>
                        <template v-else>
                            <span>-</span>
                        </template>
                    </span>
                </template>
                <template v-slot:driver_detail="props">
                    <v-btn
                        class="font-weight-regular text-none"
                        color="primary"
                        @click="router.push(`/drivers/${props.item.id}`)"
                    >
                        {{ t('driverInfo.toDetail') }}
                    </v-btn>
                </template>
            </Table>
        </div>
    </main>
</div></template>