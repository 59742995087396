import moment from 'moment'

// Utils
import {apiRequest} from '@/utils/apiRequest'

// configs
import { DATE_FORMAT } from '@/configs/index.js'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }
        commit('account/resetStore', state)
        cookies.remove('mobile-token')
    },
    fetchNews: async ({commit, dispatch, state}, pageNumber) => {
        if(pageNumber === 1) commit('setLoader', 1, {root: true})
        let requestPrams = `?page=${pageNumber}&category=all`
        if (state.search && state.search.length >= 3) {
            requestPrams = `${requestPrams}&search=${state.search}`
        }

        const {data} = await apiRequest(`news${requestPrams}`)
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                if(pageNumber === 1) commit('setLoader', -1, {root: true})
            })

        let news = data.map(({title, content, published_at}) => ({
            title,
            content,
            publishedAt: moment(published_at,DATE_FORMAT).format(DATE_FORMAT),
            category: 'News'
        }))

        if (news.length === 10) {
            commit('setIsLoadMore', true)
        } else {
            commit('setIsLoadMore', false)
        }
        commit('setPage', pageNumber)
        return news
    },
    getNews: ({ commit, dispatch }) => {
        dispatch('fetchNews', 1).then(news => {
            commit('setNews', news)
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
    },
    loadMoreNews: async ({ commit, state, dispatch }) => {
        const news = await dispatch('fetchNews', state.page)
            .catch(error => {
                dispatch('checkStatus', error)
            })
        commit('pushNews', news)
    },
    getNotifications: async ({commit, dispatch}) => {
        commit('setLoader', 1, {root: true})
        await apiRequest('client/notifications').then(({data}) => {
            let notifications = data.map(({title, content, published_at, category}) => ({
                title,
                content,
                publishedAt: moment(published_at, DATE_FORMAT).format(DATE_FORMAT),
                category
            }))
            commit('setNotifications', notifications)
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
    }
}