<script setup>
import { ref, watch, onBeforeMount } from 'vue'

// composables
import useFacilitySessions from '@/composables/facilitySessions'

// components
import Table from '@/components/Table.vue'
import FacilitySessionsFilter from '@/pages/chargerManagement/components/FacilitySessionsFilter.vue'
import FacilitySessionDetail from '@/pages/chargerManagement/components/FacilitySessionDetail.vue'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

defineProps({
    isFiltersVisible: {
        type: Boolean,
        required: true
    }
})

const {
    isPickedItem,
    isCardView,
    windowWidth,
    selectedRow,
    pickedItem,
    filtersSelected,
    options,
    headers,
    sessions,
    pagination,
    getItems,
    checkChargingToRowClass,
    getDefaultFilters,
    setFilter,
    clearFilters,
    sorting,
    showDetails,
    closeDetails,
    highlightElement
} = useFacilitySessions()

const loadindData = ref(false)

watch(sessions, () => {
    pickedItem.value = null
})
watch(options, async () => {
    loadindData.value = true
    await getItems()
    loadindData.value = false
})
watch(pickedItem, () => {
    highlightElement(sessions.value.indexOf(pickedItem.value) || null)
})

onBeforeMount(() => {
    getItems()
})
</script>

<template>
    <div class="my-chargers-sessions pa-0">
        <v-row class="my-chargers-sessions__header ma-0 d-none">
            <v-col cols="12" class="px-4 px-sm-0 py-2 py-sm-0">
                <div v-if="windowWidth < 960" class="grid-switcher py-5 px-5 bg-white border-b text-center">
                    <v-btn
                        icon
                        :ripple="false"
                        color="grey"
                        variant="outlined"
                        class="active-button px-0 mr-3"
                        :class="{ active: isCardView }"
                        @click="isCardView = true"
                        elevation="1"
                    >
                        <v-icon class="active-icon"> mdi-view-grid </v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        :ripple="false"
                        color="grey"
                        variant="outlined"
                        class="active-button px-0"
                        :class="{ active: !isCardView }"
                        @click="isCardView = false"
                        elevation="1"
                    >
                        <v-icon class="active-icon"> mdi-view-sequential </v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <FacilitySessionsFilter
            v-show="isFiltersVisible"
            :filters-selected="filtersSelected"
            :default-filters="getDefaultFilters()"
            @clearFilters="clearFilters"
            @setFilter="setFilter"
            @getItems="getItems"
        />
        <div
            ref="my-chargers-sessions-table"
            :class="pickedItem ? 'facility-sessions__table-container-extended' : 'facility-sessions__table-container'"
        >
            <Table
                class="my-chargers-sessions__table"
                :card-view="isCardView"
                :headers="headers"
                :items="sessions"
                :item-class="checkChargingToRowClass"
                :data-loading="loadindData"
                :sort-column-name="options.sortBy[0]"
                :is-descending="options.sortDesc ? options.sortDesc : true"
                :class="{ 'table-view-mobile-size': !isCardView }"
                :pagination="pagination"
                :selected-row="selectedRow"
                :server-items="pagination.itemsLength"
                @options="(value) => (options = value)"
                @sorting="sorting"
            >
                <template v-slot:connected_to="props">
                    <v-progress-linear
                        v-if="!props.item.charging_to"
                        :rounded="true"
                        :height="7"
                        indeterminate
                        color="primary"
                    />
                    <template v-else>
                        {{ props.item.charging_to }}
                    </template>
                </template>
                <template v-slot:total_minutes="props">
                    <template v-if="!props.item.charging_to">
                        <span class="text-primary">{{ t('driverInfo.InProgress') }}</span>
                    </template>
                    <template v-else>
                        {{ props.item.total_minutes }}
                    </template>
                </template>
                <template v-slot:total_kwh="props">
                    <template v-if="!props.item.charging_to">
                        <span>-</span>
                    </template>
                    <template v-else>
                        {{ props.item.total_kwh }}
                    </template>
                </template>
                <template v-slot:detail="props">
                    <v-btn icon="mdi-magnify" variant="text" @click="showDetails(props.item)" color="primary" />
                </template>
            </Table>
        </div>
        <v-navigation-drawer
            v-model="isPickedItem"
            class="my-chargers-sessions__drawer"
            location="right"
            :temporary="true"
            :width="windowWidth < 960 ? '100%' : 550"
            @update:model-value="closeDetails"
        >
            <div class="my-chargers-sessions__side" :class="{ 'pa-5 mt-10': isPickedItem }">
                <FacilitySessionDetail v-if="isPickedItem" :session="pickedItem" @close="closeDetails" />
            </div>
        </v-navigation-drawer>
    </div>
</template>
