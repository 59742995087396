<script setup>
import store from '@/store'
import {computed} from 'vue'

// components
import Driver from './componentTypes/Driver.vue'
import Modal from '@/components/modals/Modal.vue'
import TextComponent from './componentTypes/TextComponent.vue'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const emit = defineEmits(['ok', 'cancel'])

const props = defineProps({
    active: {
        type: Boolean,
        required: true
    },
    data: {
        required: true
    }
})

const isButtonEnabled = computed(() => {
    return store.getters['coupons/isButtonEnabled']
})

const couponItemTypes = {
    driver: 'driver',
    text: 'text'
}

const getItemType = (type) => {
    switch (type) {
    case couponItemTypes.driver:
        return Driver
    case couponItemTypes.text:
    default:
        return TextComponent
    }
}
</script>

<template>
    <Modal :is-active="active"
           :max-width="650"
           @cancel="emit('cancel', !active)"
           @ok="emit('ok', !active)"
    >
        <div class="add-coupon-modal pa-5">
            <v-row class="title-wrapper">
                <v-col cols="12"
                       class="text-center"
                >
                    <h3>
                        {{ t('coupons.applyCouponTitle') }}
                    </h3>
                </v-col>
            </v-row>
            <div class="body-wrapper">
                <div v-for="(item, index) in props.data"
                     :key="`${index}-${item.name}`"
                >
                    <component
                        :is="getItemType(item.type)"
                        :id="item.name"
                        :key="`${index}-${item.name}`"
                        :data="item"
                    />
                </div>
            </div>
            <v-row class="footer-wrapper">
                <v-col cols="6">
                    <v-btn
                        block
                        color="grey"
                        variant="outlined"
                        @click="emit('cancel')"
                    >
                        {{ t('coupons.cancel') }}
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn
                        block
                        color="primary"
                        variant="outlined"
                        :disabled="!isButtonEnabled"
                        @click="emit('ok')"
                    >
                        {{ t('coupons.ok') }}
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </Modal>
</template>