// https://8a754bc1-d5fc-4f52-b4e9-bb7f3f91aab9.mock.pstmn.io/client/invoices/spending
import { ref, computed, onBeforeMount } from 'vue'
import { apiRequest } from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessageArray'
import moment from 'moment'
import router from '@/router'

// configs
import { DATE_FORMAT } from '@/configs/index.js'

export default function useSpending() {

    const fetchingData = ref(false)
    const billingFrom = ref(null)
    const billingTo = ref(null)
    const tableItems = ref([])
    const total = ref(null)
    const currency = ref(import.meta.env.VITE_APP_I18N_CURRENCY)

    const hasData = computed(() => {
        return !!(billingFrom.value && billingTo.value && tableItems.value.length && total)
    })

    const init = () => {
        onBeforeMount(async () => {
            fetchingData.value = true
            try {
                const { data } = await apiRequest('/client/invoices/spending')
                billingFrom.value = data.billing_from
                billingTo.value = data.billing_to
                tableItems.value = data.items
                total.value = data.total
                if (data.hasOwnProperty('currency')) currency.value = data.currency
            } catch (error) {
                showMessage(error.errors)
            } finally {
                fetchingData.value = false
            }
        })
    }

    const goToListOfSessions = () => {
        const from = moment(billingFrom.value).startOf('month').format(DATE_FORMAT)
        const to = moment(billingTo.value).endOf('month').format(DATE_FORMAT)
        router.push(`/sessions?from=${from}&to=${to}`)
    }

    return {
        fetchingData,
        billingFrom,
        billingTo,
        tableItems,
        total,
        currency,
        hasData,
        init,
        goToListOfSessions
    }
}