<script setup>
import {useStore} from 'vuex'
import {computed, onMounted, reactive} from 'vue'

// enums
import {Roles} from '@/configs/index.js'

// components
import DashboardLoader from '@/loaders/DashboardLoader.vue'
import DashboardView from '@/pages/dashboard/components/DashboardView.vue'

// composables
import useWindowWidth from '@/composables/windowWidth.js'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const {
    windowWidth
} = useWindowWidth()

const store = useStore()
const {getters} = store

const state = reactive({
    tab: null
})

const roles = computed(() => {
	return store.state.account.roles
})

const isFleetHomeChargingManager = computed(() => {
	return roles.value.includes(Roles.fleetHomeChargingManager)
})

const isLoading = computed(() => {
    return getters['isLoading']
})

const dashboardData = computed(() => {
    return store.state.dashboard[state.tab]
})

const dashboardDataFacility = computed(() => {
    return store.state.dashboard.facility
})

const dashboardDataHome = computed(() => {
    return store.state.dashboard.home
})

const dashboardDataAll = computed(() => {
    return store.state.dashboard.all
})

const statuses = computed(() => {
    const data = store.state.dashboard[state.tab].statuses
    return [
        {title: t('dashboard.AvailableConnectors'), value: data.availableConnectors},
        {title: t('dashboard.OccupiedConnectors'), value: data.occupiedConnectors},
        {title: t('dashboard.ConnectorsOutOfOperation'), value: data.outOfOperationConnectors},
        {title: t('dashboard.ConnectorsInFault'), value: data.inFaultConnectors},
        {title: t('dashboard.ConnectorsOutOfCommunication'), value: data.outOfCommunicationConnectors}
    ]
})

onMounted(async () => {
	if(isFleetHomeChargingManager) {
		await store.dispatch('dashboard/getData', {
			chargingCategory: 'facility'
		})

		await store.dispatch('dashboard/getData', {
			chargingCategory: 'home'
		})
	}

    await store.dispatch('dashboard/getData', {
        chargingCategory: 'all'
    })
})

</script>

<template>
    <div class="dashboard">
        <h1 class="dashboard__title mb-9">
            {{ t('dashboard.Dashboard') }}
        </h1>
        <v-container fluid>
            <v-row v-if="isLoading">
                <v-col cols="12" md="6">
                    <DashboardLoader/>
                </v-col>
                <v-col cols="12" md="6">
                    <DashboardLoader/>
                </v-col>
                <v-col cols="12" md="6">
                    <DashboardLoader/>
                </v-col>
                <v-col cols="12" md="6">
                    <DashboardLoader/>
                </v-col>
                <v-col cols="12" md="6">
                    <DashboardLoader/>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" class="px-0">
                    <v-tabs
                        class="tabs"
                        v-model="state.tab"
                        bg-color="primary"
                        align-tabs="center"
                        :show-arrows="windowWidth < 960"
                    >
	                    <template v-if="isFleetHomeChargingManager">
		                    <v-tab value="facility">
			                    {{ t('dashboard.facility') }}
		                    </v-tab>
		                    <v-tab value="home">
			                    {{ t('dashboard.home') }}
		                    </v-tab>
	                    </template>

                        <v-tab value="all">
                            {{ t('dashboard.all') }}
                        </v-tab>
                    </v-tabs>
                </v-col>

                <v-window v-model="state.tab"
                          class="w-100"
                          :touch="false"
                >
	                <template v-if="isFleetHomeChargingManager">
		                <v-window-item value="facility">
			                <DashboardView :data="dashboardDataFacility" />
		                </v-window-item>

		                <v-window-item value="home">
			                <DashboardView :data="dashboardDataHome" />
		                </v-window-item>
	                </template>

                    <v-window-item value="all">
                        <DashboardView :data="dashboardDataAll" />
                    </v-window-item>
                </v-window>
            </v-row>
        </v-container>
    </div>
</template>
