<script setup>
import {useStore} from 'vuex'

// components
import Btn from '@/components/inputs/Btn.vue'

// utils
import isObjEqual from '@/utils/isObjEqual'
import {computed, reactive} from 'vue'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const emit = defineEmits(['clearFilters', 'setFilter', 'getItems'])

const store = useStore()
const {getters} = store

const props = defineProps({
    filtersSelected: {
        required: false
    },
    defaultFilters: {
        required: false
    }
})

const state = reactive({
    searchInputCode: '',
    searchInputChargerCode: '',
    searchInputLocationCode: '',
    searchInputLocationName: ''
})

const filters = computed(() => {
    return getters['connectors/filters']
})

const locationFilters = computed(() => {
    return getters['locations/filters']
})

const chargerFilters = computed(() => {
    return getters['chargers/filters']
})

const showClear = computed(() => {
    return !isObjEqual(props.filtersSelected, props.defaultFilters)
})

const debounce = (func, delay) => {
    let timerId
    return function(...args) {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            func.apply(this, args) // Call the debounced function with arguments
        }, delay)
    }
}

const setFilter = debounce(function(field, value) {
    emit('setFilter', { field, value })
    emit('getItems')
}, 1500)

const clearFilters = () => {
    emit('clearFilters')
}

const dateFromTo = ({dateFrom: from, dateTo: to, isClear}) => {
    setFilter('date', {
        from: from || null,
        to:  to || null
    })
    state.isClear = isClear
}

</script>

<template>
    <v-container fluid class="connectors__filters">
        <v-row v-if="filters"
               class="pt-10 px-4 pb-4"
        >
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-select 
                    v-model="filtersSelected.type"
                    :items="filters.filter_by_type"
                    item-value="value"
                    item-title="name"
                    multiple
                    clearable
                    density="compact"
                    variant="outlined"
                    :placeholder="t('connectors.ConnectorType')"
                    @update:model-value="setFilter('type', $event)"
                />
            </v-col>
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-select 
                    v-model="filtersSelected.status"
                    :items="filters.filter_by_status"
                    item-value="value"
                    item-title="name"
                    multiple
                    clearable
                    density="compact"
                    variant="outlined"
                    :placeholder="t('connectors.ConnectorStatus')"
                    @update:model-value="setFilter('status', $event)"
                />
            </v-col>
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-autocomplete 
                    v-model="filtersSelected.location.name"
                    :items="locationFilters ? locationFilters.filter_by_name : []"
                    item-value="value"
                    item-title="name"
                    multiple
                    clearable
                    chips
                    density="compact"
                    variant="outlined"
                    autocomplete="off"
                    :label="t('locations.Location')"
                    @update:model-value="setFilter('location', {name: $event})"
                />
            </v-col>
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-autocomplete 
                    v-model="filtersSelected.code"
                    :items="filters.filter_by_code"
                    item-value="value"
                    item-title="name"
                    multiple
                    clearable
                    chips
                    density="compact"
                    variant="outlined"
                    :label="t('chargerDetails.ConnectorCode')"
                    @update:model-value="setFilter('code', $event)"
                />
            </v-col>
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-autocomplete 
                    v-model="filtersSelected.charger.code"
                    :items="chargerFilters.filter_by_code"
                    item-value="value"
                    item-title="name"
                    multiple
                    clearable
                    chips
                    density="compact"
                    variant="outlined"
                    :label="t('chargers.ChargerCode')"
                    @update:model-value="setFilter('charger', {code: $event})"
                />
            </v-col>
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-autocomplete
                    v-model="filtersSelected.location.code"
                    :items="locationFilters.filter_by_code"
                    item-value="value"
                    item-title="name"
                    multiple
                    clearable
                    chips
                    density="compact"
                    variant="outlined"
                    :label="t('locations.LocationCode')"
                    @update:model-value="setFilter('location', {code: $event})"
                />
            </v-col>
            <v-col 
                v-if="showClear"
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <Btn text color="primary" @click="emit('clearFilters')">
                    <v-icon class="clear-icon pr-1">
                        mdi-delete
                    </v-icon>{{ t('filter.ClearAll') }}
                </Btn>
            </v-col>
        </v-row>
    </v-container>
</template>
