<script setup>
import { mdiInformationOutline } from '@mdi/js'
</script>


<template>
    <div class="info-panel text-subtitle-2 d-flex text-blue bg-light-blue-lighten-4 pa-4">
        <v-icon class="mr-1 info-message__icon text-blue">
            mdi-information-outline
        </v-icon>
        <slot />
    </div>
</template>
