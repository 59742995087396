<script setup>
import {computed, onBeforeMount, onMounted, reactive} from 'vue'
import store from '@/store'
import {mapState} from 'vuex'

// components
import BtnGoBack from '@/components/inputs/BtnGoBack.vue'
import LocationSessions from './components/LocationSessions.vue'
import FilterByToggle from '@/components/inputs/FilterByToggle.vue'
import LocationPublicInfo from './components/LocationPublicInfo.vue'
import LocationGoogleMaps from './components/LocationGoogleMaps.vue'
import LocationDetailsInfo from './components/LocationDetailsInfo.vue'

// composables
import useWindowWidth from '@/composables/windowWidth'

// utils
import isEmptyObj from '@/utils/isEmptyObj'

// translations
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const {dispatch, getters, commit} = store

const props = defineProps({
    id: {
        type: String,
        required: true
    }
})


const {
    windowWidth,
    setWindowWidth
} = useWindowWidth()


const state = reactive({
    googleApiKey: import.meta.env.VITE_APP_GOOGLE_MAPS_API || null,
    isFiltersVisible: false,
    tab: null,
    fetchingFilters: false
})

const isLoading = computed(() => store.state.loader)

const location = computed(() => {
    return getters['locations/location']
})

const tabs = computed(() => {
    return [
        {
            content: 'LocationChargers',
            title: t('chargerManagement.Chargers')
        },
        {
            content: 'LocationConnectors',
            title: t('chargerManagement.Connectors')
        }
    ]
})


const isEmptyObject = (obj) => {
    return isEmptyObj(obj)
}

onBeforeMount(() => {
    commit('locations/setLocation', {})
})

onMounted(async () => {
    state.fetchingFilters = true
    await dispatch('locations/getLocation', props.id)
    await dispatch('chargers/getFilters', {location_id: props.id})
    await dispatch('connectors/getFilters', {location_id: props.id})
    await dispatch('facilitySession/getFilters')
    state.fetchingFilters = false
})

</script>

<script>
import LocationChargers from './components/LocationChargers.vue'
import LocationConnectors from './components/LocationConnectors.vue'

export default {
    components: {
        LocationChargers,
        LocationConnectors
    }
}
</script>

<template>
    <div class="location-details-wrapper">
        <v-row class="d-md-none">
            <v-col cols="3" md="6">
                <BtnGoBack/>
            </v-col>
            <v-col :class="windowWidth < 960 ? '' : 'text-right'" cols="12" md="6">
                <header class="location__header">
                    <div class="locations__header-head">
                        <h2 class="location__title mb-5">
                            {{ t('locationDetails.LocationDetail') }}
                        </h2>
                    </div>
                </header>
            </v-col>
        </v-row>
        <header class="location__header d-none d-md-block py-5">
            <div class="locations__header-head">
                <h1 class="home-page-title mb-5">
                    {{ t('locationDetails.LocationDetail') }}
                </h1>
            </div>
        </header>
        <section class="section location-details-holder mb-5">
            <v-row>
                <v-col cols="12">
                    <h1 :class="isEmptyObject(location) ? 'adjust-margin' : ''"
                        class="section-title mb-5"
                    >
                        {{ t('locationDetails.SystemLocationInformation') }}
                    </h1>
                </v-col>

                <v-col :class="{'pa-0':isEmptyObject(location)}" class="py-0" cols="12" md="8">
                    <LocationDetailsInfo class="bg-white border-b" :location="location"/>
                    <LocationPublicInfo class="bg-white" :location="location"/>
                </v-col>
                <v-col class="py-0 pl-0" cols="12" md="4">
                    <LocationGoogleMaps 
                        v-if="!isEmptyObject(location) && state.googleApiKey"
                        :api-key="state.googleApiKey"
                        :location="location"
                    />
                    <div v-else class="google-maps-placeholder">
                        <h6>Google Maps</h6>
                    </div>
                </v-col>
            </v-row>
        </section>
        <section class="section mt-5">
            <div class="location-details-wrapper__filters-options d-flex justify-space-between">
                <v-tabs
                    v-model="state.tab"
                    background-color="transparent"
                    class="location__tabs"
                >
                    <v-tab
                        v-for="({ title }) in tabs"
                        :key="title"
                    >
                        {{ title }}
                    </v-tab>
                </v-tabs>
                <FilterByToggle
                    :active="state.isFiltersVisible"
                    :isDisabled="state.fetchingFilters"
                    @change="state.isFiltersVisible = !state.isFiltersVisible"
                />
            </div>
            <div class="location__tabs-items">
                <v-window v-model="state.tab"
                          :touch="false"
                >
                    <v-window-item v-for="item in tabs" :key="item.title">
                        <component
                            :is="item.content"
                            :is-filters-visible="state.isFiltersVisible"
                            :location-id="id"
                            :window-width="windowWidth"
                        />
                    </v-window-item>
                </v-window>
            </div>
        </section>
        <section class="section mt-10">
            <LocationSessions :location-id="id"/>
        </section>
    </div>
</template>