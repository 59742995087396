<script setup>
const route = useRoute()
import { useStore } from 'vuex'
import router from '@/router'
import { ref, reactive, computed, onBeforeMount, toRef, nextTick } from 'vue'

// components
import Btn from '@/components/inputs/Btn.vue'
import QrcodeModal from '@/components/modals/QrcodeModal.vue'
import AddCouponModal from '@/pages/coupons/components/AddCouponModal.vue'

// utils
import showMessage from '@/utils/errorMessage'
import errorMessageArray from '@/utils/errorMessageArray'
import { useRoute } from 'vue-router'

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty } = useValidations()

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const { dispatch, getters, commit } = store

const coupon = ref()
const state = reactive({
    coupon: {
        code: null
    },
    qrCode: '',
    isNotEmpty,
    isPopupActive: false,
    activeCouponModal: false,
    data: null
})

const isLoading = computed(() => getters['isLoading'])

const cancelAddCouponModal = () => {
    state.activeCouponModal = false
    commit('coupons/updateFieldValue', {
        field: 'addCouponData',
        value: null
    })
}
const registerCoupon = async () => {
    if (!state.coupon.code || state.coupon.code.trim().length === 0) {
        return
    }
    await dispatch('coupons/registerCoupon', {
        code: state.coupon.code
    }).then(() => {
        showMessage(t('coupons.success'), 'success')
        cancelAddCouponModal()
        state.isNotEmpty = true
        state.coupon.code = null
        dispatch('freeKwhPackages/getFreeKWhPerDriver')
    }).catch(error => {
        if (typeof error.data !== 'undefined') {
            let newData = {}
            error.data.forEach(el => {
                if (el.type === 'driver') {
                    newData[el.name] = null
                }
                if (el.type === 'text' && el.required) {
                    newData[el.name] = null
                }
            })
            commit('coupons/updateFieldValue', {
                field: 'addCouponData',
                value: newData
            })

            state.data = error.data
            state.activeCouponModal = true

        } else {
            if (Array.isArray(error.errors)) {
                errorMessageArray(error.errors)
            } else {
                showMessage(error)
            }
        }
    })
}
const scanned = async (code) => {
    state.isPopupActive = false
    state.coupon.code = code

    nextTick(() => {
        coupon.value.focus()
    })

    showMessage(t('coupons.successScan'), 'success')
}

const updateCouponCode = (value) => {
  state.coupon.code = value
}

onBeforeMount(() => {
    if (route.query?.coupon) {
        state.coupon.code = route.query.coupon
    }
})
</script>
<template>
    <div class="coupons-form-wrapper">
        <v-card class="coupon-register">
            <v-card-text>
                <v-text-field
                    ref="coupon"
                    :value="state.coupon.code"
                    density="compact"
                    variant="outlined"
                    clearable
                    :rules="[state.isNotEmpty]"
                    :placeholder="t('coupons.code')"
                    @keyup.enter.prevent="registerCoupon"
                    @update:model-value="updateCouponCode"
                />
                <QrcodeModal
                    v-if="state.isPopupActive"
                    :active="state.isPopupActive"
                    @QrScanData="state.qrCode"
                    @scanned="scanned"
                    @cancel="state.isPopupActive = false"
                />
                <template v-if="isLoading || state.coupon.code === null || state.coupon.code.trim().length === 0">
                    <Btn
                        large
                        :disabled="true"
                    >
                        {{ t('coupons.apply') }}
                    </Btn>
                </template>
                <template v-else>
                    <Btn
                        large
                        @click="registerCoupon"
                    >
                        {{ t('coupons.apply') }}
                    </Btn>
                </template>

                <Btn
                    color="normal"
                    large
                    @click="state.isPopupActive = true"
                >
                    {{ t('coupons.scan') }}
                </Btn>
            </v-card-text>
        </v-card>
        <AddCouponModal
            v-if="state.activeCouponModal"
            :active="state.activeCouponModal"
            :data="state.data"
            @cancel="cancelAddCouponModal"
            @ok="registerCoupon"
        />
    </div>
</template>