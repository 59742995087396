<script setup>
import moment from 'moment'
import { computed } from 'vue'

import store from '@/store'
const { state, getters } = store

// Composables
import useSpending from '@/composables/spending/useSpending'

// utils
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const {
    init,
    goToListOfSessions,
    fetchingData,
    billingFrom,
    billingTo,
    tableItems,
    total,
    currency,
    hasData
} = useSpending()

init()

const tableHeaders = computed(() => {
    return getters['invoices/spendingTableHeaders']
})
const getPaymentOption = computed(() => {
    switch (getters['account/paymentInfo'].provider) {
		case 'payout':
            return t('invoices.PayoutOption')
        case 'braintree':
            return t('invoices.BrainTreeOption')
		default:
			return t('invoices.BankTransferOption')
		}
})
</script>

<template>
    <div class="spending px-8 py-6">
        <div
            v-if="hasData"
            class="period text-caption d-flex align-center py-2 px-4"
        >
            <v-icon
                color="var(--v-info-base)"
                class="mr-2"
            >
                mdi-information-outline
            </v-icon>
            {{ t('invoices.period', {
                from: moment(billingFrom).format('D.M.YYYY'), to: moment(billingTo).format('D.M.YYYY')
            }) }}
        </div>
        <v-data-table
            :loading="fetchingData"
            :headers="tableHeaders"
            :items="tableItems"
            class="table mb-4"
        >
            <template v-slot:[`item.name`]="{ item }">
                <span
                    v-if="item.name === 'invoices.ChargingServices'"
                    class="link"
                    @click="goToListOfSessions"
                >{{ t(`${item.name}`) }}</span>
                <span v-else>{{ item.name }}</span>
            </template>
            <template v-slot:[`item.price_vat_excl`]="{ item }">
                <span>{{ item.price_vat_excl }} {{ currency }}</span>
            </template>
            <template v-slot:[`item.vat_amount`]="{ item }">
                <span>{{ item.vat_amount }} {{ currency }}</span>
            </template>
            <template v-slot:[`item.price_vat_incl`]="{ item }">
                <span>{{ item.price_vat_incl }} {{ currency }}</span>
            </template>
        </v-data-table>
        <v-row>
            <v-col>
                <v-card
                    elevation="1"
                    class="invoicing-data py-2 px-4"
                >
                    <div class="heading d-flex align-center justify-space-between mb-2">
                        <h6 class="text-h6">
                            {{ t('invoices.InvoicingData') }}
                        </h6>
                        <div class="d-flex align-center">
                            <span class="text-caption d-none d-sm-block">
                                {{ t('invoices.EditIn') }}
                            </span>
                            <router-link
                                to="/account"
                                class="text-decoration-none"
                            >
                                <v-btn
                                    variant="text"
                                    color="rgb(var(--v-theme-primary))"
                                    class="px-1"
                                >
                                    <v-icon>mdi-cog</v-icon>
                                    {{ t('invoices.settings') }}
                                </v-btn>
                            </router-link>
                        </div>
                    </div>
                    <div class="content">
                        <div class="company-data text-body-1">
                            <div class="mb-2">
                                <div>
                                    {{ t('invoices.CompanyName') }}:
                                </div>
                                <div>
                                    {{ state.account.companyName }}
                                </div>
                            </div>
                            <div class="mb-2">
                                <div>
                                    {{ t('invoices.BusinessId') }}:
                                </div>
                                <div>
                                    {{ state.account.businessId }}
                                </div>
                            </div>
                            <div class="mb-2">
                                <div>
                                    {{ t('invoices.TaxId') }}:
                                </div>
                                <div>
                                    {{ state.account.taxId }}
                                </div>
                            </div>
                            <div class="mb-4">
                                <div>
                                    {{ t('invoices.Address') }}:
                                </div>
                                <div>
                                    {{ state.account.city }}<br>
                                    {{ state.account.street }} {{ state.account.houseNumber }}<br>
                                    {{ state.account.zipCode }}
                                </div>
                            </div>
                            <div  v-if="getters['account/paymentInfo'].provider">
                                <div>
                                    {{ t('invoices.PaymentMethod') }}:
                                </div>
                                <div>
                                    {{ getPaymentOption }}
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-col>
            <v-col>
                <div
                    v-if="hasData"
                    class="summary py-2 px-4 ml-auto"
                >
                    <div class="d-flex align-center justify-space-between mb-2">
                        <h6 class="text-h6">
                            {{ t('invoices.FullVatPrice') }}
                        </h6>
                        <h6 class="text-h6">
                            {{ total.price_vat_incl }} {{ currency }}
                        </h6>
                    </div>
                    <div class="d-flex align-center justify-space-between mb-1">
                        <h6 class="text-body-2">
                            {{ t('invoices.FullPriceWithoutVat') }}
                        </h6>
                        <h6 class="text-body-2">
                            {{ total.price_vat_excl }} {{ currency }}
                        </h6>
                    </div>
                    <div class="d-flex align-center justify-space-between">
                        <h6 class="text-body-2">
                            {{ t('invoices.VatAmount') }}
                        </h6>
                        <h6 class="text-body-2">
                            {{ total.total_vat_amount }} {{ currency }}
                        </h6>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>