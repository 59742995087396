<script setup>
import {computed, onBeforeUnmount, onMounted, reactive} from 'vue'
import {useStore} from 'vuex'

// components
import Modal from '@/components/modals/Modal.vue'
import SearchField from '@/components/SearchField.vue'
import ChargingFormList from '@/components/chargingForm/ChargingFormList.vue'

// utils
import {apiRequest} from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessage'

// translations
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const store = useStore()
const {commit, getters} = store

const emit = defineEmits(['close'])

defineProps({
    driver: {
        type: Number,
        default: null
    },
    charger: {
        type: Object,
        default: null
    },
    isActive: {
        type: Boolean,
        default: true
    }
})

const state = reactive({
    connectors: null,
    search: '',
    selectedConnector: null
})

const isLoading = computed(() => {
    return !!store.state.loader
})

const isChargingEventTriggered = computed(() => {
    return getters['connectors/isChargingEventTriggered']
})


const filteredList = computed(() => {
    if (!state.search) {
        return state.connectors
    }
    return state.connectors.filter(it => it['EtrelFriendlyCode'].toLowerCase().includes(search.toLowerCase()))
})


const closeDialog = () => {
    emit('close')
}

const setValue = ({value, field}) => {
    state[field] = value
    state.selectedConnector = value
}

const getConnectors = async () => {
    commit('setLoader', +1)
    try {
        const {data} = await apiRequest('charging/public/connectors-for-charger', {
            params: {device_id: charger.id, status: 'occupied'}
        })
        state.connectors = data
    } catch (error) {
        showMessage(error)
    } finally {
        commit('setLoader', -1)
    }
}

const stop = async () => {
    try {
        commit('connectors/setIsChargingEventTriggered', true)
        await apiRequest('charging/stop', {
            params: {connector_id: state.selectedConnector.id}
        })
        showMessage(t('chargingForm.ChargingHasBeenStopped'), 'success')
    } catch (error) {
        showMessage(error)
    } finally {
        emit('close')
        commit('connectors/setIsChargingEventTriggered', false)
    }
}

onBeforeUnmount(() => {
    emit('close')
})

onMounted(() => {
    getConnectors()
})

</script>

<template>
    <Modal
        :class-item="[isLoading ? '' : '']"
        :is-active="isActive"
        :max-width="530"
        @cancel="closeDialog()"
        class="stop-charging-form-wrapper"
    >
        <a class="close-button" @click="closeDialog()">
            <v-icon class="active-icon">mdi-close</v-icon>
        </a>
        <v-card class="pa-7 stop-charging-card elevation-0">
            <v-card-title>
                {{ t('chargerManagement.stopCharging') }}
            </v-card-title>
            <SearchField
                v-model="search"
            />
            <v-divider class="mx-4"/>
            <v-card-text class="card_text">
                <v-progress-linear
                    v-if="isLoading"
                    :height="7"
                    color="primary"
                    indeterminate
                />
                <ChargingFormList
                    v-if="!isLoading"
                    :id="'id'"
                    :field="'connector'"
                    :label="'EtrelFriendlyCode'"
                    :list="filteredList"
                    form-type="stopCharging"
                    @change="setValue"
                />
            </v-card-text>
            <v-divider class="mx-4 mt-4"/>
            <v-card-actions class="d-flex justify-end px-4 pt-9 pb-2">
                <v-btn :disabled="isChargingEventTriggered"
                       class="ml-auto mr-2 px-4"
                       color="primary"
                       large
                       text
                       @click="emit('close')"
                >
                    {{ t('chargingForm.cancel') }}
                </v-btn>
                <v-btn
                    :disabled="isChargingEventTriggered || !!!state.selectedConnector"
                    :loading="isChargingEventTriggered"
                    class="px-8"
                    color="primary"
                    large
                    @click="stop()"
                >
                    {{ t('chargingForm.stop') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </Modal>
</template>