import { useCookies } from 'vue3-cookies'

export const getDefaultState = () => {
    const { cookies } = useCookies()
    return {
        id: null,
        firstName: null,
        lastName: null,
        phone: null,
        isPhoneVerified: false,
        email: null,
        isEmailVerified: false,
        birthDate: null,
        gender: null,
        isCompany: 0,
        blockedChargingAt: null,
        companyName: null,
        businessId: null,
        isSelfEmployed: false,
        country: null,
        token: null,
        locale: null,
        lang: null,
        communicationLanguage: null,
        roles: null,
        driver: {},
        primaryDriverId: null,
        taxId: null,
        vatId: null,
        zipCode: null,
        street: null,
        city: null,
        houseNumber: null,
        actionAfterLogin: null,
        registrationSteps: [],
        braintreePayload: null,
        deliveryAddress: null,
        paymentInfo: {
            token: null,
            number: null,
            provider: null
        },
        remember_me: false,
        isCookieAccepted: cookies.get('isCookieAccepted') !== null ? cookies.get('isCookieAccepted') : false,
        isNecessaryCookieAccepted:
            cookies.get('isNecessaryCookieAccepted') !== null ? cookies.get('isNecessaryCookieAccepted') : true,
        isStatisticsCookieAccepted:
            cookies.get('isStatisticsCookieAccepted') !== null ? cookies.get('isStatisticsCookieAccepted') : true,
        revocableConsents: [],
        payerConsents: [],
        personalDataIsEditView: false,
        isEUMemberState: false,
        notifyAfterSession: true,
        drivers_count: null,
        paymentGatewayProvider: null,
        showExtraPaymentModal: false
    }
}
