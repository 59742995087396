<script setup>
import { ref, watch } from 'vue'

// composables
import useChargingCards from '@/composables/chargingCards/useChargingCards'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()


const {
    orderChargingCardDialogVisible: dialog,
    numberOfChargingCardsToOrder,
    orderChargingCards,
    chargingCardsOrdersLimit,
    chargingCardsOrdersRemaining,
    fetchingData,
    windowWidth
} = useChargingCards()

const isValid = ref(false)
const form = ref(null)

const isInRange = (v) => {
    if (v) {
        if (v <= chargingCardsOrdersLimit.value && v >= 1) {
            isValid.value = true
            return true
        }
        if (v > chargingCardsOrdersLimit.value) {
            isValid.value = false
            return t('chargingCards.ordersRemaining', { orderAmount: chargingCardsOrdersRemaining.value })
        }
        if (v < 1) {
            isValid.value = false
            return t('chargingCards.minCards')
        }
    }
    return false
}

const remainingCardsToOrder = (v) => {
    if (v) {
        if (chargingCardsOrdersRemaining.value === 0) {
            isValid.value = false
            return t('chargingCards.maxNumberOfOrdersReached')
        }
        if (v > chargingCardsOrdersRemaining.value) {
            isValid.value = false
            return t('chargingCards.ordersRemaining', { orderAmount: chargingCardsOrdersRemaining.value })
        }
    }
    return true
}

const rule = ref([
    isInRange,
    remainingCardsToOrder
])

watch(dialog, (val) => {
    if (!val) {
        numberOfChargingCardsToOrder.value = null
        form.value.resetValidation()
    }
})

</script>

<template>
    <div class="text-center order-cards">
        <v-dialog v-model="dialog" width="375">
            <template v-slot:activator="{ props }">
                <template v-if="windowWidth > 959">
                    <v-btn class="white--text w-100" color="primary" elevation="2" v-bind="props" x-large>
                        {{ t('chargingCards.orderNewCard') }}
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn :ripple="false" icon v-bind="props">
                        <v-icon>
                            mdi-cart
                        </v-icon>
                    </v-btn>
                </template>
            </template>
            <v-card class="order-cards-modal pt-4 px-4 pb-8">
                <v-card-title class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4">
                    {{ t('chargingCards.orderNewCardTitle') }}
                </v-card-title>
                <v-card-text class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center">
                    <v-form ref="form">
                        <v-text-field 
                            v-model="numberOfChargingCardsToOrder"
                            :label="t('chargingCards.numberOfChargingCards')" :rules="rule" variant="outlined"
                            type="number" />
                    </v-form>
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center">
                    <v-btn color="primary" text x-large @click="dialog = false">
                        {{ t('chargingCards.cancel') }}
                    </v-btn>
                    <v-btn :disabled="numberOfChargingCardsToOrder === null || fetchingData || !isValid"
                        :loading="fetchingData" class="ml-4 ml-sm-8" color="primary" x-large @click="orderChargingCards">
                        {{ t('chargingCards.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
