<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

// configs
import { Locale } from '@/configs/index.js'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const locale = computed(() => store.state.account.locale)

const requestCardHasError = computed({
    get() {
        return store.state.eshop.requestCardHasError
    },
    set(value) {
        store.commit('eshop/setFieldValue', {
            field: 'requestCardHasError',
            value
        })
    }
})

const hasError = computed(() => {
    return requestCardHasError.value ? t('error.FieldIsRequired') : null
})

const requestCard = computed({
    get() {
        return store.state.eshop.requestCard
    },
    set(value) {
        store.commit('eshop/setFieldValue', {
            field: 'requestCard',
            value
        })
        requestCardHasError.value = false
    }
})

const getTranslationOrder = () => {
    switch (locale.value.trim().toLowerCase()) {
        case Locale.sk:
            return t('orderCard.requestDescriptionSK')
        case Locale.pl:
            return t('orderCard.requestDescriptionPL')
        default:
            return t('orderCard.requestDescriptionAddDriver')
    }
}

const getTranslation = () => {
    switch (locale.value.trim().toLowerCase()) {
        case Locale.sk:
            return t('orderCard.allINeedSK')
        case Locale.pl:
            return t('orderCard.allINeedPL')
        default:
            return t('orderCard.allINeed')
    }
}
</script>

<template>
    <v-row class="rfid-card-request-wrapper">
        <v-col class="px-8" cols="12">
            <v-radio-group v-model="requestCard" :error-messages="hasError" column>
                <v-radio :label="getTranslationOrder()" :value="true" data-testid="rfidCardRadio" />
                <v-radio :label="getTranslation()" :value="false" data-testid="mobileAppRadio" />
            </v-radio-group>
        </v-col>
    </v-row>
</template>