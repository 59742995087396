export const ROLES = {
    driver: {
        dashboard: {
            read: false,
            write: false,
            delete: false
        },
        news: {
            read: true,
            write: false,
            delete: false
        },
        sessions: {
            read: true,
            write: false,
            delete: false
        },
        driverDetail: {
            read: true,
            write: false,
            delete: false
        },
        driverZone: {
            read: true,
            write: false,
            delete: false
        },
        account: {
            read: true
        },
        help: {
            read: true,
            write: false,
            delete: false
        },
        coupons: {
            read: true,
            write: false,
            delete: false
        },
        invoices: {
            read: false,
            write: false,
            delete: false
        },
        accountSettings: {
            read: false,
            write: false,
            delete: false
        },
        blockedAccountWarning: {
            read: true,
            write: false,
            delete: false
        },
        chargingCards: {
            read: false,
            write: false,
            delete: false
        },
        driverDetails: {
            read: true,
            write: false,
            delete: false
        },
        drivers: {
            read: false,
            write: false,
            delete: false
        },
        'add-driver': {
            read: false,
            write: false,
            delete: false
        },
        chargerManagement: {
            read: true,
            write: false,
            delete: false
        },
        charging: {
            read: true
        },
        locationMap: {
            read: true
        },
        pageNotFound: {
            read: true
        }
    },
    payer: {
        news: {
            read: true,
            write: true,
            delete: true
        },
        sessions: {
            read: true,
            write: true,
            delete: true
        },
        invoices: {
            read: true,
            write: true,
            delete: true
        },
        driverDetail: {
            read: true,
            write: true,
            delete: true
        },
        driverZone: {
            read: true,
            write: true,
            delete: true
        },
        coupons: {
            read: true,
            write: true,
            delete: false
        },
        account: {
            read: true
        },
        help: {
            read: true,
            write: true,
            delete: true
        },
        accountSettings: {
            read: true,
            write: true,
            delete: true
        },
        driverManagement: {
            read: true,
            write: true,
            delete: true
        },
        blockedAccountWarning: {
            read: true,
            write: true,
            delete: true
        },
        chargingCards: {
            read: true,
            write: true,
            delete: true
        },
        chargerManagement: {
            read: true
        }
    },
    'facility-manager': {
        facilitySessions: {
            read: true
        },
        dashboard: {
            read: true
        },
        chargerManagement: {
            read: true
        },
        account: {
            read: true
        }
    },
    'fleet-manager': {
        sessions: {
            read: true,
            write: true,
            delete: true
        },
        fleetDashboard: {
            read: true
        },
        driverDetail: {
            read: true,
            write: true,
            delete: true
        },
        driverManagement: {
            read: true,
            write: true,
            delete: true
        },
        account: {
            read: true
        }
    },
    'account-manager': {
        sessions: {
            read: true,
            write: true,
            delete: true
        },
        invoices: {
            read: true,
            write: true,
            delete: true
        },
        account: {
            read: true
        }
    }
}
