import qs from 'qs'

// Utils
import {apiRequest} from '@/utils/apiRequest'
import {mapPagination} from '@/utils/mapPagination'

// Translations
import translations from '@/translations/EN.json'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if (error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if (typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if (typeof error.message !== 'undefined') {
                throw error.message
            } else if (typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({commit, state, dispatch}, data = {}) => {
        if (data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }

        commit('account/resetStore', state)
        cookies.remove('mobile-token')

    },
    getConsumptionsSessions: async ({commit, dispatch}, options) => {
        commit('setLoader', 1, {root: true})
        const {data, headers} = await apiRequest('client/consumption', {
            params: options,
            paramsSerializer: params => qs.stringify(params, {skipNulls: true})
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })

        const pagination = await mapPagination(headers)
        commit('setConsumptionsSessions', data)
        commit('setPagination', pagination)
    },
    getFilters: async ({commit, rootState, dispatch}, options) => {
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest('client/consumption/filters', {
            params: options,
            paramsSerializer: params => qs.stringify(params, {skipNulls: true})
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
        commit('setFilters', data)
    },
    fetchConsumption: async ({commit, dispatch}, id) => {
        commit('setLoader', 1, {root: true})
        const {data, headers} = await apiRequest(`client/consumption/${id}`)
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
        commit('setConsumptionSession', data)
    },
    updateConsumption: async ({commit, dispatch}, {id, payload}) => {
        commit('setLoader', 1, {root: true})
        return apiRequest.put(`client/consumption/${id}`, payload)
            .then(({data}) => {
                commit('setConsumptionSession', data)
                return data
            }).catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
    }
}