<script setup>
import useRegistration from '@/composables/registration/useRegistration'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { dialog } = useRegistration()
</script>

<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ props }">
            <v-btn
                icon="mdi-close"
                size="small"
                variant="text"
                class="ml-1"
                data-testid="closeRegistration"
                v-bind="props"
                @click="dialog = true"
            />
        </template>
        <span>{{ t('registrationWizard.closeRegistration') }}</span>
    </v-tooltip>
</template>