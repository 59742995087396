import {onBeforeUnmount, onMounted, ref} from 'vue'

export default function useWindowWidth() {

    const windowWidth = ref(null)

    function setWindowWidth() {
        windowWidth.value = window.innerWidth
    }

    onMounted(() => {
        setWindowWidth()
        window.addEventListener('resize', setWindowWidth)
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', setWindowWidth)
    })

    return {
        windowWidth,
        setWindowWidth
    }
}