import { ref, computed } from 'vue'
import store from '@/store'
import useWindowWidth from './windowWidth'
import router from '@/router'
import useFilterableList from './filterableList'

const { getters, state } = store
export default function useLocations() {

    const {
        windowWidth,
        setWindowWidth
    } = useWindowWidth()

    const {
        filtersSelected,
        options,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting
    } = useFilterableList({
        filters: {
            status: [],
            code: [],
            name: []
        },
        options: {
            sortBy: ['name'],
            sortDesc: true
        },
        action: 'locations/getLocations'
    })

    const selected = ref([])

    const headers = computed(() => {
        return getters['locations/tableHeaders']
    })

    const locations = computed(() => {
        return getters['locations/locations']
    })
    const pagination = computed(() => {
        return state.locations.pagination
    })

    function goToDetail(location) {
        router.push(`/location/${location.id}`)
    }

    function checkChargingToRowClass(item) {
        if (typeof item !== 'undefined' && !item.charging_to) {
            return 'highlight-row'
        }
    }

    return {
        windowWidth,
        selected,
        filtersSelected,
        options,
        headers,
        locations,
        pagination,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting,
        checkChargingToRowClass,
        goToDetail,
        setWindowWidth
    }
}