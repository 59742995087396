// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        fontSize: {
            default: {
                'font-extra-small-size': '0.75rem',     // 12px
                'font-small-size': '0.875rem',          // 14px
                'font-small-plus-size': '0.938rem',     // 15px
                'font-base-size': '1rem',               // 16px
                'font-middle-size': '1.125rem',         // 18px
                'font-large-size': '1.25rem',           // 20px
                'font-extra-large-size': '1.375rem',    // 22px
                'font-x-extra-large-size': '1.5rem',    // 24px
                'font-xx-extra-large-size': '2rem',     // 32px
                'font-xxx-extra-large-size': '2.5rem'  // 40px
            }
        },
        light: {
            primary: '#8dc641',
            secondary: '#fff',
            accent: '#82b1ff',
            error: '#e23121',
            info: '#2196F3',
            'info-light': '#E5F3FE',
            success: '#009f3b',
            warning: '#ffc107',
            white: '#fff',
            back: '#f4f5f8',
            'logo-line': '#72914d',
            'footer-background': '#f7f7fb',
            'color-gray': '#777',
            'menu-background': '#2F3343',
            background: '#222930',
            'color-dark-gray': '#222',
            black: '#000',
            'menu-background-light': '#f6f6f6',
            'sc-light-grey': '#eeeeee',
            'sc-error': '#e93e3e',
            'sc-text': '#666666',
            'sc-stage': '#e6f5ff',
            'sc-unknown': '#F09409',
            'sc-stage-offline': '#e1f0fa',
            'sc-blue': '#0099FF',
            'sc-bubble': '#1aa3ff',
            'sc-bubble-failed': '#757575',
            'sc-topbar': '#0091f2',
            'sc-topbar-failed': '#616161',
            'sc-charger-offline': '#fafafa',
            'cc-light-grey': '#c0c0c0',
            'rw-free-kwhs': '787878',
            'rw-ribbon': '#E23121',
            'reg-background': '#fafafa',
        }
    }
}

export const state = getDefaultState()