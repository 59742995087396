import { apiRequest } from '@/utils/apiRequest'
import { computed, ref } from 'vue'
import showMessage from '@/utils/errorMessageArray'
import router from '@/router'
import store from '@/store'

const { commit, dispatch, getters } = store

export default function useSecurity() {

    const listOfDevices = ref(null)

    const computedListOfDevices = computed(() => {
        return listOfDevices.value    
    })

    const logoutEverywhere = async () => {
        commit('setLoader', 1, { root: true })
        try {
            await apiRequest.post('oauth-token/revoke-all')
            router.push('/logout')
        } catch(error) {
            showMessage(error.errors)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    }

    const logout = async (val) => {
        commit('setLoader', 1, { root: true })
        try {
            await apiRequest.post('oauth-token/revoke', { id: val.id })
            if(val.current_device) {
                router.push('/logout')
                return 
            }
            fetchListOfDevices()
        } catch(error) {
            showMessage(error.errors)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    }

    const fetchListOfDevices = async () => {
        try {
            commit('setLoader', 1, { root: true })
            const { data } = await apiRequest('oauth-token')
            listOfDevices.value = data
        } catch(error) {
            showMessage(error.errors)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    }

    return {
        computedListOfDevices,
        fetchListOfDevices,
        logout,
        logoutEverywhere
    }
}