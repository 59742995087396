const ConnectorStatus = Object.freeze({
    Available: 'available',
    Unknown: null,
    isAvailable: (status) => {
        return (ConnectorStatus.Available === status || ConnectorStatus.Unknown === status) ? true : false
    }
})

export {
    ConnectorStatus
}
