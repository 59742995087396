export const getDefaultState = () => {
    return {
        drivers: [],
        driver: {
            vehicle: {
                id: null,
                producer: null,
                producer_code: null,
                model: null,
                model_code: null,
                version: null,
                version_code: null,
                registration: null,
                production: null,
                plate_number: null,
                note: null
            }
        },
        tariffs: null,
        isEditView: false,
        pagination: {
            itemsLength: 0,
            itemsPerPage: 10,
            pageCount: null,
            page: 1,
            pageStart: null,
            pageStop: null
        }
    }
}