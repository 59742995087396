import { ref, computed } from 'vue'
import store from '@/store'
import { apiRequest } from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessage'
import { fileDownload } from '@/utils/fileDownload'
import qs from 'qs'
import useWindowWidth from './windowWidth'
import router from '@/router'
import useFilterableList from './filterableList'
import { formatLanguage } from '@/utils/formatLanguage'
import { useI18n } from 'vue-i18n'

const { dispatch, state, getters } = store
export default function useDrivers() {

    const { t } = useI18n()
    const {
        windowWidth,
        setWindowWidth
    } = useWindowWidth()

    const {
        isFiltersVisible,
        filtersSelected,
        options,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting
    } = useFilterableList({
        filters: {
            phone: null,
            tariff_id: null,
            driver_data: null,
            blocked_drivers: null,
            running_session: null
        },
        options: {
            sortBy: ['name'],
            sortDesc: true
        },
        action: 'drivers/getDrivers'
    })

    const tariffs = ref([])
    const chargingDriverId = ref(null)
    const isPopupActive = ref(null)
    const isCustomVisible = ref(false)
    const isExportLoading = ref(false)
    const selected = ref([])
    const selectedRow = ref(null)

    const popupData = ref({
        question: {
            title: t('driverInfo.AreYouSure'),
            type: 'question',
            subtitle: t('driverInfo.YouWantDeleteDriver'),
            icon: 'mdi-help-circle'
        }
    },)

    const actions = ref([
        {
            title: t('driverInfo.delete'),
            action: 'delete',
            icon: 'mdi-delete'
        },
        {
            title: t('driverInfo.delete'),
            action: 'deleteSingle',
            icon: 'mdi-delete'
        }
        // TODO: return (DEV-1150)
        // {
        //   title: t('chargerManagement.startCharging'),
        //   action: 'startCharging',
        //   icon: 'mdi-flash-circle',
        // },
        // {
        //   title: t('chargerManagement.stopCharging'),
        //   action: 'stopCharging',
        //   icon: 'mdi-stop-circle',
        // },
    ])

    const headers = computed(() => {
        return getters['drivers/tableHeaders']
    })

    const drivers = computed(() => {
        return state.drivers.drivers.map(driver => ({
            id: driver.id,
            name: driver.first_name,
            status: driver.status === 'OK' ? t('driverInfo.Active') : t(`driverInfo.${driver.status}`),
            status_key: driver.status,
            isCharging: driver.running_session === 'Yes',
            email: driver.email,
            freeKWh: driver.freeKWh,
            phone: driver.phone,
            deletedAt: driver.deleted_at,
            isSelectable: driver.deleted_at === null && driver.id !== state.account.primaryDriverId,
            vehicle: driver.vehicle?.producer,
            priceProgram: driver.tariff_name,
            language: formatLanguage(driver.lang)
        }))
    })
    const packages = computed(() => {
        return state.freeKwhPackages.freeKwhPerDriver
    })
    const pagination = computed(() => {
        return state.drivers.pagination
    })
    const countSelectedItems = computed(() => {
        return selected.value.length
    })

    function getBatchActions() {
        return [actions.value[0]]
    }

    function getSingleDeleteAction() {
        return [actions.value[1]]
    }
    function getActions(item) {
        const displayActions = [actions.value[0]]

        if (item.status_key !== 'Blocked') {
            if (item.isCharging) {
                actions.value[2] ? displayActions.push(actions.value[2]) : false
            } else {
                actions.value[1] ? displayActions.push(actions.value[1]) : false
            }
        }

        return displayActions
    }

    function handleAction(action, id) {
        if (action === 'startCharging') {
            chargingDriverId.value = id
            return
        }

        if (action === 'stopCharging') {
            apiRequest('charging/stop', {
                params: { driver_id: id }
            })
            showMessage(t('chargingForm.ChargingHasBeenStopped'), 'success')
            return
        }

        if (!selected.value.length && action === 'deleteSingle') {
            selected.value = [{ id: id }]
        } else if (!selected.value.length) {
            selected.value.push({ id: id })
        }

        isPopupActive.value = true
    }

    function deleteDriver() {
        if (selected.value.length) {
            const ids = selected.value.map((item) => item.id)
            dispatch('drivers/deleteDrivers', ids).then(response => {

                if (selected.value.length > 1) {
                    showMessage(t('driverInfo.DriversWillBeDeleted'), 'success')
                    dispatch('account/getAccount')
                } else {
                    showMessage(t('driverInfo.DriverWillBeDeleted') + ' ' + response.data[0].deleted_at, 'success')
                }

                getItems().then(() => {
                    updateTableStyles()
                })
                selected.value = []
            }).catch(error => {
                showMessage(error.response.data.message)
            })
        }
    }

    async function handleDownload(type) {
        isExportLoading.value = true
        await apiRequest('client/drivers/export', {
            params: {
                filters: filtersSelected.value,
                type
            },
            responseType: 'blob',
            paramsSerializer: params => qs.stringify(params, { skipNulls: true })
        }).then((response) => {
            if (response.data.error) {
                showMessage(response.data.error)
            } else {
                fileDownload(response.data, 'Drivers', type)
            }
        }).catch(err => {
            showMessage(err.response.data.message)
        })
        isExportLoading.value = false
    }

    function goToDetail(driver) {
        router.push(`/drivers/${driver.id}`)
    }

    function addDriver() {
        router.push('/drivers/add')
    }

    function selectedItems(items) {
        selected.value = items
    }

    function isActionDisabled(id) {
        return id === state.account.primaryDriverId
    }

    function updateTableStyles() {
        const checkboxes = document.getElementsByClassName('v-simple-checkbox--disabled')
        checkboxes.forEach(checkbox => {
            const row = checkbox.parentNode.parentNode
            row.style.color = 'lightgray'
        })
    }

    function highlightElement(index) {
        const rows = document.querySelectorAll('.v-data-table__wrapper tr')
        rows.forEach(row => {
            row.classList.remove('selected-row')
        })

        if (index !== -1) {
            const row = document.querySelectorAll('.v-data-table__wrapper tr')[index + 1]
            row.className = 'selected-row'
        }
    }

    const fields = {
        packages,
        tariffs,
        chargingDriverId,
        isPopupActive,
        isFiltersVisible,
        isCustomVisible,
        isExportLoading,
        windowWidth,
        selected,
        selectedRow,
        filtersSelected,
        popupData,
        options,
        actions,
        headers,
        drivers,
        pagination,
        countSelectedItems
    }

    const methods = {
        getItems,
        getBatchActions,
        getActions,
        getSingleDeleteAction,
        isActionDisabled,
        handleAction,
        deleteDriver,
        handleDownload,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting,
        goToDetail,
        addDriver,
        selectedItems,
        highlightElement,
        setWindowWidth
    }

    return {
        ...fields,
        ...methods
    }
}