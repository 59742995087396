<script setup>
const emit = defineEmits(['click'])

defineProps({
    show: {
        type: Boolean,
        default: true
    },
    loading: {
        type: Boolean,
        default: false
    },
    // 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain'
    variant: {
        type: String,
        default: 'elevated'
    },
    // 'x-small' | 'small' | 'default' | 'large' | 'x-large'
    size: {
        type: String,
        default: 'default'
    },
    color: {
        type: String,
        default: 'primary'
    },
    disabled: {
        type: Boolean,
        default: false
    }
})
</script>
<template>
    <v-btn
        v-bind="$attrs"
        :disabled="disabled"
        :loading="loading"
        :variant="variant"
        :size="size"
        :color="color"
        @click.stop="emit('click')"
    >
        <slot />
    </v-btn>
</template>
