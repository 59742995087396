<script setup>
import { onMounted, computed, reactive } from 'vue'
import store from '@/store'
const { dispatch } = store

// components
import Modal from '@/components/modals/Modal.vue'
import PricePrograms from '@/components/PricePrograms.vue'
import FreeKWh from '@/pages/drivers/components/FreeKWh.vue'
import ChargingCard from '@/pages/drivers/components/ChargingCard.vue'
import TariffChanges from '@/pages/drivers/components/TariffChanges.vue'
import VehicleDetails from '@/pages/drivers/components/VehicleDetails.vue'
import DriversManagement from '@/pages/drivers/components/DriversManagement.vue'
import PriceProgramConfirmation from '@/pages/drivers/components/PriceProgramConfirmation.vue'

// utils
import markdownParser from '@/utils/markdownParser'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const state = reactive({
    loading: true,
    isConfirmationDialogVisible: false,
    tempPriceChange: {
        tariffId: null,
        price: null,
        subscriptionMonthlyPrice: null
    }
})

const driver = computed(() => {
    return store.state.drivers.driver
})

const driverId = computed(() => {
    return store.state.account.primaryDriverId
})

const setTariff = (data) => {
    state.tempPriceChange.tariffId = data.id
    state.tempPriceChange.price = data.price
    state.tempPriceChange.subscriptionMonthlyPrice = data.subscriptionMonthlyPrice
    state.isConfirmationDialogVisible = true
}

const refetch = async () => {
    state.loading = true
    await dispatch('drivers/getDriver', driverId.value).finally(() => {
        state.loading = false
    })
}

const cancel = () => {
    state.isConfirmationDialogVisible = false
}

onMounted(async () => {
    if (driverId.value) {
        await dispatch('drivers/getDriver', driverId.value)
        state.loading = false
    }
})
</script>

<template>
    <v-container fluid
                 class="account-charging-settings-wrapper pt-5"
    >
        <v-row v-if="state.loading"
               class="loading-wrapper"
        >
            <v-col cols="12"
                   class="d-flex align-items-center justify-content-end text-center mt-5"
            >
                <v-progress-circular
                    class="m-auto"
                    :size="50"
                    color="primary"
                    indeterminate
                />
            </v-col>
        </v-row>
        <template v-else>
            <v-row>
                <v-col v-if="driver && driver.freeKWh?.freekwh_amount > 0"
                       cols="12"
                       md="6"
                       lg="3"
                >
                    <FreeKWh />
                </v-col>
                <v-col cols="12"
                       md="6"
                       lg="4"
                >
                    <DriversManagement />
                </v-col>
                <v-col cols="12"
                       md="6"
                       lg="5"
                >
                    <ChargingCard
                        v-if="driver && driverId"
                        :driver-id="parseInt(driverId)"
                    />
                </v-col>
            </v-row>
            <v-row class="mt-5">
                <v-col cols="12" class="pt-0">
                    <h2 class="home-page-title">
                        {{ t('driverInfo.PriceProgram') }}
                    </h2>
                </v-col>
                <v-col v-if="driver.tariff.price_list"
                       cols="12"
                       class="price-box"
                >
                    <v-card cols="12" elevation="0"
                            class="d-flex align-items-center justify-center"
                    >
                        <PricePrograms
                            :tariff-id="driver.tariff.id"
                            @setTariff="setTariff"
                        />
                    </v-card>
                </v-col>
                <v-col v-else
                       cols="12"
                       class="pt-0"
                >
                    <v-card elevation="0"
                            class="d-flex align-items-center justify-center"
                    >
                        <span class="pa-5">
                            {{ t('driverInfo.specialPriceApplied') }}
                        </span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <TariffChanges />
                </v-col>
            </v-row>
            <v-row v-if="driver">
                <v-col cols="12">
                    <VehicleDetails :driver="driver" />
                </v-col>
            </v-row>
        </template>
        <Modal :is-active="state.isConfirmationDialogVisible"
               :max-width="1280"
               @cancel="cancel"
        >
            <div class="pa-5 price-program-modal">
                <v-container>
                    <PriceProgramConfirmation
                        :temp-price-change="state.tempPriceChange"
                        :driver-id="driver.id"
                        @refetch="refetch"
                        @cancel="cancel"
                    />
                </v-container>
            </div>
        </Modal>
    </v-container>
</template>