<script setup>
import {useStore} from 'vuex'
import router from '@/router'
import {computed, onMounted, onUnmounted, reactive} from 'vue'

// components
import Pricing from '@/components/Pricing.vue'
import Btn from '@/components/inputs/Btn.vue'
import Popup from '@/components/modals/Popup.vue'
import BtnGoBack from '@/components/inputs/BtnGoBack.vue'

// utils
import showMessage from '@/utils/errorMessage'
import showMessageArray from '@/utils/errorMessageArray'

// composables
import useBeforeWindowUnload from '@/composables/useBeforeWindowUnload'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const store = useStore()
const {dispatch, commit} = store
const beforeWindowUnload = useBeforeWindowUnload()

defineProps({
    id: {
        type: String,
        required: true,
        default: null
    }
})

const state = reactive({
    isActivePopup: false,
    isPopupActiveDone: false,
    tariffPrice: 0,
    popupQuestion: {
        title: t('driverInfo.AreYouSure'),
        type: 'question',
        subtitle: t('driverInfo.YouWantToChangeSubscription'),
        icon: 'mdi-help-circle'
    }
})

const tariffId = computed({
    get() {
        return store.state.drivers.driver.tariffId
    },
    set(value) {
        commit('drivers/setDriverTariff', value)
    }
})

onUnmounted(() => {
    window.onbeforeunload = null
})

onMounted(() => {
    window.onbeforeunload = beforeWindowUnload
    dispatch('drivers/getDriver', id)
})

const setTariff = (data) => {
    state.tariffId = data.id
    state.tariffPrice = data.price
}

const handleSave = () => {
    state.isActivePopup = true
}

const save = async () => {
    dispatch('drivers/changeDriverTariff', {
        driver_id: id,
        tariff_id: tariffId
    }).then(response => {
        if (response.error) {
            showMessage(response.error)
        }
        // disable dialog box and redirect
        window.onbeforeunload = null

        router.go(-1)
    }).catch(error => {
        showMessageArray(error)
    })
}

const cancel = () => {
    window.onbeforeunload = null
    router.go(-1)
}
</script>

<template>
    <div class="edit-driver-pricing">
        <BtnGoBack class="ml-6 pt-2"/>
        <header class="driver__header">
            <h1 class="home-page-title driver__header--title">
                {{ t('driverInfo.EditPriceProgram') }}
            </h1>
            <div>
                <Btn class="ml-auto mr-6"
                     color="primary"
                     variant="outlined"
                     @click="cancel"
                >
                    {{ t('driverInfo.cancel') }}
                </Btn>
                <Btn v-if="state.tariffPrice === 0" :loading="store.getters['isLoading']" @click="save">
                    {{ t('addDriver.save') }}
                </Btn>
                <Btn v-else :loading="store.getters['isLoading']" @click="handleSave">
                    {{ t('driverInfo.save') }}
                </Btn>
            </div>
        </header>
        <main class="driver__main px-4">
            <Popup :active="state.isActivePopup"
                   :loading="store.getters['isLoading']"
                   :popup-data="state.popupQuestion"
                   @accept="save()"
                   @cancel="state.isActivePopup = false"
            />
            <Pricing v-if="tariffId"
                     v-model="tariffId"
                     :tariff-id="tariffId"
                     @setTariff="setTariff"
            />
        </main>
    </div>
</template>