import { onBeforeMount } from 'vue'
import store from '@/store'
const { dispatch, commit, getters } = store

// modules
import { setMessages, setLocale } from '@/modules/i18n'

// Cookies
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

export default function useResolveTranslations() {

    const resolveTranslations = () => {
        const language = cookies.get('gw_lang') || import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en'
        const locale = store.state.account.locale ? store.state.account.locale : import.meta.env.VITE_APP_I18N_LOCALE

        commit('account/setLocale', locale)
        if (!store.state.account.lang) {
            commit('account/setLang', language)
        }
        setMessages(store.state.account.lang, store.state.translation[store.state.account.lang])
        setLocale(store.state.account.lang)
    }

    onBeforeMount(() => {
        resolveTranslations()
    })

    return {
        resolveTranslations
    }
}