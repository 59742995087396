<script setup>
import { reactive, computed, onMounted, onBeforeUpdate, onUpdated } from 'vue'
import { useStore } from 'vuex'

// validations
import useValidations from '@/composables/useValidations'
const {
    isValidYear,
    isValid2Characters,
    isLongPlateNumber,
    maxCharsExceeded
} = useValidations()

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const { dispatch, getters } = store

const emit = defineEmits(['setCarFields'])
const props = defineProps({
    infoMessage: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },
    isCreate: {
        type: Boolean,
        default: false
    },
    vehicle: {
        type: [Object, Array],
        default() {
            return {
                id: null,
                producer: null,
                producer_code: null,
                model: null,
                model_code: null,
                version: null,
                version_code: null,
                registration: null,
                production: null,
                plate_number: null,
                note: null
            }
        }
    }
})

const state = reactive({
    isCarNotInList: false,
    isFormValid: false,
    hasProducerSelected: false
})

const cars = computed(() => getters['cars/cars'])

const carBrands = computed(() => {
	if (getters['cars/cars'].length) {
		const arrOfProducers = [...new Set(getters['cars/cars'].map(item => item.producer))]
		const list = []
		arrOfProducers.forEach(item => {
			list.push(getters['cars/cars'].find(brand => brand.producer === item))
		})
		return list
	}
	return []
})

const modelCode = computed(() => {
	const arrayOfModels = getters['cars/cars'].length
		? getters['cars/cars'].filter(car => car.producerCode === props.vehicle['producer_code'])
		: []

	// make unique array of models
	return arrayOfModels.filter((a, i) => arrayOfModels.findIndex((s) => a.model === s.model) === i)
})
const versionCode = computed(() => {
    return cars.value.length
        ? cars.value.filter(car => +car.modelCode === +props.vehicle['model_code'])
        : []
})

const registrationYearValid = computed(() => {
    if (props.vehicle['registration'] && props.vehicle.production) {
        return props.vehicle.registration >= props.vehicle.production || t('error.CarRegistrationYear')
    }
    return true
})

const vehicleRegistrationYearValid = computed(() => {
    return props.vehicle['registration'] <= new Date().getFullYear() || t('error.InvalidYear')
})

const vehicleProductionYearValid = computed(() => {
    return props.vehicle.production <= new Date().getFullYear() || t('error.InvalidYear')
})

const customVehicleData = computed(() => {
    return props.vehicle
})

const changeProducerCode = (val) => {
    (val !== null) ? state.hasProducerSelected = true : state.hasProducerSelected = false
    emit('setCarFields', {
        version_code: null,
        model_code: null,
        producer_code: val
    })
}

const changeModelCode = (val) => {
    emit('setCarFields', {
        version_code: null,
        model_code: val
    })
}

const changeVersionCode = (val) => {
    emit('setCarFields', {
        version_code: val
    })
}

const checkVehicleNote = () => {
    props.vehicle['note']?.trim().length > 0 ? props.vehicle.note = '' : ''
}
const toggleNotInList = () => {
    const fields = {}
    if (state.isCarNotInList) {
        fields.producer = null
        fields.producer_code = null
        fields.model = null
        fields.model_code = null
        fields.version = null
        fields.version_code = null
    } else {
        fields.producer = ''
        if (!props.isCreate) {
            fields.producer = store.state.drivers.driver.vehicle.producer
            fields.producer_code = store.state.drivers.driver.vehicle.producer_code
            fields.model = store.state.drivers.driver.vehicle.model
            fields.model_code = store.state.drivers.driver.vehicle.model_code
            fields.version = store.state.drivers.driver.vehicle.version
            fields.version_code = store.state.drivers.driver.vehicle.version_code
        }
        checkVehicleNote()
    }
    emit('setCarFields', fields)
}

onMounted(async () => {
    await dispatch('cars/getCars')
})

onBeforeUpdate(() => {
    if (state.isCarNotInList) {
        emit('setCarFields', {
            producer: null,
            producer_code: null,
            model: null,
            model_code: null,
            version: null,
            version_code: null
        })
    }
})

onUpdated(() => {
    if (customVehicleData.note) {
        state.isCarNotInList = true
    }
})
</script>

<template>
    <div class="vehicle-information">
        <v-row no-gutters>
            <v-col
                class="py-0"
                md="4"
                cols="12"
            >
                <v-select
                    :model-value="vehicle.producer_code"
                    :items="carBrands"
                    clearable
                    :disabled="state.isCarNotInList"
                    item-title="producer"
                    item-value="producerCode"
                    variant="outlined"
                    :placeholder="t('addDriver.Producer')"
                    :label="t('addDriver.Producer')"
                    data-testid="carManufacturer"
                    @update:model-value="changeProducerCode"
                />
            </v-col>
            <v-col
                class="py-0 pl-md-8"
                md="4"
                cols="12"
            >
                <v-select
                    :model-value="vehicle.model_code"
                    :disabled="!state.hasProducerSelected || state.isCarNotInList"
                    :items="modelCode"
                    clearable
                    item-title="model"
                    item-value="modelCode"
                    variant="outlined"
                    :placeholder="t('addDriver.Model')"
                    :label="t('addDriver.Model')"
                    data-testid="carModel"
                    @update:model-value="changeModelCode"
                />
            </v-col>
            <v-col
                class="py-0 pl-md-8"
                md="4"
                cols="12"
            >
                <v-select
                    :model-value="vehicle.version_code"
                    :disabled="!vehicle.model_code || state.isCarNotInList"
                    :items="versionCode"
                    clearable
                    item-title="version"
                    item-value="versionCode"
                    variant="outlined"
                    :placeholder="t('addDriver.Version')"
                    :label="t('addDriver.Version')"
                    data-testid="carVersion"
                    @update:model-value="changeVersionCode"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <v-checkbox
                    v-model="state.isCarNotInList"
                    :label="t('addDriver.IsNotInTheList')"
                    data-testid="notInListCheckbox"
                    @update:model-value="toggleNotInList"
                />
                <v-textarea
                    v-if="state.isCarNotInList"
                    variant="outlined"
                    :rules="[isValid2Characters, maxCharsExceeded(120)]"
                    class="pb-4"
                    :model-value="vehicle.note"
                    :label="t('addDriver.FillProducerModelVersion')"
                    data-testid="notInListCarField"
                    @update:model-value="emit('setCarFields', { note: $event })"
                />
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col
                class="py-0"
                md="4"
                cols="12"
            >
                <v-text-field
                    variant="outlined"
                    :rules="[isValidYear, registrationYearValid, vehicleRegistrationYearValid]"
                    :model-value="vehicle.registration"
                    :label="t('addDriver.YearOfRegistration')"
                    data-testid="registrationYear"
                    @update:model-value="emit('setCarFields', { registration: $event })"
                />
            </v-col>
            <v-col
                class="py-0 pl-md-8"
                md="4"
                cols="12"
            >
                <v-text-field
                    variant="outlined"
                    :rules="[isValidYear, vehicleProductionYearValid]"
                    :model-value="vehicle.production"
                    :label="t('addDriver.YearOfProduction')"
                    data-testid="productionYear"
                    @update:model-value="emit('setCarFields', { production: $event })"
                />
            </v-col>
            <v-col
                class="py-0 pl-md-8"
                md="4"
                cols="12"
            >
                <v-text-field
                    variant="outlined"
                    :rules="[isValid2Characters, isLongPlateNumber]"
                    :model-value="vehicle.plate_number"
                    :label="t('addDriver.PlateNumber')"
                    data-testid="licencePlate"
                    @update:model-value="emit('setCarFields', { plate_number: $event })"
                />
            </v-col>
        </v-row>
    </div>
</template>
