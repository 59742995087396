import qs from 'qs'

// Utils
import {apiRequest} from '@/utils/apiRequest'
import {mapPagination} from '@/utils/mapPagination'

// Translations
import translations from '@/translations/EN.json'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }

        commit('account/resetStore', state)
        cookies.remove('mobile-token')

    },
    getConnectors: async ({commit, dispatch}, options) => {
        commit('setLoader', 1, {root: true})
        const {data, headers} = await apiRequest('facility/charger-management/connectors', {
            params: options,
            paramsSerializer: params => qs.stringify(params, { skipNulls: true })
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
        
        const pagination = await mapPagination(headers)
        commit('setConnectors', data)
        commit('setPagination', pagination)
    },
    getFilters: async ({commit, rootState, dispatch}, options) => {
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest('facility/charger-management/connectors/filters', {
            params: options,
            paramsSerializer: params => qs.stringify(params, { skipNulls: true })
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })

        data.filter_by_status.forEach(state => {
            const newName = rootState.translation[rootState.account.lang][`etrelstatus.${state.value}`]
            state.name = typeof newName !== 'undefined' ? newName : translations.etrelstatus[state.value]
        })

        commit('setFilters', data)
    },
    startCharging: async ({commit, dispatch}, data) => {
        await apiRequest('charging/start', {
            params: data,
            paramsSerializer: params => qs.stringify(params, { skipNulls: true })
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
    },
    stopCharging: async ({commit, dispatch}, data) => {
        await apiRequest.post(`command/charging/stop?driver_id=${data.driver_id}&connector_id=${data.connector_id}`).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
    }
}