import {apiRequest} from '@/utils/apiRequest'
import {getEnvironment, getFeatureFlagsList, isFeatureAvailable} from '@/utils/isFeatureAvailable'

export const actions = {
    async getFeatures({commit}, {host, env, ID}) {
        commit('setLoading', true)
        apiRequest.get(`${host}/features`, {
            headers: {
                'UNLEASH-APPNAME': env,
                'UNLEASH-INSTANCEID': ID
            }
        }).then((response) => {
            if (response && typeof response.data !== 'undefined' && typeof response.data.features !== 'undefined') {
                commit('setFeatures', response.data.features)
                localStorage.setItem('featureFlags', JSON.stringify(response.data.features))
            }
        }).catch((e) => {
            // check if localstorage flags exists (which should be up-to-date)
            // if even localStorage is not available use local config file
            let featureFlagsLS = localStorage.getItem('featureFlags') || null
            if (featureFlagsLS) {
                commit('setFeatures', JSON.parse(featureFlagsLS))
            } else {
                let data = []
                const origin = getEnvironment(window.location.origin)
                getFeatureFlagsList().forEach(featureFlag => {
                    data.push({
                        name: `${origin}_${featureFlag}`,
                        enabled: isFeatureAvailable(origin, featureFlag)
                    })
                })
                commit('setFeatures', data)
            }
        }).finally(() => {
            commit('setLoading', false)
        })
    }
}