import { reactive, ref, toRef } from 'vue'
import router from '@/router'

// Utils
import showMessage from '../utils/errorMessage'

// store
import store from '@/store'
const { dispatch } = store

const isFiltersVisible = ref(false)
const exportFilters = ref(null)

export default function useFilterableList(props) {
    const isTable = ref(true)
    const filtersSelected = ref({ ...props.filters })
    const options = ref({ ...props.options })

    async function getItems() {
        const route = toRef(reactive(router), 'currentRoute').value
        if (route?.query.from && route?.query.to) {
            filtersSelected.value.date.from = route.query.from
            filtersSelected.value.date.to = route.query.to
            route.query.from = null
            route.query.to = null
            window.history.replaceState(null, '', window.location.pathname)
        }

        return await dispatch(props.action, { filters: filtersSelected.value, ...options.value }).catch((error) => {
            error === 'Cancelled request' ? console.error(error) : showMessage(error)
        })
    }

    function setFilter({ field, value }) {
        if (value && value.constructor.name === 'Object' && field !== 'is_roaming') {
            Object.keys(value).forEach((key) => (value[key] = value[key] === '' ? null : value[key]))
        }
        filtersSelected.value[field] = value || null
        exportFilters.value = filtersSelected.value
        options.value.page = 1
    }

    function getDefaultFilters() {
        return {
            ...props.filters
        }
    }

    function clearFilters() {
        filtersSelected.value = { ...props.filters }
        if (filtersSelected.value.location) {
            filtersSelected.value.location.name = []
            filtersSelected.value.location.code = []
        }
        exportFilters.value = { ...props.filters }
        getItems()
    }

    function sorting(opt) {
        if (options.value.sortDesc) {
            options.value.sortDesc = opt.sortDesc ? true : false
        }
    }

    return {
        isTable,
        isFiltersVisible,
        filtersSelected,
        exportFilters,
        options,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting
    }
}
