<script setup>
import moment from 'moment'
import { useStore } from 'vuex'
import { reactive, computed, watch, nextTick, ref, onMounted } from 'vue'

// configs
import { Lang } from '@/configs/index.js'

const BD_ALLOWED_FORMAT = 'YYYY-MM-DD'

// components
import LanguageSelect from '@/components/registrationWizard/LanguageSelect.vue'

// validations
import useValidations from '@/composables/useValidations'
const {
    isNotEmpty,
    isValidEmail,
    isValidPhone,
    isValidPassword,
    isLongPassword,
    isValid3Characters,
    isValid2Characters,
    maxCharsExceeded
} = useValidations()

// utils
import { apiRequest } from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessage'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const picker = ref()

const store = useStore()
const emit = defineEmits(['setFormField', 'setIsPhoneVerified', 'unsetIsPhoneVerified', 'clearError', 'clearBirthdateError'])

const componentProps = defineProps({
    isPhoneVerified: {
        type: Boolean,
        required: false,
        default: false
    },
    isSkipVerificationPhone: {
        type: Boolean,
        required: false,
        default: false
    },
    formData: {
        type: Object,
        required: true
    },
    errors: {
        type: Object,
        required: true
    }
})

const state = reactive({
	selectedBirthDate: null,
    isPasswordVisible: false,
    isDateMenu: false,
    isPhoneSkipVerificationModalVisible: false,
    isEmailVerified: false,
    isEmailVerificationChecked: false,
    hotlinePhoneService: import.meta.env.VITE_APP_PHONE_CUSTOMER_SERVICE_HOTLINE
})

const titles = computed(() => {
    return store.state.filters.titles
})

const roles = computed(() => {
    return store.state.account.roles
})

const gender = computed({
    get() {
        return componentProps.formData.gender
    },
    set(value) {
        setValue('gender', value)
    }
})

const phone = computed({
    get() {
        return componentProps.formData.phone
    },
    set(value) {
        setValue('phone', value)
    }
})

const email = computed({
    get() {
        return componentProps.formData.email
    },
    set(value) {
        setValue('email', value)
    }
})

const password = computed({
    get() {
        return componentProps.formData.password
    },
    set(value) {
        setValue('password', value)
    }
})

const firstName = computed({
    get() {
        return componentProps.formData.firstName
    },
    set(value) {
        setValue('firstName', value)
    }
})

const lastName = computed({
    get() {
        return componentProps.formData.lastName
    },
    set(val) {
        setValue('lastName', val)
    }
})

const birthDate = computed({
    get() {
        return componentProps.formData.birthDate && componentProps.formData.birthDate.length > 10
            ? componentProps.formData.birthDate.slice(0, 10)
            : componentProps.formData.birthDate
    },
    set(val) {
        setValue('birthDate', val)
        componentProps.errors.birth_date = ''
    }
})

const isCompany = computed(() => {
    return componentProps.formData.isCompany
})

const maxDate = computed(() => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    date.setMonth(date.getMonth())
    date.setFullYear(date.getFullYear() - 18)
    return date.toISOString().slice(0, 10)
})

const formatDateForSelect = (date) => {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${day}.${month}.${year}`
}

const formattedBirthDate = computed(() => {
    return formatDateForSelect(birthDate.value?.slice(0, 10))
})

const isBtnVerifyPhone = computed(() => {
    return isValidPhone(phone) === true && !componentProps.isPhoneVerified
})

const setValue = (field, value) => {
    emit('setFormField', { field, value })
}

const allowedDates = (val) => {
    const val1 = moment(val, BD_ALLOWED_FORMAT)
    const val2 = moment(maxDate.value, BD_ALLOWED_FORMAT)
    return val1.isBefore(val2)
}
const setIsPhoneVerified = () => {
    emit('setIsPhoneVerified')
}
const changePhone = (value) => {
    setValue('phone', value)
    if (componentProps.isPhoneVerified && !componentProps.isSkipVerificationPhone) {
        emit('unsetIsPhoneVerified')
    }
}
const resendVerificationEmail = () => {
    apiRequest.post('client/resend-verification-email').catch(error => {
        showMessage(error)
    })
    showMessage(t('Client.VerificationEmailSent'), 'success')
}

const changeDate = () => {
	nextTick(() => {
		setTimeout(() => {
			picker.value.$el.querySelector('.v-date-picker-years').scrollTo({
				top: picker.value.$el.querySelector('.v-date-picker-years').scrollHeight,
				behavior: "smooth",
			})
		}, 500)
	})
    emit('clearError', 'birth_date')
}

const updateBirthdate = (val) => {
	state.selectedBirthDate = val
    birthDate.value = moment(val).format(BD_ALLOWED_FORMAT)
}

onMounted(() => {
    if (roles.value.includes('payer')) {
        apiRequest('client/email-verified').then(response => {
            state.isEmailVerified = response?.data
        }).catch(error => {
            showMessage(error)
        }).finally(() => {
            state.isEmailVerificationChecked = true
        })
    }
})

</script>

<template>
    <div class="personal-form">
        <v-row no-gutters>
            <v-col
                class="py-0"
                cols="12"
                md="4"
            >
                <v-select
                    v-model="gender"
                    :items="titles || []"
                    :label="t('Client.Title')"
                    :placeholder="t('Client.Title')"
                    :error-messages="errors.gender"
                    item-title="name"
                    item-value="value"
                    variant="outlined"
                    @update:modelValue="errors.gender = ''"
                />
            </v-col>
            <v-col
                class="py-0 pl-md-8"
                cols="12"
                md="4"
            >
                <v-text-field
                    v-model="firstName"
                    :label="t('Client.FirstName') + '*'"
                    :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                    data-testid="firstName"
                    variant="outlined"
                />
            </v-col>
            <v-col
                class="py-0 pl-md-8"
                cols="12"
                md="4"
            >
                <v-text-field
                    v-model="lastName"
                    :label="t('Client.LastName') + '*'"
                    :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                    data-testid="lastName"
                    variant="outlined"
                />
            </v-col>
            <!--TODO BirthDate component-->
            <v-col
                v-if="store.getters['account/locale'] === Lang.sk && !isCompany"
                class="py-0 birthdate"
                cols="12"
                md="4"
            >
                <v-menu
                    class="bd-menu"
                    v-model="state.isDateMenu"
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ props }">
                        <v-text-field
                            v-model="formattedBirthDate"
                            :error-messages="errors.birth_date"
                            :label="t('Client.DateOfBirth') + '*'"
                            :rules="[isNotEmpty]"
                            data-testid="dateOfBirth"
                            variant="outlined"
                            readonly
                            v-bind="props"
                            @click="changeDate"
                        />
                    </template>
                    <v-date-picker
	                    reactive
	                    hide-header
	                    show-adjacent-months
	                    :max="maxDate"
	                    color="primary"
	                    ref="picker"
	                    data-testid="datePicker"
	                    :allowed-dates="allowedDates"
	                    :label="t('Client.DateOfBirth') + '*'"
	                    @update:model-value="updateBirthdate"
	                    :model-value="state.selectedBirthDate"
	                    :view-mode="state.selectedBirthDate ? 'month' : 'year'"
                    />
                </v-menu>
            </v-col>
            <v-col
                :class="{ 'pl-md-8': !formData.isCompany && store.getters['account/locale'] === Lang.sk }"
                class="py-0"
                cols="12"
                md="4"
            >
                <LanguageSelect
                    :language="formData.communicationLanguage"
                    data-testid="communicationLanguage"
                    @setValue="setValue('communicationLanguage', $event)"
                />
            </v-col>
            <!--TODO Phone component-->
            <v-col
                class="py-0 pl-md-8"
                cols="12"
                md="4"
            >
                <v-text-field
                    :error-messages="errors.phone"
                    :label="t('Client.Phone') + '*'"
                    :rules="[isNotEmpty, isValidPhone]"
                    :success="isSkipVerificationPhone ? false : isPhoneVerified"
                    v-model="formData.phone"
                    data-testid="phone"
                    variant="outlined"
                    type="tel"
                    @update:model-value="[changePhone($event), emit('clearError', 'phone')]"
                />
            </v-col>
            <v-col
                :class="{ 'pl-md-8': formData.isCompany || store.getters['account/locale'] !== Lang.sk }"
                class="py-0"
                cols="12"
                md="4"
            >
                <v-text-field
                    disabled
                    :label="t('Client.Email') + '*'"
                    :rules="[isNotEmpty, isValidEmail]"
                    v-model="formData.email"
                    data-testid="email"
                    variant="outlined"
                    type="email"
                    @update:model-value="setValue('email', $event)"
                />
            </v-col>
        </v-row>
    </div>
</template>