<script setup>
import { ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty } = useValidations()

// utils
import { isMemberState } from '@/configs'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const { commit } = store

const emit = defineEmits(['setDeliveryCountry', 'setValue', 'update'])
const props = defineProps({
    isDeliveryCountry: {
        type: Boolean,
        required: false
    },
    isDeliveryAddress: {
        type: Boolean,
        required: false
    },
    country: {
        type: String,
        required: false,
        default: ''
    }
})

watch(() => props.country, (val) => {
    resolveMemberState(val)
})

const setCountry = (val) => {
    if(props.isDeliveryCountry) {
        emit('setDeliveryCountry', val)
        return
    }
    commit('account/setIsEUMemberState', isMemberState[val.is_eu])
    emit('setValue', val.value)
    emit('update')
}

const resolveMemberState = (val) => {
    if(!props.isDeliveryCountry && val) {
        const country = store.state.filters.countries.find( item => item.value === val)
        commit('account/setIsEUMemberState', isMemberState[country.is_eu])
    }
}

onMounted(() => {
    resolveMemberState(props.country)
})
</script>

<template>
    <v-select
        :placeholder="t('Client.Country') + '*'"
        :label="t('Client.Country') + '*'"
        :model-value="country"
        :items="store.state.filters.countries || []"
        item-title="name"
        item-value="value"
        :return-object="!isDeliveryCountry"
        :rules="(isDeliveryAddress) ? [isNotEmpty] : []"
        variant="outlined"
        @update:model-value="setCountry"
    />
</template>
