<script setup>
import {reactive, computed} from 'vue'
import { useStore } from 'vuex'

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty } = useValidations()

// utils
import moment from 'moment'

// configs
import { DATE_FORMAT } from '@/configs/index.js'

// constants
const BD_ALLOWED_FORMAT = 'YYYY-MM-DD'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

// store
const store = useStore()
const { commit } = store

const state = reactive({
    isDateMenu: false,
    errors: null
})

const account = computed(() => store.state.account)
const accountTemp = computed(() => store.state.settings.accountTemp)
const isEditView = computed(() => {
    return store.state.account.personalDataIsEditView
})

const formattedBirthDate = computed(() => {
    const account = isEditView.value ? accountTemp.value : store.state.account
    if (account.birthDate) {
        return moment(account.birthDate).format(DATE_FORMAT)
    }
    return null
})

const setBirthDate = (value) => {
    if (value) {
        const validationDate = new Date(maxDate.value)
        const validateDate = new Date(value)

        if (validateDate <= validationDate) {
            updateAccountTemp(new Date(value), 'birthDate')
            state.errors = ''
            state.isDateMenu = false
        } else {
            state.errors = t('error.IncorrectBirthDate')
        }
    } else {
        state.errors = t('error.IncorrectBirthDate')
    }
}

const maxDate = computed(() => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    date.setMonth(date.getMonth())
    date.setFullYear(date.getFullYear() - 18)
    return moment(date).format(BD_ALLOWED_FORMAT)
})

const allowedDates = (val) => {
    const val1 = moment(val, BD_ALLOWED_FORMAT)
    const val2 = moment(maxDate.value, BD_ALLOWED_FORMAT)
    return val1.isBefore(val2)
}

const updateAccountTemp = (value, field) => {
    commit('settings/setAccountTempFieldValue', {
        field,
        value
    })
}
</script>

<template>
    <v-row class="phone-email-component">
        <v-col cols="12">
            <v-menu
                ref="menu"
                v-model="state.isDateMenu"
                :close-on-content-click="false"
                min-width="290px"
            >
                <template v-slot:activator="{ props }">
                    <v-text-field
                        :disabled="!isEditView"
                        :error-messages="state.errors"
                        :label="t('Client.DateOfBirth') + '*'"
                        :placeholder="t('Client.DateOfBirth') + '*'"
                        :model-value="formattedBirthDate"
                        :rules="[isNotEmpty]"
                        density="compact"
                        variant="outlined"
                        readonly
                        @click="state.errors = null"
                        v-bind="props"
                    />
                </template>
                <v-date-picker
                    color="primary"
                    show-adjacent-months
                    :allowed-dates="allowedDates"
                    :max="maxDate"
                    :v-model="isEditView ? moment(accountTemp.birthDate).format(BD_ALLOWED_FORMAT) : moment(account.birthDate).format(BD_ALLOWED_FORMAT)"
                    @update:model-value="setBirthDate"
                />
            </v-menu>
        </v-col>
    </v-row>
</template>