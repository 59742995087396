import { config } from '@/featureFlags'

const getFeatureFlagsList = () => {
    return [
        'websockets',
        'driverDetailCharging',
        'discounts',
        'creditBox',
        'couponsHistory',
        'charging'
    ]
}

const getEnvironment = (origin) => {
    if (origin.indexOf('localhost') > -1) {
        return 'localhost'
    }

    if (origin.indexOf('gwtest') > -1) {
        return 'dev'
    }

    if (origin.indexOf('client-uat') > -1) {
        return 'uat'
    }

    if (origin.indexOf('client.greenway') > -1 || origin.indexOf('client.greenwaypolska.pl') > -1) {
        return 'production'
    }
    return 'production'
}

const isFeatureEnabledGitlab = (flag, features, email) => {
    const feature = features[flag] || null
    const strategyFilterByUserId = feature ? feature.strategies.find(el => el.name === 'userWithId') : null
    if (strategyFilterByUserId) {
        return strategyFilterByUserId.parameters['userIds'].toLowerCase().indexOf(email.toLowerCase()) > -1
    } else {
        return feature?.enabled
    }
}

const isFeatureAvailable = (origin, flag) => {
    if (origin.indexOf('localhost') > -1) {
        if (config.localhost[flag]) {
            return true
        }
    }

    if (origin.indexOf('gwtest') > -1) {
        if (config.dev[flag]) {
            return true
        }
    }

    if (origin.indexOf('client-uat') > -1) {
        if (config.uat[flag]) {
            return true
        }
    }

    if (origin.indexOf('client.greenway') > -1) {
        if (config.production[flag]) {
            return true
        }
    }
    return false
}

export {
    getEnvironment,
    isFeatureAvailable,
    getFeatureFlagsList,
    isFeatureEnabledGitlab
}