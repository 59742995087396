// Utils
import { apiRequest } from '@/utils/apiRequest'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }

        commit('account/resetStore', state)
        cookies.remove('mobile-token')
    },
    getSessionsFilters: async ({commit, dispatch}) => {
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest('client/sessions/filters')
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
        commit('setSessionsFilters', data)
    },
    getFilterLocation: async ({state, commit, dispatch}, {value}) => {
        commit('setLoader', 1, {root: true})
        let query = value ? `location/list?q=${value}` : `location/list`

        const {data} = await apiRequest(query)
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })

        // data = {locations:[], places: []}
        // merge data
        let searchData = []
        if(state.sessionsFilters.location) {
            const combinedArray = [
                ...state.sessionsFilters.location,
                ...data.locations
            ]
            const uniqueMap = new Map()
            combinedArray.forEach(item => {
                uniqueMap.set(item.location_id, item)
            })
            searchData = Array.from(uniqueMap.values())
        } else {
            searchData = data.locations
        }

        commit('setSessionsFiltersLocation', searchData)
    },
    getTitles: async ({commit, dispatch}) => {
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest('filters/titles')
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
        commit('setTitles', data)
    },
    getCountries: async ({commit, dispatch}) => {
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest('filters/countries')
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
        
        commit('setCountries', data)
    },
    getLanguages: async ({commit, dispatch}) => {
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest('filters/languages')
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
        
        commit('setLanguages', data)
    }
}