<script setup>
import {useStore} from 'vuex'

// components
import Btn from '@/components/inputs/Btn.vue'

// utils
import isObjEqual from '@/utils/isObjEqual'
import {computed, reactive} from 'vue'

// translations
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const store = useStore()
const {getters} = store

const emit = defineEmits(['clearFilters', 'setFilter', 'getItems'])

const props = defineProps({
  filtersSelected: {
    required: false
  },
  defaultFilters: {
    required: false
  }
})

const state = reactive({
  searchInputName: '',
  searchInputCode: ''
})

const filters = computed(() => {
  return getters['locations/filters']
})

const showClear = computed(() => {
  return !isObjEqual(props.filtersSelected, props.defaultFilters)
})

function debounce(func, delay) {
  let timerId
  return function (...args) {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      func.apply(this, args) // Call the debounced function with arguments
    }, delay)
  }
}

const setFilter = debounce(function (field, value) {
  emit('setFilter', {field, value})
  emit('getItems')
}, 1500)

const dateFromTo = ({dateFrom: from, dateTo: to, isClear}) => {
  setFilter('date', {
    from: from || null,
    to: to || null
  })
  state.isClear = isClear
}

</script>

<template>
  <v-container v-if="filters" class="locations-list-filter__filters" fluid>
    <v-row class="pt-10 px-4 pb-4">
      <v-col
          class="py-0"
          cols="12"
          md="3"
          sm="6"
      >
        <v-autocomplete
            v-model="filtersSelected.name"
            :items="filters.filter_by_name"
            :label="t('locations.Name')"
            item-title="name"
            item-value="value"
            clearable
            chips
            multiple
            density="compact"
            name="location_name"
            variant="outlined"
            @update:model-value="setFilter('name', $event)"
        />
      </v-col>
      <v-col
          class="py-0"
          cols="12"
          md="3"
          sm="6"
      >
        <v-select
            v-model="filtersSelected.status"
            :items="filters.filter_by_status"
            :placeholder="t('locations.Status')"
            item-title="name"
            item-value="value"
            clearable
            multiple
            density="compact"
            variant="outlined"
            @update:model-value="setFilter('status', $event)"
        />
      </v-col>

      <v-col
          class="py-0"
          cols="12"
          md="3"
          sm="6"
      >
        <v-autocomplete
            v-model="filtersSelected.code"
            :items="filters.filter_by_code"
            :label="t('locations.Code')"
            item-title="name"
            item-value="value"
            clearable
            chips
            multiple
            density="compact"
            variant="outlined"
            @update:model-value="setFilter('code', $event)"
        />
      </v-col>
      <v-col
          v-if="showClear"
          class="py-0"
          cols="12"
          md="3"
          sm="6"
      >
        <Btn color="primary" text @click="[emit('clearFilters'), state.isClear = true]">
          <v-icon class="clear-icon pr-1">
            mdi-delete
          </v-icon>
          {{ t('filter.ClearAll') }}
        </Btn>
      </v-col>
    </v-row>
  </v-container>
</template>
