<script setup>
import {QrcodeStream, QrcodeDropZone, QrcodeCapture} from 'vue-qrcode-reader'

// components
import Modal from '@/components/modals/Modal.vue'
import Btn from '@/components/inputs/Btn.vue'

// translations
import {useI18n} from 'vue-i18n'
const { t } = useI18n()

// utils
import showMessage from '@/utils/errorMessage'

const emit = defineEmits(['scanned', 'cancel'])

defineProps({
    active: {
        required: false
    },
    QrScanData: {
        required: false
    }
})

const onInit = async (promise) => {
    // show loading indicator
    try {
        await promise
        // successfully initialized
    } catch (error) {
        showMessage('cameraError.' + error.name)
    } finally {
        // hide loading indicator
    }
}

/**
 * Decode scanned QR code and parse it
 * @param result - scanned url
 */
const onDecode = (result) => {
    emit('scanned', parseResult(result))
    emit('cancel')
}

const onError = (err) => {
	showMessage(t(`cameraError.${err.name}`))
}

const parseResult = (result) => {
    // get everything after coupon tag '?coupon=FOO' => '=FOO'
    const regExp = /[^&?coupon]*?=[^&?]*/g

    // take the result and cut out '='
    if (result.indexOf('http') >= 0) {
        result = regExp.exec(result)[0].substr(1)
    }
    return result
}
</script>

<template>
    <Modal :is-active="active"
           :max-width="450"
           @cancel="emit('cancel', !active)"
           class="qr-code-modal-wrapper"
    >
        <div class="pa-5 pl-8 qrcode-modal">
            <div class="col justify-center">
                <qrcode-stream @decode="onDecode" @init="onInit" @error="onError"/>
                <qrcode-drop-zone/>
                <div class="row justify-center">
                    <div>
                        <Btn
                            :outlined="true"
                            class="ma-4"
                            medium
                            @click="emit('cancel')"
                        >
                            {{ t('addDriver.cancel') }}
                        </Btn>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>