<script setup>
import Modal from '@/components/modals/Modal.vue'
import Btn from '@/components/inputs/Btn.vue'
import {onMounted, reactive, watch} from 'vue'
import {useI18n} from 'vue-i18n'

const { t } = useI18n()
const emit = defineEmits(['updateHeaders', 'cancel'])

const props = defineProps({
    active: {
        required: false
    },
    headers: {
        required: false
    },
    allHeaders: {
        required: false
    }
})

const state = reactive({
    selectedHeaders: []
})

const saveHeaders = () => {
    emit('updateHeaders', state.selectedHeaders)
    emit('cancel', false)
}

watch(() => props.active, () => {
    state.selectedHeaders = props.headers.map(header => header.value)
})

onMounted(() => {
    state.selectedHeaders = props.headers.map(header => header.value)
})
</script>

<template>
    <Modal :is-active="props.active"
           :max-width="450"
           @cancel="emit('cancel')"
           class="customize-column-wrapper"
    >
        <a class="close-button" @click="emit('cancel', !props.active)">
            <v-icon class="active-icon">mdi-close</v-icon>
        </a>
        <div class="pa-5 pl-8">
            <h3 class="mb-4">
                {{ t('customizeColumn.CustomView') }}
            </h3>
            <v-container fluid>
                <v-checkbox
                    v-for="(item, index) in props.allHeaders"
                    v-show="item.customizable"
                    :key="item.text + index"
                    v-model="state.selectedHeaders"
                    :label="item.text"
                    :value="item.value"
                />
            </v-container>
            <div class="row justify-center">
                <Btn
                    class="ma-4"
                    :outlined="true"
                    medium
                    @click="emit('cancel', !state.active)"
                >
                    {{ t('popUp.Cancel') }}
                </Btn>
                <Btn
                    class="ma-4"
                    medium
                    @click="saveHeaders"
                >
                    {{ t('customizeColumn.Customize') }}
                </Btn>
            </div>
        </div>
    </Modal>
</template>