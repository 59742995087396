<script setup>
// composables
import useRegistration from '@/composables/registration/useRegistration'

// utils
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { dialog, closeRegistration } = useRegistration()
</script>

<template>
    <v-dialog
        v-model="dialog"
        max-width="275"
    >
        <v-card class="pb-4">
            <h4 class="mb-4 pt-4 text-center text-subtitle-1 font-weight-bold">
                {{ t('error.HasChanges') }}
            </h4>
            <v-card-actions class="d-flex justify-space-between mx-6">
                <v-btn
                    color="primary"
                    variant="flat"
                    large
                    data-testid="cancelButton"
                    @click="dialog = false"
                >
                    {{ t('registrationWizard.Cancel') }}
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    data-testid="closeButton"
                    @click="closeRegistration()"
                >
                    {{ t('registrationWizard.Yes') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>