// Utils
import {apiNoAuth} from '@/utils/apiRequest'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({dispatch, commit}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                commit('setNotify', {
                    content: error.response.data.message,
                    color: 'error'
                }, {root: true})
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }
        commit('account/resetStore', state)
        cookies.remove('mobile-token')
    },
    sendEmail: async ({ commit, dispatch }, {email, recaptchaToken}) => {
        commit('setLoader', 1, {root: true})
        try {
            await apiNoAuth.post('auth/forgot-password-request', {
                email,
                'g-recaptcha-response': recaptchaToken
            }).catch(error => {
                if(error.response && error.response.data) {
                    throw error.response.data.message
                }
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })

            commit('account/setEmail', email, { root: true })
        } finally {
            commit('setLoader', -1, {root: true})
        }
    },
    passwordChange: async ({ commit, dispatch }, credentials) => {
        commit('setLoader', 1, {root: true})
        try {
            // remove old token
            const { data } = await apiNoAuth.post('auth/forgot-password-change', credentials)
                .catch(error => {
                    dispatch('checkStatus', error)
                }).finally(() => {
                    commit('setLoader', -1, {root: true})
                })

            await commit('account/auth', data, { root: true })
            // redirect to news
            window.location.href = 'news'
        } catch (error) {
            if(error.error) {
                throw error.error
            } else if(error.token) {
                throw error.token
            } else {
                const errorStatus = error?.response.status
                switch (errorStatus) {
                case 422:
                    throw ('password recovery link expired')
                default:
                    throw ('error.SomethingGoesWrong')
                }
            }
        } finally {
            commit('setLoader', -1, {root: true})
        }
    }
}