<script setup>
import {useStore} from 'vuex'

// components
import DiscountsLoader from '@/loaders/DiscountsLoader.vue'

//utils
import formatDate from '@/utils/formatDate'
import {computed, onMounted, reactive} from 'vue'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const store = useStore()

defineProps({
    isLoading: {
        required: true,
        type: Boolean,
        default: () => {
            return true
        }
    }
})

const state = reactive({
    headers: [
        {
            title: t('discounts.description'),
            align: 'start',
            sortable: false,
            value: 'name'
        },
        {
            title: t('discounts.appliesTo'),
            align: 'start',
            sortable: false,
            value: 'applies_to'
        },
        {
            title: t('discounts.validTo'),
            align: 'center',
            sortable: false,
            value: 'valid_to'
        }
    ]
})

const discounts = computed({
    get() {
        return store.state.coupons.discounts
    },
    set(value) {
        store.commit('coupons/setDiscounts', value)
    }
})

const fetchDiscounts = () => {
    store.dispatch('coupons/fetchDiscounts').then(result => {
        discounts.value = result
    })
}

onMounted(() => {
    fetchDiscounts()
})

</script>

<template>
    <div v-if="!isLoading && discounts.length"
         :class="[isLoading ? 'pt-3' : 'pt-8']"
         class="discounts"
    >
        <header class="discounts__header align-self-center">
            <div class="discounts__title">
                {{ t('discounts.title') }}
            </div>
        </header>
        <main class="discounts__main">
            <DiscountsLoader v-if="isLoading"/>
            <v-data-table v-else-if="!isLoading && discounts.length"
                          :headers="state.headers"
                          :items="discounts"
                          hide-default-footer
            >
                <template v-slot:[`item.applies_to`]="{item}">
                    <template v-if="item.applies_to === 'discounts.appliesTo'">
                        {{ t(item.applies_to) }} {{ item.first_name }}
                    </template>
                    <template v-else>
                        {{ t(item.applies_to) }}
                    </template>
                </template>
                <template v-slot:[`item.valid_to`]="{item}">
                    <span v-if="item.valid_to" class="discounts__valid-to">
                        {{ formatDate(item.valid_to) }}
                    </span>
                    <span v-else class="discounts__valid-to">
                        -
                    </span>
                </template>
            </v-data-table>
            <div v-if="!isLoading && discounts.length < 1" class="text-center">
                <small>{{ t('error.NoDataAvailable') }}</small>
            </div>
        </main>
    </div>
</template>