export const mutations = {
    setNews: (state, data) => {
        state.news = data
    },
    setNotifications: (state, data) => {
        state.notifications = data
    },
    pushNews: (state, data) => {
        state.news.push(...data)
    },
    setPage: (state, data) => {
        state.page = data
    },
    setSearch: (state, data) => {
        state.search = data
    },
    setIsLoadMore: (state, data) => {
        state.isLoadMore = data
    },
    clearNews: (state) => {
        state.news = []
    }
}