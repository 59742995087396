import moment from 'moment'

// Utils
import {apiRequest} from '@/utils/apiRequest'

// configs
import { DATE_FORMAT } from '@/configs/index.js'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }

        commit('account/resetStore', state)
        cookies.remove('mobile-token')

    },
    getCouponsHistory: async ({ state, commit, dispatch }) => {
        const coupons = await dispatch('fetchCoupons', {
            pageNumber: 1,
            limit: state.limit,
            getHistory: true
        })
        commit('setCouponsHistory', coupons)
    },
    getCoupons: async ({ state, commit, dispatch }) => {
        const coupons = await dispatch('fetchCoupons', {
            pageNumber: 1,
            limit: state.limit,
            getHistory: false
        })
        commit('setCoupons', coupons)
    },
    loadMoreCoupons: async ({ state, commit, dispatch }) => {
        const coupons = await dispatch('fetchCoupons', {
            pageNumber: state.page,
            limit: state.limit,
            getHistory: false
        })
        commit('pushCoupons', coupons)
    },
    fetchCoupons: async ({commit, dispatch}, {pageNumber, getHistory, limit}) => {
        commit('setLoader', 1, {root: true})
        try {
            let params = {
                page: pageNumber,
                limit
            }

            const url = getHistory ? 'client/coupons?type=past' : 'client/coupons?type=present'
            const {headers, data} = await apiRequest.get(url, {params})
                .catch(error => {
                    dispatch('checkStatus', error)
                }).finally(() => {
                    commit('setLoader', -1, {root: true})
                })

            let coupons = data.map(({code, used_at, valid_to, registred_at, campaign_name, campaign_description}) => ({
                code,
                campaignName: campaign_name,
                campaignDescription: campaign_description,
                usedAt: used_at ? moment(used_at).format(DATE_FORMAT) : null,
                validTo: valid_to ? moment(valid_to).format(DATE_FORMAT) : null,
                registredAt: registred_at ? moment(registred_at).format(DATE_FORMAT) : ''
            }))

            if (headers['x-pagination-next_page_url']) {
                commit('setIsLoadMore', true)
            } else {
                commit('setIsLoadMore', false)
            }
            commit('setPage', pageNumber)
            return coupons

        } catch (e) {
            commit('setNotify', {
                content: e.response.data.errors
                    ? e.response.data.errors.code[0]
                    : e.response.data.message,
                color: 'error'
            }, {root: true})
            return []
        } finally {
            commit('setLoader', -1, {root: true})
        }
    },
    // TODO - Andrej - check error messages format
    registerCoupon: async ({state, commit, dispatch}, { code }) => {
        commit('setLoader', 1, {root: true})
        await apiRequest.post('client/coupons/register', { code, ...state.addCouponData})
            .catch(error => {
                if(typeof error.errors !== 'undefined') {
                    throw error
                } else {
                    dispatch('checkStatus', error)
                }
            }).finally(() => {
                commit('clearCoupons')
                dispatch('getCoupons')
                commit('setLoader', -1, {root: true})
            })
    },
    fetchDiscounts: ({commit, dispatch}) => {
        commit('setLoader', 1, {root: true})
        return apiRequest.get('/client/discounts').then(({data}) => {
            return data
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
    },
    getCredits: async ({commit, dispatch}) => {
        commit('setLoader', 1, {root: true})
        try {
            const { data } = await apiRequest('client/credits')
            commit('setCredits', data)
        } catch(error) {
            dispatch('checkStatus', error)
        } finally {
            commit('setLoader', -1, {root: true})
        }
    }
}