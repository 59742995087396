<script setup>
import {computed, reactive} from 'vue'
import {useStore} from 'vuex'

// components
import Btn from '@/components/inputs/Btn.vue'

// utils
import isObjEqual from '@/utils/isObjEqual'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const emit = defineEmits(['setFilter', 'getItems', 'clearFilters'])

const props = defineProps({
    filtersSelected: {
        required: true
    },
    defaultFilters: {
        required: true
    }
})

const store = useStore()

const state = reactive({
    currency: import.meta.env.VITE_APP_I18N_CURRENCY,
    filters: {
        runningSession: [
            { key: 'past', value: t('driverInfo.NotCharging')},
            { key: 'running', value: t('driverInfo.Running') }
        ],
        blockedDrivers: [
            { name: t('driverInfo.Blocked'), key: 1 },
            { name: t('driverInfo.Unblocked'), key: 2 }
        ]
    }
})

const tariffs = computed(() => {
    return store.state.drivers.tariffs
})

const showClear = computed(() => {
    return !isObjEqual(props.filtersSelected, props.defaultFilters)
})

function debounce(func, delay) {
    let timerId
    return function(...args) {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            func.apply(this, args) // Call the debounced function with arguments
        }, delay)
    }
}

const setFilter = debounce(function(field, value)  {
    emit('setFilter', { field, value })
    emit('getItems')
}, 1500)

const clearFilters = () => {
    emit('clearFilters')
}

const dateFromTo = computed(({dateFrom: from, dateTo: to, isClear}) => {
    setFilter('date', {
        from: from || null,
        to:  to || null
    })
    state.isClear = isClear
})

</script>

<template>
    <v-container fluid class="drivers__filters">
        <v-row class="pt-10 px-4 pb-4">
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-text-field 
                    :model-value="filtersSelected.driver_data"
                    density="compact"
                    variant="outlined"
                    clearable
                    :label="t('drivers.FilterByNameEmail')"
                    @update:model-value="setFilter('driver_data', $event)"
                />
            </v-col>
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-text-field 
                    :model-value="filtersSelected.phone"
                    density="compact"
                    variant="outlined"
                    clearable
                    :label="t('drivers.Phone')"
                    @update:model-value="setFilter('phone', $event)"
                />
            </v-col>
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-select 
                    :model-value="filtersSelected.tariff_id"
                    :items="tariffs || []"
                    item-value="id"
                    item-title="name"
                    density="compact"
                    variant="outlined"
                    clearable
                    :placeholder="t('drivers.ByPriceProgram')"
                    @update:model-value="setFilter('tariff_id', $event)"
                >
                </v-select>
            </v-col>
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-select 
                    :model-value="filtersSelected.blocked_drivers"
                    :items="state.filters.blockedDrivers || []"
                    item-value="key"
                    item-title="name"
                    density="compact"
                    variant="outlined"
                    clearable
                    :placeholder="t('drivers.BlockedDrivers')"
                    @update:model-value="setFilter('blocked_drivers', $event)"
                />
            </v-col>
            <v-col 
                v-if="showClear"
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <Btn :variant="'text'" color="primary" @click="emit('clearFilters')">
                    <v-icon class="clear-icon pr-1 active-icon">
                        mdi-delete
                    </v-icon>{{ t('filter.ClearAll') }}
                </Btn>
            </v-col>
        </v-row>
    </v-container>
</template>
