<script setup>
import { ref, watch } from 'vue'

// composable
import useConfirmation from '@/composables/registration/useConfirmation'

// utils
import markdownParser from '@/utils/markdownParser'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const {	isPromotionSelected, promotionItem } = useConfirmation()

const model = ref(isPromotionSelected.value)

watch(model, (v) => {
    isPromotionSelected.value = v
})
</script>

<template>
    <section v-if="promotionItem" class="promotion bg-light-green-lighten-2 mb-8 pa-4">
        <h3 class="text-center text-grey-darken-3 mb-4">{{ t('registration.promotionTitle') }}</h3>
        <div class="text-subtitle-1 mb-4" v-html="markdownParser(t('registration.promotionDescription'))"></div>
        <v-row>
            <v-col>
                <v-checkbox
                    v-model="model"
                    class="text-subtitle-1"
                    :label="`${t('registration.promotionItemTitle')} - ${promotionItem.price} ${promotionItem.currency}`"
                ></v-checkbox>
            </v-col>
        </v-row>
    </section>
</template>