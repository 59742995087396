import qs from 'qs'

// Utils
import {apiRequest} from '@/utils/apiRequest'
import {mapPagination} from '@/utils/mapPagination'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }
        commit('account/resetStore', state)
        cookies.remove('mobile-token')
    },
    getInvoices: async ({commit, dispatch}, options) => {
        commit('setLoader', 1, {root: true})
        const {data, headers} = await apiRequest('client/invoices', {
            params: options,
            paramsSerializer: params => qs.stringify(params, { skipNulls: true })
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
            
        const pagination = await mapPagination(headers)
        commit('setInvoices', data)
        commit('setPagination', pagination)
    },
    getInvoice: async ({commit, dispatch}, idInvoice) => {
        commit('setLoader', 1, {root: true})
        return await apiRequest.post(`client/invoices/${idInvoice}`).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
    },
    payInvoice: async ({ commit, dispatch }, idInvoice) => {
        commit('setLoader', 1, {root: true})
        return await apiRequest.post(`client/invoices/pay-by-invoice/${idInvoice}`)
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
    },
    payMultipleInvoice: async ({commit, dispatch}, idInvoices) => {
        commit('setLoader', 1, {root: true})
        await apiRequest.post('client/invoices/pay-multiple-invoice', idInvoices ).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
    }
}