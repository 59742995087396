import { Country } from '../configs'

const isCountry = Object.freeze({
    Poland: 'poland',
    isPoland: (value) => {
        return isCountry.Poland === Country[value.toLowerCase()] ? true: false
    }
})

export {
    isCountry
}