<script setup>
import { computed } from 'vue'

// composables
import useRegistration from '@/composables/registration/useRegistration'

// components
import LangSwitcher from '@/components/inputs/LangSwitcher.vue'
import CloseRegistrationButton from './CloseRegistrationButton.vue'

// utils
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { step, steps } = useRegistration()

const emit = defineEmits(['back'])

const calcWidth = computed(() => {
    if(steps.value.length) {
        const numberOfSteps = steps.value.length
        const position = steps.value.indexOf(step.value) + 1
        return (100 / numberOfSteps) * position + '%'
    }
    return 0
})
</script>

<template>
    <header class="registration-header d-flex align-center justify-space-between py-2 px-1">
        <div class="left">
            <v-btn
                variant="text"
                size="small"
                class="text-subtitle-2 font-weight-bold text-none"
                @click="emit('back')"
            >
                {{ t('registrationWizard.Back') }}
            </v-btn>
        </div>
        <h1 class="text-subtitle-1 font-weight-bold">
            {{ t('registrationWizard.AccountSetup') }}
        </h1>
        <div class="right d-flex align-center">
            <LangSwitcher />
            <CloseRegistrationButton />
        </div>
        <div class="progress-line" :style="{ width: calcWidth }" />
    </header>
</template>