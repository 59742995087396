// Helpers
import {getDefaultState} from '../oauth/helper'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

export const mutations = {
    setFieldValue: (state, {field, value}) => {
        state[field] = value
    },
    setOauthClient: (state, data) => {
        state.client_id = data.client_id
        state.redirect_uri= data.redirect_uri
        state.response_type= data.response_type
        state.scope= data.scope
        state.response_mode= data.response_mode
        state.state= data.state
    },
    authorize: (state, data) => {
        state.client_name = data.client_name
        state.redirect_to= data.redirect_to
        state.scopes= data.scopes

        const urlParams = new URLSearchParams(data.redirect_to)
        const keys = [...urlParams.keys()]
        if(keys[0].indexOf('code') > -1) {
            state.code = urlParams.get(keys[0])
        }
        state.state = urlParams.get('state')
    },
    resetStore: state => {
        const DOMAIN = import.meta.env.VITE_APP_COOKIES_DOMAIN
        Object.assign(state, getDefaultState())
        cookies.remove('gw_token', '/', DOMAIN)
        cookies.remove('gw_token_expires_at', '/', DOMAIN)
    }
}