import store from '@/store'
const { commit } = store

export default function errorMessageArray(errors, type) {
    if (errors.length) {
        let err = ''
        errors.forEach((item, index) => {
            err = err + ' ' + item
        })
        commit('setNotify', {
            content: err ?? 'Something went wrong',
            color: type ?? 'error'
        }, { root: true })
        return
    }
    const keys = Object.keys(errors)
    keys.forEach((item, index) => {
        commit('setNotify', {
            content: errors[item][index] ?? 'Something went wrong',
            color: type ?? 'error'
        }, { root: true })
    })
}