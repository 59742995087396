<script setup>
import { computed } from 'vue'
import store from '@/store'

// composabels
import useInvoices from '@/composables/invoices'

// components
import MenuActionsMobile from '@/components/inputs/MenuActionsMobile.vue'
import Btn from '@/components/inputs/Btn.vue'

// utils
import { useI18n } from 'vue-i18n'
import { currencies } from '@/utils/currencies'
import { PaymentStatus } from '@/utils/paymentStatus'
import { PaymentProviders } from '@/utils/paymentProviders'

const emit = defineEmits(['action'])
const props = defineProps({
    invoice: {
        type: Object,
        default: null
    }
})

const { getters } = store
const { t } = useI18n()
const { payInvoice, paymentProvider } = useInvoices()

const triggerAction = (val) => {
    emit('action', val, props.invoice.invoiceId, null)
}
const invoiceDetails = computed(() => {
    return [
        {
            title: t('invoices.Status'),
            key: props.invoice.status
        },
        {
            title: t('invoices.InvoiceNumber'),
            key: props.invoice.invoice_number
        },
        {
            title: t('invoices.VariableSymbol'),
            key: props.invoice.variableSymbol
        },
        {
            title: t('invoices.Amount'),
            key: `${props.invoice.amount} ${currencies[props.invoice.currency]}`
        },
        {
            title: t('invoices.AmountNet'),
            key: props.invoice.amountNet
        },
        {
            title: t('invoices.IssuedDate'),
            key: props.invoice.issue_date
        },
        {
            title: t('invoices.DueDate'),
            key: props.invoice.due_date
        },
        {
            title: t('invoices.BillingPeriod'),
            key: props.invoice.billingPeriod
        },
        {
            title: t('invoices.GreenWayBankAccount'),
            key: props.invoice.bankAccount
        }
    ]
})
</script>

<template>
    <div class="invoice invoice-detail-wrapper pa-8">
        <header class="invoice__header mb-5">
            <div class="invoice__header">
                <v-row>
                    <v-col
                        cols="5"
                        class="d-flex align-center"
                    >
                        <h2>
                            {{ t('invoices.InvoiceDetail') }}
                        </h2>
                    </v-col>
                    <v-col
                        cols="5"
                        class="d-flex align-center"
                    >
                        <div class="invoice__header-actions--mobile">
                            <MenuActionsMobile
                                :id="invoice.invoiceId"
                                :text="t('invoices.More')"
                                :show-dots="false"
                                :show-chevron="true"
                                @action="triggerAction"
                            />
                        </div>
                    </v-col>
                    <v-col
                        cols="2"
                        class="d-flex align-center"
                    >
                        <v-btn
                            variant="text"
                            size="large"
                            color="primary"
                            @click="emit('close')"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </header>
        <main
            v-for="({ title, key }) in invoiceDetails"
            :key="title"
            class="invoice__main"
        >
            <v-row class="invoice__row text-capitalize mb-2">
                <v-col
                    cols="6"
                    class="invoice__row-title"
                >
                    {{ title }}
                </v-col>
                <v-col
                    cols="6"
                    class="invoice__row-value"
                >
                    {{ key }}
                </v-col>
            </v-row>
        </main>
        <section
            v-if="(PaymentStatus.isUnpaid(invoice.status_key) || PaymentStatus.isPartialyPaid(invoice.status_key)) && PaymentProviders.canPay(paymentProvider) && invoice.sourceInvoiceId === null"
            class="footer pt-3"
        >
            <v-row>
                <v-col
                    cols="12"
                    class="d-flex justify-center"
                >
                    <Btn
                        block
                        large
                        :loading="getters['isLoading']"
                        class="invoice__header-pay--mobile"
                        @click="payInvoice(invoice.invoiceId)"
                    >
                        {{ t('invoices.Pay') }}
                    </Btn>
            </v-col>
        </v-row>
    </section>
</div></template>
