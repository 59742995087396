// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        en: null,
        sk: null,
        hr: null,
        pl: null
    }
}

export const state = getDefaultState()