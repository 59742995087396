<script setup>
// composables
import useChargingCards from '@/composables/chargingCards/useChargingCards'
import useChargingCardsActions from '@/composables/chargingCards/useChargingCardsActions'

// components
import TabContent from '@/pages/chargingCards/components/TabContent.vue'
import TabsAndFilters from '@/pages/chargingCards/components/TabsAndFilters.vue'
import ExportTokens from '@/pages/chargingCards/components/modals/ExportTokens.vue'
import RegisterGWCard from '@/pages/chargingCards/components/modals/RegisterGWCard.vue'
import OrderChargingCard from '@/pages/chargingCards/components/modals/OrderChargingCard.vue'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { init } = useChargingCards()
const { snackbar, snackbarText } = useChargingCardsActions()
        
init()
</script>

<template>
    <div class="charging-cards">
        <div class="heading d-block d-sm-flex flex-column flex-sm-row justify-space-between align-center pa-2 pa-sm-8">
            <h1 class="text-h5 mb-sm-4 mb-sm-0 pa-4 pa-sm-0">
                {{ t('chargingCards.pageTitle') }}
            </h1>
            <div class="main-buttons d-flex flex-row justify-space-between">
                <div class="buttons-wrapper d-flex flex-row">
                    <ExportTokens />
                    <OrderChargingCard />
                </div>
                <RegisterGWCard />
            </div>
        </div>
        <main class="mt-sm-4">
            <TabsAndFilters />
            <TabContent />
        </main>
        <v-snackbar
            v-model="snackbar"
            timeout="3000"
            location="top"
            color="var(--v-primary)"
        >
            {{ snackbarText }}
        </v-snackbar>
    </div>
</template>
