<script setup>
import { reactive, computed } from 'vue'
import { useStore } from 'vuex'

// components
import Btn from '@/components/inputs/Btn.vue'
import Popup from '@/components/modals/Popup.vue'

// utils
import showMessage from '@/utils/errorMessage'
import { getPopupData } from '@/utils/connectors'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const emit = defineEmits(['close'])

const store = useStore()
const { dispatch } = store

const props = defineProps({
    session: {
        required: true,
        type: Object
    }
})

const state = reactive({
    activePopupStopCharging: false
})

const sessionDetails = computed(() => {
    return [
        {
            title: t('sessions.locationCode'),
            key: props.session?.location_code
        },
        {
            title: t('sessions.locationName'),
            key: props.session?.location_name
        },
        {
            title: t('sessions.locationAddress'),
            key: `${props.session?.location_street} ${props.session?.location_house_num}, ${props.session?.location_city}, ${props.session?.location_country}`
        },
        {
            title: t('sessions.totalkWh'),
            key: props.session?.charging_to ? props.session?.total_kwh : null
        },
        {
            title: t('sessions.totalMinutes'),
            key: props.session?.charging_to ? props.session?.total_minutes : null
        },
        {
            title: t('sessions.maxConnectorPower'),
            key: props.session?.connector_max_power
        },
        {
            title: t('sessions.averagePower'),
            key: props.session?.charging_to ? props.session?.average_power : null
        },
        {
            title: t('sessions.chargingType'),
            key: props.session?.connectorACDC
        },
        {
            title: t('facilitySessions.ChargerId'),
            key: props.session?.device_id
        },
        {
            title: t('facilitySessions.ChargerManufacture'),
            key: props.session?.device_manufacture
        },
        {
            title: t('facilitySessions.ChargerModel'),
            key: props.session?.device
        },
        {
            title: t('sessions.connectorType'),
            key: props.session?.connector_type_code
        },
        {
            title: t('sessions.chargingFrom'),
            key: props.session?.charging_from
        },
        {
            title: t('sessions.chargingTo'),
            key: props.session?.charging_to ? props.session?.charging_to : null
        },
        {
            title: t('sessions.connectedFrom'),
            key: props.session?.connected_from
        },
        {
            title: t('sessions.connectedTo'),
            key: props.session?.charging_to ? props.session?.connected_to : null
        }
    ]
})

const getStopChargingPopupData = () => {
    return getPopupData()
}

const stopCharging = () => {
    dispatch('connectors/stopCharging', {
        driver_id: props.session?.driver_id,
        connector_id: props.session?.connector_id
    })
        .then(() => {
            showMessage(t('driverInfo.ChargingStoppedSuccessfully'), 'success')
        })
        .catch((error) => {
            showMessage(error)
        })
        .finally(() => {
            dispatch('filters/getSessionsFilters')
        })
}
</script>

<template>
    <div class="facility-session-detail">
        <header class="facility-session-detail__header">
            <h2 class="d-flex align-center">{{ t('facilitySessions.chargerSessionDetail') }}</h2>
            <v-btn variant="text" size="large" color="primary" @click="emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </header>
        <main v-for="{ title, key } in sessionDetails" :key="title" class="facility-session-detail__main">
            <div class="facility-session-detail__row">
                <span class="facility-session-detail__row-title">{{ title }}</span>
                <span v-if="key" class="facility-session-detail__row-value">{{ key }}</span>
                <span v-else class="facility-session-detail__row-value d-flex align-center">
                    <v-progress-linear class="width-50" :rounded="true" :height="7" indeterminate color="primary" />
                </span>
            </div>
        </main>
        <Popup
            :popup-data="getStopChargingPopupData()"
            :active="state.activePopupStopCharging"
            @cancel="state.activePopupStopCharging = false"
            @accept="stopCharging()"
        />
        <Btn
            v-if="session.charging_to === null"
            class="mb-5 mt-3 mt-sm-10"
            @click="state.activePopupStopCharging = true"
        >
            {{ t('driverInfo.StopCharging') }}
        </Btn>
    </div>
</template>
