<script setup>
// utils
import { currencies } from '@/utils/currencies'
import formatDate from '@/utils/formatDate'
import formatDecimal from '@/utils/formatDecimal'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
    data: {
        required: true,
        type: Array,
        default: () => {
            return []
        }
    }
})

const getPrice = (price, currency) => {
    return props.data.length ? `${formatDecimal(price)} ${currencies[currency]}` : '-'
}
</script>

<template>
    <v-card
        class="creditBox mt-5"
        elevation="0"
    >
        <v-card-title class="pb-0">
            <v-row v-if="data.length === 1">
                <v-col
                    cols="12"
                    sm="7"
                >
                    <strong class="creditBox__title">{{ t('creditBox.title') }}</strong>
                </v-col>
                <v-col
                    cols="12"
                    sm="5"
                    class="text-right"
                >
                    <div
                        v-if="data[0].valid_to !== null"
                        class="creditBox__valid_to pr-3"
                    >
                        <small class="text-caption">
                            {{ t('creditBox.validTo') }}
                        </small>
                        <strong class="text-caption font-weight-bold ml-2">
                            {{ formatDate(data[0].valid_to) }}
                        </strong>
                    </div>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <span class="creditBox__title">{{ t('creditBox.title') }}</span>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="pb-0">
            <v-list
                v-if="data"
                max-height="200"
                class="overflow-y-auto"
            >
                <v-list-item
                    v-for="({ first_name, name, price, valid_to, currency }, index) in data"
                    :key="index"
                >
                    <v-list-item-title>
                        <v-row>
                            <v-col
                                cols="12"
                                :sm="(data.length > 1) ? '6' : '8'"
                                class="d-flex align-center"
                            >
                                <div class="d-flex flex-column flex-sm-row">
                                    <h3 class="text-body-2">{{ name }}</h3>
                                    <div class="brackets d-flex align-center">
                                        <div class="text-caption">
                                            <span v-if="first_name">({{ first_name }})</span>
                                            <span v-else>({{ t('creditBox.wholeInvoice') }})</span>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col
                                v-if="data.length > 1"
                                cols="6"
                                sm="4"
                                class="d-flex align-center justify-center"
                            >
                                <div
                                    v-if="valid_to !== null"
                                    class="creditBox__valid_to"
                                >
                                    <small class="text-caption">
                                        {{ t('creditBox.validTo') }}
                                    </small>
                                    <strong class="text-caption font-weight-bold">
                                        &nbsp;{{ formatDate(valid_to) }}
                                    </strong>
                                </div>
                            </v-col>
                            <v-col
                                :cols="(data.length > 1) ? '6' : '12'"
                                :sm="(data.length > 1) ? '2' : '4'"
                                class="d-flex align-center justify-end"
                            >
                                <span class="creditBox__price pl-2">
                                    <strong>{{ getPrice(price, (currency) ? currency : 'EUR') }}</strong>
                                </span>
                            </v-col>
                        </v-row>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
            <div v-else>
                <p class="pt-5 text-center">
                    {{ t("error.NoDataAvailable") }}
                </p>
            </div>
        </v-card-text>
    </v-card>
</template>