export const config = {
    localhost: {
        'websockets': false,
        'driverDetailCharging': true,
        'discounts': true,
        'creditBox': true,
        'couponsHistory': true,
        'locationMap': true,
        'charging': true
    },
    dev: {
        'websockets': false,
        'driverDetailCharging': true,
        'discounts': true,
        'creditBox': true,
        'couponsHistory': true,
        'locationMap': true,
        'charging': true
    },

    uat: {
        'websockets': false,
        'driverDetailCharging': true,
        'discounts': true,
        'creditBox': true,
        'couponsHistory': true,
        'locationMap': true,
        'charging': false
    },
    production: {
        'websockets': false,
        'driverDetailCharging': true,
        'discounts': true,
        'creditBox': true,
        'couponsHistory': true,
        'locationMap': false,
        'charging': false
    }
}