import qs from 'qs'

// Utils
import { apiRequest } from '@/utils/apiRequest'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'

// Cookies
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({ dispatch }, error) => {
        if (error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if (typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if (typeof error.message !== 'undefined') {
                throw error.message
            } else if (typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    getConsents: async ({ commit, dispatch, rootState }, options) => {
        commit('setLoader', 1, { root: true })
        const { data } = await apiRequest('consents', {
            params: options,
            paramsSerializer: params => qs.stringify(params, { skipNulls: true })
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, { root: true })
        })

        return data.map(consent => ({
            id: consent.consent_id,
            code: consent.code,
            title: consent.text,
            checked: false,
            revocable: consent.revocable,
            visible: consent.visible_to_client,
            isLegal: !!consent.provided_by_legal,
            isIndividual: !!consent.provided_by_individual
        }))
    },
    setConsents: async ({ commit, dispatch }, payload) => {
        commit('setLoader', 1, { root: true })
        try {
            await apiRequest.put('client/consents', payload)
        } catch (error) {
            dispatch('checkStatus', error)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    },
}