<script setup>
import { watch } from 'vue'
import facilitySessionsHeaders from '@/composables/useTableHeaders'

// components
import Table from '@/components/Table.vue'
import ExportBtn from '@/components/ExportBtn.vue'
import ChargerSessionsFilter from './ChargerSessionsFilter.vue'
import FilterByToggle from '@/components/inputs/FilterByToggle.vue'

// composables
import useFacilitySessions from '@/composables/facilitySessions'

// translations
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
    chargerId: {
        type: String,
        required: true
    }
})
const {
    isTable,
    isFiltersVisible,
    isCustomVisible,
    isCardView,
    isExportLoading,
    windowWidth,
    selected,
    selectedRow,
    pickedItem,
    filtersSelected,
    options,
    actions,
    sessions,
    pagination,
    countSelectedItems,
    getItems,
    handleFacilityDownload,
    checkChargingToRowClass,
    getDefaultFilters,
    setFilter,
    clearFilters,
    sorting,
    closeDetails,
    rowSelect,
    headers,
    highlightElement,
    setWindowWidth
} = useFacilitySessions({
    filters: {
        charger_id: [props.chargerId],
        is_roaming: null,
        status_session: null,
        date: {
            from: null,
            to: null
        }
    },
    tableHeaders: facilitySessionsHeaders()
})

watch(sessions, () => {
    pickedItem.value = null
})

watch(windowWidth, () => {
    isTable.value = !(windowWidth.value < 600 && pickedItem.value)
})

watch(options, () => {
    getItems()
})
</script>

<template>
    <v-container
        class="charger-sessions pa-0"
        fluid
    >
        <main class="charger-sessions__main mt-5">
            <v-row class="new-line pt-3 pb-7">
                <v-col
                    class="left-size"
                    cols="6"
                >
                    <h1 class="section__title float-left pl-5">
                        {{ t('chargerDetails.Sessions') }}
                    </h1>
                </v-col>
                <v-col
                    class="charger-sessions__options"
                    cols="6"
                >
                    <div class="options-wrapper">
                        <ExportBtn
                            :loading="isExportLoading"
                            @handleDownload="handleFacilityDownload"
                        />
                        <FilterByToggle
                            :active="isFiltersVisible"
                            @change="isFiltersVisible = !isFiltersVisible"
                        />
                    </div>
                </v-col>
            </v-row>
            <ChargerSessionsFilter
                v-show="isFiltersVisible"
                :charger-id="chargerId"
                :default-filters="getDefaultFilters()"
                :filters-selected="filtersSelected"
                @clearFilters="clearFilters"
                @getItems="getItems"
                @setFilter="setFilter"
            />
            <div>
                <Table
                    :card-view="true"
                    :headers="headers"
                    :is-descending="options.sortDesc ? options.sortDesc : true"
                    :item-class="checkChargingToRowClass"
                    :item-key="'id'"
                    :items="sessions"
                    :model="selected"
                    :pagination="pagination"
                    :server-items="pagination.itemsLength"
                    :show-select="false"
                    :single-select="true"
                    :sort-column-name="options.sortBy[0]"
                    class="chargers-sessions__table"
                    @options="value => options = value"
                >
                    <template v-slot:charging_to="props">
                        <v-progress-linear
                            v-if="!props.item.charging_to"
                            :height="7"
                            :rounded="true"
                            color="primary"
                            indeterminate
                        />
                        <template v-else>
                            {{ props.item.charging_to }}
                        </template>
                    </template>
                    <template v-slot:total_minutes="props">
                        <template v-if="!props.item.charging_to">
                            <span class="text-primary">{{ t('driverInfo.InProgress') }}</span>
                        </template>
                        <template v-else>
                            {{ props.item.total_minutes }}
                        </template>
                    </template>
                    <template v-slot:total_kwh="props">
                        <template v-if="!props.item.charging_to">
                            <span>-</span>
                        </template>
                        <template v-else>
                            {{ props.item.total_kwh }}
                        </template>
                    </template>
                </Table>
            </div>
        </main>
    </v-container>
</template>