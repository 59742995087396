import { i18n } from '@/modules/i18n'

const isAvailableCharger = (connector) => {
    switch (connector.status) {
        case 'ConnectorStatus_Available': // available,
        case 'ConnectorStatus_Occupied': // occupied,
        case 'ConnectorStatus_Preparing': // preparing,
            return true
        default:
            return false
    }
}

const getIconCharger = (connector) => {
    switch (connector.status) {
        case 'ConnectorStatus_Available': // available,
        case 'ConnectorStatus_Preparing': // preparing,
            return 'mdi-flash'
        default:
            return 'mdi-stop'
    }
}

const getHintCharger = (connector) => {
    switch (connector.status) {
        case 'ConnectorStatus_Out_Of_Communication':
        case 'ConnectorStatus_Unavailable':
        case 'ConnectorStatus_In_Fault':
        case 'ConnectorStatus_Out_Of_Working_Time':
        case 'ConnectorStatus_Suspended_EVSE':
        case 'ConnectorStatus_Suspended_EV':
            return i18n.global.t('chargerManagement.Unavailable')
        case 'ConnectorStatus_Available': // available,
        case 'ConnectorStatus_Preparing': // preparing,
            return i18n.global.t('chargerManagement.startCharging')
        case 'ConnectorStatus_Occupied':
            return i18n.global.t('chargerManagement.stopCharging')
    }
}

const isAvailableConnectors = (connector) => {
    switch (connector.etrel_status) {
        case 'ConnectorStatus_Available': // available,
        case 'ConnectorStatus_Occupied': // occupied,
        case 'ConnectorStatus_Preparing': // preparing,
            return true
        default:
            return false
    }
}

const getIconConnectors = (connector) => {
    switch (connector.etrel_status) {
        case 'ConnectorStatus_Available': // available,
        case 'ConnectorStatus_Preparing': // preparing,
            return 'mdi-flash'
        default:
            return 'mdi-stop'
    }
}

const getHintConnectors = (connector) => {
    switch (connector.etrel_status) {
        case 'ConnectorStatus_Out_Of_Communication':
        case 'ConnectorStatus_Unavailable':
        case 'ConnectorStatus_In_Fault':
        case 'ConnectorStatus_Out_Of_Working_Time':
        case 'ConnectorStatus_Suspended_EVSE':
        case 'ConnectorStatus_Suspended_EV':
            return i18n.global.t('chargerManagement.Unavailable')
        case 'ConnectorStatus_Available': // available,
        case 'ConnectorStatus_Preparing': // preparing,
            return i18n.global.t('chargerManagement.startCharging')
        case 'ConnectorStatus_Occupied':
            return i18n.global.t('chargerManagement.stopCharging')
    }
}

const popupData = {
    title: i18n.global.t('driverInfo.AreYouSure'),
    type: 'question',
    subtitle: i18n.global.t('chargerManagement.YouWantToStopCharging'),
    icon: 'mdi-help-circle'
}

const getPopupData = () => {
    return popupData
}

const getConnectorParamFromQuery = (route) => {
    const connectorId = route.query.connectorId || null
    return connectorId ? {param:'id', value: connectorId} : {param:'code', value: route.query.connectorCode}
}

export {
    getConnectorParamFromQuery,
    isAvailableConnectors,
    getIconConnectors,
    getHintConnectors,
    isAvailableCharger,
    getIconCharger,
    getHintCharger,
    getPopupData,
    popupData
}