<script setup>
import store from '@/store'
import { computed, watch } from 'vue'

// composables
import useChargingCards from '@/composables/chargingCards/useChargingCards'
import useFilteredCards from '@/composables/chargingCards/useFilteredCards'

// components
import ChargingCardDetail from '@/pages/chargingCards/components/lists/ChargingCardDetail.vue'
import ActionPrompt from '@/pages/chargingCards/components/lists/ActionPrompt.vue'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { getters } = store

const {
    isFiltersVisible,
    fetchingData,
    chargingCardsListItems: items,
    selectedCards,
    showDetail,
    cardDetail,
    closeDetail,
    detailVisible,
    showActionPrompt,
    PRIMARY_DRIVER_ID
} = useChargingCards()

const {
    filteredCards,
    printedIdFilterValue,
    selectedStateFilterValue,
    assigneeNameFilterValue
} = useFilteredCards(items, PRIMARY_DRIVER_ID)

const tableHeaders = computed(() => {
    return getters['drivers/chargingCardsTableHeaders']
})

watch(detailVisible, (newVal) => {
    if (!newVal) cardDetail.value = null
})

const selectValue = [
    {
        title: t('chargingCards.activeOnly'),
        value: 'active'
    }, {
        title: t('chargingCards.unassignedOnly'),
        value: 'unassigned'
    }
]
</script>

<template>
    <v-container
        class="list pa-0"
        fluid
    >
        <div class="d-flex align-center justify-center">
            <v-data-table
                v-model="selectedCards"
                :headers="tableHeaders"
                :items="filteredCards"
                :loading="fetchingData"
                :show-select="true"
                return-object
                class="table"
            >
                <template v-slot:[`item.card_number`]="{ item }">
                    <span
                        v-if="item.assignee_id === null"
                        class="light-grey"
                    >{{ item.card_number }}</span>
                    <span v-else>{{ item.card_number }}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <div class="d-flex align-center">
                        <v-icon
                            v-if="item.is_active === false"
                            size="x-small"
                        >
                            mdi-lock
                        </v-icon>
                        <v-icon
                            v-else
                            color="var(--v-primary)"
                            size="x-small"
                        >
                            mdi-lock-open
                        </v-icon>
                        <span class="ml-1">{{ item.status }}</span>
                    </div>
                </template>
                <template v-slot:[`item.assignee_id`]="{ item }">
                    <span
                        v-if="item.assignee_id === PRIMARY_DRIVER_ID && item.is_active === false"
                        class="light-grey"
                    />
                    <span v-else>{{ item.assignee_id }}</span>
                </template>
                <template v-slot:[`item.assignee_name`]="{ item }">
                    <span
                        v-if="item.assignee_id === PRIMARY_DRIVER_ID && item.is_active === false"
                        class="light-grey"
                    >{{
                        t('chargingCards.unassigned')
                    }}</span>
                    <span v-else>{{ item.assignee_name }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions d-flex justify-end">
                        <div
                            v-for="(action, index) in item.actions"
                            :key="index"
                            class="action"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        :disabled="fetchingData"
                                        icon
                                        variant="plain"
                                        size="x-small"
                                        v-bind="props"
                                        @click.stop.prevent="showActionPrompt(action.action, item)"
                                    >
                                        <v-icon>
                                            {{ action.icon }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ action.title }}</span>
                            </v-tooltip>
                        </div>
                    </div>
                </template>
                <template v-slot:top>
                    <v-container
                        v-if="isFiltersVisible"
                        fluid
                    >
                        <v-row class="mt-4 mb-2 px-4 flex-column flex-sm-row">
                            <v-col
                                cols="12"
                                sm="4"
                            >
                                <v-select
                                    v-model="selectedStateFilterValue"
                                    :items="selectValue"
                                    :placeholder="t('tableHeaders.Status')"
                                    clearable
                                    density="compact"
                                    hide-details
                                    variant="outlined"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                            >
                                <v-text-field
                                    v-model="printedIdFilterValue"
                                    :label="t('chargingCards.printedId')"
                                    clearable
                                    density="compact"
                                    hide-details
                                    variant="outlined"
                                    type="number"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                            >
                                <v-text-field
                                    v-model="assigneeNameFilterValue"
                                    :label="t('tableHeaders.AssigneeName')"
                                    clearable
                                    density="compact"
                                    hide-details
                                    variant="outlined"
                                    type="text"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-container
                    v-if="selectedCards.length > 0"
                    fluid
                >
                    <v-row>
                        <v-col
                            class="d-flex justify-end py-6 px-4"
                            cols="12"
                        >
                            <v-btn
                                class="text-none"
                                small
                                text
                                @click="showActionPrompt('removeCards', selectedCards)"
                            >
                                <v-icon>mdi-delete</v-icon>
                                {{ t('chargingCards.deleteSelected') }} ({{ selectedCards.length }})
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-data-table>
    </div>
    <v-navigation-drawer
        v-model="detailVisible"
        class="detail-drawer"
        location="right"
        :temporary="true"
        width="420"
        @update:model-value="closeDetail"
    >
        <div class="sessions__side"
             :class="{'pa-5 mt-10':detailVisible}"
        >
            <ChargingCardDetail
                v-if="cardDetail !== null"
                @close="closeDetail"
            />
        </div>
    </v-navigation-drawer>
    <ActionPrompt />
</v-container></template>
