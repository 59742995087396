<script setup>
// composables
import useChargingCards from '@/composables/chargingCards/useChargingCards'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const {
    cardDetail,
    closeDetail,
    PRIMARY_DRIVER_ID
} = useChargingCards()


</script>

<template>
    <v-container class="chargin-card-detail pa-8">
        <div class="heading d-flex justify-space-between mb-6">
            <h5 class="text-h5">
                {{ t('chargingCards.chargingCardDetailTitle') }}
            </h5>
            <v-btn icon @click="closeDetail">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <v-row v-if="cardDetail.assignee_id !== null">
            <v-col class="title text-subtitle-2" cols="6">
                {{ t('tableHeaders.AssigneeName') }}
            </v-col>
            <v-col class="value text-subtitle-2" cols="6">
                <template v-if="cardDetail.assignee_id === PRIMARY_DRIVER_ID && cardDetail.is_active === false">
                    {{ t('chargingCards.unassigned') }}
                </template>
                <router-link v-else :to="`/drivers/${cardDetail.assignee_id}`">
                    {{ cardDetail.assignee_name }}
                </router-link>
            </v-col>
        </v-row>
        <v-row v-if="cardDetail.assignee_id !== null">
            <v-col class="title text-subtitle-2" cols="6">
                {{ t('chargingCards.assigneeId') }}
            </v-col>
            <v-col class="value text-subtitle-2" cols="6">
                {{ cardDetail.assignee_id }}
            </v-col>
        </v-row>
        <v-row>
            <v-col class="title text-subtitle-2" cols="6">
                {{ t('tableHeaders.Status') }}
            </v-col>
            <v-col class="value text-subtitle-2" cols="6">
                {{ cardDetail.status }}
            </v-col>
        </v-row>
        <v-row>
            <v-col class="title text-subtitle-2" cols="6">
                {{ t('chargingCards.printedId') }}
            </v-col>
            <v-col class="value text-subtitle-2" cols="6">
                {{ cardDetail.card_number }}
            </v-col>
        </v-row>
    </v-container>
</template>