<script setup>
import { watch } from 'vue'

// components
import Table from '@/components/Table.vue'
import ExportBtn from '@/components/ExportBtn.vue'
import LocationSessionsFilter from './LocationSessionsFilter.vue'
import FilterByToggle from '@/components/inputs/FilterByToggle.vue'

// composables
import useFacilitySessions from '@/composables/facilitySessions'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
    locationId: {
        type: String,
        required: true
    }
})

const {
    isFiltersVisible,
    isExportLoading,
    selected,
    filtersSelected,
    options,
    headers,
    sessions,
    pagination,
    getItems,
    handleFacilityDownload,
    checkChargingToRowClass,
    getDefaultFilters,
    setFilter,
    clearFilters
} = useFacilitySessions({
    filters: {
        location_id: props.locationId,
        is_roaming: null,
        status_session: null,
        date: {
            from: null,
            to: null
        }
    },
    options: {
        sortBy: ['charging_from'],
        sortDesc: true
    }
})

watch(options, () => {
    getItems()
})
</script>

<template>
    <v-container class="location-sessions pa-0" fluid>
        <main class="location-sessions__main">
            <div class="location-sessions__options">
                <h2 class="section__title">
                    {{ t('locationDetails.Sessions') }}
                </h2>
                <ExportBtn :loading="isExportLoading" @handleDownload="handleFacilityDownload" />
                <FilterByToggle :active="isFiltersVisible" @change="isFiltersVisible = !isFiltersVisible" />
            </div>
            <LocationSessionsFilter
                v-show="isFiltersVisible"
                :default-filters="getDefaultFilters()"
                :filters-selected="filtersSelected"
                @clearFilters="clearFilters"
                @getItems="getItems"
                @setFilter="setFilter"
            />
            <div>
                <Table
                    class="locations-sessions__table"
                    :card-view="true"
                    :headers="headers"
                    :is-descending="options.sortDesc ? options.sortDesc : true"
                    :item-class="checkChargingToRowClass"
                    :item-key="'id'"
                    :items="sessions"
                    :model="selected"
                    :pagination="pagination"
                    :server-items="pagination.itemsLength"
                    :sort-column-name="options.sortBy[0]"
                    @options="(value) => (options = value)"
                >
                    <template v-slot:charging_to="props">
                        <v-progress-linear
                            v-if="!props.item.charging_to"
                            :height="7"
                            :rounded="true"
                            color="primary"
                            indeterminate
                        />
                        <template v-else>
                            {{ props.item.charging_to }}
                        </template>
                    </template>
                    <template v-slot:total_minutes="props">
                        <template v-if="!props.item.charging_to">
                            <span class="text-primary">{{ t('driverInfo.InProgress') }}</span>
                        </template>
                        <template v-else>
                            {{ props.item.total_minutes }}
                        </template>
                    </template>
                    <template v-slot:total_kwh="props">
                        <template v-if="!props.item.charging_to">
                            <span>-</span>
                        </template>
                        <template v-else>
                            {{ props.item.total_kwh }}
                        </template>
                    </template>
                </Table>
            </div>
        </main>
    </v-container>
</template>
