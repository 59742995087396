<script setup>
// translations
import {useI18n} from 'vue-i18n'
const { t } = useI18n()

defineProps({
    info: {
        required: true,
        default: () => {
            return null
        }
    }
})

const emit = defineEmits(['close'])

const getSvgIcon = (type) => {
    switch (type) {
    case 'AC Type 2 Cable':
    case 'AC Type 2 kábel':
    case 'przewód AC Typ 2':
    case 'AC Type 2 Socket':
    case 'AC Type 2 zásuvka':
    case 'AC typ 2 gniazdo':
        return 'connectorType2Cable'
    case 'CCS':
        return 'connectorCCS'
    case 'CHAdeMO':
        return 'connectorCHAdeMO'
    }
}

const getSvgText = (type) => {
    switch (type) {
    case 'AC Type 2 Cable':
    case 'AC Type 2 kábel':
    case 'przewód AC Typ 2':
        return t('chargerDetails.IconConnectorType2Cable')
    case 'AC Type 2 Socket':
    case 'AC Type 2 zásuvka':
    case 'AC typ 2 gniazdo':
        return t('chargerDetails.IconConnectorType2Socket')
    default:
        return ''
    }
}

const getConnectorTitle = (connector) => {
    return connector.type + ' ' + t('chargerDetails.ConnectorInformation')
}
</script>

<template>
    <div v-if="info" class="connector-info">
        <header class="connector-info__header">
            <h2 class="pl-md-5">
                {{ getConnectorTitle(info) }}
            </h2>
            <a class="close-button" @click="emit('close')">
                <v-icon class="active-icon">mdi-close</v-icon>
            </a>
        </header>
        <div class="connector-info__card-body">
            <v-row>
                <v-col class="connector-info__block" cols="12">
                    <h3>{{ t('chargerDetails.ConnectorCode') }}</h3>
                    <div>{{ info.code }}</div>
                </v-col>
                <v-col class="connector-info__block" cols="12">
                    <h3>{{ t('chargerDetails.MaxPower') }}</h3>
                    <div>{{ info.max_power }} kW</div>
                </v-col>
                <v-col class="connector-info__block" cols="12">
                    <h3>{{ t('chargerDetails.ConnectorChargingType') }}</h3>
                    <div>{{ info.ac_dc }}</div>
                </v-col>
                <v-col class="connector-info__block" cols="12">
                    <h3>{{ t('chargerDetails.EVSE Code') }}</h3>
                    <div>{{ info.evse_id }}</div>
                </v-col>
                <v-col class="connector-info__block" cols="12">
                    <h3>{{ t('chargerDetails.ConnectorType') }}</h3>
                    <div class="info-holder d-flex align-center">
                        <div class="icon d-flex align-center pr-3">
                            <img :src="`/assets/icons/${getSvgIcon(info.type)}.svg`" width="30" alt=""/>
                            <span class="icon-text pl-2">{{ getSvgText(info.type) }}</span>
                        </div>
                        <div class="pl-3 border-left">
                            {{ info.type }}
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>