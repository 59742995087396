import { reactive, computed, toRefs } from 'vue'
import { apiRequest } from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessage'
import useRegistration from '@/composables/registration/useRegistration'
import store from '@/store'

const { dispatch } = store

const globalState = reactive({
    tariffs: [],
    selectedTariff: null,
    locale: import.meta.env.VITE_APP_I18N_LOCALE,
    userTariffData: null,
    clickedIndex: null,
    loading: false
})

const getCurrency = computed(() => {
    return import.meta.env[`VITE_APP_I18N_CURRENCY_${globalState.locale.toUpperCase()}`] || import.meta.env.VITE_APP_I18N_CURRENCY
})

export default function usePriceProgram() {

    const { nextStep } = useRegistration()

    const getTariffs = async () => {
        try {
            const { data } = await apiRequest('tariffs')
            globalState.tariffs = data.map(tariff => ({
                id: tariff.id,
                name: tariff.name,
                monthlyPrice: tariff.price_list.monthlyPrice,
                priceList: tariff.price_list
            }))
        } catch (error) {
            dispatch('tariffs/checkStatus', error)
            return []
        }
    }

    const registerTariff = async (id) => {
        try {
            globalState.loading = true
            await apiRequest.post('auth/registration-data', { tariff_id: id })
            await dispatch('account/getAccount').then(async response => {
                globalState.userTariffData = response.registration_data
                globalState.selectedTariff = globalState.tariffs.find(x => x.id === globalState.userTariffData.tariff_id)
            })
        } catch (error) {
            showMessage(error.errors.tariff_id[0])
        } finally {
            globalState.loading = false
        }
    }

    const next = async (id, index) => {
        globalState.clickedIndex = index
        await registerTariff(id)
        nextStep()
    }

    return {
        getCurrency,
        ...toRefs(globalState),
        getTariffs,
        next
    }
} 