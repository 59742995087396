export default function isEmptyObj(obj) {
    if(!obj) {
        return true
    }

    if (Object.keys(obj).length === 0) {
        return true
    }

    for (let key in obj) {
        if (obj[key] !== null && obj[key] != '')
            return false
    }

    return true
}