<script setup>
import { watch, onBeforeMount } from 'vue'

// components
import Table from '@/components/Table.vue'
import LocationsListFilter from './LocationsListFilter.vue'

// composables
import uselocations from '@/composables/locations'

defineProps({
    isFiltersVisible: {
        type: Boolean,
        required: true
    }
})
const {
    options,
    filtersSelected,
    selected,
    headers,
    locations,
    pagination,
    windowWidth,

    getItems,
    clearFilters,
    setFilter,
    getDefaultFilters,
    goToDetail
} = uselocations()

watch(options, () => {
    getItems()
})

onBeforeMount(() => {
    getItems()
})
</script>

<template>
    <v-container
        class="locations pa-0"
        fluid
    >
        <LocationsListFilter
            v-show="isFiltersVisible"
            :default-filters="getDefaultFilters()"
            :filters-selected="filtersSelected"
            @clearFilters="clearFilters"
            @getItems="getItems"
            @setFilter="setFilter"
        />
        <div>
            <Table
                class="locations__table"
                :card-view="true"
                :headers="headers"
                :is-descending="options.sortDesc ? options.sortDesc : true"
                :item-key="'id'"
                :items="locations"
                :model="selected"
                :pagination="pagination"
                :server-items="pagination.itemsLength"
                :sort-column-name="options.sortBy[0]"
                @options="value => options = value"
            >
                <template
                    v-if="windowWidth < 960"
                    v-slot:name="props"
                >
                    <span class="text-primary">{{ props.item.name }}</span>
                </template>
                <template
                    v-else
                    v-slot:name="props"
                >
                    <span>{{ props.item.name }}</span>
                </template>
                <template v-slot:code="props">
                    <span>{{ props.item.code }}</span>
                </template>
                <template v-slot:detail="props">
                    <v-btn
                        icon="mdi-magnify"
                        variant="text"
                        @click="goToDetail(props.item)"
                        color="primary"
                    />
                </template>
            </Table>
        </div>
    </v-container>
</template>