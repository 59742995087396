export const mutations = {
    setConnectors: (state, connectors) => {
        state.connectors = connectors
    },
    setConnector: (state, connector) => {
        state.connector = connector
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination
    },
    toggleConnectorEdit: (state, value) => {
        state.isEditView = value
    },
    setPage: (state, page) => {
        state.pagination.page = page
    },
    setItemPerPage: (state, itemsPerPage) => {
        state.pagination.itemsPerPage = itemsPerPage
    },
    setFilters: (state, filters) => {
        state.filters = filters
    },
    setIsChargingEventTriggered: (state, value) => {
        state.isChargingEventTriggered = value
    }
}