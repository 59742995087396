<script setup>

// composables
import useWindowWidth from '@/composables/windowWidth'
import useChargingCards from '@/composables/chargingCards/useChargingCards'

const { tab, tabItems } = useChargingCards()
const {windowWidth} = useWindowWidth()

</script>

<script>
// components - TODO - Do we need to import this here?
import ListOfChargingCards from '@/pages/chargingCards/components/lists/ListOfChargingCards.vue'
import ListOfOrders from '@/pages/chargingCards/components/lists/ListOfOrders.vue'

export default {
    components: {
        ListOfChargingCards,
        ListOfOrders
    }
}
</script>

<template>
    <div class="tab-content">
        <v-window v-model="tab"
                  :touch="false"
        >
            <v-window-item
                v-for="item in tabItems"
                :key="item.title"
                transition="fade-transition"
                :disabled="windowWidth <= 960"
            >
                <component :is="item.content" />
            </v-window-item>
        </v-window>
    </div>
</template>
