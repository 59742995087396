<script setup>
import { watch } from 'vue'

// composables
import useConnectors from '../../../composables/connectors'

// components
import Table from '@/components/Table.vue'
import Modal from '@/components/modals/Modal.vue'
import Popup from '@/components/modals/Popup.vue'
import ConnectorsListFilter from './ConnectorsListFilter.vue'
import ChargingPrice from '@/components/chargingForm/ChargingPrice.vue'

// utils
import { isAvailableConnectors, getIconConnectors, getHintConnectors, popupData } from '@/utils/connectors'

// translations
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
    isFiltersVisible: {
        type: Boolean,
        required: true
    }
})
const {
    activePopupStartCharging,
    activePopupStopCharging,
    activePopup,
    windowWidth,
    selected,
    filtersSelected,
    options,
    actions,
    headers,
    connectors,
    pagination,
    countSelectedItems,
    getItems,
    getDefaultFilters,
    setFilter,
    clearFilters,
    sorting,
    goToDetail,
    getActions,
    handleBatchAction,
    batchAction,
    action,
    selectedItems,
    setWindowWidth,
    handleActionConnectors,
    cancel,
    selectedConnector,
    driver,
    primaryDriverId,
    isChargingEventTriggered
} = useConnectors()


watch(options, () => {
    getItems()
})
</script>


<template>
    <v-container
        class="connectors connectors-list-wrapper pa-0"
        fluid
    >
        <ConnectorsListFilter
            v-show="isFiltersVisible"
            :default-filters="getDefaultFilters()"
            :filters-selected="filtersSelected"
            @clearFilters="clearFilters"
            @getItems="getItems"
            @setFilter="setFilter"
        />
        <div>
            <div
                v-if="!!countSelectedItems"
                class="connectors__actions"
            >
                <span class="mr-6">
                    {{ countSelectedItems }} {{ t('drivers.itemsAreSelected') }}
                </span>
                <div
                    v-for="{ icon, action, title } in actions"
                    :key="title"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-show="!!countSelectedItems"
                                :key="title"
                                class="actions"
                                color="var(--v-color-gray-darken2)"
                                variant="text"
                                v-bind="props"
                                @click="handleBatchAction(action)"
                            >
                                <v-icon>{{ icon }}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ title }}</span>
                    </v-tooltip>
                </div>
            </div>
            <div
                v-for="action in actions"
                :key="action.title"
            >
                <Popup
                    :active="activePopup === action.action"
                    :popup-data="action.question"
                    @accept="batchAction(action.action)"
                    @cancel="activePopup = ''"
                />
            </div>
            <Table
                class="connectors__table"
                :card-view="true"
                :headers="headers"
                :is-descending="options.sortDesc ? options.sortDesc : true"
                :item-key="'id'"
                :items="connectors"
                :model="selected"
                :pagination="pagination"
                :server-items="pagination.itemsLength"
                :show-select="false"
                :sort-column-name="options.sortBy[0]"
                @countSelectedItems="selectedItems"
                @options="value => options = value"
            >
                <template v-slot:actions="item">
                    <v-tooltip
                        v-if="isAvailableConnectors(item.item)"
                        bottom
                    >
                        <template v-slot:activator="{ props }">

                            <span
                                :disabled="isChargingEventTriggered"
                                class="charger-status__charging"
                                text
                                v-bind="props"
                                @click="handleActionConnectors(item.item)"
                            >
                                <v-icon class="active-icon charger-status__charging-icon">
                                    {{ getIconConnectors(item.item) }}
                                </v-icon>
                                <span />
                            </span>
                        </template>
                        <span>{{ getHintConnectors(item.item) }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:charger_label="props">
                    <span>{{ props.item.charger_label }}</span>
                </template>
                <template
                    v-if="windowWidth < 960"
                    v-slot:code="props"
                >
                    <span class="text-primary">{{ props.item.code }}</span>
                </template>
                <template
                    v-else
                    v-slot:code="props"
                >
                    <span>{{ props.item.code }}</span>
                </template>
                <template v-slot:detail="props">
                    <v-btn
                        icon="mdi-magnify"
                    variant="text"
                    @click="goToDetail(props.item)"
                    color="primary"
                />
            </template>
        </Table>
        <Modal
            :is-active="activePopupStartCharging"
            :max-width="530"
            @cancel="cancel()"
        >
            <ChargingPrice
                v-if="activePopupStartCharging"
                :connector="selectedConnector"
                :driver="primaryDriverId"
                @close="cancel()"
            />
        </Modal>
        <Popup
            :active="activePopupStopCharging"
            :popup-data="popupData"
            class-item="d-flex justify-center align-center"
            @accept="action('stopCharging', selectedConnector)"
            @cancel="cancel()"
        />
    </div>
</v-container></template>