<script setup>
import router from '@/router.js'

// components
import Btn from '@/components/inputs/Btn.vue'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const emit = defineEmits(['goBack'])

const props = defineProps({
	skipGo: {
		type: Boolean,
		required: false,
		default: () => {
			return false
		}
	}
})

const goBack = () => {
    emit('goBack')
	if(!props.skipGo) {
		router.go(-1)
	}
}
</script>

<template>
    <div>
        <Btn :ripple="false"
             class="back-button back-mobile text-none pa-0 active-button active-icon"
             color="rgb(var(--v-theme-color-gray))"
             :variant="'text'"
             @click="goBack"
        >
            <v-icon class="mr-2">
                mdi-arrow-left
            </v-icon>
            <span class="back-mobile--text">{{ t('BtnGoBack.Back') }}</span>
        </Btn>
    </div>
</template>