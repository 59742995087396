<script setup>
import { watch } from 'vue'

// composables
import useChargers from '@/composables/chargers'

// components
import Table from '@/components/Table.vue'
import Btn from '@/components/inputs/Btn.vue'
import ChargersListFilter from '@/pages/chargerManagement/components/ChargersListFilter.vue'

// translations
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
    isFiltersVisible: {
        type: Boolean
    }
})

const {
    windowWidth,
    currentCharger,
    isPopupStartCharging,
    isPopupStopCharging,
    isPopupRestartCharging,
    filtersSelected,
    options,
    getItems,
    getDefaultFilters,
    setFilter,
    clearFilters,
    sorting,
    selected,
    popupData,
    headers,
    chargers,
    pagination,
    goToDetail,
    restart
} = useChargers({
    filters: {
        code: null,
        status: null,
        location: {
            code: null,
            name: null
        }
    }
})


watch(options, () => {
    getItems()
})
</script>

<template>
    <v-container
        class="chargers-list pa-0"
        fluid
    >
        <ChargersListFilter
            v-show="isFiltersVisible"
            :default-filters="getDefaultFilters()"
            :filters-selected="filtersSelected"
            @clearFilters="clearFilters"
            @getItems="getItems"
            @setFilter="setFilter"
        />
        <div>
            <Table
                class="chargers__table"
                :card-view="true"
                :headers="headers"
                :is-descending="options.sortDesc ? options.sortDesc : true"
                :item-key="'id'"
                :items="chargers"
                :model="selected"
                :pagination="pagination"
                :server-items="pagination.itemsLength"
                :sort-column-name="options.sortBy[0]"
                @options="value => options = value"
            >
                <template
                    v-if="windowWidth < 960"
                    slot="detail"
                >
                    <Btn class="chargers__btn">
                        {{ t('drivers.details') }}
                    </Btn>
                </template>
                <template
                    v-if="windowWidth < 960"
                    v-slot:manufacture="props"
                >
                    <span class="text-primary">{{ props.item.manufacture }}</span>
                </template>
                <template
                    v-else
                    v-slot:manufacture="props"
                >
                    <span>{{ props.item.manufacture }}</span>
                </template>
                <template v-slot:code="props">
                    <span>{{ props.item.code }}</span>
                </template>
                <template v-slot:detail="props">
                    <v-btn
                        icon="mdi-magnify"
                        variant="text"
                        @click="goToDetail(props.item)"
                        color="primary"
                    />
                </template>
            </Table>
        </div>
    </v-container>
</template>
