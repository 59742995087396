import {getDefaultState} from './helper'
import qs from 'qs'
import { apiRequest } from '@/utils/apiRequest'

export const actions = {
    getOauthClient: async ({commit, rootState}) => {
        try {
            const {data} = await apiRequest('auth/authorize', {
                params: rootState.oauth,
                paramsSerializer: params => qs.stringify(params, { skipNulls: true })
            }).catch(e => {
                commit('setFieldValue', {
                    field: 'errors',
                    value: e
                })
                const errorStatus = e?.response.status
                const redirectTo = e?.response?.data?.redirect_to ? e.response.data.redirect_to : '/welcome'

                switch (errorStatus) {
                case 403:
                    const store = require('@/store')
                    const {i18n} = require('@/i18n')

                    commit('resetStore')
                    store.default.commit('setNotify', {
                        content: i18n.t('error.AuthorizationError'),
                        color: 'error'
                    }, {root: true})

                    // redirect
                    setTimeout(() => window.location.href = redirectTo, 5000)
                    break
                default:
                    throw ('error.SomethingGoesWrong')
                }
            })

            // TODO - Andrej - double check if this should be here
            // TODO - Andrej  - saving data only to session storage
            commit('authorize', data)
        }
        catch (e) {
            throw ('error.AuthorizationError')
        }
    },
    saveClient: async ({commit}, data) => {
        commit('setOauthClient', data)
    },
    resetClient: async ({commit}) => {
        commit('setOauthClient', getDefaultState())
    }
}