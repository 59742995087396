<script setup>
import { ref, reactive, computed, onMounted, onUnmounted, onBeforeMount } from 'vue'
import qs from 'qs'
import store from '@/store'

// components
import Logo from '@/components/icons/Logo.vue'
import Btn from '@/components/inputs/Btn.vue'
import LangSwitcher from '@/components/inputs/LangSwitcher.vue'

// utils
import { useI18n } from 'vue-i18n'

const { dispatch, getters, commit } = store
const { t } = useI18n()

const state = reactive({
    showDebug: false,
    buttonsEnabled: false,
    emailSupport: import.meta.env.VITE_APP_EMAIL_SUPPORT,
    error: false,
    clicked: false
})
const oauthForm = ref()

const locale = computed(() => {
    return getters['account/locale'].toUpperCase()
})
const lang = computed(() => {
    return getters['account/lang']?.toUpperCase() || null
})
const getWebPageLink = computed(() => {
    return t('weblinks.VITE_APP_WEB')
})
const oauth = computed(() => {
    return getters['oauth/oauth']
})

const toggleDebugger = () => {
    state.showDebug = !state.showDebug
}
const submitForm = () => {
    // https://auth0.com/docs/authenticate/protocols/oauth
    // TODO - POST FORM - redirect to - has code/state
    // redirect to - action
    // params - hidden params -> code / state
    oauthForm.value.submit()
}
const authorize = () => {
    state.clicked = true
    const redirect_to = getters['oauth/redirect_to'] || getters['oauth/redirect_uri']

    // RESET
    dispatch('oauth/resetClient')
    commit('oauth/resetStore')

    switch (this.responseMode) {
        case 'query':
        case 'fragment':
            // redirect
            window.location.href = redirect_to
            return
        case 'web_message':
            return
        case 'form_post':
        default:
            window.location.href = redirect_to
        // this.submitForm()
    }
}
const cancel = () => {
    const redirect_uri = getters['oauth/redirect_uri'] || '/'

    // RESET
    dispatch('oauth/resetClient')
    commit('oauth/resetStore')

    window.location.href = redirect_uri + '?' + qs.stringify(state.error, { skipNulls: true })
}

onMounted(() => {
    window.onbeforeunload = () => {
        dispatch('oauth/resetClient')
        commit('oauth/resetStore')
    }
})
onUnmounted(() => {
    window.onbeforeunload = null
})
onBeforeMount(async () => {
    try {
        await dispatch('oauth/getOauthClient')
        state.buttonsEnabled = true
    } catch (e) {
        state.error = e.response ? e.response.data : { message: 'Invalid authorization' }
    }
})
</script>

<template>
    <div>
        <section class="oauth">
            <header class="oauth__header">
                <a
                    :href="getWebPageLink"
                    target="_blank"
                >
                    <Logo
                        width="184px"
                        height="37px"
                    />
                </a>
                <LangSwitcher />
            </header>
            <main class="oauth__main">
                <h1 class="oauth__main-title mb-7">
                    {{ t('oAuth.title') }}
                </h1>
                <v-alert
                    v-if="state.error"
                    color="red"
                    type="error"
                >
                    {{ state.error.message }}
                </v-alert>
                <template v-else>
                    <span v-if="!oauth.redirect_to">
                        <v-progress-circular
                            class="help-page__loader mb-9"
                            :size="35"
                            indeterminate
                            color="rgb(var(--v-theme-primary))"
                        />
                    </span>
                    <p
                        v-else
                        class="text-center"
                    >
                        {{ t('oAuth.message', { client: oauth.client_name }) }}
                    </p>
                </template>
                <div class="d-flex align-center">
                    <Btn
                        v-if="!state.error"
                        :loading="store.getters['isLoading']"
                        color="primary"
                        class="mr-4"
                        variant="outlined"
                        :disabled="!oauth.redirect_to || state.clicked"
                        @click="authorize"
                    >
                        {{ t('oAuth.authorize') }}
                    </Btn>
                    <Btn
                        class="mr-4"
                        text
                        @click="cancel"
                    >
                        {{ t('oAuth.cancel') }}
                    </Btn>
                </div>
            </main>
            <footer class="text-center mt-7 pt-4">
                <p>{{ t('thankYou.anyQuestions') }}</p>
                <div>{{ t('phoneNumber.technical') }}</div>
                <div>{{ state.emailSupport }}</div>
            </footer>
        </section>
        <section class="hidden-form d-none">
            <form
                id="oauthForm"
                ref="oauthForm"
                class="d-none"
                method="post"
                :action="oauth.redirect_uri"
            >
                <input
                    type="hidden"
                    name="code"
                    :value="oauth.code"
                >
                <input
                    type="hidden"
                    name="state"
                    :value="oauth.state"
                >
                <button type="submit" />
            </form>
        </section>
        <section class="debugger">
        <v-btn
            rounded="xl"
            variant="text"
            @click="toggleDebugger"
        >
            <small class="text-lowercase">debug</small>
        </v-btn>
        <v-row v-if="state.showDebug">
            <v-col
                cols="10"
                offset="1"
            >
                <v-card elevation="0">
                    <small>
                        <strong>oauth:</strong> {{ oauth }}<br>
                        <strong>locale:</strong> {{ locale }}<br>
                        <strong>lang:</strong> {{ lang }}<br>
                        <strong>clientName:</strong> {{ oauth.client_name }}<br>
                        <strong>getWebPageLink:</strong> {{ getWebPageLink }}<br>
                        <strong>code:</strong> {{ oauth.code }}<br>
                        <strong>state:</strong> {{ oauth.state }}<br>
                        <strong><a :href="oauth.redirect_to">redirect_to</a>:</strong> {{ oauth.redirect_to }}<br>
                        <strong>redirect_uri:</strong> {{ oauth.redirect_uri }}<br>
                    </small>
                </v-card>
            </v-col>
        </v-row>
    </section>
</div></template>