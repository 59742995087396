<script setup>
// composables
import useCharging from '@/composables/charging/useCharging'

// translations
const {t} = useI18n()
import {useI18n} from 'vue-i18n'

const { commandId, cancelChargingCommand, fetchingCommandId } = useCharging()
</script>

<template>
    <v-card
        :elevation="3"
        class="
            initiating-connection
            mr-auto
            ml-auto
            mt-md-8 mt-2
            pa-4
            d-flex
            flex-column
            align-center
            justify-center
            text-center
        "
    >
        <div class="stage d-flex align-center justify-center mb-4">
            <div class="spinner d-flex align-center justify-center">
                <span class="dot" />
                <span class="line" />
            </div>
        </div>
        <h3 class="text-h6 text-center mb-2">
            {{ t("startCharging.initiatingConnection") }}
        </h3>
        <v-btn
            variant="outlined"
            rounded="xl"
            :disabled="fetchingCommandId"
            @click="cancelChargingCommand"
        >
            {{ t('startCharging.cancel') }}
        </v-btn>
    </v-card>
</template>
