import { createI18n } from 'vue-i18n'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// configs
import { Lang } from '@/configs/index.js'

import store from '@/store'
import en from '@/translations/EN.json'

const { state } = store

const locale = typeof cookies.get('gw_lang') !== 'undefined'
    ? cookies.get('gw_lang')
    : state.account.lang || import.meta.env.VITE_APP_I18N_LOCALE || Lang.en

const i18n = createI18n({
    legacy: false,
    locale,
    fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE,
    messages: { ...state.translation, en: en },
    silentTranslationWarn: true
})

const setMessages = (locale, messages) => {
    i18n.global.setLocaleMessage(locale, messages)
}

const setLocale = (locale) => {
    i18n.global.locale.value = locale
}

export {
    i18n,
    setMessages,
    setLocale
}
