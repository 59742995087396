<script setup>
import { reactive, computed } from 'vue'
import store from '@/store'

// components
import Btn from '@/components/inputs/Btn.vue'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { getters } = store
const emit = defineEmits(['handleDelete', 'saveCard'])

const props = defineProps({
    instance: {
        type: Object
    },
    paymentInfo: {
        type: Object
    }
})

const state = reactive({
    cardNumber: ''
})

const isLoading = computed(() => {
    return getters['isLoading']
})
const showExpiredCardInfo = computed(() => {
    if (props.paymentInfo.expire && props.paymentInfo.expire !== null) {
        if (isCardExpired(new Date((props.paymentInfo.expire).split(' ')[0]))) {
            setMockData()
            return true
        }
        return false
    }
    return false
})
const saveCard = () => {
    emit('saveCard')
}
const isValidPayment = () => {
    return !!(props.paymentInfo.number && props.paymentInfo.provider)
}
const isCardExpired = (date) => {
    const today = new Date()
    today.toLocaleDateString()
    today.setHours(0, 0, 0, 0)
    return date < today
}
const setMockData = () => {
    state.cardNumber = props.paymentInfo.number
}
</script>

<template>
    <section class="section braintree-wrapper">
        <main class="main mt-5">
            <div
                v-show="!showExpiredCardInfo"
                id="dropin-container"
            />
            <div
                v-if="showExpiredCardInfo"
                class="card-expired d-flex justify-center mx-auto my-4 pa-4"
            >
                <span class="text-subtitle-2 red--text">{{ t('account.cardExpired', { cardNo: state.cardNumber }) }}</span>
            </div>
            <Btn
                v-if="instance"
                :loading="isLoading"
                @click="saveCard()"
            >
                {{ t('account.Save') }}
            </Btn>
        </main>
    </section>
</template>