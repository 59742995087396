export const mutations = {
    setSessions: (state, sessions) => {
        state.sessions = sessions
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination
    },
    setPage: (state, page) => {
        state.pagination.page = page
    },
    setItemPerPage: (state, itemsPerPage) => {
        state.pagination.itemsPerPage = itemsPerPage
    }
}