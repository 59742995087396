import {ref, computed, toRef, reactive, nextTick} from 'vue'
import store from '@/store'
import moment from 'moment'
import router from '@/router'

// Utils
import showMessage from '@/utils/errorMessage.js'
import showMessageArray from '@/utils/errorMessageArray.js'
import { apiRequest } from '@/utils/apiRequest'
import { currencies } from '@/utils/currencies'
import formatDecimal from '@/utils/formatDecimal'
import { getFullFileName } from '@/utils/getFileName'
import { PaymentStatus } from '@/utils/paymentStatus'
import { specificFileDownload } from '@/utils/fileDownload'

// Composables
import useWindowWidth from './windowWidth'
import useDetailsCard from './detailsCard'
import useFilterableList from './filterableList'

// configs
import { DATE_FORMAT } from '@/configs/index.js'

// Translations
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { dispatch, commit, getters, state } = store

export default function useInvoices() {

    const { t } = useI18n()
    const {
        windowWidth,
        setWindowWidth
    } = useWindowWidth()

    const {
        isCardView,
        isTable,
        isFiltersVisible,
        filtersSelected,
        options,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting
    } = useFilterableList({
        filters: {
            invoice_number: null,
            status: null,
            date: {
                from: null,
                to: null
            }
        },
        options: {
            sortBy: ['invoice_number'],
            sortDesc: true
        },
        action: 'invoices/getInvoices'
    })

    const {
        isPickedItem,
        pickedItem,
        selectedRow,
        highlightElement
    } = useDetailsCard()

    const payingInvoiceID = ref([])
    const isCustomVisible = ref(false)
    const spendingVisible = ref(false)
    const selected = ref([])
    const isInvoiceAction = ref(false)
    const route = useRoute()

    const customizeHeaders = computed(() => {
        return getters['invoices/tableHeaders']
    })

    const allHeaders = computed(() => {
        return getters['invoices/tableHeaders']
    })

    const actions = computed(() => {
        return [
            {
                title: t('invoices.DownloadInvoice'),
                action: 'Download invoice',
                icon: 'mdi-download'
            },
            {
                title: t('invoices.DownloadListOfSessions'),
                action: 'Download list of sessions',
                icon: 'mdi-file-download-outline'
            },
            {
                title: t('invoices.GoToListOfSessions'),
                action: 'Go to list of sessions',
                icon: 'mdi-lightning-bolt'
            }
        ]
    })

    const filteredInvoices = computed(() => state.invoices.invoices.map(invoice => ({
        invoiceId: invoice.invoice_id,
        invoice_number: invoice.invoice_number,
        amount: `${formatDecimal(invoice.amount)}`,
        currency: `${invoice.currency}`,
        priceWithoutVat: `${formatDecimal(invoice.price_vat_excl)} ${currencies[invoice.currency]}`,
        tax: `${formatDecimal(invoice.tax_amount)} ${currencies[invoice.currency]}`,
        variableSymbol: invoice.variable_symbol,
        status: t(`invoices.${invoice.status}`),
        status_key: invoice.status,
        has_pdf_invoice: invoice.has_pdf_invoice,
        has_pdf_statement: invoice.has_pdf_statement,
        amountNet: `${formatDecimal(invoice.net_amount)} ${currencies[invoice.currency]}`,
        bankAccount: invoice.greenway_bank_account,
        sourceInvoiceId: invoice.source_invoice_id,
        billingPeriod:
            `${moment(invoice.delivery_date_from).format(DATE_FORMAT)} - ${moment(invoice.delivery_date_to).format(DATE_FORMAT)}`,
        issue_date: moment(invoice.issue_date).format(DATE_FORMAT),
        due_date: moment(invoice.due_date).format(DATE_FORMAT)
    })))

    const getActions = (invoice) => {
        if (invoice) {
            const actions = [
                {
                    title: t('invoices.GoToListOfSessions'),
                    action: 'Go to list of sessions',
                    icon: 'mdi-lightning-bolt'
                }
            ]

            if (invoice.has_pdf_statement) {
                actions.unshift(
                    {
                        title: t('invoices.DownloadListOfSessions'),
                        action: 'Download list of sessions',
                        icon: 'mdi-file-download-outline'
                    }
                )
            }
            if (invoice.has_pdf_invoice) {
                actions.unshift(
                    {
                        title: t('invoices.DownloadInvoice'),
                        action: 'Download invoice',
                        icon: 'mdi-download'
                    }
                )
            }
            return actions
        }
    }

    const showPayColumn = computed(() => {
        return !!filteredInvoices.value.filter(el => el.status_key === 'unpaid').length
    })
    const paymentProvider = computed(() => {
        return state.account.paymentInfo.provider
    })
    const pagination = computed(() => {
        return state.invoices.pagination
    })
    const countSelectedItems = computed(() => {
        return selected.value.length
    })
    const isLoading = computed(() => {
        return getters['isLoading']
    })
    const batchActions = computed(() => {
        const displayActions = [...actions.value]

        displayActions.unshift({
            title: t('invoices.PayInvoices'),
            action: 'Pay invoices',
            icon: 'mdi-credit-card-outline'
        })

        displayActions.splice(displayActions.indexOf({
            title: t('invoices.GoToListOfSessions'),
            action: 'Go to list of sessions',
            icon: 'mdi-lightning-bolt'
        }), 1)

        return displayActions
    })

    const isPaidInvoiceSelected = computed(() => {
        let statusKey
        for (let i = 0; i < selected.value.length; i++) {
            statusKey = (selected.value[i].status_key).toLowerCase()
            if (PaymentStatus.isUnpaid(statusKey) || PaymentStatus.isPartialyPaid(statusKey)) {
                return false
            }
        }
        return true
    })

    async function actionInvoice(title, id, items) {
        const invoice = filteredInvoices.value.find(invoice => invoice.invoiceId === id) || null
        switch (title) {
            case 'Pay invoices':
                const selectedUnpaidInvoices = selected.value.filter(item => {
                    return item.status_key === 'unpaid' || item.status_key === 'partialy_paid'
                })
                const idsInvoices = selectedUnpaidInvoices.map(item => item.invoiceId)
                if (idsInvoices.length > 1) {
                    dispatch('invoices/payMultipleInvoice', { id: idsInvoices }).then(() => {
                        showMessage(t('invoices.InvoicesWereSuccessfullyPaid'), 'success')
                        getItems()
                        selected.value = []
                        isPaidInvoiceSelected.value = true
                    }).catch(error => {
                        showMessage(error)
                    })
                    break
                }
                dispatch('invoices/payInvoice', idsInvoices[0]).then(response => {
                    showMessage(response.data.message, 'success')
                    selected.value = []
                    isPaidInvoiceSelected.value = true
                    getItems()
                }).catch(error => {
                    showMessage(error)
                })
                break
            case 'Download invoice':
                download('invoice', id, items)
                break
            case 'Download list of sessions':
                download('statement', id, items)
                break
            case 'Go to list of sessions':
                if (route.name === 'sessions') {
                    isPickedItem.value = false
                    await dispatch('sessions/getSessions', { 'filters[invoice_id]': id })
                    break
                } else if (invoice) {
                    isPickedItem.value = false
                    await router.replace(
                        {
                            name: 'sessions',
                            query: {
                                invoiceId: invoice.invoiceId
                            },
                            params: {
                                invoiceId: invoice.invoiceId
                            }
                        }
                    )
                    break
                }
                isPickedItem.value = false
                await router.push({ name: 'sessions', params: { invoiceId: id } })
                break
        }
        isInvoiceAction.value = false
    }
    function downloadFileNow(response) {
        const fileName = getFullFileName(response)
        specificFileDownload(response.data, fileName)
    }

    const download = (path, id, items) => {
        const idDesktop = items ? selected.value[0]?.invoiceId : pickedItem.value?.invoiceId
        const idInvoice = idDesktop || id

        if (selected.value.length > 1 && isPickedItem.value === false) {

            const filterField = path === 'invoice' ? 'has_pdf_invoice' : 'has_pdf_statement'
            const idsInvoices = selected.value.filter(el => el[filterField] === true).map(item => item.invoiceId)
            const idsNotAvailable = selected.value.filter(el => el[filterField] === false).map(item => item.invoice_number)

            const messages = idsNotAvailable.map(id => `The ${path} ${id} ${t('invoices.notAvailable')}`)
            showMessageArray(messages, 'warning')

            idsInvoices.forEach(id => {
                apiRequest(`client/invoices/${id}/${path}`, {
                    responseType: 'blob'
                }).then((response) => {
                    if (response.data.error) {
                        showMessage(response.data.error)
                    } else {
                        downloadFileNow(response)
                    }
                }).catch(() => {
                    // show error
                    switch (path) {
                    case 'statement':
                        showMessage(i18n.t('invoices.downloadStatementError'))
                        break
                    case 'invoice':
                    default:
                        showMessage(i18n.t('invoices.downloadInvoiceError'))

                    }
                })
            })
        } else {
            apiRequest(`client/invoices/${idInvoice}/${path}`, {
                responseType: 'blob'
            }).then((response) => {
                if (response.data.error) {
                    showMessage(response.data.error)
                } else {
                    downloadFileNow(response)
                }
            }).catch(() => {
                // show error
                switch (path) {
                case 'statement':
                    showMessage(i18n.t('invoices.downloadStatementError'))
                    break
                case 'invoice':
                default:
                    showMessage(i18n.t('invoices.downloadInvoiceError'))

                }
            })
        }
    }

    function payInvoice(id) {
        payingInvoiceID.value.push(id)
        dispatch('invoices/payInvoice', id).then(response => {
            showMessage(response.data.message, 'success')
            payingInvoiceID.value = payingInvoiceID.value.filter(el => el !== id)
            selected.value = []
            isPaidInvoiceSelected.value = false
            getItems()
        }).catch(error => {
            showMessage(error)
        })
    }

    function updateHeaders(headers) {
        customizeHeaders.value = allHeaders.value.filter(header => headers.includes(header.value))
    }

    function showDetails(invoice) {
        commit('invoices/setInvoice', invoice)
        pickedItem.value = invoice
        isPickedItem.value = true

        if (windowWidth.value >= 600) {
            // window.scrollTo({
            //     top: this.$refs['invoices-table'].offsetParent.offsetTop,
            //     left: 0,
            //     behavior: 'smooth'
            // })
        } else {
            // position fixed + save the current Y position
            const scrollY = window.scrollY
            document.body.style.position = 'fixed'
            document.body.style.top = `-${scrollY}px`
        }
        isTable.value = windowWidth.value > 600
    }

    function closeDetails() {
        pickedItem.value = null
        isTable.value = true
        isInvoiceAction.value = false
        isPickedItem.value = false

        if (windowWidth.value < 960) {
            // scroll back to recent position
            const scrollY = document.body.style.top
            document.body.style.position = ''
            document.body.style.top = ''

            nextTick(() => {
                window.scrollTo(0, parseInt(scrollY || '0') * -1)
            })
        }
    }

    return {
        isPickedItem,
        isLoading,
        isTable,
        isFiltersVisible,
        isCustomVisible,
        spendingVisible,
        isCardView,
        windowWidth,
        selected,
        selectedRow,
        pickedItem,
        isInvoiceAction,
        isPaidInvoiceSelected,
        filtersSelected,
        options,
        actions,
        allHeaders,
        customizeHeaders,
        filteredInvoices,
        showPayColumn,
        payingInvoiceID,
        paymentProvider,
        pagination,
        countSelectedItems,
        batchActions,
        actionInvoice,
        getActions,
        downloadFileNow,
        payInvoice,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting,
        updateHeaders,
        showDetails,
        closeDetails,
        highlightElement,
        setWindowWidth
    }
}