const keyBy = (array, key) => {
    return array.reduce((result, item) => {
        result[item[key]] = item;
        return result;
    }, {})
}

export const mutations = {
    setFeatures(state, features) {
        const enabledFeatures = features.reduce((result, feature) => {
            result[feature.name] = feature.enabled
            return result
        }, {})

        state.enabledFeatures = Object.assign({}, enabledFeatures)
        state.features = Object.assign({}, keyBy(features, 'name'))
    },

    setLoading(state, loading) {
        state.loading = loading
    }
}