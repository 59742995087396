export const getDefaultState = () => {
    return {
        client_id: null,
        client_name: null,
        redirect_uri: null,
        response_type: 'code',
        scope: null,
        response_mode: 'form_post',
        code: null,
        state: null,
        redirect_to: null,
        scopes: null,
        errors: null
    }
}