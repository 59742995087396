<script setup>
import { computed, onBeforeMount } from 'vue'

// configs
import { Locale } from '@/configs/index.js'

// composables
import useLocale from '@/composables/locale'

// components
import Footer from '@/components/Footer.vue'
import Btn from '@/components/inputs/Btn.vue'
import Logo from '@/components/icons/Logo.vue'
import LangSwitcher from '@/components/inputs/LangSwitcher.vue'
import Information from './components/Information.vue'

// utils
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const { locale } = useLocale()

const geWebPageLink = computed(() => {
    return t('weblinks.VITE_APP_WEB')
})

const goBack = () => {
    window.close()
}
</script>

<template>
    <div class="legal-information-wrapper">
        <div class="legal">
            <div class="d-flex justify-space-between align-center mb-6">
                <v-btn
                    class="legal__header-back"
                    color="primary"
                    variant="text"
                    :ripple="false"
                    @click="goBack"
                >
                    <v-icon class="mr-2">
                        mdi-close
                    </v-icon>
                    <span class="page__header-back--text">{{ t('legal.Close') }}</span>
                </v-btn>
                <LangSwitcher class="lang-switcher-mobile" />
            </div>
            <header class="legal__header">
                <a
                    :href="geWebPageLink"
                    target="_blank"
                >
                    <Logo />
                </a>
                <LangSwitcher class="lang-switcher" />
            </header>
            <main class="legal__main">
                <div class="legal__main-container">
                    <div class="legal__main-header">
                        <h1>{{ t('legal.LegalInformation') }}</h1>
                    </div>
                    <div class="legal__main-content">
                        <Information :locale="locale.toLowerCase()" />
                    </div>
                </div>
            </main>
        </div>
        <Footer />
    </div>
</template>