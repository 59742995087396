import { useI18n } from 'vue-i18n'

export default function useTableHeaders() {

    const { t } = useI18n()

    const facilitySessionsHeaders = () => {
        return [
            {
                text: t('tableHeaders.From'),
                width: '150px',
                align: 'start',
                sortable: true,
                value: 'charging_from'
            },
            {
                text: t('tableHeaders.To'),
                width: '150px',
                align: 'start',
                sortable: true,
                value: 'charging_to'
            },
            {
                text: t('tableHeaders.Duration minutes'),
                align: 'start',
                sortable: true,
                value: 'total_minutes'
            },
            {
                text: t('tableHeaders.Total kWh'),
                align: 'start',
                sortable: true,
                value: 'total_kwh'
            },
            {
                text: t('tableHeaders.Connector'),
                align: 'start',
                sortable: true,
                value: 'connector_type_code'
            },
            {
                text: t('tableHeaders.Charger ID'),
                width: '150px',
                align: 'start',
                sortable: true,
                value: 'device_id'
            },
            {
                text: t('tableHeaders.Connector ID'),
                width: '160px',
                align: 'start',
                sortable: true,
                value: 'connector_id'
            },
            {
                text: t('tableHeaders.Device'),
                align: 'start',
                sortable: true,
                value: 'device'
            },
            {
                text: t('tableHeaders.Price'),
                align: 'start',
                sortable: true,
                value: 'price'
            }
        ]
    }

    return {
        facilitySessionsHeaders
    }
}