<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty } = useValidations()

// translations
import { useI18n } from 'vue-i18n'

const store = useStore()
const { t } = useI18n()
const { dispatch } = store

const emit = defineEmits(['setValue'])

const props = defineProps({
    language: {
        type: String,
        required: false,
        default: ''
    }
})

const lang = ref(props.language)

const setLang = (v) => {
    emit('setValue', v)
}

onMounted(() => {
    if (!store.state.filters.languages) dispatch('filters/getLanguages')
})

</script>

<template>
    <v-select
        :items="store.state.filters.languages || []"
        :placeholder="t('Client.CommunicationLanguage') + '*'"
        :label="t('Client.CommunicationLanguage') + '*'"
        :rules="[isNotEmpty]"
        v-model="lang"
        item-title="name"
        item-value="value"
        variant="outlined"
        data-testid="communicationLanguageSelect"
        @update:model-value="setLang"
    />
</template>