<script setup>
import { ContentLoader } from 'vue-content-loader'
import useWindowWidth from '@/composables/windowWidth'

const { windowWidth } = useWindowWidth()

const props = defineProps({
    height: {
        required: true,
        type: Number,
        default: () => {
            return 100
        }
    },
    mobileHeight: {
        required: false,
        type: Number,
        default: () => {
            return 70
        }
    }
})
</script>

<template>
    <content-loader v-if="windowWidth > 600"
                    :height="props.height"
                    :width="700"
                    :speed="2"
                    primary-color="rgb(var(--v-theme-white))"
                    secondary-color="rgb(var(--v-theme-back))"
    >
        <rect x="0"
              y="0"
              rx="0"
              ry="0"
              width="700"
              :height="props.height"
        />
    </content-loader>
    <content-loader v-else
                    :height="props.mobileHeight"
                    :width="100"
                    :speed="2"
                    primary-color="rgb(var(--v-theme-white))"
                    secondary-color="rgb(var(--v-theme-back))"
    >
        <rect x="0"
              y="0"
              rx="0"
              ry="0"
              width="100"
              :height="props.mobileHeight"
        />
    </content-loader>
</template>