<script setup>
import { ref, computed, onBeforeMount, onMounted } from 'vue'
import store from '@/store'

// configs
import { Lang } from '@/configs/index.js'

// composables
import useRegistration from '@/composables/registration/useRegistration'
import useLocale from '@/composables/locale'
import usePhoneVerification from '@/composables/registration/usePhoneVerification'

// utils
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { state } = store
const { isPhoneVerified } = useRegistration()
const { locale, lang } = useLocale()
        
const {
    code,
    phoneCode,
    form,
    errorMessage,
    isFormValid,
    phoneNumber,
    showRecaptcha,
    isCodeChecking,
    sendVerificationCode,
    skipPhoneVerification,
    verifyCode,
    recaptcha,
    recaptchaToken
} = usePhoneVerification()

const phoneAlreadyVerified = computed(() => {
    return state.account.isPhoneVerified
})
const siteKey = computed(() => {
    return state.settings.recaptchaSiteKey
})
const language = computed(() => {
    return lang.value || import.meta.env.VITE_APP_I18N_LOCALE || Lang.en
})

const resolveVerification = (token) => {
    recaptchaToken.value = token
}

onBeforeMount(() => {
    if (!phoneAlreadyVerified.value) {
        sendVerificationCode()
        return
    }
    isPhoneVerified.value = true
})
</script>

<template>
    <div class="phone-verification">
        <div class="heading text-center mb-6">
            <v-icon class="cellphone-icon mb-16 text-grey text-grey-lighten-2">
                mdi-cellphone
            </v-icon>
            <template v-if="!isPhoneVerified">
                <h1 class="text-h5 font-weight-bold mb-2">
                    {{ t('PhoneVerification.EnterCode') }}
                </h1>
                <p class="text-body-2">
                    {{ t('registrationWizard.EnterSmsCode', { phoneNo: phoneNumber }) }}
                </p>
            </template>
            <template v-else>
                <h1 class="text-h5 font-weight-bold mb-2">
                    {{ t('PhoneVerification.AlreadyVerified', {phoneNo: phoneNumber}) }}
                </h1>
            </template>
        </div>
        <main>
            <template v-if="!isPhoneVerified">
                <v-form ref="form" v-model="isFormValid">
                    <v-text-field 
                        ref="phoneCode"
                        v-model="code"
                        focused
                        variant="outlined"
                        :error-messages="errorMessage"
                        :placeholder="t('PhoneVerification.EnterCode')"
                        type="tel"
                        class="phone-code mx-auto"
                        :disabled="isPhoneVerified"
                        data-testid="verificationCodeField"
                        @click="errorMessage = ''"
                        @keydown.enter.prevent="verifyCode"
                    />
                </v-form>
                <v-btn
                    variant="plain"
                    class="text-none d-flex mx-auto mb-4"
                    data-testid="sendCodeAgain"
                    :disabled="(showRecaptcha && recaptcha) ? !recaptchaToken : false"
                    @click.prevent="sendVerificationCode"
                >
                    {{ t('PhoneVerification.SendAgain') }}
                </v-btn>
                <div class="info-panel d-flex bg-blue-lighten-4 lighten-4 pa-4 mx-auto mb-4">
                    <v-icon class="text-blue align-start">
                        mdi-information-outline
                    </v-icon>
                    <p class="text-body-2 text-blue ma-0">
                        {{ t('registrationWizard.YouCanSkipThisStep') }}
                    </p>
                </div>
                <v-btn
                    :loading="store.getters['isLoading'] || isCodeChecking"
                    rounded="xl"
                    color="primary"
                    large
                    :disabled="!code.trim().length || isPhoneVerified"
                    class="d-flex mx-auto mb-4"
                    data-testid="nextStep"
                    @click="verifyCode"
                >
                    {{ t('registrationWizard.SaveAndNext') }}
                </v-btn>
            </template>
            <v-btn
                variant="plain"  
                class="text-none d-flex mx-auto"
                data-testid="skipStep"
                @click="skipPhoneVerification"
            >
                {{ t('PhoneVerification.Skip') }}
            </v-btn>
        </main>
    </div>
</template>