import en from '@/translations/EN.json'

export const getters = {
    invoice: state => state.invoice,
    tableHeaders: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                width: '50px',
                align: 'center',
                sortable: false,
                value: 'detail'
            },
            {
                title: translation['tableHeaders.Invoice Number'],
                width: '180px',
                align: 'start',
                sortable: true,
                value: 'invoice_number',
                customizable: true
            },
            {
                title: translation['tableHeaders.Issue date'],
                width: '170px',
                align: 'start',
                sortable: true,
                value: 'issue_date',
                customizable: true
            },
            {
                title: translation['tableHeaders.Due date'],
                width: '170px',
                align: 'start',
                sortable: true,
                value: 'due_date',
                customizable: true
            },
            {
                title: translation['tableHeaders.Price'],
                width: '140px',
                align: 'start',
                sortable: true,
                value: 'amount',
                customizable: true
            },
            {
                title: translation['tableHeaders.Currency'],
                width: '100px',
                align: 'start',
                sortable: true,
                value: 'currency',
                customizable: true
            },
            {
                title: translation['tableHeaders.Status'],
                width: '100px',
                align: 'start',
                sortable: true,
                value: 'status',
                customizable: true
            },
            {
                title: translation['tableHeaders.Actions'],
                width: '100px',
                align: 'start',
                sortable: false,
                value: 'actions',
                customizable: false
            },
            {
                title: '',
                value: 'status_key',
                width: 'auto',
                customizable: false
            }
        ]
    },
    spendingTableHeaders: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                title: translation ? translation['tableHeaders.InvoiceItems'] : '',
                align: 'start',
                sortable: true,
                value: 'name'
            },
            {
                title: translation ? translation['tableHeaders.Quantity'] : '',
                align: 'start',
                sortable: true,
                value: 'quantity',
                width: '100px'
            },
            {
                title: translation ? translation['tableHeaders.PriceWithoutVat'] : '',
                align: 'start',
                sortable: true,
                value: 'price_vat_excl'
            },
            {
                title: translation ? translation['tableHeaders.VatAmount'] : '',
                align: 'start',
                sortable: true,
                value: 'vat_amount'
            },
            {
                title: translation ? translation['tableHeaders.PriceWithVat'] : '',
                align: 'start',
                sortable: true,
                value: 'price_vat_incl'
            }
        ]
    }
}