<script setup>
import { reactive } from 'vue'
import store from '@/store'

const props = defineProps({
    data: {
        type: Object,
        required: true
    }
})

const state = reactive({
    value: null
})

const clearData = () => {
    store.commit('coupons/updateAddCouponFieldValue', {
        field: props.data.name,
        value: null
    })
}

const updateDriver = (event) => {
    store.commit('coupons/updateAddCouponFieldValue', {
        field: props.data.name,
        value: event.trim().toString()
    })
}

</script>

<template>
    <v-row>
        <v-col cols="12">
            <p class="mb-1">
                {{ props.data.label }}{{ props.data.required ? '*' : '' }}
            </p>
            <v-text-field
                v-model="state.value"
                full-width
                density="compact"
                clearable
                hide-details
                variant="outlined"
                :placeholder="props.data.description"
                @update:model-value="updateDriver"
                @click:clear="clearData"
            />
        </v-col>
    </v-row>
</template>