<script setup>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'

import { useRoute } from 'vue-router'

// components
import DatePicker from '@/components/inputs/DatePicker.vue'

// utils
import isObjEqual from '@/utils/isObjEqual'

// translations
import { useI18n } from 'vue-i18n'
import Btn from '@/components/inputs/Btn.vue'

// configs
import { Roles } from '@/configs/index.js'

const { t } = useI18n()
const store = useStore()
const { getters, commit } = store

const emit = defineEmits(['clearFilters', 'setFilter', 'getItems'])

const props = defineProps({
    filtersSelected: {
        required: false
    },
    defaultFilters: {
        required: false
    }
})

const state = reactive({
    searchInputLocation: '',
    isClear: false,
    key: 0
})

const key = computed(() => {
    return store.state.consumptions.key
})

const increaseKey = () => {
    commit('consumptions/setFieldValue', {
        field: 'key',
        value: store.state.consumptions.key + 1
    })
}

const filters = computed(() => {
    return getters['consumptions/filters']
})

const isPayer = computed(() => {
    return store.state.account.roles.includes(Roles.payer)
})

const showClear = computed(() => {
    return !isObjEqual(props.filtersSelected, props.defaultFilters)
})

const isNotChargerDetailsView = computed(() => {
    const route = useRoute()
    return route.name !== 'chargerDetails'
})

const debounce = (func, delay) => {
    let timerId
    return function (...args) {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            func.apply(this, args) // Call the debounced function with arguments
        }, delay)
    }
}

const setFilter = debounce(function (field, value) {
    store.commit('consumptions/setSelectedFiltersFieldValue', {
        field,
        value
    })
    emit('setFilter', { field, value })
    emit('getItems')
}, 1500)

const clearFilters = () => {
    store.commit('consumptions/setFieldValue', {
        field: 'selectedFilters',
        value: props.defaultFilters
    })
    increaseKey()
    emit('clearFilters')
    state.isClear = true
}

const dateFromTo = ({ dateFrom: from, dateTo: to, isClear }) => {
    setFilter('date', {
        from: from || null,
        to: to || null
    })
    state.isClear = isClear
}

onMounted(() => {
    store.commit('consumptions/setFieldValue', {
        field: 'selectedFilters',
        value: props.defaultFilters
    })
})
</script>

<template>
    <v-container v-if="filters" class="consumption-filter pa-3 pa-md-8" fluid>
        <v-row class="pt-2 ma-0">
            <v-col v-if="isPayer" class="py-0" cols="12" md="3" sm="6">
                <v-select
                    :key="key"
                    :items="filters.drivers || null"
                    :placeholder="t('sessions.user')"
                    :value="filtersSelected.user"
                    clearable
                    density="compact"
                    item-title="name"
                    item-value="key"
                    variant="outlined"
                    @update:model-value="setFilter('user', $event)"
                />
            </v-col>
            <v-col v-if="isNotChargerDetailsView" class="py-0" cols="12" md="3" sm="6">
                <v-select
                    :key="key"
                    :items="filters.devices || null"
                    :placeholder="t('sessions.charger')"
                    :value="filtersSelected.charger"
                    clearable
                    density="compact"
                    item-title="name"
                    item-value="key"
                    variant="outlined"
                    @update:model-value="setFilter('charger', $event)"
                />
            </v-col>
            <v-col v-if="isPayer && filtersSelected.category" class="py-0" cols="12" md="3" sm="6">
                <v-select
                    :key="key"
                    :items="filters.home_charger_type || null"
                    :placeholder="t('sessions.chargerType')"
                    :value="filtersSelected.category"
                    clearable
                    density="compact"
                    item-title="name"
                    item-value="key"
                    variant="outlined"
                    @update:model-value="setFilter('category', $event)"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <DatePicker
                    :key="key"
                    :is-clear="state.isClear"
                    :label1="t('invoices.DateFrom')"
                    :label2="t('invoices.DateTo')"
                    @date="dateFromTo"
                />
            </v-col>
            <v-col v-if="showClear && isNotChargerDetailsView" class="py-0" cols="12" md="3" sm="6">
                <Btn color="primary" text @click="clearFilters()">
                    <v-icon class="clear-icon pr-1"> mdi-delete </v-icon>
                    {{ t('filter.ClearAll') }}
                </Btn>
            </v-col>
        </v-row>
    </v-container>
</template>
