<script setup>
import moment from 'moment'
import {useStore} from 'vuex'
import {computed} from 'vue'

// composables
import useChargingCards from '@/composables/chargingCards/useChargingCards'

const store = useStore()
const {getters} = store

const {
    chargingCardsOrdersList,
    isFiltersVisible,
    fetchingData
} = useChargingCards()

const tableHeaders = computed(() => {
    return getters['drivers/chargingCardsOrdersTableHeaders']
})
</script>

<template>
    <v-container class="list pa-0" fluid>
        <div class="d-flex align-center justify-center">
            <v-data-table
                :headers="tableHeaders"
                :items="chargingCardsOrdersList"
                :items-length="chargingCardsOrdersList.length"
                :loading="fetchingData"
                class="table"
            >
                <template v-slot:[`item.created_at`]="{ item }">
                    {{ moment(item.created_at).format('DD.MM.YYYY HH:mm') }}
                </template>
            </v-data-table>
        </div>
    </v-container>
</template>