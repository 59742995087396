export const mutations = {
    setTab: (state, {tabIndex}) => {
        state.chargerManagement.tab = tabIndex
    },
    setSessionTab: (state, {tabIndex}) => {
        state.sessions.tab = tabIndex
    },
    setRecaptchaSiteKey: (state, {recaptchaSiteKey}) => {
        state.recaptchaSiteKey = recaptchaSiteKey
    },
    setFieldValue: (state, {field, value}) => {
        state[field] = value
    },
    setAccountTempFieldValue: (state, { field, value }) => {
        if(state.accountTemp) {
            state.accountTemp[field] = value
        }
    },
    setAccountTempDeliveryFieldValue: (state, {field, value}) => {
        state.accountTemp.deliveryAddress[field] = value
    }
}