<template>
    <content-loader v-if="windowWidth > 600"
                    class="dashboard-loader"
                    :height="195"
                    :width="350"
                    :speed="2"
                    primary-color="rgb(var(--v-theme-white))"
                    secondary-color="rgb(var(--v-theme-back))"
    >
        <rect x="0"
              y="5"
              rx="5"
              ry="5"
              width="100"
              height="10"
        />
        <rect x="0"
              y="27"
              rx="3"
              ry="3"
              width="350"
              height="185"
        />
    </content-loader>
    <content-loader v-else
                    class="dashboard-loader"
                    :height="250"
                    :width="350"
                    :speed="2"
                    primary-color="rgb(var(--v-theme-white))"
                    secondary-color="rgb(var(--v-theme-back))"
    >
        <rect x="0"
              y="5"
              rx="5"
              ry="5"
              width="100"
              height="10"
        />
        <rect x="0"
              y="27"
              rx="3"
              ry="3"
              width="350"
              height="245"
        />
    </content-loader>
</template>

<script setup>
import { ContentLoader } from 'vue-content-loader'
import useWindowWidth from '@/composables/windowWidth'

const { windowWidth } = useWindowWidth()
</script>