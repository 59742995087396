<script setup>
import {watch} from 'vue'

// composables
import useConnectors from '@/composables/connectors'

// components
import Table from '@/components/Table.vue'
import LocationConnectorsFilter from './LocationConnectorsFilter.vue'
import MenuActionsMobile from '@/components/inputs/MenuActionsMobile.vue'

const props = defineProps({
  locationId: {
    required: false
  },
  isFiltersVisible: {
    required: false
  }
})

const {
  activePopup,
  selected,
  actions,
  headers,
  connectors,
  pagination,
  filtersSelected,
  options,
  getItems,
  getDefaultFilters,
  setFilter,
  clearFilters,
  sorting,
  countSelectedItems,
  goToDetail,
  getActions,
  handleBatchAction,
  batchAction,
  action,
  selectedItems
} = useConnectors({
  filters: {
    code: [],
    status: [],
    type: [],
    location: {
      id: [props.locationId],
      name: [],
      code: []
    },
    charger: {
      code: []
    }
  }
})

watch(options, () => {
  getItems()
})

</script>

<template>
  <v-container class="location-connectors-wrapper__list pa-0" fluid>
    <LocationConnectorsFilter
        v-show="isFiltersVisible"
        :default-filters="getDefaultFilters()"
        :filters-selected="filtersSelected"
        @clearFilters="clearFilters"
        @getItems="getItems"
        @setFilter="setFilter"
    />
    <div class="connectors__table">
      <Table
          :card-view="true"
          :headers="headers"
          :is-descending="options.sortDesc ? options.sortDesc : true"
          :item-key="'id'"
          :items="connectors"
          :pagination="pagination"
          :server-items="pagination.itemsLength"
          :sort-column-name="options.sortBy[0]"
          @options="value => options = value"
      >
        <template v-slot:actions="props">
          <MenuActionsMobile :id="props.item.id"
                             :actionsFor="'connectors'"
                             :is-styled="true"
                             class="d-inline-flex"
                             text=""
                             @action="action"
          />
        </template>
        <template v-slot:detail="props">
          <v-btn color="primary" icon="mdi-magnify" variant="text" @click="goToDetail(props.item)"/>
        </template>
      </Table>
    </div>
  </v-container>
</template>