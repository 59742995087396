<script setup>
import store from '@/store'
import router from '@/router.js'
import { useRoute } from 'vue-router'

import { computed, onMounted, reactive } from 'vue'
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'

// Components
import Btn from '@/components/inputs/Btn.vue'
import Modal from '@/components/modals/Modal.vue'
import BtnGoBack from '@/components/inputs/BtnGoBack.vue'
import AccountSecurity from '@/pages/account/components/AccountSecurity.vue'
import AccountNotifications from '@/pages/account/components/AccountNotifications.vue'
import AccountPersonalData from '@/pages/account/components/AccountPersonalData.vue'
import AccountManagePayment from '@/pages/account/components/AccountManagePayment.vue'
import AccountChargingSettings from '@/pages/account/components/AccountChargingSettings.vue'

// utils
import { cloneDeep } from '@/utils/index.js'
import { useI18n } from 'vue-i18n'

const route = useRoute()

const { t } = useI18n()
const { dispatch, commit } = store
const state = reactive({
    isLoading: true,
    view: 0,
    showDialogBox: false,
    navigateTo: '',
    prevRoute: null,
    next: null
})

const account = computed(() => {
    return { ...state.account, password: '' }
})

const driversCount = computed(() => {
    return store.state.account?.drivers_count
})

const accountTemp = computed({
    get() {
        return store.state.settings.accountTemp
    },
    set(value) {
        commit('settings/setFieldValue', {
            field: 'accountTemp',
            value
        })
    }
})

const hasChanges = computed(() => {
    // or we don't edit, or there is already edit without accountTemp
    if (!account.value.personalDataIsEditView || (account.value.personalDataIsEditView && !accountTemp.value)) {
        return false
    }

    const cleanedAccount = cloneDeep(account.value)
    const cleanedAccountTemp = cloneDeep(accountTemp.value)

    delete cleanedAccount.personalDataIsEditView
    delete cleanedAccountTemp.personalDataIsEditView

    if (typeof cleanedAccountTemp.password === 'undefined' || cleanedAccountTemp.password.trim().length === 0) {
        delete cleanedAccount.password
        delete cleanedAccountTemp.password
    }

    return JSON.stringify(cleanedAccount) !== JSON.stringify(cleanedAccountTemp)
})

const resolveYes = () => {
    let next = null
    if (typeof next !== 'undefined') {
        next = next
    }

    state.showDialogBox = false
    commit('account/setPersonalDataIsEditView', false)
    accountTemp.value = null

    if (state.navigateTo !== '') {
        router.push({ path: state.navigateTo })
        state.navigateTo = ''
    }

    if (next) {
        next()
    }
}

const resolveNo = () => {
    state.showDialogBox = false
    commit('account/setPersonalDataIsEditView', true)
}

onBeforeRouteLeave((to, from, next) => {
    if (hasChanges.value) {
        next = next
        state.showDialogBox = true
    } else {
        commit('account/setPersonalDataIsEditView', false)
        if (store.state.account.personalDataIsEditView) {
            state.navigateTo = to.path
            return false
        }
        next()
    }
})

router.beforeEach((to, from, next) => {
    next((vm) => {
        vm.prevRoute = from.path
    })
})

onBeforeRouteUpdate((to, from, next) => {
    const roles = [...store.state.account.roles]
    if (roles.length === 1 && roles.includes('driver')) {
        window.location.replace(`/drivers/${store.state.account.driver.id}`)
    }
    next()
})

onMounted(async () => {
    const roles = store.state.account.roles ? [...store.state.account.roles] : []
    if (roles.length === 1 && roles.includes('driver')) {
        await router.replace(`drivers/${store.state.account.driver.id}`)
    }

    if (route.query.tab && route.query.tab === 'payment') {
        state.view = 2
        await router.replace('/account')
    }

    await dispatch('filters/getTitles')
    await dispatch('account/getAccount')
    await dispatch('account/getPaymentInfo')

    commit('account/setPersonalDataIsEditView', false)
    state.isLoading = false
})
</script>

<template>
    <div class="account-settings-wrapper settings" v-if="store.state.account">
        <BtnGoBack v-if="state.prevRoute && state.prevRoute !== '/'" class="ml-6 pt-2" />
        <header class="header">
            <h1 class="header__title home-page-title">
                {{ t('account.AccountSettings') }}
            </h1>
        </header>
        <div class="drivers__options">
            <v-tabs
                v-model="state.view"
                :show-arrows="true"
                class="tabs pl-0 pl-sm-4 pl-md-8"
                background-color="transparent"
            >
                <v-tab data-testid="chargingTab">
                    {{ t('account.chargingSettings') }}
                </v-tab>
                <v-tab data-testid="personalTab">
                    {{ t('account.PersonalData') }}
                </v-tab>
                <v-tab data-testid="managePaymentTab">
                    {{ t('account.ManagePayment') }}
                </v-tab>
                <v-tab data-testid="securityTab">
                    {{ t('account.security') }}
                </v-tab>
                <v-tab data-testid="notificationsTab">
                    {{ t('account.notifications') }}
                </v-tab>
            </v-tabs>
        </div>
        <main class="px-2 px-md-6 px-md-8" :class="state.view === 0 ? '' : 'main'">
            <AccountChargingSettings v-show="state.view === 0" />
            <AccountPersonalData
                v-show="state.view === 1"
                :current-view="state.view"
                @showPrompt="state.showDialogBox = true"
                @resolveYes="resolveYes"
                @resolveNo="resolveNo"
                :is-loading="state.isLoading"
            />
            <AccountManagePayment v-show="state.view === 2" :current-view="state.view" />

            <AccountSecurity v-show="state.view === 3" />
            <AccountNotifications v-show="state.view === 4" />
        </main>
        <!-- has changes dialog box -->
        <Modal :is-active="state.showDialogBox" :max-width="275">
            <div class="modal">
                <div class="pa-5">
                    <h4 class="py-5 mb-5 text-center">
                        {{ t('error.HasChanges') }}
                    </h4>
                    <div class="d-flex justify-space-around">
                        <Btn class="modal__btn" medium @click="resolveNo()">
                            {{ t('oAuth.cancel') }}
                        </Btn>
                        <Btn class="modal__btn active-link active-button" variant="text" medium @click="resolveYes()">
                            {{ t('BtnGoBack.ok') }}
                        </Btn>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>
