import {computed} from 'vue'
import store from '@/store'

// configs
import {Lang} from '@/configs/index.js'

const { state } = store

export default function useLocale() {

    const locale = computed(() => {
        // TODO - Andrej - This needs to be refactored
        const localeFallback = window.location.origin.indexOf('.sk') > -1 ? Lang.sk.toUpperCase() : Lang.pl.toUpperCase()
        return state.account.locale || import.meta.env.VITE_APP_I18N_LOCALE || localeFallback
    })

    const lang = computed(() => {
        return state.account.lang || import.meta.env.VITE_APP_I18N_LOCALE || Lang.en
    })

    return {
        locale,
        lang
    }
}