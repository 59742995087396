<script setup>
import {useStore} from 'vuex'
import {computed, reactive} from 'vue'

// components
import Btn from '@/components/inputs/Btn.vue'

// utils
import isObjEqual from '@/utils/isObjEqual'

// translations
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const store = useStore()
const {getters} = store

const emit = defineEmits(['clearFilters', 'setFilter', 'getItems'])

const props = defineProps({
    filtersSelected: {
        required: false
    },
    defaultFilters: {
        required: false
    }
})

const state = reactive({
    searchInputCode: '',
    searchInputLocationCode: '',
    searchInputLocationName: ''
})

const filters = computed(() => {
    return getters['chargers/filters']
})

const locationFilters = computed(() => {
    return getters['locations/filters']
})

const showClear = () => {
    return !isObjEqual(props.filtersSelected, props.defaultFilters)
}


const updateLocationFilter = (value) => {
    setFilter('location', {
        ...props.filtersSelected.location,
        ...value
    })
}

const debounce = (func, delay) => {
    let timerId
    return function (...args) {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            func.apply(this, args) // Call the debounced function with arguments
        }, delay)
    }
}

const setFilter = debounce(function (field, value) {
    emit('setFilter', {field, value})
    emit('getItems')
}, 1500)

const clearFilters = () => {
    emit('clearFilters')
}

const dateFromTo = ({dateFrom: from, dateTo: to, isClear}) => {
    setFilter('date', {
        from: from || null,
        to: to || null
    })
    state.isClear = isClear
}

</script>

<template>
    <v-container v-if="locationFilters && filters"
                 class="chargers-list-filter-wrapper chargers__filters"
                 fluid
    >
        <v-row class="pt-10 px-4 pb-10">
            <v-col
                class="py-0"
                cols="12"
                md="3"
                sm="6"
            >
                <v-autocomplete
                    v-model="filtersSelected.location.name"
                    :items="locationFilters.filter_by_name"
                    :label="t('chargers.Location')"
                    autocomplete="off"
                    clearable
                    chips
                    density="compact"
                    item-title="name"
                    item-value="value"
                    multiple
                    variant="outlined"
                    @update:model-value="updateLocationFilter({name: $event})"
                />
            </v-col>
            <v-col
                class="py-0"
                cols="12"
                md="3"
                sm="6"
            >
                <v-autocomplete
                    v-model="filtersSelected.code"
                    :items="filters.filter_by_code ? filters.filter_by_code : []"
                    :label="t('chargers.ChargerCode')"
                    item-title="name"
                    item-value="value"
                    clearable
                    chips
                    multiple
                    density="compact"
                    variant="outlined"
                    @update:model-value="setFilter('code', $event)"
                />
            </v-col>
            <v-col
                class="py-0"
                cols="12"
                md="3"
                sm="6"
            >
                <v-autocomplete
                    v-model="filtersSelected.category"
                    :items="filters.filter_by_charger_type ? filters.filter_by_charger_type : []"
                    :label="t('sessions.chargerType')"
                    item-title="name"
                    item-value="key"
                    clearable
                    chips
                    density="compact"
                    variant="outlined"
                    @update:model-value="setFilter('category', $event)"
                />
            </v-col>
            <v-col
                class="py-0"
                cols="12"
                md="3"
                sm="6"
            >
                <v-autocomplete
                    v-model="filtersSelected.location.code"
                    :items="locationFilters.filter_by_code ? locationFilters.filter_by_code : []"
                    :label="t('chargers.LocationCode')"
                    item-title="name"
                    item-value="value"
                    multiple
                    clearable
                    chips
                    density="compact"
                    variant="outlined"
                    @update:model-value="updateLocationFilter({code: $event})"
                />
            </v-col>
            <v-col v-if="showClear"
                   class="py-0"
                   cols="12"
                   md="3"
                   sm="6"
            >
                <Btn color="primary" text @click="[emit('clearFilters'), state.isClear = true]">
                    <v-icon class="clear-icon pr-1">
                        mdi-delete
                    </v-icon>
                    {{ t('filter.ClearAll') }}
                </Btn>
            </v-col>
        </v-row>
    </v-container>
</template>
