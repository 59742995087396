<script setup>
import Fuse from 'fuse.js'
import { useStore } from 'vuex'
import { computed, reactive, watch, onMounted, onBeforeUnmount } from 'vue'

// components
import Modal from '@/components/modals/Modal.vue'
import SearchField from '@/components/SearchField.vue'
import ChargingPrice from '@/components/chargingForm/ChargingPrice.vue'
import ChargingFormList from '@/components/chargingForm/ChargingFormList.vue'

// utils
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'
import formatChargerCode from '@/utils/formatChargerCode'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const { commit } = store

const emit = defineEmits(['close'])

defineProps({
    driver: {
        type: Number,
        default: null
    },
    initialLocation: {
        type: Object,
        default: null
    },
    initialCharger: {
        type: Object,
        default: null
    },
    isActive: {
        type: Boolean,
        default: true
    },
    initialStep: {
        type: Number,
        default: 1
    }
})

const state = reactive({
    fuse: null,
    locations: null,
    chargers: null,
    connectors: null,
    location: null,
    charger: null,
    connector: null,
    isMyLocation: false,
    search: '',
    step: 1,
    views: {
        1: { name: 'location', id: 'id', label: 'name', list: 'locations' },
        2: { name: 'charger', id: 'id', label: 'code', list: 'chargers' },
        3: { name: 'connector', id: 'id', label: 'EtrelFriendlyCode', additional_label: 'type', list: 'connectors' }
    }
})

const subtitle = computed(() => {
    let subtitle = ''
    subtitle += state.location?.name || ''
    subtitle += state.charger ? ` - ${state.charger.code}` : ''
    subtitle += state.connector ? ` - ${state.connector.EtrelFriendlyCode}` : ''
    return subtitle
})

const isNextStepAvailable = computed(() => {
    const requiredField = state.views[step].name
    return this[requiredField] !== null
})

const filteredList = computed(() => {
    const { label, list } = state.views[step]
    if (!state.search) {
        return this[list]
    }
    return fuse.search(state.search).map((el) => el.item)
})

const isFacilityRole = computed(() => {
    const roles = store.state.account.roles
    return roles.includes('facility-manager')
})

watch(isMyLocation, () => {
    getLocations()
})

watch(step, (step) => {
    state.search = ''
    switch (step) {
        case 1:
            state.location = null
            getLocations()
            break
        case 2:
            getChargers()
            state.charger = null
            satte.connector = null
            break
        case 3:
            getConnectors()
            break
    }
})

onBeforeUnmount(() => {
    emit('close')
})

const initializeFuse = (items, keys) => {
    state.fuse = new Fuse(this[items], {
        ignoreFieldNorm: false,
        includeMatches: true,
        threshold: 0.4,
        keys: keys
    })
}

const closeDialog = () => {
    emit('close')
}

// TODO - Andrej - check if it works
const setValue = ({ value, field }) => {
    state[field] = value
}
const getLocations = async () => {
    commit('setLoader', +1)
    try {
        const { data } = await apiRequest('charging/locations', {
            params: { my_locations: isMyLocation ? 1 : 0 }
        })
        state.locations = data.sort((a, b) => a.name.localeCompare(b.name))
        initializeFuse('locations', ['name'])
    } catch (error) {
        showMessage(error)
    } finally {
        commit('setLoader', -1)
    }
}

const getChargers = async () => {
    commit('setLoader', +1)
    try {
        const { data } = await apiRequest('charging/public/chargers-for-location', {
            params: { location_id: location.id }
        })
        data.forEach((el) => {
            const code = formatChargerCode(el.code)
            el.code = `${el.code} - ${code}`
        })
        state.chargers = data
        initializeFuse('chargers', ['code'])
    } catch (error) {
        showMessage(error)
    } finally {
        commit('setLoader', -1)
    }
}

const getConnectors = async () => {
    commit('setLoader', +1)
    try {
        const { data } = await apiRequest('charging/public/connectors-for-charger', {
            params: { device_id: state.charger.id, status: 'available' }
        })
        state.connectors = data
        initializeFuse('connectors', ['EtrelFriendlyCode', 'type'])
    } catch (error) {
        showMessage(error)
    } finally {
        commit('setLoader', -1)
    }
}

onMounted(() => {
    if (initialLocation) {
        state.location = initialLocation
    }
    if (initialCharger) {
        state.charger = initialCharger
    }
    if (initialStep) {
        state.step = initialStep
    }
    if (initialStep === 1) {
        getLocations()
    }
})
</script>

<template>
    <Modal class="charging-form-wrapper" :is-active="isActive" :max-width="530" @cancel="closeDialog()">
        <a class="close-button" @click="closeDialog()">
            <v-icon class="active-icon">mdi-close</v-icon>
        </a>
        <ChargingPrice v-if="state.step === 4" :driver="driver" :connector="state.onnector.id" @close="emit('close')" />
        <v-card v-if="state.step < 4" class="pa-7 pt-0 charging-form elevation-0">
            <v-card-title class="pt-0">
                <div class="title-holder d-flex align-center py-5">
                    <span class="title">
                        {{ t('chargingForm.startCharging') }}
                    </span>
                    <v-checkbox
                        v-if="isFacilityRole && state.step === 1"
                        v-model.lazy="state.isMyLocation"
                        :hide-details="true"
                        class="pl-2"
                        :label="t('chargingForm.myLocation')"
                    />
                </div>
            </v-card-title>
            <v-card-subtitle class="charging-form__subtitle">
                {{ subtitle }}
            </v-card-subtitle>
            <v-card-title class="py-2 charging-form__title">
                {{ t(`chargingForm.select`) }} {{ t(`chargingForm.${state.views[state.step].name}`) }}
            </v-card-title>
            <SearchField v-model="state.search" />
            <v-divider class="mx-4" />
            <v-card-text class="charging-form__text">
                <v-progress-linear
                    v-if="store.getters['isLoading']"
                    :rounded="true"
                    :height="7"
                    color="primary"
                    indeterminate
                />
                <ChargingFormList
                    v-if="!store.getters['isLoading']"
                    :id="state.views[state.step].id"
                    :list="filteredList"
                    :field="state.views[state.step].name"
                    :label="state.views[state.step].label"
                    :additional_label="state.views[state.step].additional_label || null"
                    form-type="startCharging"
                    @change="setValue"
                />
            </v-card-text>
            <v-divider class="mx-4 mt-4" />
            <v-card-actions class="d-flex justify-end px-4 pt-9 pb-2">
                <v-btn v-if="state.step === 1" class="mr-4" color="primary" text large @click="closeDialog()">
                    {{ t('chargingForm.cancel') }}
                </v-btn>
                <v-btn v-if="state.step > 1" class="ml-auto mr-2 px-4" color="primary" text large @click="state.step--">
                    {{ t('chargingForm.back') }}
                </v-btn>
                <v-btn
                    v-if="state.step < 4"
                    color="primary"
                    large
                    :disabled="!isNextStepAvailable"
                    class="px-8"
                    @click="state.step++"
                >
                    {{ t('chargingForm.next') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </Modal>
</template>
