<script setup>
import { reactive } from 'vue'
import { useStore } from 'vuex'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()

const props = defineProps({
    data: {
        type: Object,
        required: true
    }
})

const state = reactive({
    selectedDriver: null
})

const clearData = () => {
    store.commit('coupons/updateAddCouponFieldValue', {
        field: props.data.name,
        value: null
    })
}

const updateDriver = (event) => {
    store.commit('coupons/updateAddCouponFieldValue', {
        field: props.data.name,
        value: event && event.toString()
    })
}

</script>

<template>
    <v-row v-if="props.data">
        <v-col cols="12">
	        <p class="mb-3">{{ props.data?.description }}</p>

	        <label>{{ props.data?.label }}</label>
            <v-select
                single-line
                density="compact"
                variant="outlined"
                clearable
                :items="props.data.options"
                item-value="id"
                item-title="name"
                :value="state.selectedDriver"
                :placeholder="t('coupons.selectDriver')"
                @update:model-value="updateDriver"
                @click:clear="clearData"
            />
        </v-col>
    </v-row>
</template>