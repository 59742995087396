// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        data: {
            maxChargingSessions: null,
            maxEnergy: null,
            maxTime: null,
            roamingSessions: null,
            chargingSessions: null,
            energy: null,
            chargingTime: null
        }
    }
}

export const state = getDefaultState()