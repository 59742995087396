// Utils
import { apiRequest } from '@/utils/apiRequest'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }

        commit('account/resetStore', state)
        cookies.remove('mobile-token')

    },
    getData: async ({ commit, dispatch }, {chargingCategory}) => {
        commit('setLoader', 1, { root: true })

        // Just commented out for now
        // const utilizationPercent = apiRequest(`client/${chargingCategory}/utilization-percentage?lastXDays=7`).catch(error => {
        //     dispatch('checkStatus', error)
        // }).finally(() => {
        //     commit('setLoader', -1, {root: true})
        // })

        const statuses = apiRequest(`client/${chargingCategory}/connector-statuses`).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })

        const chargingSessions = apiRequest(`client/${chargingCategory}/charging-sessions-per-days?lastXDays=7`).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })

        const chargingTime = apiRequest(`client/${chargingCategory}/charging-time-per-days?lastXDays=7`).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })

        const energy = apiRequest(`client/${chargingCategory}/energy-per-days?lastXDays=7`).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })


        // utilizationPercent,
        // utilizationPercent: values[0].data,
        Promise.all([
            statuses,
            chargingSessions,
            chargingTime,
            energy
        ]).then(values => {
            commit('setFieldValue', {
                field: chargingCategory,
                value: {
                    statuses: values[0].data,
                    chargingSessions: values[1].data,
                    chargingTime: values[2].data,
                    energy: values[3].data
                }
            })
        })
    }
}