<script setup>
import {onMounted, reactive, ref} from 'vue'

// loader
import {Loader} from '@googlemaps/js-api-loader'
import {styles} from './googleMapsStyles'

const googleMapRef = ref()

defineProps({
    location: Object,
    apiKey: String
})

const state = reactive({
    google: null,
    map: null,
    mapConfig: {
        center: {
            lat: location.latitude,
            lng: location.longitude
        },
        zoom: 16,
        mapTypeId: 'greenWayStyle',
        controlSize: 24
    },
    styles: styles
})

const initializeMap = () => {
    state.google.load().then((google) => {
        state.map = new google.maps.Map(
            googleMapRef,
            state.mapConfig
        )
        state.map.mapTypes.set('greenWayStyle', new google.maps.StyledMapType(state.styles, { name: 'GreenWayStyle' }))
        new google.maps.Marker({
            position: state.mapConfig.center,
            map: state.map,
            icon: {
                url: '/images/marker.png',
                scaledSize: new google.maps.Size(28, 32)
            }
        })
    })
}

    onMounted(async() => {
        state.google = await new Loader({
            apiKey: apiKey,
            version: 'weekly',
            libraries: ['places']
        })
        initializeMap()
    })

</script>

<template>
    <div class="location-google-maps__wrapper">
        <div ref="googleMapRef" class="google-maps__map" />
        <template v-if="!!state.google && !!state.map">
            <slot :google="state.google"
                  :map="state.map"
            />
        </template>
    </div>
</template>
