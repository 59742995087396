<script setup>
import { watch } from 'vue'

// composables
import useFacilitySessions from '@/composables/facilitySessions'

// components
import Table from '@/components/Table.vue'
import FacilitySessionsFilter from './FacilitySessionsFilter.vue'
import FacilitySessionDetail from './FacilitySessionDetail.vue'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

defineProps({
    isFiltersVisible: {
        type: Boolean,
        required: true
    }
})

const {
    isPickedItem,
    isCustomVisible,
    isCardView,
    windowWidth,
    selected,
    selectedRow,
    pickedItem,
    filtersSelected,
    options,
    headers,
    sessions,
    pagination,
    countSelectedItems,
    getItems,
    checkChargingToRowClass,
    getDefaultFilters,
    setFilter,
    clearFilters,
    sorting,
    showDetails,
    closeDetails,
    rowSelect,
    highlightElement,
    setWindowWidth
} = useFacilitySessions()


watch(sessions, () => {
    pickedItem.value = null
})

watch(options, () => {
    getItems()
})

watch(pickedItem, () => {
    highlightElement(sessions.value.indexOf(pickedItem.value) || null)
})
</script>

<template>
    <div
        class="facility-sessions pa-0"
        fluid
    >
        <v-row
            v-if="windowWidth < 960"
            class="facility-sessions__header d-none"
        >
            <v-col
                class="d-flex justify-start"
                cols="12"
            >
                <div class="grid-switcher d-flex justify-center">
                    <v-btn
                        :class="{ 'active': isCardView }"
                        :ripple="false"
                        class="active-button px-0"
                        color="var(--v-white)"
                        icon
                        @click="isCardView = true"
                        elevation="1"
                    >
                        <v-icon class="active-icon">
                            mdi-view-grid
                        </v-icon>
                    </v-btn>
                    <v-btn
                        :class="{ 'active': !isCardView }"
                        :ripple="false"
                        class="active-button px-0"
                        color="var(--v-white)"
                        icon
                        @click="isCardView = false"
                        elevation="1"
                    >
                        <v-icon class="active-icon">
                            mdi-view-sequential
                        </v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <FacilitySessionsFilter
            v-show="isFiltersVisible"
            :default-filters="getDefaultFilters()"
            :filters-selected="filtersSelected"
            @clearFilters="clearFilters"
            @getItems="getItems"
            @setFilter="setFilter"
        />
        <div
            ref="session-table"
            :class="pickedItem ? 'facility-sessions__table-container-extended' : 'facility-sessions__table-container'"
        >
            <Table
                class="facility-sessions__table"
                :card-view="isCardView"
                :class="{ 'table-view-mobile-size': !isCardView }"
                :headers="headers"
                :is-descending="options.sortDesc ? options.sortDesc : true"
                :item-class="checkChargingToRowClass"
                :items="sessions"
                :pagination="pagination"
                :selected-row="selectedRow"
                :server-items="pagination.itemsLength"
                :sort-column-name="options.sortBy[0]"
                @options="value => options = value"
                @sorting="sorting"
            >
                <template v-slot:connected_to="props">
                    <v-progress-linear
                        v-if="!props.item.charging_to"
                        :height="7"
                        :rounded="true"
                        color="primary"
                        indeterminate
                    />
                    <template v-else>
                        {{ props.item.charging_to }}
                    </template>
                </template>
                <template v-slot:total_minutes="props">
                    <template v-if="!props.item.charging_to">
                        <span class="text-primary">{{ t('driverInfo.InProgress') }}</span>
                    </template>
                    <template v-else>
                        {{ props.item.total_minutes }}
                    </template>
                </template>
                <template v-slot:total_kwh="props">
                    <template v-if="!props.item.charging_to">
                        <span>-</span>
                    </template>
                    <template v-else>
                        {{ props.item.total_kwh }}
                    </template>
                </template>
                <template v-slot:detail="props">
                    <v-btn
                        icon="mdi-magnify"
                        variant="text"
                        @click="showDetails(props.item)"
                        color="primary"
                    />
                </template>
            </Table>
        </div>
        <v-navigation-drawer
            v-model="isPickedItem"
            :temporary="true"
            :width="windowWidth < 960 ? '100%' : 550"
            class="facility-sessions__drawer"
            location="right"
            @update:model-value="closeDetails"
        >
            <div v-if="isPickedItem"
                 class="facility-sessions__side"
                 :class="{'pa-5':isPickedItem}"
            >
                <FacilitySessionDetail
                    :session="pickedItem"
                    @close="closeDetails"
                />
            </div>
        </v-navigation-drawer>
    </div>
</template>