<script setup>
import {reactive, computed, watch} from 'vue'
import store from '@/store'
import moment from 'moment'

// composables
import useInvoices from '@/composables/invoices'

// configs
import { DATE_FORMAT } from '@/configs/index.js'

// components
import Btn from '@/components/inputs/Btn.vue'
import Popup from '@/components/modals/Popup.vue'
import InvoiceDetail from '@/pages/invoices/components/InvoiceDetail.vue'

// utils
import {useI18n} from 'vue-i18n'
import showMessage from '@/utils/errorMessage'
import {apiRequest} from '@/utils/apiRequest'
import {currencies} from '@/utils/currencies'
import {getPopupData} from '@/utils/connectors'
import formatDecimal from '@/utils/formatDecimal'

const {t} = useI18n()
const {commit, dispatch, getters} = store

const emit = defineEmits(['close'])
const props = defineProps({
  session: {
    required: false,
    type: Object,
    default: () => {
      return null
    }
  }
})


const state = reactive({
  currencies,
  pickedInvoice: null,
  activePopupStopCharging: false,
  isPayer: getters['account/roles']?.includes('payer') || false
})

const {actionInvoice} = useInvoices()

const sessionDetails = computed(() => {
  return [
    {
      title: t('sessions.locationName'),
      key: props.session?.location_name
    },
    {
      title: t('sessions.locationAddress'),
      key: props.session?.location_street !== '-' ? `${props.session?.location_street} ${props.session?.location_house_num}, ${props.session?.location_city}, ${props.session?.location_country}` : props.session?.location_street
    },
    {
      title: t('sessions.totalkWh'),
      key: props.session?.charging_to ? props.session?.total_kwh : null
    },
    {
      title: t('sessions.totalMinutes'),
      key: props.session?.charging_to ? props.session?.total_minutes : null
    },
    {
      title: t('sessions.maxConnectorPower'),
      key: props.session?.connector_max_power
    },
    {
      title: t('sessions.averagePower'),
      key: props.session?.charging_to ? props.session?.average_power : null
    },
    {
      title: t('sessions.chargingType'),
      key: props.session?.connectorACDC
    },
    {
      title: t('sessions.connectorType'),
      key: props.session?.connector_type_code
    },
    {
      title: t('sessions.chargingFrom'),
      key: props.session?.charging_from
    },
    {
      title: t('sessions.chargingTo'),
      key: props.session?.charging_to ? props.session?.charging_to : null
    },
    {
      title: t('sessions.connectedTo'),
      key: props.session?.charging_to ? props.session?.connected_to : null
    },
    {
      title: t('sessions.priceIncludedVAT'),
      key: props.session?.charging_to ? props.session?.price !== '-' ? `${props.session?.price}` : '-' : null
    },
    {
      title: t('sessions.InvoiceID'),
      key: props.session?.charging_to ? props.session?.invoice_id : null
    },
    {
      title: t('sessions.Driver'),
      key: props.session?.driver_name
    },
    {
      title: t('sessions.RoamingSession'),
      key: props.session?.is_roaming
    }
  ]
})

watch(props.session, () => {
  state.pickedInvoice = null
})

const openInvoiceDetail = async (id) => {
  try {
    const {data} = await apiRequest(`client/invoices/${id}`)
    state.pickedInvoice = data
    commit('invoices/setInvoice', {
      invoiceId: data.invoice_id,
      invoice_number: data.invoice_number,
      amount: `${formatDecimal(data.amount)}`,
      currency: `${data.currency}`,
      priceWithoutVat: `${formatDecimal(data.price_vat_excl)} ${state.currencies[data.currency]}`,
      tax: `${formatDecimal(data.tax_amount)} ${state.currencies[data.currency]}`,
      variableSymbol: data.variable_symbol,
      status: t(`invoices.${data.status}`),
      status_key: data.status,
      has_pdf_invoice: data.has_pdf_invoice,
      has_pdf_statement: data.has_pdf_statement,
      amountNet: `${formatDecimal(data.net_amount)} ${state.currencies[data.currency]}`,
      bankAccount: data.greenway_bank_account,
      billingPeriod: `${moment(data.delivery_date_from).format(DATE_FORMAT)} - ${moment(data.delivery_date_to).format(DATE_FORMAT)}`,
      issue_date: moment(data.issue_date).format(DATE_FORMAT),
      due_date: moment(data.due_date).format(DATE_FORMAT)
    })
  } catch (error) {
    showMessage(error)
  }

}
const getStopChargingPopupData = () => {
  return getPopupData()
}
const stopCharging = () => {
  dispatch('connectors/stopCharging', {
    driver_id: props.session?.driver_id,
    connector_id: props.session?.connector_id
  })
      .then(() => {
        showMessage(t('driverInfo.ChargingStoppedSuccessfully'), 'success')
      }).catch(error => {
    showMessage(error)
  }).finally(() => {
    dispatch('filters/getSessionsFilters')
  })
}
</script>

<template>
  <div v-if="!state.pickedInvoice"
       class="session-detail-wrapper pa-8"
  >
    <header class="session__header">
      <h2 class="d-flex align-center">{{ t('sessions.sessionDetail') }}</h2>
      <v-btn
          color="primary"
          size="large"
          variant="text"
          @click="emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </header>
    <main
        v-for="({ title, key }) in sessionDetails"
        :key="title"
        class="session__main"
    >
      <div
          v-if="title === t('sessions.InvoiceID')"
          class="session__row"
      >
        <span class="session__row-title">{{ t('sessions.InvoiceID') }}</span>
        <span
            v-if="key !== '-' && key !== null && state.isPayer"
            class="active-link session__row-value"
            @click="openInvoiceDetail(key)"
        >
                    {{ props.session.invoice_number }}
                </span>
        <span
            v-else
            class="session__row-value"
        >
                    {{ key }}
                </span>
      </div>
      <div
          v-else
          class="session__row"
      >
        <span class="session__row-title">{{ title }}</span>
        <span
            v-if="key"
            class="session__row-value"
        >{{ key }}</span>
        <span
            v-else
            class="session__row-value d-flex align-center"
        >
                    <v-progress-linear
                        :height="7"
                        :rounded="true"
                        class="width-50"
                        color="primary"
                        indeterminate
                    />
                </span>
      </div>
    </main>
    <Popup
        :active="state.activePopupStopCharging"
        :popup-data="getStopChargingPopupData()"
        @accept="stopCharging()"
        @cancel="state.activePopupStopCharging = false"
    />
    <Btn
        v-if="session.charging_to === null"
        class="mb-5 mt-3 mt-sm-10"
        @click="state.activePopupStopCharging = true"
    >
      {{ t('driverInfo.StopCharging') }}
    </Btn>
  </div>
  <div v-else>
    <InvoiceDetail
        :invoice="getters['invoices/invoice']"
        @action="actionInvoice"
        @close="state.pickedInvoice = null"
    />
  </div>
</template>
