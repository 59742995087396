<script setup>
import {computed, watch, reactive, onMounted} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'

const route = useRoute()

const store = useStore()
const {dispatch} = store

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const state = reactive({
    isLocationMap: false
})

const isWarningLine = computed(() => {
    return store.state.news?.notifications.some(message => message.category === 'danger')
})

watch(route, () => {
    state.isLocationMap = window.location.pathname.indexOf('locationMap') > -1
})

const scrollToAndFocus = () => {
    const element = document.getElementById('danger-notification-1')
    if(element) {
        element.scrollIntoView({ behavior: "smooth" })
        element.classList.add('pulse')
    }
}

onMounted(() => {
    state.isLocationMap = window.location.pathname.indexOf('locationMap') > -1
    store.subscribeAction({
        after: async (action) => {
            if (['account/setAccount', 'account/getAccount', 'account/saveCard', 'account/removeCard'].includes(action.type)) {
                await dispatch('news/getNotifications')
            }
        }
    })
})
</script>

<template>
    <div 
        v-if="isWarningLine && !state.isLocationMap"
        class="text-center blocked-wrapper"
    >   
        <v-sheet class="page__sheet" color="error">
            <template v-if="route.name === 'news'">
                <span class="page__sheet-link" @click="scrollToAndFocus">{{ t('Client.BlockedChargingServices') }}</span>
            </template>
            <router-link v-else class="page__sheet-link" to="/news">
                {{ t('Client.BlockedChargingServices') }}
            </router-link>
        </v-sheet>
    </div>
</template>