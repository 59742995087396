import { apiRequest } from '@/utils/apiRequest'

import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

export const actions = {
    getTranslation: async ({ commit, rootState }) => {
        const lang = rootState.account.lang || cookies.get('gw_lang') || import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en'
        try {
            const { data } = await apiRequest(`translations/all/${lang}`)
            commit('setTranslation', { lang, data })
        } catch (e) {
            const result = typeof e?.response !== 'undefined' ? e.response?.data : e.message
            if (result) {
                throw result
            } else {
                throw ('something goes wrong')
            }
        }
    }
}