<script setup>
import { useStore } from 'vuex'
import { ref, watch, computed, onUnmounted, reactive } from 'vue'

// components
import BillingForm from '@/components/BillingForm.vue'
import CompanyForm from '@/components/CompanyForm.vue'
import PersonalForm from '@/components/PersonalForm.vue'

// utils
import { cloneDeep } from '@/utils/index'
import formatDate from '@/utils/formatDate'
import showMessage from '@/utils/errorMessage'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'

// configs
import { Client, Locale } from '@/configs/index.js'

// composables
import useBeforeWindowUnload from '@/composables/useBeforeWindowUnload'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const beforeWindowUnload = useBeforeWindowUnload()

const store = useStore()
const { dispatch, commit, getters } = store

defineProps({
    isLoading: {
        type: Boolean,
        required: false,
        default: () => {
            return false
        }
    }
})

const emit = defineEmits(['showPrompt', 'resolveYes'])

const state = reactive({
    errors: {
        password: '',
        birth_date: ''
    },
    linkedAccounts: [
        { title: 'Microsoft', icon: 'IconMicrosoft' },
        { title: 'Google', icon: 'IconGoogle' },
        { title: 'Facebook', icon: 'IconFacebook', link: import.meta.env.VITE_APP_FACEBOOK_LINK }
    ],
    actions: [{ title: t('driverInfo.edit'), action: 'edit', icon: 'mdi-pencil' }],
    actionsChange: [
        { title: t('driverInfo.cancel'), action: 'cancel', color: 'gray', icon: 'mdi-close', buttonVariant: 'text' },
        {
            title: t('driverInfo.apply'),
            action: 'apply',
            color: 'primary',
            icon: 'mdi-check',
            buttonVariant: 'elevated'
        }
    ]
})
const form = ref()

const hasChanges = computed(() => {
    // or we don't edit, or there is already edit without accountTemp
    if (!account.value.personalDataIsEditView || (account.value.personalDataIsEditView && !accountTemp.value)) {
        return false
    }

    const cleanedAccount = cloneDeep(account.value)
    const cleanedAccountTemp = cloneDeep(accountTemp.value)

    delete cleanedAccount.personalDataIsEditView
    delete cleanedAccountTemp.personalDataIsEditView

    if (typeof cleanedAccountTemp.password === 'undefined' || cleanedAccountTemp.password.trim().length === 0) {
        delete cleanedAccount.password
        delete cleanedAccountTemp.password
    }

    return JSON.stringify(cleanedAccount) !== JSON.stringify(cleanedAccountTemp)
})

const accountTemp = computed({
    get() {
        return store.state.settings.accountTemp
    },
    set(value) {
        commit('settings/setFieldValue', {
            field: 'accountTemp',
            value
        })
    }
})

const account = computed(() => {
    return { ...store.state.account, password: '' }
})

const isCompany = computed(() => {
    return !!store.state.account.isCompany
})

const personalDataIsEditView = computed({
    get() {
        return store.state.account.personalDataIsEditView
    },
    set(value) {
        commit('account/setPersonalDataIsEditView', value)
    }
})

const setError = ({ key, value }) => {
    state.errors[key] = value
}

const handleAction = (title) => {
    if (title === 'edit') {
        // clone current account data
        accountTemp.value = cloneDeep({ ...account.value, password: '' })
        personalDataIsEditView.value = true
    }
}

const handleActionDetails = async (title) => {
    const { valid } = await form.value.validate()

    switch (title) {
        case 'cancel':
            if (hasChanges.value) {
                emit('showPrompt')
            } else {
                emit('resolveYes')
            }
            break
        case 'apply':
            if (valid) {
                updateSettings()
            } else {
                showMessage(t('error.FormContainsErrors'))
            }
    }
}

const prepareData = () => {
    const data = {
        language: accountTemp?.value?.communicationLanguage,
        first_name: accountTemp?.value?.firstName,
        last_name: accountTemp?.value?.lastName,
        phone: accountTemp?.value?.phone,
        email: accountTemp?.value?.email,
        gender: accountTemp?.value?.gender
            ? capitalizeFirstLetter(accountTemp?.value?.gender)
            : accountTemp?.value?.gender,
        type_registration: accountTemp?.value?.isCompany ? Client.isCompany : Client.isIndividual,
        delivery_address_object: accountTemp?.value?.deliveryAddress
            ? {
                  name: accountTemp?.value?.deliveryAddress.name,
                  street: accountTemp?.value?.deliveryAddress.street,
                  house_number: accountTemp?.value?.deliveryAddress.houseNumber,
                  zip_code: accountTemp?.value?.deliveryAddress.zipCode,
                  city: accountTemp?.value?.deliveryAddress.city,
                  country_name: accountTemp?.value?.deliveryAddress.country
              }
            : null,
        billing_address: {
            city: accountTemp?.value?.city,
            street: accountTemp?.value?.street,
            house_number: accountTemp?.value?.houseNumber,
            zip_code: accountTemp?.value?.zipCode
        },
        birth_date: getters['account/locale'] === Locale.sk ? formatDate(accountTemp?.value?.birthDate) : null
    }

    if (accountTemp?.value?.password) {
        data.password = accountTemp?.value?.password
    }
    if (isCompany.value) {
        data.company_information = {
            self_employed: accountTemp?.value?.isSelfEmployed,
            name: accountTemp?.value?.companyName,
            tax_id: accountTemp?.value?.taxId,
            vat_id: accountTemp?.value?.vatId
        }
    }
    if (!isCompany.value) {
        data.billing_address.country_name = accountTemp?.value?.country
    }

    return data
}

const updateSettings = () => {
    dispatch('account/setAccount', prepareData())
        .then(() => {
            showMessage(t('driverInfo.DataBeenChangedSuccessfully'), 'success')
            personalDataIsEditView.value = false
        })
        .catch((error) => {
            for (const [key, value] of Object.entries(error)) {
                switch (key) {
                    case 'email':
                        setError('email', value[0])
                        break
                    case 'password':
                        setError('password', value[0])
                        break
                    case 'birth_date':
                        setError('birth_date', value[0])
                        break
                    default:
                        showMessage(value[0])
                        break
                }
            }
        })
}

watch(personalDataIsEditView, (value) => {
    if (value) {
        window.onbeforeunload = beforeWindowUnload
    } else {
        window.onbeforeunload = null
    }
})

onUnmounted(() => {
    window.onbeforeunload = null
    personalDataIsEditView.value = false
})
</script>

<template>
    <v-container class="account-personal-data-wrapper" fluid>
        <v-row v-if="!account.id || isLoading" class="loading-wrapper mt-5">
            <v-col class="d-flex align-items-center justify-content-end text-center mt-5" cols="12">
                <v-progress-circular :size="50" class="m-auto" color="primary" indeterminate />
            </v-col>
        </v-row>
        <v-row v-else class="mt-5">
            <v-col class="pt-0" cols="12">
                <v-form ref="form">
                    <CompanyForm
                        v-if="isCompany && account.id"
                        @handleAction="handleAction($event)"
                        :is-loading="isLoading"
                    />
                    <PersonalForm
                        v-if="account.id"
                        :errors="state.errors"
                        @handleAction="handleAction($event)"
                        @setError="setError"
                        :is-loading="isLoading"
                    />
                    <BillingForm v-if="account.id" />
                </v-form>
                <v-row class="mt-10">
                    <v-col v-if="personalDataIsEditView" class="d-flex flex-row justify-center" cols="12">
                        <div v-for="{ title, action, color, buttonVariant } in state.actionsChange" :key="title">
                            <v-btn
                                :disabled="action === 'apply' ? !hasChanges : false"
                                :loading="store.getters['isLoading']"
                                :outlined="action !== 'apply'"
                                class="mx-10 px-10"
                                :color="color"
                                :variant="buttonVariant"
                                data-testid="editAccount"
                                @click="handleActionDetails(action)"
                            >
                                {{ title }}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
