<script setup>
import { ContentLoader } from 'vue-content-loader'
import useWindowWidth from '@/composables/windowWidth'

const { windowWidth } = useWindowWidth()
</script>

<template>
    <div class="news-loader-wrapper">
        <v-skeleton-loader type="article" width="100%" class="mb-4"></v-skeleton-loader>
    </div>
</template>