export const mutations = {
    setFacilitySessions: (state, facilitySessions) => {
        state.facilitySessions = facilitySessions
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination
    },
    setPage: (state, page) => {
        state.pagination.page = page
    },
    setItemPerPage: (state, itemsPerPage) => {
        state.pagination.itemsPerPage = itemsPerPage
    },
    setFilters: (state, filters) => {
        state.filters = filters
    }
}