export const mutations = {
    setSessionsFilters: (state, filters) => {
        state.sessionsFilters = Object.assign(state.sessionsFilters, filters)
    },
    setSessionsFiltersLocation: (state, location) => {
        state.sessionsFilters.location = location
    },
    setTitles: (state, titles) => {
        state.titles = titles
    },
    setCountries: (state, countries) => {
        state.countries = countries
    },
    setLanguages: (state, languages) => {
        state.languages = languages
    }
}