<script setup>
// components
import Aside from '@/components/Aside.vue'
import FooterLogIn from '@/pages/login/components/Footer.vue'
</script>

<template>
    <div class="login-register">
        <Aside class="login-register__aside " />
        <div class="d-flex flex-column login-register__section--login">
            <section class="login-register__section">
                <header class="login-register__header">
                    <slot name="header" />
                </header>
                <main class="login-register__main">
                    <div class="login-register__main-content">
                        <slot name="main" />
                    </div>
                </main>
            </section>
            <FooterLogIn class="v-container" />
        </div>
    </div>
</template>