// Utils
import { apiRequest } from '@/utils/apiRequest'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }

        commit('account/resetStore', state)
        cookies.remove('mobile-token')
    },
    getData: async ({ commit, dispatch }) => {
        commit('setLoader', 1, { root: true })
        const maxChargingSessionsPerDriver = apiRequest('client/fleet/max-charging-sessions-per-driver?maxDriverCount=10&lastXDays=7').catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
            
        const maxEnergyPerDriver = apiRequest('client/fleet/max-energy-per-driver?maxDriverCount=10&lastXDays=7').catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
            
        const maxTimePerDriver = apiRequest('client/fleet/max-time-per-driver?maxDriverCount=10&lastXDays=7').catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
            
        const roamingSessionsCount = apiRequest('client/fleet/roaming-sessions-count?lastXDays=7').catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
            
        const chargingSessionsPerDay = apiRequest('client/fleet/charging-sessions-per-days?lastXDays=7').catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
            
        const energyPerDays = apiRequest('client/fleet/energy-per-days?lastXDays=7').catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
            
        const chargingTimePerDays = apiRequest('client/fleet/charging-time-per-days?lastXDays=7').catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
            
        Promise.all([
            maxChargingSessionsPerDriver,
            maxEnergyPerDriver,
            maxTimePerDriver,
            roamingSessionsCount,
            chargingSessionsPerDay,
            energyPerDays,
            chargingTimePerDays
        ]).then(values => {
            commit('setData', {
                maxChargingSessions: values[0].data,
                maxEnergy: values[1].data,
                maxTime: values[2].data,
                roamingSessions: values[3].data,
                chargingSessions: values[4].data,
                energy: values[5].data,
                chargingTime: values[6].data
            })
        })
    }
}