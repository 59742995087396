import {getEnvironment} from '@/utils/isFeatureAvailable'

const host = 'https://gitlab.com/api/v4/feature_flags/unleash/48393453'
const env = getEnvironment(window.location.origin)
const ID = 'fbQMtJYaf7sa-JacCzcS'

export {
    host,
    env,
    ID
}