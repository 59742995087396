<script setup>
import {onBeforeMount, watch} from 'vue'
import store from '@/store'
import {useI18n} from 'vue-i18n'
import router from '@/router.js'
import {ref, computed} from 'vue'

// components
import Table from '@/components/Table.vue'
import ConsumptionsFilter from './ConsumptionsFilter.vue'

// composables
import useFilterableList from '@/composables/filterableList.js'
import useWindowWidth from '@/composables/windowWidth.js'

// configs
import {Roles} from '@/configs/index.js'

const selectedRow = ref(null)
const {dispatch, commit, getters, state} = store

const props = defineProps({
    isFiltersVisible: {
        type: Boolean,
        required: true
    },
    preSelectFilter: {
        type: Object,
        required: false,
        default: null
    }
})

const {
    isCardView,
    isTable,
    filtersSelected,
    options,
    getItems,
    getDefaultFilters,
    setFilter,
    clearFilters,
    sorting
} = useFilterableList({
    preSelectFilter: props.preSelectFilter,
    filters: {
        user: null,
        charger: null,
        category: null,
        date: {
            from: null,
            to: null
        },
        tags: null
    },
    options: {
        sortBy: ['id']
    },
    action: 'consumptions/getConsumptionsSessions'
})

const {t} = useI18n()
const {
    windowWidth,
    setWindowWidth
} = useWindowWidth()

const isCustomVisible = ref(false)
const consumptionsExportLoading = ref(false)
const selected = ref([])

const actions = ref([
    {
        title: t('invoices.DownloadInvoice'),
        action: 'Download invoice',
        icon: 'mdi-download'
    },
    {
        title: t('invoices.DownloadListOfSessions'),
        action: 'Download list of sessions',
        icon: 'mdi-file-download-outline'
    }
])
const headers = computed(() => {
    return roles.value.includes(Roles.payer) ? getters['consumptions/tableHeaderPayer'] : getters['consumptions/tableHeader']
})
const sessions = computed(() => {
    return getters['consumptions/consumptionSessions']
})
const pagination = computed(() => {
    return state.consumptions.pagination
})
const countSelectedItems = computed(() => {
    return selected.value.length
})
const roles = computed(() => {
    return state.account.roles
})

const goToDetail = (consumption) => {
    router.push(`/consumption/${consumption.id}`)
}

const checkChargingToRowClass = (item) => {
    if (typeof item !== 'undefined' && !item.charging_to) {
        return 'highlight-row'
    }
}

// because watch is before onMount
onBeforeMount(() => {
    dispatch('consumptions/getFilters').then(() => {
        if(props.preSelectFilter) {
            commit('consumptions/setSelectedFiltersFieldValue', {
                field: props.preSelectFilter.field,
                value: props.preSelectFilter.value
            })
            setFilter(props.preSelectFilter)
            getItems()

        }
    })
})

watch(options, () => {
    getItems()
})
</script>

<template>
    <v-container class="consumptions-sessions pa-0" fluid>
        <main class="consumptions-sessions__main">
            <v-row v-if="windowWidth < 960" class="consumptions-sessions__header d-none">
                <v-col class="d-flex justify-start" cols="12">
                    <div class="grid-switcher d-flex justify-center">
                        <v-btn :class="{'active': isCardView}"
                               :ripple="false"
                               class="active-button px-0"
                               color="var(--v-white)"
                               icon
                               @click="isCardView = true"
                               elevation="1"
                        >
                            <v-icon class="active-icon">
                                mdi-view-grid
                            </v-icon>
                        </v-btn>
                        <v-btn :class="{'active': !isCardView}"
                               :ripple="false"
                               class="active-button px-0"
                               color="var(--v-white)"
                               icon
                               @click="isCardView = false"
                               elevation="1"
                        >
                            <v-icon class="active-icon">
                                mdi-view-sequential
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <ConsumptionsFilter
                v-show="props.isFiltersVisible"
                :default-filters="getDefaultFilters()"
                :filters-selected="filtersSelected"
                @clearFilters="clearFilters"
                @getItems="getItems"
                @setFilter="setFilter"
            />
            <div ref="session-table"
                 class="consumptions-sessions__table-container'"
            >
                <Table
                    :card-view="isCardView"
                    :class="{'table-view-mobile-size': !isCardView}"
                    :headers="headers"
                    :is-descending="options.sortDesc ? options.sortDesc : true"
                    :item-class="checkChargingToRowClass"
                    :items="sessions"
                    :pagination="pagination"
                    :selected-row="selectedRow"
                    :server-items="pagination.itemsLength"
                    :sort-column-name="options.sortBy[0]"
                    class="consumptions-sessions__table"
                    @options="value => options = value"
                    @sorting="sorting"
                >
                    <template v-slot:detail="props">
                        <v-btn icon="mdi-magnify" variant="text" @click="goToDetail(props.item)" color="primary" />
                    </template>
                </Table>
            </div>
        </main>
    </v-container>
</template>

