import store from '@/store'
import {Lang} from '@/configs/index.js'

export default function formatDecimal(number) {
    'use strict'

    if (!number && number !==0) {
        return ''
    }

    let locale = store.getters['account/locale'] ?
        `${store.getters['account/locale']}-${store.getters['account/locale'].toUpperCase()}` :
        `${import.meta.env.VITE_APP_I18N_LOCALE}-${import.meta.env.VITE_APP_I18N_LOCALE.toUpperCase()}`
    let lang = store.getters['account/lang']
    
    return (lang && (lang === Lang.sk || lang === Lang.pl))
        ? Number(number).toLocaleString(locale, {minimumFractionDigits: 2, maximumFractionDigits: 2}) 
        : Number(number).toLocaleString(locale, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(/,/g, '.')
}