import formatChargerCode from '@/utils/formatChargerCode'
import en from '@/translations/EN.json'

export const getters = {
    charger: state => state.charger,
    chargers: state => state.chargers.map(charger => ({
        'id': charger.id,
        'code_value': `${formatChargerCode(charger.code)}`,
        'manufacture': charger.manufacture,
        'category': charger.category ? charger.category : '-',
        'model': charger.model,
        'location': !Array.isArray(charger.location) ? charger.location : '-',
        'location.name': !Array.isArray(charger.location) ? charger.location.name : '-',
        'location.id': !Array.isArray(charger.location) ? charger.location.code : '-',
        'location_address': !Array.isArray(charger.location) ? charger.location.street +
            ' ' + charger.location.house_number +
            ', ' + charger.location.city : '-',
        'code': charger.code

    })),
    filters: state => state.filters,
    tableHeaders: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                width: '50px',
                align: 'center',
                sortable: false,
                value: 'detail'
            },
            {
                title: translation['tableHeaders.ChargerLabel'],
                align: 'start',
                sortable: false,
                value: 'code_value'
            },
            {
                title: translation['tableHeaders.Manufacture'],
                align: 'start',
                sortable: true,
                value: 'manufacture'
            },
            {
                title: translation['tableHeaders.Model'],
                align: 'start',
                sortable: true,
                value: 'model'
            },
            {
                title: translation['tableHeaders.Location name'],
                align: 'start',
                sortable: true,
                value: 'location.name'
            },
            {
                title: translation['tableHeaders.Location address'],
                align: 'start',
                sortable: false,
                value: 'location_address'
            },
            {
                title: translation['chargers.ChargerCode'],
                align: 'start',
                sortable: true,
                value: 'code'
            },
            {
                title: translation['tableHeaders.ChargerType'],
                align: 'start',
                sortable: true,
                value: 'category',
                width: '150px'
            },
            {
                title: translation['tableHeaders.Location ID'],
                align: 'start',
                sortable: true,
                value: 'location.id'
            }
        ]
    }
}