import formatChargerCode from '@/utils/formatChargerCode'
import en from '@/translations/EN.json'
import moment from 'moment/moment.js'
import formatDecimal from '@/utils/formatDecimal.js'

// configs
import { DATE_FORMAT } from '@/configs/index.js'

export const getters = {
    filters: (state) => {
        return state.filters
    },
    selectedFilters: (state) => {
        return state.selectedFilters
    },
    consumption: state => state.consumptionSession,
    consumptionSessions: (state, getters, rootState) => state.consumptionsSessions.map(session => ({
        id: session.id,
        user: session.driver ? session.driver.first_name : '-',
        from: session.from ? moment(session.from).format('DD.MM.YYYY HH:mm') : '-',
        to: session.to ? moment(session.to).format('DD.MM.YYYY HH:mm') : null,
        fromTo: session.from && session.to ? `${moment(session.from).format(DATE_FORMAT)} - ${moment(session.to).format(DATE_FORMAT)}` : '-',
        charger: session.device ? `${session.device.code} ${session.device.location.name ? ' -' + session.device.location.name : ''}` : '-',
        // tags: session.tags ? session.tags.join(', ') : '-',
        consumption: session.quantity_kwh ? session.quantity_kwh : '-',
        cost_kwh: session.cost_kwh ? session.cost_kwh : '-',
        cost: session.total ? session.total : '-',
        odometer: session.odometer_reading ? session.odometer_reading.value : '-',
        average_consumption: session.odometer_reading ? session.odometer_reading.avg_consumption : '-',
        connector_type: session.device ? session.device.category : '-'
    })),
    tableHeaderPayer: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                width: '50px',
                align: 'center',
                sortable: false,
                value: 'detail'
            },
            {
                title: translation['tableHeaders.user'],
                align: 'start',
                sortable: false,
                value: 'user'
            },
            {
                title: translation['tableHeaders.charger'],
                align: 'start',
                sortable: false,
                value: 'charger'
            },
            // {
            //     title: translation['tableHeaders.tags'],
            //     align: 'start',
            //     sortable: false,
            //     value: 'tags'
            // },
            {
                title: translation['tableHeaders.type'],
                align: 'start',
                sortable: false,
                value: 'connector_type'
            },
            {
                title: translation['tableHeaders.fromTo'],
                align: 'start',
                sortable: false,
                value: 'fromTo'
            },
            {
                title: translation['tableHeaders.consumption'],
                align: 'start',
                sortable: false,
                value: 'consumption'
            },
            {
                title: translation['tableHeaders.cost'],
                align: 'start',
                sortable: false,
                value: 'cost'
            },
            {
                title: translation['tableHeaders.odometer'],
                align: 'start',
                sortable: false,
                value: 'odometer'
            },
            {
                title: translation['tableHeaders.averageConsumption'],
                align: 'start',
                sortable: false,
                value: 'average_consumption'
            }
        ]
    },
    tableHeader: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                width: 'auto',
                align: 'center',
                sortable: false,
                value: 'detail'
            },
            {
                title: translation['tableHeaders.charger'],
                align: 'start',
                sortable: false,
                value: 'charger'
            },
            {
                title: translation['tableHeaders.fromTo'],
                align: 'start',
                sortable: false,
                value: 'fromTo'
            },
            {
                title: translation['tableHeaders.consumption'],
                align: 'start',
                sortable: false,
                value: 'consumption'
            },
            {
                title: translation['tableHeaders.cost'],
                align: 'start',
                sortable: false,
                value: 'cost'
            },
            {
                title: translation['tableHeaders.odometer'],
                align: 'start',
                sortable: false,
                value: 'odometer_reading'
            },
            {
                title: translation['tableHeaders.averageConsumption'],
                align: 'start',
                sortable: false,
                value: 'average_consumption'
            }
        ]
    }
}