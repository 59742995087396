<script setup>
import {useStore} from 'vuex'

// components
import Btn from '@/components/inputs/Btn.vue'

// utils
import isObjEqual from '@/utils/isObjEqual'
import {computed, reactive} from 'vue'

// translations
import {useI18n} from 'vue-i18n'

const { t } = useI18n()

const store = useStore()
const { getters } = store

const emit = defineEmits(['setFilter', 'getItems', 'clearFilters'])

const props = defineProps({
    filtersSelected: {
        required: false
    },
    defaultFilters: {
        required: false
    }
})

const state = reactive({
    searchInputCode: ''
})

const filters = computed(() => {
    return getters['chargers/filters']
})

const locationFilters = computed(() => {
    return getters['locations/filters']
})

const showClear = computed(() => {
    return !isObjEqual(props.filtersSelected, props.defaultFilters)
})

function debounce(func, delay) {
    let timerId
    return function (...args) {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            func.apply(this, args) // Call the debounced function with arguments
        }, delay)
    }
}

const setFilter = debounce(function (field, value) {
    emit('setFilter', {field, value})
    emit('getItems')
}, 1500)

const clearFilters = () => {
    emit('clearFilters')
}

const dateFromTo = ({dateFrom: from, dateTo: to, isClear}) => {
    setFilter('date', {
        from: from || null,
        to: to || null
    })
    isClear = isClear
}

</script>

<template>
    <v-container v-if="filters" class="location-chargers-filter__filters" fluid>
        <v-row class="pt-10 px-4 pb-4 mr-0">
            <v-col 
                class="py-0"
                cols="12"
                md="3"
                sm="6"
            >
                <v-autocomplete 
                    :items="filters.filter_by_code"
                    :label="t('chargers.Code')"
                    v-model="filtersSelected.code"
                    clearable
                    density="compact"
                    variant="outlined"
                    item-title="name"
                    item-value="value"
                    multiple
                    @update:model-value="setFilter('code', $event)"
                />
            </v-col>
            <v-col 
                v-if="showClear"
                class="py-0"
                cols="12"
                md="3"
                sm="6"
            >
                <Btn color="primary" text @click="[emit('clearFilters'), state.isClear = true]">
                    <v-icon class="clear-icon pr-1">
                        mdi-delete
                    </v-icon>
                    {{ t('filter.ClearAll') }}
                </Btn>
            </v-col>
        </v-row>
    </v-container>
</template>
