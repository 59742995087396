<script setup>
// components
import Footer from '@/components/Footer.vue'
import Logo from '@/components/icons/Logo.vue'
import LangSwitcher from '@/components/inputs/LangSwitcher.vue'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<template>
    <div class="campaigns">
        <header class="campaigns__header">
            <div class="d-flex justify-space-between align-center mb-4">
                <logo class="campaigns-logo" />
                <LangSwitcher class="campaigns-switcher" />
            </div>
        </header>
        <main class="campaigns__main">
            <div class="campaigns__main-container">
                <div class="campaigns__main-header">
                    <h1>{{ t('campaigns.HowToActivate') }}</h1>
                </div>
                <div class="campaigns__main-content">
                    <div class="campaigns-info">
                        <div class="campaigns-info__block">
                            <h2>{{ t('campaigns.StepsToActivateIfNoAccount') }}</h2>
                            <div class="content">
                                <ol>
                                    <li>
                                        {{ t('campaigns.GoToPage') }}
                                        <router-link target="_blank" :to="{ name: 'registration' }">
                                            {{ t('campaigns.RegistrationPage') }}
                                        </router-link>
                                    </li>
                                    <li>{{ t('campaigns.PleaseSignUp') }}</li>
                                    <li>
                                        {{ t('campaigns.InClientZoneGoToSection') }}
                                        <router-link target="_blank" :to="{ name: 'coupons' }">
                                            {{ t('sidebar.Coupons') }}
                                        </router-link>
                                    </li>
                                    <li>
                                        <i18n path="campaigns.EnterYourCouponCode" tag="span">
                                            <br />
                                        </i18n>
                                    </li>
                                    <li>{{ t('campaigns.PressApply') }}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="campaigns-info__block">
                            <h2>{{ t('campaigns.StepsToActivateWithAccount') }}</h2>
                            <div class="content">
                                <ol>
                                    <li>
                                        {{ t('campaigns.PleaseLoginInClientZoneGoToSection') }}
                                        <router-link target="_blank" :to="{ name: 'coupons' }">
                                            {{ t('sidebar.Coupons') }}
                                        </router-link>
                                    </li>
                                    <li>
                                        <i18n path="campaigns.EnterYourCouponCode" tag="span">
                                            <br />
                                        </i18n>
                                    </li>
                                    <li>{{ t('campaigns.PressApply') }}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="campaigns-info__block">
                            <h3>{{ t('campaigns.IfYouHaveAnyQuestions') }}</h3>
                            <a :href="`tel:${t('phoneNumber.customerServiceOnline')}`">
                                {{ t('phoneNumber.customerServiceOnline') }}</a
                            >
                        </div>
                        <small>{{ t('campaigns.WeHopeYouWillEnjoyOurService') }}</small>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>
