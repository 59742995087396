<script setup>
const props = defineProps({
    firstName: {
        type: String,
        default: '?'
    },
    lastName: {
        type: String,
        default: ' '
    },
    size: {
        type: Number,
        default: 48
    },
    fontSize: {
        type: Number,
        default: 1
    }
})

const availableColors = [
    'red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue',
    'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber',
    'orange', 'deep-orange', 'brown', 'grey', 'blue-grey'
]

const randomColor = () => {
    const randomIndex = Math.floor(Math.random() * availableColors.length)
    return availableColors[randomIndex]
}

const getInitials = (firstName, lastName) => {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
}
</script>

<template>
    <v-avatar :color="randomColor()"
              :size="size"
              :style="`font-size: ${fontSize}rem`"
    >
        <span>
            {{ getInitials(props.firstName, props.lastName) }}
        </span>
    </v-avatar>
</template>
