<script setup>
import { ContentLoader } from 'vue-content-loader'
import useWindowWidth from '@/composables/windowWidth'

const { windowWidth } = useWindowWidth()
</script>

<template>
    <div class="coupons-loader">
        <content-loader v-if="windowWidth > 960"
                        :height="105"
                        :width="698"
                        :speed="2"
                        primary-color="rgb(var(--v-theme-white))"
                        secondary-color="rgb(var(--v-theme-back))"
        >
            <rect x="4"
                  y="4"
                  rx="0"
                  ry="0"
                  width="225"
                  height="105"
            />
            <rect x="236.5"
                  y="4"
                  rx="0"
                  ry="0"
                  width="225"
                  height="105"
            />
            <rect x="469.5"
                  y="4"
                  rx="0"
                  ry="0"
                  width="227.5"
                  height="105"
            />
        </content-loader>
        <content-loader v-else-if="windowWidth > 600"
                        :height="50"
                        :width="200"
                        :speed="2"
                        primary-color="rgb(var(--v-theme-white))"
                        secondary-color="rgb(var(--v-theme-back))"
        >
            <rect x="2.5"
                  y="5"
                  rx="0"
                  ry="0"
                  width="95"
                  height="50"
            />
            <rect x="102.5"
                  y="5"
                  rx="0"
                  ry="0"
                  width="95"
                  height="50"
            />
        </content-loader>
        <content-loader v-else
                        :width="100"
                        :height="50"
                        :speed="2"
                        primary-color="rgb(var(--v-theme-white))"
                        secondary-color="rgb(var(--v-theme-back))"
        >
            <rect x="0"
                  y="2"
                  rx="0"
                  ry="0"
                  width="100"
                  height="48"
            />
        </content-loader>
    </div>
</template>