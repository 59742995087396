<script setup>
import { useStore } from 'vuex'
import { watch } from 'vue'

// composables
import useChargers from '@/composables/chargers'

// components
import Table from '@/components/Table.vue'
import Btn from '@/components/inputs/Btn.vue'
import LocationChargersFilter from './LocationChargersFilter.vue'
import ChargingForm from '@/components/chargingForm/ChargingForm.vue'
import MenuActionsMobile from '@/components/inputs/MenuActionsMobile.vue'
import StopChargingForm from '@/components/chargingForm/StopChargingForm.vue'

// translations
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const store = useStore()

const props = defineProps({
    locationId: {
        required: false
    },
    isFiltersVisible: {
        required: false
    }
})

const {
    windowWidth,
    currentCharger,
    isPopupStartCharging,
    isPopupStopCharging,
    isPopupRestartCharging,
    selected,
    popupData,
    actions,
    headers,
    chargers,
    pagination,
    filtersSelected,
    options,
    getItems,
    getDefaultFilters,
    setFilter,
    clearFilters,
    sorting,
    goToDetail,
    action,
    restart
} = useChargers({
    filters: {
        code: [],
        status: null,
        location: {
            id: [props.locationId],
            code: [],
            name: []
        }
    }
})

watch(options, () => {
    getItems()
})

</script>

<template>
    <v-container
        class="location-chargers__list pa-0"
        fluid
    >
        <LocationChargersFilter
            v-show="isFiltersVisible"
            :default-filters="getDefaultFilters()"
            :filters-selected="filtersSelected"
            @clearFilters="clearFilters"
            @getItems="getItems"
            @setFilter="setFilter"
        />
        <div class="chargers__table">
            <Table
                :card-view="true"
                :headers="headers"
                :is-descending="options.sortDesc ? options.sortDesc : true"
                :item-key="'id'"
                :items="chargers"
                :pagination="pagination"
                :server-items="pagination.itemsLength"
                :sort-column-name="options.sortBy[0]"
                @options="value => options = value"
            >
                <!-- <template v-if="windowWidth < 600" slot="detail">
            <Btn class="chargers__btn">
                {{ t('drivers.details') }}
            </Btn>
        </template> -->
                <template v-slot:actions="props">
                    <MenuActionsMobile
                        :id="props.item"
                        :actions-for="'chargers'"
                        :is-invoice="false"
                        :is-styled="true"
                        class="d-inline-flex"
                        text=""
                        @action="action"
                    />
                </template>
                <template
                    v-if="windowWidth < 600"
                    v-slot:manufacture="props"
                >
                    <span class="text-primary">{{ props.item.manufacture }}</span>
                </template>
                <template
                    v-else
                    v-slot:manufacture="props"
                >
                    <span>{{ props.item.manufacture }}</span>
                </template>
            <template v-slot:code="props">
                <span>{{ props.item.code }}</span>
            </template>
            <template v-slot:detail="props">
                <v-btn
                    color="primary"
                    icon="mdi-magnify"
                    variant="text"
                    @click="goToDetail(props.item)"
                />
            </template>
        </Table>
    </div>
    <ChargingForm
        v-if="isPopupStartCharging === true"
        :driver="store.state.account.primaryDriverId"
        :initial-charger="currentCharger"
        :initial-location="currentCharger.location"
        :initial-step="3"
        :is-active="isPopupStartCharging"
        @close="isPopupStartCharging = false"
    />
    <StopChargingForm
        v-if="isPopupStopCharging === true"
        :charger="currentCharger"
        :driver="store.state.account.primaryDriverId"
        :is-active="isPopupStopCharging"
        @close="isPopupStopCharging = false"
    />
</v-container></template>
