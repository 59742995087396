<script setup>
import { ref, computed } from 'vue'
import router from '@/router'

// composabled
import useCharging from '@/composables/charging/useCharging'
import useSessionSummary from '@/composables/charging/useSessionSummary'

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty, isValidEmail } = useValidations()

// translations
const { t } = useI18n()
import { useI18n } from 'vue-i18n'

const {
    activeScreen,
    sessionTerminated,
    sessionSummary,
    isGuest,
    closeChargingSessionAndSendReceipt,
    blockingUserInput
} = useCharging()
const { receiptEmail, sendReceipt } = useSessionSummary()

const promptVisible = ref(false)

const isFormValid = computed(() =>{
    return typeof isValidEmail(receiptEmail.value) === 'boolean' && typeof isNotEmpty(receiptEmail.value) === 'boolean'
})

const togglePrompt = (val) => {
    if (!isGuest.value) {
        router.push('locationMap')
        return
    }
    promptVisible.value = val
}

</script>

<template>
    <v-card
        :elevation="3"
        class="
            session-ended
            mr-auto
            ml-auto
            mt-md-8 mt-2
            d-flex
            flex-column
            align-center
            justify-center
            text-center
        "
    >
        <div class="top-bar d-flex justify-center align-center">
            <h6 class="
                    text-subtitle-1
                    font-weight-medium
                    text-no-wrap text-truncate
                ">
                {{ t('startCharging.sessionEnded') }}
            </h6>
        </div>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <div class="
                            stage
                            d-flex
                            align-center
                            justify-center
                            my-10
                            mx-auto
                        ">
                        <v-icon
                            v-if="sessionTerminated"
                            class="bounce"
                            color="var(--v-sc-blue)"
                            size="64"
                        >
                            mdi-check
                        </v-icon>
                        <v-icon
                            v-else
                            class="bounce"
                            color="var(--v-sc-blue)"
                            size="64"
                        >
                            mdi-information-outline
                        </v-icon>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h2 class="text-h6 font-weight-medium text-center mb-4">
                        {{ t('startCharging.thankYou') }}
                    </h2>
                    <p class="text-body-2 mb-2">
                        {{ t('startCharging.chargingEnded') }}
                    </p>
                    <p
                        v-if="sessionSummary === null || !!sessionSummary.length"
                        class="text-body-2 mb-0"
                    >
                        {{ t('startCharging.finalChargerData') }}
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn
                        :disabled="sessionSummary === null || !!sessionSummary.length"
                        :loading="sessionSummary === null || !!sessionSummary.length"
                        class="mb-4"
                        variant="outlined"
                        rounded="xl"
                        @click="activeScreen = 5"
                    >
                        {{ t('startCharging.showSummary') }}
                    </v-btn>
                    <v-btn
                        variant="outlined"
                        rounded="xl"
                        @click="togglePrompt(true)"
                    >
                        {{ t('startCharging.close') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <div
            :class="{ 'visible': promptVisible }"
            class="prompt pa-4"
        >
            <div class="heading mb-4">
                <h3 class="text-subtitle-1 font-weight-medium mb-4">
                    {{ t('startCharging.confirmAction') }}
                </h3>
            </div>
            <div class="content">
                <h4 class="text-body-2 mb-4">
                    {{ t('startCharging.areYouSureSummary') }}
                </h4>
                <v-text-field
                    v-model="receiptEmail"
                    :disabled="blockingUserInput"
                    :label="t('Client.Email')"
                    density="compact"
                    variant="outlined"
                    type="email"
                />
                <div class="buttons d-flex align-center justify-space-around">
                    <v-btn
                        variant="outlined"
                        rounded="xl"
                        @click="togglePrompt(false)"
                    >
                        {{ t('startCharging.back') }}
                    </v-btn>
                    <v-btn
                        :disabled="!isFormValid"
                        :loading="blockingUserInput"
                        color="error"
                        rounded="xl"
                        @click="closeChargingSessionAndSendReceipt()"
                    >
                        {{ t('startCharging.continue') }}
                    </v-btn>
                </div>
            </div>
        </div>
    </v-card>
</template>
