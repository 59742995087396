<script setup>
import {computed} from 'vue'

// utils
import formatDecimal from '@/utils/formatDecimal'
import formatDate from '@/utils/formatDate'

// configs
import { DATE_FORMAT } from '@/configs/index.js'

// translations
import {useI18n} from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
    driverId: {
        type: Number,
        default: null
    },
    freeKwh: {
        required: true,
        type: Array,
        default: () => {
            return []
        }
    }
})

const isLoading = computed(() => {
    return state.loader
})

const formatValidTo = (valid_to) => {
	return formatDate(valid_to, DATE_FORMAT)
}

</script>

<template>
    <v-card class="freeKwhCard mt-5"
            elevation="0"
    >
        <v-card-title>
            <v-row>
                <v-col cols="12" sm="7">
                    <span class="freeKwhCard__title">{{ t('freeKWhpanel.title') }}</span>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-list v-if="freeKwh.length" class="overflow-y-auto" max-height="220">
                <v-list-item
                    v-for="({ freekwh_amount, used_amount, valid_to }, index) in freeKwh"
                    :key="index"
                >
                    <v-list-item-title>
	                    <v-row class="mt-2">
		                    <v-col cols="6">
			                    <span>
		                            <v-avatar color="primary" size="20">
		                                <v-icon color="white" size="15" dark small>mdi-lightning-bolt</v-icon>
		                            </v-avatar>
		                            <span class="freeKwhCard__used_amount pl-2">
		                                <strong>{{ formatDecimal(freekwh_amount - used_amount) }}</strong>
		                                <span class="text text-small"> / {{ formatDecimal(freekwh_amount) }} kWh</span>
		                            </span>
		                        </span>
		                    </v-col>
		                    <v-col class="text-right text-sm-right pa-0 pt-sm-3"
		                           cols="6"
		                    >
			                    <div class="freeKwhCard__valid_to pr-3 ml-3">
				                    <small class="text">
					                    {{ t('freeKWhpanel.validTo') }}
				                    </small>
				                    <strong class="text pl-1">{{ formatValidTo(valid_to) }}</strong>
			                    </div>
		                    </v-col>
	                    </v-row>
                    </v-list-item-title>
                    <v-progress-linear :model-value="formatDecimal((1-(used_amount/freekwh_amount))*100)"
                                        background-color="grey lighten-2"
                                        class="freeKwhCard__bar mt-2"
                                        height="7"
                                        color="primary"
                                        rounded
                    />
                </v-list-item>
            </v-list>
            <div v-else>
                <p class="pt-5 text-center">
                    {{ t('error.NoDataAvailable') }}
                </p>
            </div>
        </v-card-text>
    </v-card>
</template>
