<script setup>
import {useStore} from 'vuex'
import {computed} from 'vue'
import formatDate from '@/utils/formatDate'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const store = useStore()
const {getters} = store

const emit = defineEmits(['close'])

defineProps({
    id: {
        required: true
    }
})

const charger = computed(() => {
    return getters['chargers/charger']
})

const getFormattedDate = (date, format) => {
    return formatDate(date, format ?? null)
}
</script>

<template>
    <div class="charger-info">
        <header class="charger-info__header">
            <h2 class="pl-md-5">{{ t('chargerDetails.locationInformation') }}</h2>
        </header>
        <div class="charger-info__card-body">
            <v-row>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.locationName') }}</h3>
                    <div>{{ charger.location.name }}</div>
                </v-col>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.assignedTo') }}</h3>
                    <div>{{ charger.assigned_to ?  charger.assigned_to : '-'}}</div>
                </v-col>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.address') }}</h3>
                    <div>{{ `${charger.location.street} ${charger.location.house_number}, ${charger.location.city}` }}</div>
                </v-col>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.gps') }}</h3>
                    <div>latitude: {{ charger.location.latitude }}</div>
                    <div>longitude: {{ charger.location.longitude }}</div>
                </v-col>
            </v-row>
        </div>
        <header class="charger-info__header pt-5">
            <h2 class="pl-md-5">
                {{ t('chargerDetails.ChargePointInformation') }}
            </h2>
            <a class="close-button" @click="emit('close')">
                <v-icon class="active-icon">mdi-close</v-icon>
            </a>
        </header>
        <div class="charger-info__card-body">
            <v-row>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.ChargePointCode') }}</h3>
                    <div>{{ charger.code }}</div>
                </v-col>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.SerialNumber') }}</h3>
                    <div>{{ charger.serial_number }}</div>
                </v-col>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.InOperationSince') }}</h3>
                    <div>{{ getFormattedDate(charger.in_operation_since) }}</div>
                </v-col>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.manufacturer') }}</h3>
                    <div>{{ charger.manufacture }}</div>
                </v-col>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.ChargePointModel') }}</h3>
                    <div>{{ charger.model }}</div>
                </v-col>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.FirmwareVersion') }}</h3>
                    <div>{{ charger.firmware_version }}</div>
                </v-col>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.firmwareVersion') }}</h3>
                    <div>{{ charger.firmware_version }}</div>
                </v-col>
                <v-col class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.LastFirmwareChangeTime') }}</h3>
                    <div>{{ getFormattedDate(charger.firmware_update_at, 'DD.MM.YYYY hh:mm') }}</div>
                </v-col>
                <v-col v-if="charger.chassis_number" class="charger-info__block" cols="12">
                    <h3>{{ t('chargerDetails.ChasisNumber') }}</h3>
                    <div>{{ charger.chassis_number }}</div>
                </v-col>

                <v-col v-for="(connector, index) in charger.connectors"
                       class="charger-info__block"
                       cols="12"
                >
                    <h2>{{ t('chargerDetails.ConnectorInformation') }}</h2>
                    <h3 class="pt-2">{{ t('chargerDetails.ConnectorCode') }}</h3>
                    <div>{{ connector.code }}</div>
                    <h3 class="pt-2">{{ t('chargerDetails.MaxPower') }}</h3>
                    <div>{{ connector.max_power }} kW</div>
                    <h3 class="pt-2">{{ t('chargerDetails.ConnectorType') }}</h3>
                    <div>{{ connector.type }}</div>
                    <h3 class="pt-2">{{ t('chargerDetails.EVSE Code') }}</h3>
                    <div>{{ connector.evse_id }}</div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>