<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'

// components
import CreditBox from '@/components/CreditBox.vue'
import FreeKwhLoader from '@/loaders/FreeKwhLoader.vue'

const store = useStore()

const credits = computed(() => {
    return store.getters['coupons/credits']
})

</script>

<template>
    <section class="section credit-box-wrapper">
        <v-row v-if="!credits">
            <v-col>
                <FreeKwhLoader class="mt-5"/>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col>
                <CreditBox v-if="credits.length"
                           :data="credits"
                />
            </v-col>
        </v-row>
    </section>
</template>