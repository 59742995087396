<script setup>
import { computed, ref } from 'vue'

// translations
const { t } = useI18n()
import { useI18n } from 'vue-i18n'

// composables
import useCharging from '@/composables/charging/useCharging'

const { activeScreen, instanceRequestPaymentMethod, blockInstanceRequestPaymentMethodButton, oneTimeFee } =
    useCharging()

const returnsPolicy = ref(false)

// DON'T CHANGE TO VITE_APP, because on production we have keys VUE_APP - REPLACED BY NEW TRANSLATIONS
const generalTermsAndConditionsLinks = computed(() => t('otCard.VITE_APP_GTC'))
</script>

<template>
    <v-card
        :elevation="3"
        class="start-charging mr-auto ml-auto mt-md-8 mt-2 pa-4 d-flex flex-column align-center justify-center text-center"
    >
        <v-btn class="d-flex mr-auto pa-0" variant="plain" width="60px" @click.prevent="activeScreen = 0">
            <v-icon> mdi-chevron-left </v-icon>
            {{ t('startCharging.back') }}
        </v-btn>
        <h3 class="text-h6 text-center mb-2 mt-16">
            {{ t('startCharging.cardDetails') }}
        </h3>
        <p class="mb-16">
            {{ t('startCharging.cardVerifyText', { amount: oneTimeFee }) }}
        </p>
        <v-checkbox v-model="returnsPolicy" data-testid="iAgreeOT" class="mr-auto d-inline-flex text-left" hide-details >
            <template v-slot:label>
                <a :href="generalTermsAndConditionsLinks" target="_blank" data-testid="iAgreeOTtext" @click.stop>
                    {{ '*' + t(`startCharging.returnsPolicy`) }}
                </a>
            </template>
        </v-checkbox>
        <v-container class="my-4 pa-0" fluid>
            <div id="dropin-container" class="mb-4" />
        </v-container>
        <v-btn
            :disabled="!returnsPolicy || blockInstanceRequestPaymentMethodButton"
            :loading="blockInstanceRequestPaymentMethodButton"
            color="blue"
            rounded="xl"
            data-testid="paymentSubmit"
            @click.prevent="instanceRequestPaymentMethod()"
        >
            {{ t('startCharging.submit') }}
        </v-btn>
    </v-card>
</template>
