import { ref, reactive, computed, toRefs } from 'vue'
import store from '@/store'

// utils
import { useI18n } from 'vue-i18n'
import formatDate from '@/utils/formatDate'
import isEmptyObj from '@/utils/isEmptyObj'
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'
import showMessageArray from '@/utils/errorMessageArray'
import { PaymentProviders } from '@/utils/paymentProviders'

// composables
import useRegistration from '@/composables/registration/useRegistration'
import useLocale from '@/composables/locale'

// components
import dropIn from 'braintree-web-drop-in'

const { getters, commit, dispatch } = store

export default function usePaymentMethod() {
    const { nextStep } = useRegistration()
    const { locale, lang } = useLocale()
    const { t } = useI18n()

    const state = reactive({
        isPopupAskDelete: false,
        popupData: {
            questionDelete: {
                title: t('managePayment.doYouReallyWantTo'),
                type: 'question',
                subtitle: t('managePayment.removingOfThePaymentCard'),
                icon: 'mdi-help-circle'
            }
        },
        addCardUrl: null,
        recaptchaSiteKey: import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
    })

    const instance = ref(null)
    const paymentMethods = ref(null)
    const threeDSecureParameters = ref({
        amount: Number(import.meta.env.VITE_APP_BRAINTREE_VERIFY_AMOUNT).toFixed(2).toString(),
        email: store.state.account.email
    })

    const paymentLink = computed(() => {
        return `https://www.braintreepayments.com/${locale.value}/legal`
    })
    const paymentInfo = computed(() => {
        return getters['account/paymentInfo']
    })
    const hasPaymentInfo = computed(() => {
        return !!(getters['account/paymentInfo'].provider)
    })
    const language = computed(() => {
        return lang.value || import.meta.env.VITE_APP_I18N_LOCALE || 'en'
    })

    const loadPaymentInfo = () => {
        dispatch('account/getPaymentInfo').catch(error => {
            showMessage(error)
        })
    }

    const createDropIn = () => {
        dropIn.create({
            authorization: paymentMethods.value.token,
            container: '#dropin-container',
            locale: `${lang.value}_${lang.value.toUpperCase()}`,
            dataCollector: true,
            threeDSecure: true
        }, (createErr, inst) => {
            if (createErr) return
            instance.value = inst
            if (instance.value.isPaymentMethodRequestable()) {
                const labelSmall = document.querySelector('.braintree-method__label--small')
                if (labelSmall) {
                    document.querySelector('.braintree-method__label').innerHTML = `${t('payment.ExpireAt')} ${formatDate(paymentInfo.value.expire, 'MM/YYYY')}<br><div class="braintree-method__label--small">${labelSmall.innerHTML}</div>`
                }
            }
        })
    }

    const instanceRequestPaymentMethod = () => {
        commit('setLoader', 1, { root: true })
        instance.value.requestPaymentMethod({ threeDSecure: threeDSecureParameters.value }).then(async response => {
            commit('account/setBraintreePayload', response)
            let payload = {
                'payment_method_nonce': response.nonce,
                'device_data': response.deviceData
            }
            try {
                grecaptcha.enterprise.ready(async () => {
                    const recaptchaToken = await grecaptcha.enterprise.execute(state.recaptchaSiteKey, { action: 'payment_add' })
                    try {
                        commit('setLoader', 1, { root: true })
                        await apiRequest.post('client/braintree/save-card', {
                            ...payload,
                            'g-recaptcha-response': recaptchaToken
                        })
                        loadPaymentInfo()
                        nextStep()
                    } catch (err) {
                        if (err.messages) {
                            showMessage(err.messages[0])
                        } else {
                            showMessage(err.message)
                        }
                        instance.value.teardown()
                        createDropIn()
                    } finally {
                        commit('setLoader', -1, { root: true })
                    }
                })
            } catch (e) {
                instance.value.clearSelectedPaymentMethod()
                showMessageArray(e.errors)
            }
        }).catch(error => {
            showMessage(error.message)
        }).finally(() => {
            commit('setLoader', -1, { root: true })
        })
    }

    const loadPaymentMethods = async () => {
        grecaptcha.enterprise.ready(async () => {
            const recaptchaToken = await grecaptcha.enterprise.execute(state.recaptchaSiteKey, { action: 'payment_add' })
            try {
                const { data } = await apiRequest('client/braintree/generate-token', {
                    params: {
                        'g-recaptcha-response': recaptchaToken
                    }
                })

                paymentMethods.value = data
                createDropIn()
                if (instance.value) {
                    instance.value.teardown()
                }
            } catch (error) {
                showMessage(error)
            }
        })
    }

    const handleDelete = () => {
        state.isPopupAskDelete = !state.isPopupAskDelete
    }

    const removeCard = async () => {
        try {
            await dispatch('account/removeCard', {})
            loadPaymentInfo()
            showMessage('managePayment.CardBeenDeletedSuccessfully', 'success')
            await loadPaymentMethods()
            commit('account/setBraintreePayload', null)
        } catch (error) {
            showMessage(error)
        }
    }

    const skip = async () => {
        grecaptcha.enterprise.ready(async () => {
            const recaptchaToken = await grecaptcha.enterprise.execute(state.recaptchaSiteKey, { action: 'payment_add' })
            try {
                commit('setLoader', 1, { root: true })
                await apiRequest.post('client/braintree/save-card', {
                    'g-recaptcha-response': recaptchaToken,
                    skip: true
                })
                nextStep()
            } catch (error) {
                showMessage(error.message)
            } finally {
                commit('setLoader', -1, { root: true })
            }
        })
    }

    const continueRegistration = () => {
        if (hasPaymentInfo.value) {
            skip()
            return
        }
        if(getters['account/paymentGatewayProvider'] === PaymentProviders.Braintree) instanceRequestPaymentMethod()
    }

    return {
        paymentLink,
        paymentInfo,
        language,
        ...toRefs(state),
        handleDelete,
        loadPaymentMethods,
        loadPaymentInfo,
        removeCard,
        continueRegistration
    }
}
