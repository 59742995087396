import {nextTick, ref} from 'vue'
import useWindowWidth from './windowWidth'

export default function useDetailsCard() {
    const {
        windowWidth
    } = useWindowWidth()

    const isPickedItem = ref(false)
    const pickedItem = ref(null)
    const selectedRow = ref(null)

    function showDetails(item) {
        pickedItem.value = item
        isPickedItem.value = true
        // Don't scroll if mobile view
        if (windowWidth.value < 600) {
            const scrollY = window.scrollY
            document.body.style.position = 'fixed'
            document.body.style.top = `-${scrollY}px`
        }
    }

    function closeDetails() {
        selectedRow.value = null
        pickedItem.value = null
        isPickedItem.value = false

        if (windowWidth.value < 960) {
            // scroll back to recent position
            const scrollY = document.body.style.top
            document.body.style.position = ''
            document.body.style.top = ''

            nextTick(() => {
                window.scrollTo(0, parseInt(scrollY || '0') * -1)
            })
        }
    }

    function rowSelect(idx, session) {
        pickedItem.value = session
        isPickedItem.value = true
        selectedRow.value = idx
    }

    function highlightElement(index) {
        const rows = document.querySelectorAll('.v-data-table__wrapper tr')
        rows.forEach(row => {
            row.classList.remove('selected-row')
        })

        if (index !== -1) {
            const row = document.querySelectorAll('.v-data-table__wrapper tr')[index + 1]
            row.classList.add('selected-row')
        }
    }

    return {
        isPickedItem,
        pickedItem,
        selectedRow,
        showDetails,
        scroll,
        closeDetails,
        rowSelect,
        highlightElement
    }
}