import moment from 'moment'
import formatDecimal from '@/utils/formatDecimal'
import en from '@/translations/EN.json'

export const getters = {
    facilitySession: state => state.facilitySession,
    facilitySessions: state => state.facilitySessions.map(session => ({
        charging_from: session.charging_from ? moment(session.charging_from).format('DD.MM.YYYY HH:mm') : '-',
        charging_to: session.charging_to ? moment(session.charging_to).format('DD.MM.YYYY HH:mm') : null,
        connected_from: session.connected_from ? moment(session.connected_from).format('DD.MM.YYYY HH:mm') : '-',
        connected_to: session.connected_to ? moment(session.connected_to).format('DD.MM.YYYY HH:mm') : '-',
        total_minutes: session.total_minutes ? `${formatDecimal(session.total_minutes)} min` : '-',
        total_kwh: session.total_kwh ? `${formatDecimal(session.total_kwh)} kWh` : '-',
        connector: session.connector ? session.connector : '-',
        location_code: session.location_code ? session.location_code : '-',
        location_name: session.location_name ? session.location_name : '-',
        location_street: session.location_street ? session.location_street : '-',
        location_house_num: session.location_house_num ? session.location_house_num : '-',
        location_city: session.location_city ? session.location_city : '-',
        location_country: session.location_country ? session.location_country : '-',
        device: `${session.device_manufacture} ${session.device_model}`,
        driver_name: session.driver_name ? session.driver_name : '-',
        device_model: session.device_model ? session.device_model : '-',
        device_id: session.device_code ? session.device_code : '-',
        device_manufacture: session.device_manufacture ? session.device_manufacture : '-',
        price: session.price ? `${formatDecimal(session.price)}` : '-',
        currency: session.currency ? `${session.currency}` : '-',
        invoice_id: session.invoice_number ? session.invoice_number : '-',
        connectorACDC: session.connector_ac_dc ? session.connector_ac_dc : '-',
        connector_id: session.connector_id ? session.connector_id : '-',
        connector_type_code: session.connector_type_code ? `${session.connector_ac_dc} | ${session.connector_type_code ? session.connector_type_code : '-'} | ${session.connector_max_power ? session.connector_max_power : '-'} kW` : '-',
        connector_max_power: session.connector_max_power ? `${session.connector_max_power} kW` : '-',
        average_power: session.average_power ? `${formatDecimal(session.average_power)} kW` : '-',
        max_power: session.max_power ? `${formatDecimal(session.max_power)} kW` : '-',
        auth_tag_token: session.auth_tag ? session.auth_tag : session.auth_tag_token ? session.auth_tag_token : '-',
        category: session.category ? session.category : '-'
    })),
    filters: state => state.filters,
    tableHeaders: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                width: '50px',
                align: 'center',
                sortable: false,
                value: 'detail'
            },
            {
                title: translation['tableHeaders.From'],
                width: '150px',
                align: 'start',
                sortable: true,
                value: 'charging_from'
            },
            {
                title: translation['tableHeaders.Duration minutes'],
                align: 'start',
                sortable: true,
                value: 'total_minutes'
            },
            {
                title: translation['tableHeaders.Total kWh'],
                align: 'start',
                sortable: true,
                value: 'total_kwh'
            },
            {
                title: translation['tableHeaders.Connector'],
                align: 'start',
                width: '300px',
                sortable: true,
                value: 'connector_type_code'
            },
            {
                title: translation['tableHeaders.ChargerType'],
                align: 'start',
                sortable: true,
                value: 'category',
                width: '120px'
            },
            {
                title: translation['tableHeaders.Charger ID'],
                width: '150px',
                align: 'start',
                sortable: false,
                value: 'device_id'
            },
            {
                title: translation['tableHeaders.Connector ID'],
                width: '160px',
                align: 'start',
                sortable: true,
                value: 'connector_id'
            },
            {
                title: translation['tableHeaders.Driver'],
                align: 'start',
                sortable: true,
                value: 'driver_name',
                width: '175px'
            },
            {
                title: translation['tableHeaders.token'],
                align: 'start',
                sortable: false,
                value: 'auth_tag_token',
                width: '100px'
            },
            {
                title: translation['tableHeaders.Device'],
                align: 'start',
                sortable: false,
                value: 'device'
            }
        ]
    }
}