<script setup>
// components
import Modal from '@/components/modals/Modal.vue'
import Btn from '@/components/inputs/Btn.vue'

// translations
import {useI18n} from 'vue-i18n'
const { t } = useI18n()

const emit = defineEmits(['accept', 'cancel', 'goBack'])

defineProps({
    active: {
        required: false
    },
    popupData: {
        required: false
    },
    loading: {
        required: false
    },
    classItem: {
        type: String,
        required: false,
        default: () => {
            return ''
        }
    }
})

const accept = () => {
    emit('accept')
    emit('cancel')
}

const goBack = () => {
    emit('accept')
    emit('cancel')
    emit('goBack')
}

</script>

<template>
    <Modal :class-item="classItem"
           :is-active="active"
           :max-width="330"
           @cancel="emit('cancel', !active)"
           class="popup-wrapper"
    >
        <a class="close-button" @click="emit('cancel')">
            <v-icon class="active-icon">mdi-close</v-icon>
        </a>
        <div class="pa-5">
            <div class="col justify-center">
                <div align="center">
                    <v-icon class="mb-4 " color="primary" large>
                        {{ popupData.icon }}
                    </v-icon>
                </div>
                <div align="center" class="mb-4 popup-title">
                    {{ popupData.title }}
                </div>
                <div align="center" class="mb-4 popup-subtitle">
                    {{ popupData.subtitle }}
                </div>
                <div class="row justify-center">
                    <Btn
                        v-if="popupData.type === 'done'"
                        class="ma-4"
                        medium
                        @click="goBack"
                    >
                        {{ t('PhoneVerification.Ok') }}
                    </Btn>
                    <div v-else class="d-flex justify-space-between">
                        <Btn
                            :outlined="true"
                            class="ma-4 popup-cancel"
                            data-testid="cancelConfirmation"
                            medium
                            @click="emit('cancel')"
                        >
                            {{ t('popUp.Cancel') }}
                        </Btn>
                        <Btn
                            :loading="loading"
                            class="ma-4"
                            data-testid="confirm"
                            medium
                            @click="accept"
                        >
                            {{ t('popUp.Yes') }}
                        </Btn>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>