<script setup>
import {reactive} from 'vue'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const emit = defineEmits(['handleDownload'])

defineProps({
    loading: {
        type: Boolean
    }
})

const state = reactive({
    typesExport: ['csv', 'xlsx', 'ods', 'xls'],
    type: 0
})

const handleDownload = (type) => {
    emit('handleDownload', type)
}
</script>

<template>
    <div class="export export-button-wrapper ml-auto mr-sm-4 d-flex align-items-center">
        <v-menu v-if="!loading" offset-y>
            <template v-slot:activator="{ props }">
                <v-icon class="mr-2 active-icon"
                        v-bind="props"
                        size="small"
                >
                    mdi-download
                </v-icon>
                <small v-bind="props">{{ t('sessions.Export') }}</small>
                <v-icon class="order-3 active-icon" v-bind="props">
                    mdi-menu-down
                </v-icon>
            </template>
            <v-list class="py-0">
                <v-list-item color="primary" class="px-0">
                    <v-list-item
                        v-for="type in state.typesExport"
                        :key="type"
                        @click="handleDownload(type)"
                        link
                    >
                        <v-list-item-title>{{ type }}</v-list-item-title>
                    </v-list-item>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-progress-circular v-else
                             :size="27"
                             color="var(--v-primary)"
                             indeterminate
        />
    </div>
</template>
