<script setup>
import { useStore } from 'vuex'
import { computed, reactive, onMounted } from 'vue'

// components
import CountrySelect from '@/components/inputs/CountrySelect.vue'

// utils
import { apiRequest } from '@/utils/apiRequest'
import showMessageArray from '@/utils/errorMessageArray'
import { Country } from '@/configs'

// validations
import useValidations from '@/composables/useValidations'
const {
    isNotEmpty,
    isValid2Characters,
    isValidSlovakTax,
    isValidSlovakVat,
    isValidCroatianTax,
    isValidCroatianVat,
    isValidPolishVat,
    isValidPolishCompany,
    isValidSlovakCompany,
    isValidCroatianCompany,
    maxCharsExceeded
} = useValidations()

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const { commit, getters } = store

const emit = defineEmits(['handleAction', 'edit'])

const state = reactive({
    currentBusinessId: store.state.account.businessId,
    canValidateVat: false,
    actions: [{ title: t('driverInfo.edit'), action: 'edit', icon: 'mdi-pencil' }]
})

const account = computed(() => store.state.account)
const accountTemp = computed(() => store.state.settings.accountTemp)
const isEditView = computed(() => store.state.account.personalDataIsEditView)
const isPolishCompany = computed(() =>
    isEditView.value
        ? accountTemp.value?.country === Country.Poland || false
        : account.value?.country === Country.Poland || false
)
const isSlovakCompany = computed(() =>
    isEditView.value
        ? accountTemp.value?.country === Country.Slovakia || false
        : account.value?.country === Country.Slovakia || false
)
const isCroatianCompany = computed(() =>
    isEditView.value
        ? accountTemp.value?.country === Country.Croatia || false
        : account.value?.country === Country.Croatia || false
)
const isLoading = computed(() => getters['isLoading'])
const companyIdPlaceholder = computed(() => {
    if (isPolishCompany.value) return 'NIP'
    if (isSlovakCompany.value) return 'ICO'
    if (isCroatianCompany.value) return 'OIB'
    return 'CompanyBusinessId'
})
const companyTaxIdPlaceholder = computed(() => {
    if (isCroatianCompany.value) return 'MBS'
    return 'TaxId'
})
const isSelfEmployed = computed({
    get() {
        const data = isEditView.value ? accountTemp.value : account.value
        return !!data?.taxId
    },
    set(value) {
        value ? updateAccountTemp(' ', 'taxId') : updateAccountTemp(null, 'taxId')
    }
})
const isVatPayer = computed({
    get() {
        const data = isEditView.value ? accountTemp.value : account.value
        return !!data?.vatId
    },
    set(value) {
        value ? updateAccountTemp(' ', 'vatId') : updateAccountTemp(null, 'vatId')
    }
})
const vatId = computed({
    get() {
        const data = isEditView.value ? accountTemp.value : account.value
        return data?.vatId
    },
    set(value) {
        state.canValidateVat = value.length > 6 ? true : false
        updateAccountTemp(value, 'vatId')
    }
})

const isValidCompanyId = (v) => {
    if (isSlovakCompany.value) return isValidSlovakCompany(v)
    if (isPolishCompany.value) return isValidPolishCompany(v)
    if (isCroatianCompany.value) return isValidCroatianCompany(v)
    return isValid2Characters(v)
}
const isValidVat = (v) => {
    if (isPolishCompany.value) return isValidPolishVat(v)
    if (isSlovakCompany.value) return isValidSlovakVat(v)
    if (isCroatianCompany.value) return isValidCroatianVat(v)
    return isValid2Characters(v)
}
const isValidTax = (v) => {
    if (isSlovakCompany.value) return isValidSlovakTax(v)
    if (isCroatianCompany.value) return isValidCroatianTax(v)
    return isValid2Characters(v)
}
const toggleEdit = () => {
    emit('handleAction', 'edit')
}
const updateAccountTemp = (value, field) => {
    commit('settings/setAccountTempFieldValue', {
        field,
        value
    })
}
const validateVatId = async () => {
    if (state.canValidateVat) {
        try {
            commit('setLoader', 1, { root: true })
            await apiRequest.post('client/validate-vat', { vat_id: vatId })
        } catch (error) {
            if (error.response?.status !== 503) {
                if (error.response?.status === 419 && isVatPayer) {
                    showMessageArray(error.response.data.errors)
                } else {
                    showMessageArray(error.errors)
                }
            }
        } finally {
            commit('setLoader', -1, { root: true })
        }
    }
}

onMounted(() => {
    store.subscribeAction({
        after: (action) => {
            if (action.type === 'account/getAccount') {
                state.currentBusinessId = store.state.account.businessId
            }
        }
    })
})
</script>

<template>
    <v-row>
        <v-col class="pa-lg-5 bg-white" cols="12" lg="8" offset-lg="2">
            <v-row>
                <v-col class="d-flex align-items-center" cols="6">
                    <h2 class="home-page-title">
                        {{ t('personalData.CompanyInformation') }}
                    </h2>
                </v-col>
                <v-col class="d-flex justify-end" cols="6">
                    <template v-if="!isEditView">
                        <v-btn
                            v-for="{ title } in state.actions"
                            :key="title"
                            class="ma-2 text-white"
                            color="primary"
                            @click="toggleEdit()"
                        >
                            {{ title }}
                        </v-btn>
                    </template>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <CountrySelect
                        :country="isEditView ? accountTemp.country : account.country"
                        data-testid="countrySelect"
                        @setValue="updateAccountTemp($event, 'country')"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        ref="business_id"
                        disabled
                        :label="t(`Client.${companyIdPlaceholder}`) + '*'"
                        :rules="[isNotEmpty, isValidCompanyId]"
                        :model-value="isEditView ? accountTemp.businessId : account.businessId"
                        data-testid="ico"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'businessId')"
                    />
                </v-col>
                <v-col class="py-0" cols="12">
                    <v-text-field
                        :disabled="!isEditView"
                        :label="t('Client.CompanyName') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(200)]"
                        :model-value="isEditView ? accountTemp.companyName : account.companyName"
                        data-testid="companyName"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'companyName')"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <div class="d-flex flex-column">
                        <v-checkbox
                            v-model="isSelfEmployed"
                            :disabled="!isEditView"
                            :label="t('Client.IAmSelfEmployedPerson')"
                            data-testid="selfEmplyed"
                        />
                        <v-text-field
                            v-if="!isPolishCompany && isSelfEmployed"
                            :disabled="!isEditView"
                            :label="t(`Client.${companyTaxIdPlaceholder}`)"
                            :rules="[isValidTax]"
                            :model-value="isEditView ? accountTemp.taxId : account.taxId"
                            data-testid="taxId"
                            density="compact"
                            variant="outlined"
                            @update:model-value="updateAccountTemp($event, 'taxId')"
                        />
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="d-flex flex-column">
                        <v-checkbox
                            v-model="isVatPayer"
                            :disabled="!isEditView"
                            :label="t('Client.IAmVatPayer')"
                            data-testid="vatPayer"
                        />
                        <v-text-field
                            v-if="isVatPayer"
                            v-model="vatId"
                            :disabled="!isEditView"
                            :label="t('Client.VatId') + '*'"
                            :loading="isLoading"
                            :rules="[isNotEmpty, isValidVat]"
                            data-testid="isVatPayer"
                            density="compact"
                            variant="outlined"
                            @blur="validateVatId"
                        />
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
