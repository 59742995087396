<script setup>
import moment from 'moment'
import router from '@/router'
import {useStore} from 'vuex'
import {computed, onBeforeMount, onMounted, reactive, ref, watch} from 'vue'

// configs
import { Roles, DATE_FORMAT } from '@/configs/index.js'

// composables
import useWindowWidth from '@/composables/windowWidth'

// components
import BtnGoBack from '@/components/inputs/BtnGoBack.vue'

// translations
import {useI18n} from 'vue-i18n'
import {cloneDeep} from '@/utils/index.js'

// validations
import useValidations from '@/composables/useValidations'

const {isNotEmpty, isMinValue} = useValidations()

const {t} = useI18n()

// helpers
import showMessage from '@/utils/errorMessage.js'
import DatePicker from '@/components/inputs/DatePicker.vue'

// store
const store = useStore()
const {dispatch, getters, commit} = store

const form = ref()

const props = defineProps({
	id: {
		type: String,
		required: true
	}
})

const {windowWidth, setWindowWidth} = useWindowWidth()

const state = reactive({
	editTemp: null,
	isEditView: false,
	isFormValid: false,
	isOdometerFromDatepickerOpen: false
})

const rules = {
	required: value => !!value || 'Field is required'
}

const consumption = computed(() => {
	return getters['consumptions/consumption']
})

const isLoading = computed(() => {
	return getters['isLoading']
})

const isPayer = computed(() => {
	return store.state.account.roles.includes(Roles.payer)
})

const isDriver = computed(() => {
	return store.state.account.roles.includes(Roles.driver)
})

const goBack = () => {
	router.go(-1)
}

const save = async () => {
	try {
		await dispatch('consumptions/updateConsumption', {
			id: props.id,
			payload: {
				price_per_kwh: state.editTemp.price_per_kwh,
				fixed_cost: state.editTemp.fixed_cost,
				odometer_reading: state.editTemp.odometer_reading,
				note_owner: state.editTemp.note_owner,
				note_user: state.editTemp.note_user,
				locked: state.editTemp.locked
			}
		}).then((response) => {
			state.isEditView = false
			state.editTemp = null
			showMessage(t('consumptionDetail.successMessage'), 'success')
		})
	} catch (e) {
		showMessage(e)
	}
}

const cancelEdit = () => {
	state.isEditView = false
	state.editTemp = null
}

const switchMode = async (value) => {
	if (value) {
		state.editTemp = cloneDeep(consumption.value)
		if (!state.editTemp.odometer_reading) {
			state.editTemp.odometer_reading = {
				from: null,
				value: null,
				avg_consumption: null
			}
		}

		state.isEditView = value
	} else {
		const {valid} = await form.value.validate()
		if (!valid) {
			return
		}
		await save()
	}

}

const updateOdometerFrom = (val) => {
	state.editTemp.odometer_reading.from = moment(val).format('YYYY-MM-DD')
}

onMounted(async () => {
	await dispatch('consumptions/fetchConsumption', props.id)
})
</script>

<template>
	<div class="consumption-detail-wrapper">
		<BtnGoBack/>
		<header class="charger__header">
			<div class="charger__header-head">
				<h1 class="home-page-title mb-5">
					{{ t('consumptionDetail.consumptionDetail') }}
				</h1>
			</div>
		</header>
		<section class="section mb-5">
			<v-form
				ref="form"
				v-model="state.isFormValid"
			>
				<v-card :loading="isLoading">
					<template v-slot:loader="{ isActive }">
						<v-progress-linear
							:active="isActive"
							color="primary"
							height="4"
							indeterminate
						></v-progress-linear>
					</template>
					<v-card-title class="pb-5">
						<v-row>
							<v-col
								cols="12"
								md="6"
							></v-col>
							<v-col
								class="text-right"
								cols="12"
								md="6"
							>
								<v-btn
									v-if="state.isEditView"
									:disabled="isLoading"
									color="secondary"
									density="comfortable"
									elevation="0"
									text
									@click="cancelEdit()"
								>
									{{ t('consumptionDetail.cancel') }}
								</v-btn>
								<v-btn
									:disabled="isLoading"
									class="ml-3"
									color="primary"
									density="comfortable"
									elevation="0"
									text
									@click="switchMode(!state.isEditView)"
								>
									{{ state.isEditView ? t('consumptionDetail.save') : t('consumptionDetail.edit') }}
								</v-btn>
							</v-col>
						</v-row>
					</v-card-title>
					<v-row>
						<v-col cols="12">
							<v-card-subtitle>
								{{ t('consumptionDetail.charger') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								{{ consumption.device ? consumption.device.code : '-' }}
							</v-card-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col
							cols="6"
							md="4"
						>
							<v-card-subtitle>
								{{ t('consumptionDetail.from') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								{{ consumption.from ? moment(consumption.from).format(DATE_FORMAT) : '-' }}
							</v-card-text>
						</v-col>
						<v-col
							cols="6"
							md="4"
						>
							<v-card-subtitle>
								{{ t('consumptionDetail.to') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								{{ consumption.to ? moment(consumption.to).format(DATE_FORMAT) : '-' }}
							</v-card-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col
							cols="6"
							md="4"
						>
							<v-card-subtitle>
								{{ t('consumptionDetail.consumption') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								{{ consumption.quantity_kwh ? consumption.quantity_kwh : '-' }}
							</v-card-text>
						</v-col>
						<v-col
							cols="6"
							md="4"
						>
							<v-card-subtitle>
								{{ t('consumptionDetail.pricePerKWh') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								<template v-if="state.isEditView">
									<v-text-field
										v-model="state.editTemp.price_per_kwh"
										:rules="[isMinValue(0), isNotEmpty(state.editTemp.price_per_kwh, t)]"
										density="compact"
										type="number"
										variant="outlined"
									/>
								</template>
								<template v-else>
									{{ consumption.price_per_kwh ? consumption.price_per_kwh : '-' }}
								</template>
							</v-card-text>
						</v-col>
						<v-col
							cols="6"
							md="4"
						>
							<v-card-subtitle>
								{{ t('consumptionDetail.consumptionCost') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								{{ consumption.cost_kwh ? consumption.cost_kwh : '-' }}
							</v-card-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="4">
							<v-card-subtitle>
								{{ t('consumptionDetail.fixedCost') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								<template v-if="state.isEditView">
									<v-text-field
										v-model="state.editTemp.fixed_cost"
										:rules="[isMinValue(0), isNotEmpty(state.editTemp.fixed_cost, t)]"
										density="compact"
										type="number"
										variant="outlined"
									/>
								</template>
								<template v-else>
									{{ consumption.fixed_cost ? consumption.fixed_cost : '-' }}
								</template>
							</v-card-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-card-subtitle>
								{{ t('consumptionDetail.totalCost') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								{{ consumption.total ? consumption.total : '-' }}
							</v-card-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col
							cols="6"
							md="4"
						>
							<v-card-subtitle>
								{{ t('consumptionDetail.odometer') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								<template v-if="state.isEditView">
									<v-text-field
										v-model="state.editTemp.odometer_reading.value"
										:rules="[isMinValue(0)]"
										density="compact"
										type="number"
										variant="outlined"
									/>
								</template>
								<template v-else>
									{{ consumption.odometer_reading ? consumption.odometer_reading.value : '-' }}
								</template>
							</v-card-text>
						</v-col>
						<v-col
							cols="6"
							md="4"
						>
							<v-card-subtitle>
								{{ t('consumptionDetail.averageConsumption') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								<template v-if="state.isEditView">
									<v-text-field
										:disabled="!isPayer && consumption.locked"
										v-model="state.editTemp.odometer_reading.avg_consumption"
										:rules="[isMinValue(0)]"
										density="compact"
										type="number"
										variant="outlined"
									/>
								</template>
								<template v-else>
									{{
										consumption.odometer_reading ? consumption.odometer_reading.avg_consumption : '-'
									}}
								</template>
							</v-card-text>
						</v-col>
						<v-col cols="6"
						       md="4"
						>
							<v-card-subtitle>
								{{ t('consumptionDetail.odometerFrom') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								<template v-if="state.isEditView">
									<v-menu
										ref="menuTo"
										v-model="state.isOdometerFromDatepickerOpen"
										:close-on-content-click="false"
									>
										<template v-slot:activator="{ props }">
											<v-text-field
												ref="to"
												:value="state.editTemp.odometer_reading.from"
												clearable
												density="compact"
												prepend-inner-icon="mdi-calendar"
												readonly=""
												v-bind="props"
												variant="outlined"
											/>
										</template>
										<v-date-picker
											:allowed-dates="allowedDatesTo"
											:v-model="state.editTemp.odometer_reading.from"
											color="primary"
											show-adjacent-months
											@update:model-value="updateOdometerFrom"
										/>
									</v-menu>
								</template>
								<template v-else>
									{{ consumption.odometer_reading ? consumption.odometer_reading.from : '-' }}
								</template>
							</v-card-text>
						</v-col>
					</v-row>

					<template v-if="isPayer">
						<v-row v-if="state.isEditView">
							<v-col class="py-0">
								<v-checkbox :disabled="!state.isEditView || !isPayer || isLoading"
								            :label="t('consumptionDetail.lockConsumption')"
								            class="pl-1"
								            color="primary"
								            v-model="state.editTemp.locked"
								/>
							</v-col>
						</v-row>
						<v-row v-else>
							<v-col class="py-0">
								<v-checkbox :disabled="true"
								            :label="t('consumptionDetail.lockConsumption')"
								            class="pl-1"
								            color="primary"
								            :model-value="consumption.locked"
								/>
							</v-col>
						</v-row>
					</template>

					<v-row>
						<v-col
							cols="12"
							md="4"
						>
							<v-card-subtitle>
								{{ t('consumptionDetail.note') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								<template v-if="state.isEditView && isPayer">
									<v-textarea
										v-model="state.editTemp.note_owner"
										density="compact"
										type="number"
										variant="outlined"
									/>
								</template>
								<template v-else>
									{{ consumption.note_owner ? consumption.note_owner : '-' }}
								</template>
							</v-card-text>
						</v-col>
						<v-col
							cols="12"
							md="4"
						>
							<v-card-subtitle>
								{{ t('consumptionDetail.noteUser') }}
							</v-card-subtitle>
							<v-card-text class="pt-1">
								<template v-if="state.isEditView && isDriver && !isPayer">
									<v-textarea
										v-model="state.editTemp.note_user"
										density="compact"
										type="number"
										variant="outlined"
									/>
								</template>
								<template v-else>
									{{ consumption.note_user ? consumption.note_user : '-' }}
								</template>
							</v-card-text>
						</v-col>
					</v-row>
				</v-card>
			</v-form>
		</section>
	</div>
</template>
