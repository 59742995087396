import en from '@/translations/EN.json'

export const getters = {
    locations: (state, getters, rootState) => {
        return state.locations.map(location => ({
            name: location.name,
            access_type: location.access_type,
            access_instructions: location.access_instructions,
            id: location.id,
            code: location.code,
            street: location.street,
            house_number: location.house_number,
            city: location.city,
            status: location.status ? location.status : rootState.translation[rootState.account.lang]['etrelstatus.unknown']
        }))
    },

    location: state => state.location,
    filters: state => state.filters,
    tableHeaders: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                width: '50px',
                align: 'center',
                sortable: false,
                value: 'detail'
            },
            {
                title: translation['tableHeaders.Name'],
                align: 'start',
                sortable: true,
                value: 'name',
                width: '300px'
            },
            {
                title: translation['tableHeaders.Status'],
                align: 'start',
                sortable: true,
                value: 'status',
                width: '150px'
            },
            {
                title: translation['tableHeaders.Access type'],
                align: 'start',
                sortable: true,
                value: 'access_type',
                width: '300px'
            },
            {
                title: translation['tableHeaders.Street'],
                align: 'start',
                sortable: true,
                value: 'street',
                width: '300px'
            },
            {
                title: translation['tableHeaders.House number'],
                align: 'start',
                sortable: true,
                value: 'house_number',
                width: '50px'
            },
            {
                title: translation['tableHeaders.City'],
                align: 'start',
                sortable: true,
                value: 'city',
                width: '150px'
            },
            {
                title: translation['tableHeaders.LocationCode'],
                align: 'start',
                sortable: true,
                value: 'code',
                width: '150px'
            }
        ]
    }
}