<script setup>
import {onMounted} from 'vue'
import Chart from 'chart.js'

const props = defineProps({
    name: {
        required: false,
        type: String,
        default: () => {
            return null
        }
    },
    data: {
        required: true,
        type: Array,
        default: () => {
            return []
        }
    },
    dataTitleField: {
        required: true,
        type: String,
        default: () => {
            return null
        }
    },
    dataValueField: {
        required: true,
        type: String,
        default: () => {
            return null
        }
    },
    label: {
        required: false,
        type: String,
        default: () => {
            return null
        }
    }
})

onMounted(() => {
    const titles = []
    const values = []

    if(props.data.length && props.dataTitleField && props.dataValueField) {
        props.data.forEach(el => {
            titles.push(el[props.dataTitleField])
            values.push(el[props.dataValueField])
        })
    }
    const ctx = document.getElementById(props.name).getContext('2d')
    new Chart(ctx, {
        type: 'horizontalBar',
        data: {
            labels: titles,
            datasets: [
                {
                    label: props.label,
                    data: values,
                    backgroundColor: '#009F3A',
                    borderWidth: 0,
                    weight: 1
                }
            ]

        },
        options: {
            maintainAspectRatio: false,
            legend: {
                align: 'center'
            },
            scales: {
                xAxes: [{
                    ticks: {
                        min: 0,
                        beginAtZero: true
                    }
                }],
                yAxes: [{
                    stacked: true,
                    gridLines: false
                }]
            }
        }
    })
})
</script>

<template>
    <div class="horizontal-bar-graph">
        <div class="graph__bars">
            <canvas :id="name" />
        </div>
    </div>
</template>
