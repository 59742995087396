import moment from 'moment'
import formatDecimal from '@/utils/formatDecimal'
import en from '@/translations/EN.json'

export const getters = {
    sessions: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return state.sessions.map((session, index) => ({
            index,
            charging_from: session.charging_from ? moment(session.charging_from).format('DD.MM.YYYY HH:mm') : '-',
            charging_to: session.charging_to ? moment(session.charging_to).format('DD.MM.YYYY HH:mm') : null,
            connected_from: session.connected_from ? moment(session.connected_from).format('DD.MM.YYYY HH:mm') : '-',
            connected_to: session.connected_to ? moment(session.connected_to).format('DD.MM.YYYY HH:mm') : session.charging_to ? moment(session.charging_to).format('DD.MM.YYYY HH:mm') : '-',
            total_minutes: session.total_minutes ? formatDecimal(session.total_minutes) + ' min' : '-',
            total_kwh: session.total_kwh ? formatDecimal(session.total_kwh) + ' kWh' : '-',
            free_kwh: session.free_kwh ? `${formatDecimal(session.free_kwh)} kWh` : '-',
            connector: session.connector_max_power ? session.connector_max_power : '-',
            connector_id: session.connector_id ? session.connector_id : '-',
            location_name: session.location_name ? session.location_name : '-',
            location_street: session.location_street ? session.location_street : '-',
            location_house_num: session.location_house_num ? session.location_house_num : '-',
            location_city: session.location_city ? session.location_city : '-',
            location_country: session.location_country ? session.location_country : '-',
            driver_name: session.driver_name ? session.driver_name : '-',
            driver_id: session.driver_id ? session.driver_id : '-',
            price: `${session.price ? formatDecimal(session.price) : '-'} ${session.currency && session.price ? `${session.currency}` : ''}`,
            currency: session.currency ? `${session.currency}` : '-',
            invoice_number: session.invoice_number ? session.invoice_number : '-',
            invoice_id: session.invoice_id ? session.invoice_id : '-',
            connectorACDC: session.connector_ac_dc ? session.connector_ac_dc : '-',
            connector_type_code: session.connector_type_code ? (translation['filters.' + session.connector_type_code] || session.connector_type_code) : '-',
            connector_max_power: `${session.connector_type_code ? session.connector_type_code ? (translation['filters.' + session.connector_type_code] || session.connector_type_code) : '-' : ''} | ${session.connector_max_power ? session.connector_max_power : '-'} kW`,
            average_power: session.average_power ? formatDecimal(session.average_power) : '-',
            max_power: session.max_power ? `${formatDecimal(session.max_power)} kW` : '-',
            is_roaming: session.is_roaming ? session.is_roaming : '-',
            charger_type: session.charger_type ? session.charger_type : '-',
            category: session.category ? session.category : '-'
        }))
    },

    tableHeaders: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                width: '50px',
                align: 'start',
                sortable: false,
                value: 'detail'
            },
            {
                title: translation['tableHeaders.From'],
                width: '150px',
                align: 'start',
                sortable: true,
                value: 'connected_from'
            },
            {
                title: translation['tableHeaders.To'],
                width: '160px',
                align: 'start',
                sortable: true,
                value: 'connected_to'
            },
            {
                title: translation['tableHeaders.Duration'],
                width: '160px',
                align: 'start',
                sortable: true,
                value: 'total_minutes'
            },
            {
                title: translation['tableHeaders.Energy'],
                width: '110px',
                align: 'start',
                sortable: true,
                value: 'total_kwh'
            },
            {
                title: translation['tableHeaders.FreekWh'],
                width: '110px',
                align: 'start',
                sortable: true,
                value: 'free_kwh'
            },
            {
                title: translation['tableHeaders.Connector power'],
                width: '80px',
                align: 'start',
                sortable: true,
                value: 'connector_max_power'
            },
            {
                title: translation['tableHeaders.ChargerType'],
                align: 'start',
                sortable: false,
                value: 'category',
                width: '120px'
            },
            {
                title: translation['tableHeaders.Location'],
                width: '200px',
                align: 'start',
                sortable: true,
                value: 'location_name'
            },
            {
                title: translation['tableHeaders.Driver'],
                align: 'start',
                sortable: true,
                value: 'driver_name',
                width: '175px'
            },
            {
                title: translation['tableHeaders.Price'],
                align: 'start',
                sortable: true,
                value: 'price',
                width: '120px'
            }
        ]
    }
}