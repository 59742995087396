<script setup>
import {ref, watch, computed} from 'vue'

// composables
import useChargingCards from '@/composables/chargingCards/useChargingCards'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const {
    selectedAction,
    selectedItem,
    primaryDriverActiveChargingCard,
    availableDrivers,
    selectedDriver,
    actionPromptVisible,
    fetchingData,
    loadingDriversResults,
    triggerAction,
    addNewCardModel,
    driverDetailCard,
    numberOfChargingCardsToOrder,
    orderChargingCardsDriverDetail,
    getDrivers
} = useChargingCards()

const isOrderValid = ref(false)
const form = ref(null)
const timeout = ref(null)

watch(actionPromptVisible, (newVal) => {
    if (!newVal) {
        selectedAction.value = ''
        selectedItem.value = null
        selectedDriver.value = null
        addNewCardModel.value = ''
        numberOfChargingCardsToOrder.value = null
        if (form.value) form.value.resetValidation()
    }
})

const isValid = computed(() => {
    return !(addNewCardModel.value === '' || addNewCardModel.value.length !== 9)
})

const isMax = (v) => {
    if (v && v <= 99) {
        isOrderValid.value = true
        return true
    }
    isOrderValid.value = false
    return t('chargingCards.maxCards')
}

const maxRule = ref([
    isMax
])

const search = (val) => {
    if(val) {
        clearTimeout(timeout.value)
            timeout.value = setTimeout( async () => {
            await getDrivers(val)
        }, 500)
    }
}

</script>

<template>
    <div class="text-center">
        <v-dialog
            v-model="actionPromptVisible"
            width="375"
        >
            <v-card class="action-prompt pa-4 pa-sm-8">
                <template v-if="selectedAction === 'assignCard'">
                    <v-card-title
                        class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4 pa-0"
                    >
                        {{ t('chargingCards.assignCard') }}
                    </v-card-title>
                    <v-card-text
                        class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center"
                    >
                        <p class="mb-8">
                            {{ t('chargingCards.selectDriver') }} <span class="font-weight-medium"
                        >{{ selectedItem.card_number }}</span>
                        </p>
                        <div>
                            <v-autocomplete
                                v-model="selectedDriver"
                                :disabled="fetchingData"
                                :items="availableDrivers"
                                :loading="loadingDriversResults"
                                :label="t('chargingCards.name')"
                                clearable
                                @update:search="search"
                            />
                        </div>
                    </v-card-text>
                </template>
                <template v-if="selectedAction === 'unassignCard'">
                    <v-card-title
                        class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4 pa-0"
                    >
                        {{ t('chargingCards.unassignCard') }}
                    </v-card-title>
                    <v-card-text
                        class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center"
                    >
                        <p class="mb-8">
                            {{
                            t('chargingCards.unassignCardPrompt', {
                              cardNo: selectedItem.card_number,
                              driver: selectedItem.assignee_name
                            })
                            }}
                        </p>
                    </v-card-text>
                </template>
                <template v-if="selectedAction === 'blockCard'">
                    <v-card-title
                        class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4 pa-0"
                    >
                        {{ t('chargingCards.blockCard') }}
                    </v-card-title>
                    <v-card-text
                        class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center"
                    >
                        <p class="mb-8">
                            <span class="font-weight-medium">{{ selectedItem.card_number }}</span><br>
                            {{ t('chargingCards.blockCardPrompt') }}
                        </p>
                    </v-card-text>
                </template>
                <template v-if="selectedAction === 'unblockCard'">
                    <v-card-title
                        class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4 pa-0"
                    >
                        {{ t('chargingCards.unblockCard') }}
                    </v-card-title>
                    <v-card-text
                        class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center"
                    >
                        <p class="mb-8">
                            <span class="font-weight-medium">{{ selectedItem.card_number }}</span><br>
                            {{ t('chargingCards.unblockCardPrompt') }}
                        </p>
                    </v-card-text>
                </template>
                <template v-if="selectedAction === 'blockDriverCard'">
                    <v-card-title
                        class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4 pa-0"
                    >
                        {{ t('chargingCards.blockCard') }}
                    </v-card-title>
                    <v-card-text
                        class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center"
                    >
                        <p class="mb-8">
                            <span class="font-weight-medium">{{ driverDetailCard.card_number }}</span><br>
                            {{ t('chargingCards.blockCardPrompt') }}
                        </p>
                    </v-card-text>
                </template>
                <template v-if="selectedAction === 'unblockDriverCard'">
                    <v-card-title
                        class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4 pa-0"
                    >
                        {{ t('chargingCards.unblockCard') }}
                    </v-card-title>
                    <v-card-text
                        class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center"
                    >
                        <p class="mb-8">
                            <span class="font-weight-medium">{{ driverDetailCard.card_number }}</span><br>
                            {{ t('chargingCards.unblockCardPrompt') }}
                        </p>
                    </v-card-text>
                </template>
                <template v-if="selectedAction === 'removeCard' || selectedAction === 'removeDriverCard'">
                    <v-card-title
                        class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4 pa-0"
                    >
                        {{ t('chargingCards.removeCard') }}
                    </v-card-title>
                    <v-card-text
                        class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center"
                    >
                        <p class="mb-8">
                            <span class="font-weight-medium">{{ selectedItem.card_number }}</span><br>
                            {{ t('chargingCards.removeCardPrompt') }}
                        </p>
                    </v-card-text>
                </template>
                <template v-if="selectedAction === 'removeCards'">
                    <v-card-title
                        class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4 pa-0"
                    >
                        <span v-if="selectedItem.length > 1">{{ t('chargingCards.removeCards') }}</span>
                        <span v-else>{{ t('chargingCards.removeCard') }}</span>
                    </v-card-title>
                    <v-card-text
                        class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center"
                    >
                        <p v-if="selectedItem.length > 1" class="mb-8">
                            {{ t('chargingCards.removeCardsPrompt', {cardAmount: selectedItem.length}) }}
                        </p>
                        <p v-else class="mb-8">
                            {{ t('chargingCards.removeCardPrompt') }}
                        </p>
                    </v-card-text>
                </template>
                <template v-if="selectedAction === 'addNewCard'">
                    <v-card-title
                        class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4 pa-0"
                    >
                        <span>{{ t('chargingCards.addNewCard') }}</span>
                    </v-card-title>
                    <v-card-text
                        class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center"
                    >
                        <v-text-field
                            v-model="addNewCardModel"
                            :label="t('chargingCards.printedId')"
                            autofocus
                            hide-details
                            oninput="if(value < 0) value = 0"
                            variant="outlined"
                            type="number"
                        />
                    </v-card-text>
                </template>
                <template v-if="selectedAction === 'orderNewCards'">
                    <v-card-title
                        class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4 pa-0"
                    >
                        <span>{{ t('chargingCards.orderNewCardTitle') }}</span>
                    </v-card-title>
                    <v-card-text
                        class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center"
                    >
                        <v-form ref="form">
                            <v-text-field
                                v-model="numberOfChargingCardsToOrder"
                                :label="t('chargingCards.numberOfChargingCards')"
                                :rules="maxRule"
                                oninput="if(value < 1) value = 1"
                                variant="outlined"
                                type="number"
                            />
                        </v-form>
                    </v-card-text>
                </template>
                <v-card-actions class="d-flex align-center justify-center overflow-hidden">
                    <v-btn
                        color="primary"
                        @click="actionPromptVisible = false"
                    >
                        {{ t('chargingCards.cancel') }}
                    </v-btn>
                    <template v-if="selectedAction === 'assignCard'">
                        <v-btn
                            :disabled="fetchingData || selectedDriver === null"
                            :loading="fetchingData"
                            class="ml-4 ml-sm-8"
                            color="primary"
                            variant="flat"
                            @click="triggerAction('assignCard', selectedItem)"
                        >
                            {{ t('chargingCards.save') }}
                        </v-btn>
                    </template>
                    <template v-else-if="selectedAction === 'removeCard' || selectedAction === 'removeDriverCard'">
                        <v-btn
                            :disabled="fetchingData"
                            :loading="fetchingData"
                            class="ml-4 ml-sm-8"
                            color="error"
                            variant="flat"
                            @click="triggerAction(selectedAction, selectedItem)"
                        >
                            {{ t('chargingCards.remove') }}
                        </v-btn>
                    </template>
                    <template v-else-if="selectedAction === 'addNewCard'">
                        <v-btn
                            :disabled="!isValid || fetchingData"
                            :loading="fetchingData"
                            class="ml-4 ml-sm-8"
                            color="primary"
                            variant="flat"
                            @click="triggerAction(selectedAction, addNewCardModel)"
                        >
                            {{ t('chargingCards.save') }}
                        </v-btn>
                    </template>
                    <template v-else-if="selectedAction === 'orderNewCards'">
                        <v-btn
                            :disabled="numberOfChargingCardsToOrder === null || fetchingData || !isOrderValid"
                            :loading="fetchingData"
                            class="ml-4 ml-sm-8"
                            color="primary"
                            variant="flat"
                            @click="orderChargingCardsDriverDetail"
                        >
                            {{ t('chargingCards.send') }}
                        </v-btn>
                    </template>
                    <template v-else-if="selectedAction === 'blockDriverCard'">
                        <v-btn
                            :disabled="fetchingData"
                            :loading="fetchingData"
                            class="ml-4 ml-sm-8"
                            color="primary"
                            variant="flat"
                            @click="triggerAction(selectedAction, driverDetailCard.auth_tag_id)"
                        >
                            {{ t('chargingCards.save') }}
                        </v-btn>
                    </template>
                    <template v-else-if="selectedAction === 'unblockDriverCard'">
                        <v-btn
                            :disabled="fetchingData"
                            :loading="fetchingData"
                            class="ml-4 ml-sm-8"
                            color="primary"
                            variant="flat"
                            @click="triggerAction(selectedAction, driverDetailCard.auth_tag_id)"
                        >
                            {{ t('chargingCards.save') }}
                        </v-btn>
                    </template>
                    <template v-else>
                        <v-btn
                            :disabled="fetchingData"
                            :loading="fetchingData"
                            class="ml-4 ml-sm-8"
                            color="primary"
                            variant="flat"
                            @click="triggerAction(selectedAction, selectedItem)"
                        >
                            {{ t('chargingCards.send') }}
                        </v-btn>
                    </template>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>