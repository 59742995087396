<script setup>
import moment from 'moment'
import { computed, ref } from 'vue'

// composables
import useCharging from '@/composables/charging/useCharging'
import usePriceFormat from '@/composables/charging/usePriceFormat'

// components
import ChargerOutOfCommunication from '@/pages/charging/components/ChargerOutOfCommunication.vue'

// utils
import markdownParser from '@/utils/markdownParser'

// translations
const { t } = useI18n()
import { useI18n } from 'vue-i18n'

const {
    isDisabled,
    visibleOfflineDetails,
    sessionEnded,
    connectorData,
    chargingDetails,
    noCommunication,
    elapsedTime,
    endChargingSessionCommand,
    backToMap,
    stopChargingPromptVisible
} = useCharging()

const { pricePerKWH, pricePerMin } = usePriceFormat()

const convertedArray = ref([])
const powerMoreInfoVisible = ref(false)

// create an array of slides for carousel
const carouselSlides = computed(() => {
    convertedArray.value = []
    if (chargingDetails.value.hasOwnProperty('measurements')) {
        convertedArray.value.push(resolveElapsedTime())
        const filteredMeasurements = Object.keys(chargingDetails.value.measurements).filter(item => item !== 'active_energy_produced')
        filteredMeasurements.forEach((item) => {
            if (chargingDetails.value.measurements[item] !== null) {
                convertedArray.value.push({
                    label: item,
                    value: roundToOne(chargingDetails.value.measurements[item]),
                    unit: resolveSlideUnit(item)
                })
            }
        })
    }
    return convertedArray.value
})

const roundToOne = (item) => {
    if (typeof item !== 'number') parseInt(item)
    return (Math.round(item * 100) / 100).toFixed(1)
}

const resolveElapsedTime = () => {
    elapsedTime.value.label = 'elapsed_time'
    elapsedTime.value.value = moment(new Date()).diff(moment(new Date(chargingDetails.value.session.charging_from)), 'minutes')
    elapsedTime.value.unit = ' min'
    return elapsedTime.value
}

const resolveSlideUnit = (item) => {
    if (item === 'active_energy_consumed') {
        return ' kWh'
    }
    if (item === 'power') {
        return ' kW'
    }
    if (item === 'state_of_charge') {
        return '%'
    }
    return ''
}
</script>

<template>
    <div>
        <v-card
            v-if="!visibleOfflineDetails && connectorData"
            :elevation="3"
            :class="{ 'no-communication': noCommunication }"
            class="charging-details mr-auto ml-auto mt-md-8 mt-2 d-flex flex-column align-center justify-center text-center"
        >
            <div class="top-bar d-flex justify-center">
                <h6
                    v-if="noCommunication"
                    class="text-h6 text-no-wrap text-truncate"
                >
                    {{ t('startCharging.temporaryOffline') }}
                </h6>
                <h6
                    v-else
                    class="text-h6 text-no-wrap text-truncate"
                >
                    {{ t('startCharging.charging') }}
                </h6>
            </div>
            <v-carousel
                :continuous="false"
                :hide-delimiter-background="true"
                height="300"
                class="carousel"
            >
                <v-carousel-item
                    v-for="(slide, key) in carouselSlides"
                    :key="key"
                >
                    <v-sheet
                        height="100%"
                        tile
                    >
                        <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                        >
                            <div class="bubble d-flex align-center justify-center flex-column">
                                <h3 class="text-caption mb-2 text-white">
                                    {{ t('startCharging.' + slide.label) }}
                                </h3>
                                <h4 class="text-h4 text-white">
                                    {{ slide.value }}<span class="text-body-1">{{ slide.unit }}</span>
                                </h4>
                                <template v-if="slide.label === 'power'">
                                    <v-btn
                                        variant="text"
                                        class="text-caption text-uppercase text-white"
                                        @click="powerMoreInfoVisible = true"
                                    >
                                        {{ t('locationMap.moreInfo') }}
                                    </v-btn>
                                </template>
                            </div>
                        </v-row>
                    </v-sheet>
                </v-carousel-item>
                <div
                    v-if="noCommunication"
                    class="charger-offline"
                    @click="visibleOfflineDetails = true"
                >
                    <h4 class="text-caption text-decoration-underline">
                        {{ t('startCharging.chargerIsOffline') }}
                    </h4>
                </div>
            </v-carousel>
            <v-container>
                <v-row>
                    <v-col
                        cols="2"
                        class="pl-4 pr-0 d-flex align-center justify-center"
                    >
                        <div
                            class="location-photo"
                            @click="backToMap()"
                        >
                            <img
                                :src="connectorData.location.default_photo"
                                :title="connectorData.location.name"
                            >
                        </div>
                    </v-col>
                    <v-col cols="7">
                        <h2 class="text-caption font-weight-medium text-left text-no-wrap text-truncate">
                            {{ chargingDetails.location.name }}
                        </h2>
                        <h3 class="text-caption font-weight-regular text-left text-no-wrap text-truncate">
                            {{ t('startCharging.connector') }} #{{ connectorData.printed_label }}
                        </h3>
                        <h3
                            v-if="chargingDetails.price"
                            class="text-caption font-weight-regular text-left text-no-wrap text-truncate"
                        >
                            {{ pricePerKWH }}/kWh, {{ pricePerMin }}/min
                        </h3>
                    </v-col>
                    <v-col
                        cols="3"
                        class="pl-0 pr-4"
                    >
                        <h2
                            v-if="chargingDetails.connector.max_power"
                            class="text-caption font-weight-medium text-right text-no-wrap text-truncate"
                        >
                            {{ chargingDetails.connector.max_power }} kW
                        </h2>
                        <h2
                            v-else
                            class="text-caption font-weight-medium text-right text-no-wrap text-truncate"
                        >
                            ? kW
                        </h2>
                        <h3 class="text-caption font-weight-regular text-right text-no-wrap text-truncate">
                            {{ (chargingDetails.connector.type).split('_')[1] }}
                        </h3>
                    </v-col>
                </v-row>
            </v-container>
            <div class="buttons pa-4 d-flex flex-column">
                <v-btn
                    :disabled="isDisabled || noCommunication"
                    variant="outlined"
                    rounded="xl"
                    color="rgb(var(--v-theme-error))"
                    class="mb-4"
                    @click="stopChargingPromptVisible = true"
                >
                    {{ t('startCharging.endChargingSession') }}
                </v-btn>
                <v-btn
                    variant="outlined"
                    rounded="xl"
                    @click="backToMap()"
                >
                    {{ t('startCharging.close') }}
                </v-btn>
                <div
                    class="prompt pa-4"
                    :class="{ 'visible': stopChargingPromptVisible }"
                >
                    <div class="heading mb-4">
                        <h3 class="text-subtitle-1 font-weight-medium">
                            {{ t('startCharging.confirmAction') }}
                        </h3>
                    </div>
                    <div class="content">
                        <h4 class="text-body-2 mb-4">
                            {{ t('startCharging.areYouSure') }}
                        </h4>
                        <div class="buttons d-flex align-center justify-space-around">
                            <v-btn
                                :disabled="isDisabled"
                                variant="outlined"
                                rounded="xl"
                                @click="stopChargingPromptVisible = false"
                            >
                                {{ t('startCharging.cancel') }}
                            </v-btn>
                            <v-btn
                                :disabled="isDisabled"
                                rounded="xl"
                                color="error"
                                @click="endChargingSessionCommand()"
                            >
                                <template v-if="isDisabled">
                                    {{ t('startCharging.processing') }}
                                </template>
                                <template v-else>
                                    {{ t('startCharging.yesTerminate') }}
                                </template>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="powerMoreInfo bg-white"
                :class="{ 'visible': powerMoreInfoVisible }"
            >
                <div class="top-bar py-6 d-flex justify-center align-center">
                    <h6
                        class="text-subtitle-1 mx-auto text-grey text-grey-darken-3 font-weight-medium text-no-wrap text-truncate">
                        {{ t('locationMap.chargingPowerInfo') }}
                    </h6>
                </div>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div
                                class="text-left"
                                v-html="markdownParser(t('locationMap.chargingPowerInfoText'))"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                rounded="xl"
                                block
                                elevation="0"
                                color="#000"
                                @click="powerMoreInfoVisible = false"
                            >
                                {{ t('startCharging.close') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-card>
        <ChargerOutOfCommunication v-if="visibleOfflineDetails" />
    </div>
</template>
