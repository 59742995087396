<script setup>
// utils
import markdownParser from '@/utils/markdownParser'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = defineProps({
    locale: {
        type: String,
        required: true,
        default: 'sk'
    }
})
</script>

<template>
    <div class="legal-info">
        <div class="content">
            <div v-html="markdownParser(t('legal.content'))" />
        </div>
    </div>
</template>