// Cookies
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

// configs
import { Lang } from '@/configs/index.js'
export const getters = {
    isAuth: () => !!(cookies.get('gw_token') && localStorage.getItem('GW_isGuest') === null),
    token: state => state.token,
    locale: state => state.locale,
    email: state => state.email,
    lang: state => state.lang,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    vatId: state => state.vatId,
    fullName: state => state.firstName || state.lastName ? `${state.firstName ? state.firstName : ''} ${state.lastName ? state.lastName : ''}` : '-',
    paymentInfo: state => state.paymentInfo,
    primaryDriverId: state => state.primaryDriverId,
    phone: state => state.phone,
    personalDataIsEditView: state => state.personalDataIsEditView,
    deliveryAddress: state => state.deliveryAddress,
    id: state => state.id,
    driver: state => state.driver,
    roles: state => state.roles,
    payerConsents: state => state.payerConsents,
    revocableConsents: state => state.revocableConsents,
    notifyAfterSession: state => state.notifyAfterSession,
    actionAfterLogin: state => state.actionAfterLogin,
    registrationSteps: state => state.registrationSteps,
    isBlocked: state => (state.blockedChargingAt) ? true : false,
    isEUMemberState: state => state.isEUMemberState,
    paymentGatewayProvider: state => state.paymentGatewayProvider,
    consentsLocale: function (state) {
        switch (state.locale) {
            case Lang.sk:
                return 1
            case Lang.pl:
                return 2
        }
    },
    tableHeaders: (state, getters, rootState) => {
        const t = rootState.translation[state.lang]
        return [
            {
                title: '',
                key: 'emptyEmptyCell',
                align: 'start',
                sortable: false,
                value: 'type',
                width: '60px'
            },
            {
                title: t['tableHeaders.Browser'],
                key: 'browser',
                align: 'start',
                sortable: false,
                value: 'user_agent',
                width: '150px'
            },
            {
                title: t['tableHeaders.OperatingSystem'],
                key: 'operatingSystem',
                align: 'start',
                sortable: false,
                value: 'operating_system',
                width: '150px'
            },
            {
                title: t['tableHeaders.LastActivity'],
                key: 'lastActivity',
                align: 'start',
                sortable: false,
                value: 'updated_at'
            },
            {
                title: '',
                key: 'currentDevice',
                align: 'start',
                sortable: false,
                value: 'current_device'
            }
        ]
    }
}