<script setup>
import {reactive, computed, onMounted} from 'vue'
import {apiRequest} from '@/utils/apiRequest'

import showMessage from '@/utils/errorMessage'
import {useStore} from 'vuex'

const store = useStore()
const {commit, getters} = store

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const emit = defineEmits(['close'])

defineProps({
    step: Number,
    connector: Number,
    driver: Number
})

const state = reactive({
    currency: import.meta.env[`VITE_APP_I18N_CURRENCY_${store.state.account.locale.toString().toUpperCase()}`],
    price: null
})


const isChargingEventTriggered = computed(() => {
    return getters['connectors/isChargingEventTriggered']
})

const isForFree = computed(() => {
    return state.price.priceKwh === 0 && state.price.priceMinute === 0
})


const charge = async () => {
    try {
        commit('connectors/setIsChargingEventTriggered', true)
        await apiRequest('charging/start', {
            params: {driver_id: driver, connector_id: connector}
        })
        showMessage(t('chargingForm.chargingRequestProcessed'), 'success')
    } catch (error) {
        showMessage(error.msg)
    } finally {
        emit('close')
        commit('connectors/setIsChargingEventTriggered', false)
    }
}
const getPrice = async () => {
    state.price = null
    commit('setLoader', +1)
    try {
        const {data} = await apiRequest('charging/get-price', {
            params: {driver_id: driver, connector_id: connector}
        })
        state.price = data
    } catch (error) {
        showMessage(error)
    } finally {
        commit('setLoader', -1)
    }
}

onMounted(() => {
    getPrice()
})

</script>

<template>
    <v-card align="center"
            class="charging-price-wrapper pb-6"
    >
        <v-icon class="mb-4 mt-12" color="primary" large>
            mdi-help-circle
        </v-icon>
        <p class="question-title my-6">
            {{ t('chargingForm.DoYouWantToStartCharging') }}
        </p>
        <v-progress-linear
            v-if="store.getters['isLoading']"
            :height="7"
            class="mb-8"
            color="primary"
            indeterminate
        />
        <template v-if="!store.getters['isLoading'] && state.price">
            <v-card-text>
                <p class="my-1">
                    <b>{{ t('chargingForm.PricingInformation') }}:</b>
                </p>
                <template v-if="isForFree">
                    <p class="my-1 pb-5">
                        {{ t('chargingForm.freeOfCharge') }}
                    </p>
                </template>
                <template v-else>
                    <p class="my-1">
                        {{ t('chargingForm.ConsumedEnergyFee') }}:
                    </p>
                    <span>{{ state.price.priceKwh }}{{ state.currency }} / kWh</span>
                    <p class="my-1">
                        {{ t('chargingForm.ChargingTimeFeeAfter') }} {{ state.price.postponeMinutes }}
                        {{ t('chargingForm.minutes') }}:
                    </p>
                    <span>{{ state.price.priceMinute }}{{ state.currency }} / min</span>
                    <p class="mt-8 mb-0">
                        *{{ t('chargingForm.DiscountsAreNotIncludedInPrice') }}
                    </p>
                </template>
            </v-card-text>
        </template>
        <v-card-actions class="justify-center">
            <v-btn
                class="mr-4"
                color="primary"
                large
                text
                @click="emit('close')"
            >
                {{ t('chargingForm.cancel') }}
            </v-btn>
            <v-btn
                :disabled="isChargingEventTriggered"
                :loading="isChargingEventTriggered"
                color="primary"
                large
                @click="charge"
            >
                {{ t('chargingForm.startCharging') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
