<script setup>
defineProps({
    title: {
        type: String,
        default: ''
    },
    percent: {
        type: Number,
        default: 0
    },
    valueText: {
        type: String,
        default: ''
    }
})
</script>

<template>
    <v-progress-circular
        class="progress-circular-wrapper"
        :rotate="270"
        :size="100"
        :width="10"
        :value="percent"
        color="var(--v-success)"
    >
        <div class="d-flex flex-column align-center span-holder">
            <span class="text">{{ valueText }}</span>
            <span v-if="title" class="circle-title text-uppercase font-weight-bold">{{ title }}</span>
        </div>
    </v-progress-circular>
</template>
