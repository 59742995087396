import {Lang, Language} from '@/configs/index.js'

export function formatLanguage(lang) {
    const language = lang?.slice(-2)
    switch (language) {
    case Lang.en:
        return Language.english
    case Lang.pl:
        return Language.poland
    case Lang.sk:
        return Language.slovak
    default:
        return Language.english
    }
}