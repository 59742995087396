<template>
      <div class="charge-point-loader">
            <v-skeleton-loader
                  class="mb-6"
                  height="140"
            ></v-skeleton-loader>
            <v-skeleton-loader
                  width="100%"
                  max-width="320"
                  height="500"
            ></v-skeleton-loader>
      </div>
</template>