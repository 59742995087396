export const mutations = {
    setDrivers: (state, drivers) => {
        state.drivers = drivers
    },
    setDriver: (state, driver) => {
        state.driver = driver
    },
    setDriverTariff: (state, tariffId) => {
        state.driver.tariffId = tariffId
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination
    },
    toggleDriverEdit: (state, value) => {
        state.isEditView = value
    },
    setPage: (state, page) => {
        state.pagination.page = page
    },
    setItemPerPage: (state, itemsPerPage) => {
        state.pagination.itemsPerPage = itemsPerPage
    },
    setDriversTariffs: (state, data) => {
        state.tariffs = data.tariffs
    },
    setVehicle: (state, data) => {
        state.driver.vehicle = data
    },
    setCard: (state, data) => {
        state.driver.cards = data.data
    },
    setVehicleFields: (state, data) => {
        for (const [key, value] of Object.entries(data)) {
            state.driver.vehicle[key] = value
        }
    }
}