<script setup>
import router from '@/router'
import {useStore} from 'vuex'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

// store
const store = useStore()
const { dispatch } = store
</script>

<template>
    <section class="drivers-management-wrapper">
        <v-row>
            <v-col cols="12" class="pt-0 mb-3">
                <h2 class="home-page-title">
                    {{ t('driversManagement.title') }}
                </h2>
            </v-col>
        </v-row>
        <v-card elevation="0"
                class="drivers-management-card d-flex align-items-center justify-center"
        >
            <v-container>
                <v-row class="pt-8 pb-0">
                    <v-col cols="4"
                           class="d-flex align-items-center justify-center"
                    >
                        <v-img src="assets/icons/users.svg"
                               width="144px"
                        />
                    </v-col>
                    <v-col cols="8">
                        {{ t('driversManagement.description') }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                           class="d-flex align-items-center justify-center pt-5"
                    >
                        <v-btn
                            color="primary"
                            class="font-weight-regular text-none"
                            @click="router.push('/drivers/add')"
                        >
                            {{ t('drivers.addDriver') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </section>
</template>