<script setup>
import markdownParser from '@/utils/markdownParser'

// validations
import useValidations from '@/composables/useValidations'
const { isNotEmpty } = useValidations()

defineProps(['displayConsents'])
const emit = defineEmits(['getConsent', 'setConsent'])

const getConsent = (id) => {
    emit('getConsent', id)
}
const setConsent = (id, value) => {
    emit('setConsent', id, value)
}
</script>

<template>
    <div class="consent-wrapper">
        <div
            v-for="consent in displayConsents"
            :key="consent.id"
        >
            <v-checkbox
                v-if="consent.visible"
                :ref="consent.code"
                :input-value="getConsent(consent.id)"
                :rules="[!consent.revocable ? isNotEmpty : true]"
                :data-testid="`${consent.code}`"
                @update:modelValue="setConsent(consent.id, $event)"
            >
                <template v-slot:label>
                    <span
                        v-if="consent.required"
                        class="required_mark"
                    >*</span>
                    <div
                        class="mt-2"
                        @click.stop
                        v-html="markdownParser(consent.title)"
                    />
                </template>
            </v-checkbox>
            <div
                v-else
                v-html="markdownParser(consent.title)"
            />
        </div>
    </div>
</template>