export const mutations = {
    setChargers: (state, chargers) => {
        state.chargers = chargers
    },
    setCharger: (state, charger) => {
        state.charger = charger
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination
    },
    setItemPerPage: (state, itemsPerPage) => {
        state.pagination.itemsPerPage = itemsPerPage
    },
    setPage: (state, page) => {
        state.pagination.page = page
    },
    toggleChargerEdit: (state, value) => {
        state.isEditView = value
    },
    setFilters: (state, filters) => {
        state.filters = filters
    }
}