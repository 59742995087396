import { computed } from 'vue'
import { Lang } from '@/configs'
import useCharging from '@/composables/charging/useCharging'
import store from '@/store'

const { state } = store

export default function usePriceFormat() {

    const { connectorData, sessionSummary } = useCharging()

    const sanitizePrice = (value) => {
        return parseFloat(value).toFixed(2).toString()
    }

    const pricePerKWH = computed(() =>
        (state.account.lang === Lang.sk || state.account.lang === Lang.pl || state.account.lang === Lang.hr)
            ? `${sanitizePrice(connectorData.value.price.priceKwh_float).replace('.', ',')} ${connectorData.value.price.currency}`
            : `${sanitizePrice(connectorData.value.price.priceKwh_float)} ${connectorData.value.price.currency}`
    )

    const pricePerMin = computed(() =>
        (state.account.lang === Lang.sk || state.account.lang === Lang.pl || state.account.lang === Lang.hr)
            ? `${sanitizePrice(connectorData.value.price.priceMinute_float).replace('.', ',')} ${connectorData.value.price.currency}`
            : `${sanitizePrice(connectorData.value.price.priceMinute_float)} ${connectorData.value.price.currency}`
    )

    const sessionPrice = computed(() =>
        (state.account.lang === Lang.sk || state.account.lang === Lang.pl || state.account.lang === Lang.hr)
            ? `${sanitizePrice(sessionSummary.value.price).replace('.', ',')} ${connectorData.value.price.currency}`
            : `${sanitizePrice(sessionSummary.value.price)} ${connectorData.value.price.currency}`
    )

    const bestPricePerKWH = computed(() =>
        (state.account.lang === Lang.sk || state.account.lang === Lang.pl || state.account.lang === Lang.hr)
            ? `${sanitizePrice(connectorData.value.best_price.priceKwh_float).replace('.', ',')} ${connectorData.value.best_price.currency}`
            : `${sanitizePrice(connectorData.value.best_price.priceKwh_float)} ${connectorData.value.best_price.currency}`
    )

    const bestPricePerMin = computed(() =>
        (state.account.lang === Lang.sk || state.account.lang === Lang.pl || state.account.lang === Lang.hr)
            ? `${sanitizePrice(connectorData.value.best_price.priceMinute_float).replace('.', ',')} ${connectorData.value.best_price.currency}`
            : `${sanitizePrice(connectorData.value.best_price.priceMinute_float)} ${connectorData.value.best_price.currency}`
    )

    return {
        pricePerKWH,
        pricePerMin,
        bestPricePerKWH,
        bestPricePerMin,
        sessionPrice
    }
}