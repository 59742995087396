<script setup>
import { watch } from 'vue'
import store from '@/store'

// composables
import useInvoices from '@/composables/invoices'

// components
import Table from '@/components/Table.vue'
import Btn from '@/components/inputs/Btn.vue'
import Spending from './components/Spending.vue'
import InvoiceDetail from './components/InvoiceDetail.vue'
import CustomizeColumn from '@/components/modals/CustomizeColumn.vue'
import MenuActionsMobile from '@/components/inputs/MenuActionsMobile.vue'

// utils
import { PaymentStatus } from '@/utils/paymentStatus'
import { PaymentProviders } from '@/utils/paymentProviders'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { getters } = store

const {
    isPickedItem,
    isTable,
    isCustomVisible,
    spendingVisible,
    isCardView,
    windowWidth,
    selected,
    pickedItem,
    isPaidInvoiceSelected,
    options,
    allHeaders,
    customizeHeaders,
    filteredInvoices,
    paymentProvider,
    pagination,
    isLoading,
    countSelectedItems,
    batchActions,
    actionInvoice,
    payInvoice,
    payingInvoiceID,
    getItems,
    setFilter,
    sorting,
    updateHeaders,
    showDetails,
    closeDetails,
    highlightElement
} = useInvoices()

watch(filteredInvoices, () => {
    pickedItem.value = null
})
watch(options, (value) => {
    if (Object.keys(value).length > 2) {
        getItems()
    }
})
watch(pickedItem, () => {
    highlightElement(filteredInvoices.value.indexOf(pickedItem.value) || null)
})
</script>

<template>
    <div class="invoices">
        <header class="invoices__header pb-0 pt-6 px-4 px-md-8 pt-md-8">
            <div class="invoices__header-head">
                <h1 class="home-page-title">
                    {{ t('invoices.Invoices') }}
                </h1>
            </div>
            <v-row class="invoices__filters-options invoices__options">
                <v-col cols="12">
                    <v-tabs class="invoices__tabs" background-color="transparent">
                        <v-tab
                            @click=";[setFilter({ field: 'status', value: [] }), getItems(), (spendingVisible = false)]"
                        >
                            {{ t('invoices.All') }}
                        </v-tab>
                        <v-tab
                            @click="
                                ;[
                                    setFilter({ field: 'status', value: ['paid', 'overpaid'] }),
                                    getItems(),
                                    (spendingVisible = false)
                                ]
                            "
                        >
                            {{ t('invoices.paid') }}
                        </v-tab>
                        <v-tab
                            @click="
                                ;[
                                    setFilter({ field: 'status', value: ['unpaid', 'partialyPaid'] }),
                                    getItems(),
                                    (spendingVisible = false)
                                ]
                            "
                        >
                            {{ t('invoices.unpaid') }}
                        </v-tab>
                        <v-tab @click="spendingVisible = true">
                            {{ t('invoices.spendings') }}
                        </v-tab>
                    </v-tabs>
                </v-col>
            </v-row>
            <CustomizeColumn
                :headers="customizeHeaders"
                :all-headers="allHeaders"
                :active="isCustomVisible"
                @cancel="isCustomVisible = !isCustomVisible"
                @updateHeaders="updateHeaders"
            />
        </header>
        <main class="invoices__main">
            <div :class="{ 'invoices__table--min-height': pickedItem }">
                <div
                    v-if="!!countSelectedItems && !spendingVisible"
                    class="invoices__table-actions"
                    :class="{ 'invoices__table-actions-change': isPickedItem }"
                >
                    <span class="mr-6">{{ countSelectedItems }} {{ t('invoices.itemsAreSelected') }} </span>
                    <div v-for="{ icon, title, action } in batchActions" :key="title">
                        <div v-if="action === 'Pay invoices'">
                            <v-tooltip
                                v-if="PaymentProviders.canPay(paymentProvider) && isPaidInvoiceSelected === false"
                                top
                            >
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        variant="text"
                                        color="var(--v-color-gray-darken2)"
                                        dark
                                        v-bind="props"
                                        class="actions__button"
                                        @click="actionInvoice(action, null, true)"
                                    >
                                        <v-icon>{{ icon }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ title }}</span>
                            </v-tooltip>
                        </div>
                        <div v-else>
                            <v-tooltip top>
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        variant="text"
                                        color="var(--v-color-gray-darken2)"
                                        dark
                                        v-bind="props"
                                        class="actions__button"
                                        @click="actionInvoice(action, null, true)"
                                    >
                                        <v-icon>{{ icon }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ title }}</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
                <div v-if="!spendingVisible" ref="invoices-table">
                    <Table
                        class="invoices__table"
                        v-if="isTable"
                        :show-select="true"
                        :card-view="isCardView"
                        :model="selected"
                        :headers="customizeHeaders"
                        :items="filteredInvoices"
                        :sort-column-name="options.sortBy[0]"
                        :is-descending="options.sortDesc ? options.sortDesc : true"
                        item-key="invoice_number"
                        :class="{ 'table-view-mobile-size': !isCardView }"
                        :pagination="pagination"
                        :server-items="pagination.itemsLength"
                        data-testid="invoicesTable"
                        @updateSelected="(v) => (selected = v)"
                        @options="(value) => (options = value)"
                        @sorting="sorting"
                        @countSelectedItems="selected.length"
                    >
                        <template v-slot:actions="props">
                            <MenuActionsMobile
                                :id="props.item.invoiceId"
                                variant="outlined"
                                color="primary"
                                :is-styled="true"
                                @action="actionInvoice"
                            />
                        </template>
                        <template v-slot:status="props">
                            {{ props.item.status }}
                        </template>
                        <template v-slot:status_key="props">
                            <Btn
                                v-if="
                                    (PaymentStatus.isUnpaid(props.item.status_key) ||
                                        PaymentStatus.isPartialyPaid(props.item.status_key)) &&
                                    PaymentProviders.canPay(paymentProvider) &&
                                    props.item.sourceInvoiceId === null
                                "
                                :loading="isLoading && payingInvoiceID.includes(props.item.invoiceId)"
                                :disabled="isLoading && payingInvoiceID.includes(props.item.invoiceId)"
                                class="ml-2"
                                :size="'small'"
                                data-testid="payInvoice"
                                @click="payInvoice(props.item.invoiceId)"
                            >
                                <v-icon small color="var(--v-pure-white)" class="btn pr-2">
                                    mdi-credit-card-outline
                                </v-icon>
                                {{ t('invoices.PayInvoice') }}
                            </Btn>
                        </template>
                        <template v-slot:detail="props">
                            <v-btn
                                icon="mdi-magnify"
                                variant="text"
                                data-testid="invoiceShowDetails"
                                @click="showDetails(props.item)"
                                color="primary"
                            />
                        </template>
                    </Table>
                </div>
            </div>
            <Spending v-if="spendingVisible" />
        </main>
        <v-navigation-drawer
            v-model="isPickedItem"
            class="invoices__drawer"
            location="right"
            :temporary="true"
            :width="windowWidth < 960 ? '100%' : 550"
            @update:model-value="closeDetails"
        >
            <div class="invoices__side" :class="{ 'pa-5': isPickedItem }">
                <InvoiceDetail
                    v-if="isPickedItem"
                    :invoice="getters['invoices/invoice']"
                    @close="closeDetails"
                    @action="actionInvoice"
                />
            </div>
        </v-navigation-drawer>
    </div>
</template>
