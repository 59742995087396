<script setup>
import {useStore} from 'vuex'
import { onMounted, reactive, computed} from 'vue'

// configs
import {Country, Locale, testConfig} from '@/configs'

// components
import CountrySelect from './inputs/CountrySelect.vue'

// validations
import useValidations from '@/composables/useValidations'
const {
    isNotEmpty,
    isValid1Character,
    isValid2Characters,
    maxCharsExceeded
} = useValidations()

// translations
import {useI18n} from 'vue-i18n'
const { t } = useI18n()

const store = useStore()

const { commit } = store

const props = defineProps({
    isRegistration: {
        type: Boolean,
        required: false,
        default: false
    }
})

const state = reactive({
    testConfig
})

const account = computed(() => {
    return store.state.account
})
const accountTemp = computed(() => {
    return store.state.settings.accountTemp
})
const isEditView = computed(() => {
    return store.state.account.personalDataIsEditView
})
const deliveryAddress = computed(() => {
    return isEditView.value ? accountTemp.value.deliveryAddress : account.value.deliveryAddress
})
const isDeliveryAddress = computed(() => {
    return isEditView.value ? Boolean(!!accountTemp.value?.deliveryAddress) : Boolean(!!account.value?.deliveryAddress)
})
const isCompany = computed(() => {
    return isEditView.value ? accountTemp.value?.isCompany : account.value?.isCompany
})

const setAccountTempDeliveryFieldValue = (value, field) => {
    commit('settings/setAccountTempDeliveryFieldValue', {
        field,
        value
    })
}


const getEmptyDeliveryAddress = () => {
    const country = store.state.account.locale === Locale.pl ? getDeliveryCountryValue(null, Country.Poland) : getDeliveryCountryValue(null, Country.Slovakia)
    return {
        name: '',
        city: '',
        street: '',
        houseNumber: '',
        zipCode: '',
        country
    }
}

const updateDeliveryAddress = (value) => {
    if (value) {
        if (accountTemp.value?.deliveryAddress === null) {
            updateAccountTemp(getEmptyDeliveryAddress(), 'deliveryAddress')
        }
    }
    if (isEditView.value && !value) {
        updateAccountTemp(null, 'deliveryAddress')
    }
}

const updateAccountTemp = (value, field) => {
    commit('settings/setAccountTempFieldValue', {
        field,
        value
    })
}

const getDeliveryCountryValue = (_, val) => {
    const countries = store.state.filters.countries
    if (countries) {
        const country = countries.find(el => el.value === val) || null
        if (country) {
            return country.value
        }
        return null
    }
}

const prefillCountry = () => {
    if (account.value?.locale === Locale.sk) {
        updateAccountTemp(Country.Slovakia, 'country')
    } else if (account.value?.locale === Locale.pl) {
        updateAccountTemp(Country.Poland, 'country')
    }
}

onMounted(() => {
    if (props.isRegistration && !state.account.value?.country) {
        prefillCountry()
    }
})

</script>

<template>
    <v-row>
        <v-col
            class="pa-lg-5 bg-white"
            cols="12"
            lg="8"
            offset-lg="2"
        >
            <v-row>
                <v-col cols="12">
                    <h2 class="home-page-title">
                        {{ isCompany ? t('personalData.BillingAddressCompany') : t('personalData.BillingAddressIndividual') }}
                    </h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    v-if="!isCompany"
                    cols="12"
                    md="6"
                >
                    <CountrySelect
                        :country="isEditView ? accountTemp.country : account.country"
                        :data-testid="testConfig.accountSettings.countrySelect"
                        :is-edit-view="isEditView"
                        @setValue="updateAccountTemp($event, 'country')"
                    />
                </v-col>
                <v-col cols="12"
                       md="6"
                >
                    <v-text-field
                        :disabled="!isEditView"
                        :label="t('Client.City') + '*'"
                        :placeholder="t('Client.City') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                        :model-value="isEditView ? accountTemp.city : account.city"
                        data-testid="city"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'city')"
                    />
                </v-col>
                <v-col cols="12"
                       md="6"
                >
                    <v-text-field
                        :disabled="!isEditView"
                        :label="t('Client.Street') + '*'"
                        :placeholder="t('Client.Street') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(45)]"
                        :model-value="isEditView ? accountTemp.street : account.street"
                        data-testid="street"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'street')"
                    />
                </v-col>
                <v-col cols="12"
                       md="6"
                >
                    <v-text-field
                        :disabled="!isEditView"
                        :label="t('Client.HouseNumber') + '*'"
                        :placeholder="t('Client.HouseNumber') + '*'"
                        :rules="[isNotEmpty, isValid1Character, maxCharsExceeded(45)]"
                        :model-value="isEditView ? accountTemp.houseNumber : account.houseNumber"
                        data-testid="houseNumber"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'houseNumber')"
                    />
                </v-col>
                <v-col cols="12"
                       md="6"
                >
                    <v-text-field
                        v-if="isEditView ? accountTemp?.country === Country.Slovakia : account.country === Country.Slovakia"
                        :disabled="!isEditView"
                        :label="t('Client.ZipCode') + '*'"
                        :placeholder="t('Client.ZipCode') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(10)]"
                        :model-value="isEditView ? accountTemp.zipCode : account.zipCode"
                        data-testid="zipCode"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'zipCode')"
                    />
                    <v-text-field
                        v-else-if="isEditView ? accountTemp.country === Country.Poland : account.country === Country.Poland"
                        :disabled="!isEditView"
                        :label="t('Client.ZipCode') + '*'"
                        :placeholder="t('Client.ZipCode') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(10)]"
                        :model-value="isEditView ? accountTemp.zipCode : account.zipCode"
                        data-testid="zipCode"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'zipCode')"
                    />
                    <v-text-field
                        v-else
                        :disabled="!isEditView"
                        :label="t('Client.ZipCode') + '*'"
                        :placeholder="t('Client.ZipCode') + '*'"
                        :rules="[isNotEmpty, isValid2Characters, maxCharsExceeded(10)]"
                        :model-value="isEditView ? accountTemp.zipCode : account.zipCode"
                        data-testid="zipCode"
                        density="compact"
                        variant="outlined"
                        @update:model-value="updateAccountTemp($event, 'zipCode')"
                    />
                </v-col>
            </v-row>
        </v-col>

        <v-col
            class="pa-lg-5 bg-white"
            cols="12"
            lg="8"
            offset-lg="2"
        >
            <v-row>
                <v-col class="section__delivery" cols="12">
                    <h2 class="home-page-title">
                        {{ t('personalData.DeliveryAddress') }}
                    </h2>
                    <v-checkbox
                        :disabled="!isEditView"
                        v-model="isDeliveryAddress"
                        :label="t('personalData.DifferentDeliveryAddress')"
                        class="delivery-checkbox pt-5"
                        @update:model-value="updateDeliveryAddress"
                    />
                    <v-row v-if="isDeliveryAddress"
                           class="mt-5"
                    >
                        <v-col
                            class="py-0 pb-3"
                            cols="12"
                            md="4"
                            sm="6"
                        >
                            <v-text-field
                                :disabled="!isEditView"
                                :label="t('Client.DeliveryName') + '*'"
                                :placeholder="t('Client.DeliveryName') + '*'"
                                :rules="(isDeliveryAddress) ? [isNotEmpty, isValid2Characters, maxCharsExceeded(45)] : []"
                                :model-value="deliveryAddress.name"
                                data-testid="deliveryAddress"
                                density="compact"
                                variant="outlined"
                                @update:model-value="setAccountTempDeliveryFieldValue($event, 'name')"
                            />
                        </v-col>
                        <v-col
                            class="py-0 pb-3"
                            cols="12"
                            md="4"
                            sm="6"
                        >
                            <v-text-field
                                :disabled="!isEditView"
                                :placeholder="t('Client.Street') + '*'"
                                :label="t('Client.Street') + '*'"
                                :rules="(isDeliveryAddress) ? [isNotEmpty, isValid2Characters, maxCharsExceeded(45)] : []"
                                :model-value="deliveryAddress.street"
                                data-testid="deliveryStreet"
                                density="compact"
                                variant="outlined"
                                @update:model-value="setAccountTempDeliveryFieldValue($event, 'street')"
                            />
                        </v-col>
                        <v-col
                            class="py-0 pb-3"
                            cols="12"
                            md="4"
                            sm="6"
                        >
                            <v-text-field
                                :disabled="!isEditView"
                                :label="t('Client.HouseNumber') + '*'"
                                :placeholder="t('Client.HouseNumber') + '*'"
                                :rules="(isDeliveryAddress) ? [isNotEmpty, isValid1Character, maxCharsExceeded(45)] : []"
                                :model-value="deliveryAddress.houseNumber"
                                data-testid="deliveryHouse"
                                density="compact"
                                variant="outlined"
                                @update:model-value="setAccountTempDeliveryFieldValue($event, 'houseNumber')"
                            />
                        </v-col>
                        <v-col
                            class="py-0 pb-3"
                            cols="12"
                            md="4"
                            sm="6"
                        >
                            <v-text-field
                                :disabled="!isEditView"
                                :label="t('Client.City') + '*'"
                                :placeholder="t('Client.City') + '*'"
                                :rules="(isDeliveryAddress) ? [isNotEmpty, isValid2Characters, maxCharsExceeded(45)] : []"
                                :model-value="deliveryAddress.city"
                                data-testid="deliveryCity"
                                density="compact"
                                variant="outlined"
                                @update:model-value="setAccountTempDeliveryFieldValue($event, 'city')"
                            />
                        </v-col>
                        <v-col
                            class="py-0 pb-3"
                            cols="12"
                            md="4"
                            sm="6"
                        >
                            <v-text-field
                                v-if="isEditView ? accountTemp.country === Country.Slovakia : account.country === Country.Slovakia"
                                :disabled="!isEditView"
                                :label="t('Client.ZipCode') + '*'"
                                :placeholder="t('Client.ZipCode') + '*'"
                                :rules="(isDeliveryAddress) ? [isNotEmpty, isValid2Characters, maxCharsExceeded(5)] : []"
                                :model-value="deliveryAddress.zipCode"
                                data-testid="deliveryZip"
                                density="compact"
                                variant="outlined"
                                @update:model-value="setAccountTempDeliveryFieldValue($event, 'zipCode')"
                            />
                            <v-text-field
                                v-else-if="isEditView ? accountTemp.country === Country.Poland : account.country === Country.Poland"
                                :disabled="!isEditView"
                                :label="t('Client.ZipCode') + '*'"
                                :placeholder="t('Client.ZipCode') + '*'"
                                :rules="(isDeliveryAddress) ? [isNotEmpty, isValid2Characters] : []"
                                :model-value="deliveryAddress.zipCode"
                                data-testid="deliveryZip"
                                density="compact"
                                variant="outlined"
                                @update:model-value="setAccountTempDeliveryFieldValue($event, 'zipCode')"
                            />
                            <v-text-field
                                v-else
                                :disabled="!isEditView"
                                :label="t('Client.ZipCode') + '*'"
                                :placeholder="t('Client.ZipCode') + '*'"
                                :rules="(isDeliveryAddress) ? [isNotEmpty, isValid2Characters] : []"
                                :model-value="deliveryAddress.zipCode"
                                data-testid="deliveryZip"
                                density="compact"
                                variant="outlined"
                                @update:model-value="setAccountTempDeliveryFieldValue($event, 'zipCode')"
                            />
                        </v-col>
                        <v-col
                            class="py-0 pb-3"
                            cols="12"
                            md="4"
                            sm="6"
                        >
                            <CountrySelect
                                :country="deliveryAddress.country"
                                :is-delivery-address="isDeliveryAddress"
                                :is-delivery-country="true"
                                :is-edit-view="isEditView"
                                data-testid="deliveryCountrySelect"
                                @change="setAccountTempDeliveryFieldValue($event, 'country')"
                                @setDeliveryCountry="setAccountTempDeliveryFieldValue($event, 'country')"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>