<script setup>
import store from '@/store'
import {useI18n} from 'vue-i18n'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// utils
import DeviceDetection from '@/utils/deviceDetection'
import {computed, onBeforeMount, onMounted, reactive} from 'vue'
import {onBeforeRouteLeave} from 'vue-router'

const { t } = useI18n()
const state = reactive({
    detection: new DeviceDetection(),
    url: import.meta.env.VITE_APP_LOCATION_MAP_IFRAME_URL || 'http://localhost:3000/'
})

const isLoading = computed(() => {
    return store.state.featureFlags.loading
})

onMounted(() => {
    setTimeout(() => {
        const zsIq = document.getElementById('zsiq_float')
        if(zsIq) {
            zsIq.classList.add('d-none')
        }
        const zsIqCustomMain = document.getElementsByClassName('zsiq_custommain')
        if(zsIqCustomMain && zsIqCustomMain.length) {
            zsIqCustomMain[0].classList.add('d-none')
        }
        const options = {
            enableHighAccuracy: true,
            maximumAge: 0
        }

        // if it's firefox
        if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            navigator.geolocation.getCurrentPosition(({coords: {latitude, longitude}}) => {
                state.url = `${state.url}&lat=${latitude}&lng=${longitude}`
            }, (e) => {
                console.log(e)
            }, options)
        }
    }, 1500)
})

onBeforeRouteLeave((to, from, next) => {
    const zsIq = document.getElementById('zsiq_float')
    if(zsIq) {
        zsIq.classList.remove('d-none')
    }
    const zsIqCustomMain = document.getElementsByClassName('zsiq_custommain')
    if(zsIqCustomMain && zsIqCustomMain.length) {
        zsIqCustomMain[0].classList.remove('d-none')
    }
    next()
})

onBeforeMount(() => {
    const lang = cookies.get('lang') || store.state.account.lang || import.meta.env.VITE_APP_I18N_LOCALE
    // option params
    state.url = `${state.url}?lang=${lang}&isIframe=${true}`

    window.addEventListener('message', event => {
        if(typeof event.data !== 'undefined' && typeof event.data.indexOf === 'function' && event.data.indexOf('scanQrRedirect') > -1) {
            const url = event.data.split('|')
            if(typeof url[1] !== 'undefined') {
                window.location.href = url[1]
            }
        }
    })
})
</script>

<template>
    <div v-if="!isLoading">
        <iframe id="location-map-iframe"
             :src="state.url"
             :class="`${state.detection.isSmartPhone() ? 'extraBottomPadding' : ''} location-map-wrapper`"
             allow="fullscreen; camera *; geolocation *;"
        />
    </div>
    <div v-else-if="isLoading" class="container">
        <div class="col-12 d-flex align-content-center justify-center">
            <small>
                {{ t('locationMap.loading') }}
            </small>
        </div>
    </div>
    <div v-else class="container">
        <div class="col-12 d-flex align-content-center justify-center">
            <small>
                {{ t('chargingCards.featureNotEnabled') }}
            </small>
        </div>
    </div>
</template>