<script setup>
import store from '@/store'
import {computed, onBeforeUnmount, onMounted, reactive, ref} from 'vue'

// configs
import {Lang, Language} from '@/configs/index.js'

import { useI18n } from 'vue-i18n'
const i18n = useI18n()

const {dispatch, commit} = store
const state = reactive({
    languages: [
        {value: Lang.en, name: Language.english, icon: 'flagUK', isPersistLang: true},
        {value: Lang.sk, name: Language.slovak, icon: 'flagSlovakia'},
        {value: Lang.pl, name: Language.poland, icon: 'flagPoland'},
        {value: Lang.hr, name: Language.croatian, icon: 'flagCroatia'}
    ]
})

const language = computed(() => {
    return store.state.account.lang
})

const isLocaleLang = (l) => l.value === store.state.account.locale || l.value === import.meta.env.VITE_APP_I18N_LOCALE

const availableLanguages = computed(() => {
    return state.languages.filter(l => l.isPersistLang || isLocaleLang(l))
})

const languageIcon = computed(() => {
    return state.languages.find(l => l.value === store.state.account.lang)?.icon
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
})

const handleScroll = () => {
    const languageSelector = ref['language-selector']
    if (typeof languageSelector !== 'undefined' && languageSelector.isMenuActive) {
        languageSelector.blur()
    }
}

const changeLanguage = async (lang) => {
    if (lang !== language.value) {
        commit('account/setLang', lang)
        await dispatch('translation/getTranslation')
        i18n.setLocaleMessage(store.state.account.lang, store.state.translation[store.state.account.lang])
        window.location.reload()
    }
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll)
})

</script>

<template>
    <div class="switcher">
        <div 
            v-for="languageItem in availableLanguages"
            :key="languageItem.value"
            class="switcher__icon"
            :class="[languageItem.value === language ? 'active' : 'not-active']"
        >
            <v-tooltip bottom>
                <template v-slot:activator="{ props }">
                    <span 
                        class="icon-wrapper"
                        v-bind="props"
                        :data-testid="`${languageItem.value}-menuLanguage`"
                        @click="changeLanguage(languageItem.value)"
                    >
                        <img :src="`/assets/icons/${languageItem.icon}.svg`" :alt="languageItem.icon"/>
                    </span>
                </template>
                <span>{{ languageItem.name }}</span>
            </v-tooltip>
        </div>
    </div>
</template>
