import { ref, computed } from 'vue'
import store from '@/store'
import showMessage from '@/utils/errorMessage'
import useWindowWidth from './windowWidth'
import router from '@/router'
import useFilterableList from './filterableList'
import isEmptyObj from '@/utils/isEmptyObj'
import { useI18n } from 'vue-i18n'
import { apiRequest } from '@/utils/apiRequest.js'
import qs from 'qs'
import { fileDownload } from '@/utils/fileDownload.js'
import showMessageArray from '@/utils/errorMessageArray.js'

const { getters, state } = store
export default function useChargers(props) {
    const { t } = useI18n()
    const { windowWidth, setWindowWidth } = useWindowWidth()

    const chargersExportLoading = ref(false)

    const { filtersSelected, options, getItems, getDefaultFilters, setFilter, clearFilters, sorting } =
        useFilterableList({
            filters: props?.filters || {
                code: [],
                charger_type: null,
                status: null,
                category: null,
                location: {
                    code: [],
                    name: []
                }
            },
            options: {
                sortBy: ['code'],
                sortDesc: true
            },
            action: 'chargers/getChargers'
        })

    const currentCharger = ref()
    const isPopupStartCharging = ref()
    const isPopupStopCharging = ref()
    const isPopupRestartCharging = ref()
    const selected = ref([])

    const popupData = ref({
        question: {
            title: t('driverInfo.AreYouSure'),
            type: 'question',
            subtitle: t('chargerManagement.YouWantToRestartCharging'),
            icon: 'mdi-help-circle'
        }
    })

    const actions = ref([
        {
            title: t('chargerManagement.startCharging'),
            action: 'startCharging',
            icon: 'mdi-play-circle'
        },
        {
            title: t('chargerManagement.stopCharging'),
            action: 'stopCharging',
            icon: 'mdi-stop-circle'
        }
        // TODO: Enable this when BE is implemented
        // {
        //     title: t('chargerManagement.restartCharging'),
        //     action: 'restartCharging',
        //     icon: 'mdi-rotate-left'
        // }
    ])

    const headers = computed(() => {
        return getters['chargers/tableHeaders']
    })

    const chargers = computed(() => {
        return getters['chargers/chargers']
    })
    const pagination = computed(() => {
        return state.chargers.pagination
    })
    const isChargable = computed(() => {
        return !isEmptyObj(state.account.paymentInfo)
    })

    function goToDetail(charger) {
        router.push(`/charger/${charger.id}`)
    }

    function action(title, item) {
        switch (title) {
            case 'startCharging':
                currentCharger.value = item
                isPopupStartCharging.value = true
                break
            case 'stopCharging':
                currentCharger.value = item
                isPopupStopCharging.value = true
                break
            // TODO: Enable this when BE is implemented
            // case 'restartCharging':
            //     currentCharger.value = item
            //     isPopupRestartCharging.value = !isPopupRestartCharging
            //     break
        }
    }

    async function handleChargersDownload(type) {
        chargersExportLoading.value = true

        await apiRequest('facility/charger-management/chargers/export', {
            params: { filters: filtersSelected.value, type },
            paramsSerializer: (params) => qs.stringify(params, { skipNulls: true }),
            responseType: 'blob'
        })
            .then((response) => {
                if (response.data.error) {
                    showMessage(response.data.error)
                } else {
                    fileDownload(response.data, 'Sessions', type)
                }
            })
            .catch((err) => {
                if (typeof err.response.data.errors !== 'undefined') {
                    showMessageArray(err.response.data.errors)
                }
            })
        chargersExportLoading.value = false
    }

    function restart() {
        //TODO implementation
        showMessage(t('chargerManagement.ChargerBeenRestarted'), 'success')
    }

    return {
        currentCharger,
        isPopupStartCharging,
        isPopupStopCharging,
        isPopupRestartCharging,
        windowWidth,
        selected,
        popupData,
        actions,
        headers,
        chargers,
        pagination,
        filtersSelected,
        options,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting,
        isChargable,
        goToDetail,
        action,
        restart,
        setWindowWidth,
        handleChargersDownload
    }
}
