/* eslint-disable */
// Utils
import { apiRequest } from '@/utils/apiRequest'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }
        commit('account/resetStore', state)
        cookies.remove('mobile-token')
    },
    getFreeKWh: async ({ commit, dispatch }, id) => {
        let params = {} //detail:1 };
        if (id) {
            params = { driver_id: id }
        }
        const { data } = await apiRequest('client/package/freekwh', { params })
        .catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
        return data
    },
    getFreeKWhPerDriver: async ({commit, dispatch}) => {
        try {
            const { data } = await apiRequest('client/package/freekwh')
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
            commit('setFreeKwhPerDriver', { data: data })
            return data
        } catch (e) {
            return []
        }
    }
}