import { ref, computed } from 'vue'

// Composables
import useRegistration from '@/composables/registration/useRegistration'

// Utils
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'

// translations
import { useI18n } from 'vue-i18n'

export default function usePhoneVerification() {
    const { t } = useI18n()

    const { formData, nextStep, isPhoneVerified, errors } = useRegistration()

    const code = ref('')
    const phoneCode = ref(null)
    const form = ref()
    const errorMessage = ref('')
    const isFormValid = ref(false)
    const isCodeChecking = ref(false)
    const showRecaptcha = ref(false)
    const recaptcha = ref()
    const recaptchaToken = ref(null)
    const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null

    const phoneNumber = computed(() => {
        const phoneNumberToArr = formData.value.phone.split('')
        phoneNumberToArr.forEach((item, index) => {
            if (index === 4 || index === 7 || index === 10) phoneNumberToArr[index] = ` ${item}`
        })
        return phoneNumberToArr.join('')
    })

    const sendVerificationCode = async () => {

        grecaptcha.enterprise.ready(async () => {
            const recaptchaToken = await grecaptcha.enterprise.execute(recaptchaSiteKey, { action: 'sms_send_verification' })

            await apiRequest.post('sms/send-verification', {
                phone: formData.value.phone,
                'g-recaptcha-response': recaptchaToken
            }).then(response => {
                const status = response.data.status
                if (recaptcha.value) recaptcha.value.reset()

                switch (status) {
                    case 'SMS_SENT_OK':
                    case 'SMS_ALREADY_SENT_NOT_EXPIRED_YET':
                        showMessage(response.data.message, 'success')
                        break
                    case 'PHONE_ALREADY_VALIDATED':
                        showMessage(t('Client.PhoneIsAlreadyVerified'), 'success')
                        isPhoneVerified.value = true
                        errorMessage.value = ''
                        code.value = ''
                        break
                }
            }).catch(error => {
                if (error.recaptcha_failed) {
                    showRecaptcha.value = true
                    return
                }
                typeof error.msg !== 'undefined' ? showMessage(error.msg) : showMessage(error.message)
            })
        })
    }

    const verifyCode = async () => {
        const { valid } = await form.value.validate()
        if (!valid) {
            return
        }

        isCodeChecking.value = true
        apiRequest.post('sms/verify', {
            code: code.value,
            phone: formData.value.phone
        }).then(response => {
            if (response.data.status === 'error' || response.data.status === 'errorTimeout') {
                errorMessage.value = response.data.message
            } else {
                nextStep()
                isPhoneVerified.value = true
                errors.value.phone = ''
                errorMessage.value = ''
                code.value = ''
            }
        }).catch(error => {
            if (typeof error === 'string') {
                errorMessage.value = error
            } else {
                errorMessage.value = error.message
            }
            showMessage(errorMessage.value)
        }).finally(() => {
            isCodeChecking.value = false
        })
    }

    const skipPhoneVerification = async () => {
        try {
            isCodeChecking.value = true
            await apiRequest.post('sms/verify', { skip: true })
            nextStep()
        } catch (error) {
            showMessage(error.errors)
        } finally {
            isCodeChecking.value = false
        }
    }

    return {
        code,
        phoneCode,
        form,
        errorMessage,
        isFormValid,
        isCodeChecking,
        phoneNumber,
        showRecaptcha,
        recaptcha,
        recaptchaToken,
        sendVerificationCode,
        skipPhoneVerification,
        verifyCode
    }
}