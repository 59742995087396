<script setup>
import store from '@/store'
import router from '@/router.js'
import { computed, onBeforeUnmount, onMounted, reactive, ref } from 'vue'

// utils
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { getters } = store
const settingPanel = ref()

const isLocationMap = computed(() => {
    return window.location.pathname.indexOf('locationMap') > -1
})

const settingsLink = computed(() => {
    if (store.state.account.roles) {
        const roles = [...store.state.account.roles]
        if (roles) {
            return roles.length === 1 && roles.includes('driver') ? `/drivers/${store.state.account.driver.id}` : '/account'
        }
    }
    return '/account'
})

const fullName = computed(() => {
    return getters['account/fullName']
})

const clickPrivacyButton = () => {
    UC_UI.showSecondLayer()
}

const goToSettings = () => {
    router.push(settingsLink.value)
}

const handleScroll = () => {
    if (typeof settingPanel !== 'undefined' && settingPanel.isActive) {
        settingPanel.isActive = false
        settingPanel.$children[0].$el.blur()
    }
}

const logout = () => {
    router.push('/logout')
}

onMounted(() => {
    window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
    <div class="d-flex align-center setting-panel-wrapper">
        <v-menu
            ref="setting-panel"
            :min-width="380"
            offset-y
            transition="slide-y-transition"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    :ripple="false"
                    class="account-btn icon px-2 px-sm-3 py-5"
                    v-bind="props"
                    variant="flat"
                >
                    <div class="d-flex flex-column">
                        <span class="account-name  text-grey-darken-2">{{ fullName }}</span>
                    </div>
                    <v-icon
                        color="grey-darken-3"
                        right
                        size="24px"
                    >
                        mdi-menu-down
                    </v-icon>
                </v-btn>
            </template>
            <v-list
                :min-width="380"
                class="py-0"
                density="compact"
            >
                <div class="page__account py-5">
                    <div class="px-5">
                        {{ fullName }}
                    </div>
                    <div class="px-5 text-grey-lighten-1 email">
                        {{ store.state.account.email }}
                    </div>
                </div>

                <v-divider />

                <v-list-item class="ma-0 w-100 pa-0">
                    <v-btn
                        class="setting-item justify-space-between rounded-0"
                        block
                        variant="text"
                        @click="goToSettings"
                    >
                        <div class="text">
                            {{ t('account.AccountSettings') }}
                        </div>
                        <template v-slot:append>
                            <div class="icon">
                                <v-icon>mdi-account-settings</v-icon>
                            </div>
                        </template>
                    </v-btn>
                </v-list-item>

                <v-divider />

                <v-list-item class="ma-0 w-100 pa-0">
                    <v-btn
                        block
                        class="setting-item justify-space-between rounded-0"
                        variant="text"
                        @click="clickPrivacyButton"
                    >
                        <div class=" text">
                            {{ t('cookieBar.CookiesSettings') }}
                        </div>
                        <template v-slot:append>
                            <div class="icon">
                                <v-img
                                    src="assets/icons/cookie.svg"
                                    width="1.75em"
                                />
                            </div>
                        </template>
                    </v-btn>
                </v-list-item>

                <v-divider />

                <v-list-item class="mx-0 sign-out">
                    <v-btn
                        :ripple="false"
                        block
                        class="sign-out-button"
                        @click="logout"
                        color="error"
                        variant="plain"
                    >
                        <v-icon>mdi-logout</v-icon>
                        <span class="">
                            {{ t('account.SignOut') }}
                        </span>
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>