<script setup>
import {ref, reactive, computed, onBeforeMount} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import store from '@/store'

const {commit, dispatch} = store

// configs
import {Lang} from '@/configs/index.js'

// components
import Btn from '@/components/inputs/Btn.vue'
import Logo from '@/components/icons/Logo.vue'
import Modal from '@/components/modals/Modal.vue'
import LangSwitcher from '@/components/inputs/LangSwitcher.vue'

// utils
import {useI18n} from 'vue-i18n'
import showMessageArray from '@/utils/errorMessageArray'

// validations
import useValidations from '@/composables/useValidations'
import Notify from '@/components/Notify.vue'

const {isValidPassword, isNotEmpty, isLongPassword} = useValidations()

const {t} = useI18n()
const route = useRoute()
const router = useRouter()

const emit = defineEmits(['cancel'])

const state = reactive({
  isFormValid: false,
  isSend: false,
  rules: [
    isNotEmpty,
    isValidPassword,
    isLongPassword
  ],
  password: '',
  repeatPassword: '',
  isPasswordVisible: false,
  formErrorMessage: '',
  isLoading: false,
  recaptchaToken: null,
  token: null
})
const form = ref()

const language = computed(() => {
  return store.state.account.lang || import.meta.env.VITE_APP_I18N_LOCALE || Lang.en
})

const isRememberChecked = computed({
  get() {
    return store.state.account.remember_me
  },
  set(value) {
    commit('account/setRememberMe', value)
  }
})
const isPasswordMatch = computed(() => {
  return state.password === state.repeatPassword
})

const confirmPassword = async () => {
  if (state.isLoading) return
  const {valid} = await form.value.validate()
  state.isFormValid = valid

  if (!isPasswordMatch.value) {
    state.formErrorMessage = t('resetPassword.passwordIsNotMatch')
  }

  if (state.isFormValid && isPasswordMatch.value) {
    state.isLoading = true

    const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
    grecaptcha.enterprise.ready(async () => {
      const recaptchaToken = await grecaptcha.enterprise.execute(recaptchaSiteKey, {action: 'forgot_password'})

      try {
        await dispatch('resetPassword/passwordChange', {
          token: state.token,
          password: state.password,
          'g-recaptcha-response': recaptchaToken,
          remember_me: isRememberChecked.value
        })
        state.isSend = true
      } catch (error) {
        if (typeof error === 'string') {
          commit('setNotify', {
            content: error,
            color: 'error'
          })
        } else if (Array.isArray(error)){
          showMessageArray(error)
        }
      } finally {
        state.isLoading = false
      }
    })
  }
}

onBeforeMount(() => {
    route.query.token ? state.token = route.query.token : null
})
</script>

<template>
  <section class="reset-password__main password-recovery-wrapper pa-4 pa-md-6">
    <header class="reset-password__header pt-3">
      <Logo
          height="37px"
          width="184px"
      />
      <LangSwitcher class="custom-switch-position"/>
    </header>
    <main class="reset">
      <v-form ref="form" v-model="state.isFormValid" class="form" @submit.prevent="confirmPassword">
        <h1 class="form__title text-h5 mb-6">
          {{ t('resetPassword.PasswordRecovery') }}
        </h1>
        <v-text-field v-model="state.password" :error-messages="state.formErrorMessage" :placeholder="`${t('resetPassword.NewPassword')}*`"
                      :rules="state.rules"
                      :type="state.isPasswordVisible ? 'text' : 'password'" class="form__input"
                      variant="outlined" @click="state.formErrorMessage = ''"
                      @click:append="state.isPasswordVisible = !state.isPasswordVisible"
        >
          <template v-slot:append-inner>
              <v-icon
                  :icon="state.isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                  @click="state.isPasswordVisible = !state.isPasswordVisible"
              />
          </template>
        </v-text-field>
        <v-text-field v-model="state.repeatPassword" :error-messages="state.formErrorMessage" :placeholder="`${t('resetPassword.RepeatPassword')}*`"
                      :rules="state.rules"
                      :type="state.isPasswordVisible ? 'text' : 'password'" class="form__input"
                      variant="outlined" @click="state.formErrorMessage = ''"
                      @click:append="state.isPasswordVisible = !state.isPasswordVisible"
        >
            <template v-slot:append-inner>
              <v-icon
                  :icon="state.isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                  @click="state.isPasswordVisible = !state.isPasswordVisible"
              />
          </template>
        </v-text-field>
        <Btn :loading="state.isLoading" class="w-100" height="56px" large
             type="submit" @click="confirmPassword"
        >
          {{ t('resetPassword.ConfirmPassword') }}
        </Btn>
        <div class="form__footer">
          {{ t('resetPassword.BackTo') }}
          <router-link class="form__footer-link" to="/login">
            {{ t('resetPassword.LogIn') }}
          </router-link>
        </div>
      </v-form>

      <Modal :is-active="state.isSend" :max-width="450" @cancel="emit('cancel')">
        <div class="modal pa-5">
          <v-row>
            <v-col cols="12" class="text-center">
              {{ t('resetPassword.ChangedSuccessfully') }}<br>
              <Btn class="modal__btn" medium @click="router.push('news')">
                OK
              </Btn>
            </v-col>
          </v-row>
        </div>
      </Modal>

    </main>
  </section>
</template>
