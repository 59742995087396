<script setup>
// translations
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

defineProps({
  data: {
    required: true
  }
})
</script>

<template>
	<v-row class="coupons-list-wrapper" no-gutters>
      <v-col
          v-for="(coupon, index) in data"
          :key="index"
          cols="12"
          md="4"
          sm="6"
      >
        <v-card class="coupon-card">
          <v-card-title>{{ coupon.code }}</v-card-title>
          <v-card-subtitle class="pb-0">
            {{ coupon.registredAt }}
          </v-card-subtitle>
          <v-card-text>
            <v-expansion-panels
                accordion
                flat
            >
              <v-expansion-panel>
                <v-expansion-panel-title class="campaign-name px-2">
                  {{ coupon.campaignName }}
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  {{ coupon.campaignDescription }}
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-text v-if="coupon.valid_to">
            <div>{{ t('coupons.validTo') }}: {{ coupon.validTo }}</div>
          </v-card-text>
          <v-card-text>
            <div v-if="coupon.usedAt">
              {{ t('coupons.used') }}: {{ coupon.usedAt }}
            </div>
            <div v-else>
              {{ t('coupons.active') }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</template>