<script setup>
defineProps({
    fill: {
        type: Number,
        default: 0
    },
    selected: {
        type: Boolean,
        default: true
    }
})
</script>

<template>
    <div class="metter mb-4">
        <svg width="44"
             height="24"
             viewBox="0 0 44 24"
             :fill="selected ? 'rgba(var(--v-theme-white), 0.1)' : 'rgb(var(--v-theme-white))'"
             xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3.41379 14.614C2.54669 16.796 2.07149 19.1113 2.00747 21.4535C1.9934 21.9683 2.42109 22.3778 2.93593 22.3678V22.3678C3.45078 22.3579 3.85759 21.9323 3.87413 21.4176C3.94141 19.3234 4.37122 17.2542 5.14674 15.3027C6.03717 13.062 7.36549 11.0213 9.05399 9.30011C10.7425 7.57889 12.7573 6.21165 14.9805 5.27838C17.2037 4.3451 19.5907 3.86453 22.0018 3.86477C24.413 3.86501 26.7999 4.34606 29.0229 5.27978C31.2459 6.2135 33.2604 7.58114 34.9485 9.3027C36.6367 11.0243 37.9646 13.0652 38.8546 15.3061C39.6297 17.2577 40.0591 19.327 40.126 21.4212C40.1424 21.9359 40.5491 22.3616 41.064 22.3717V22.3717C41.5788 22.3817 42.0066 21.9723 41.9926 21.4575C41.9291 19.1153 41.4543 16.7999 40.5877 14.6177C39.6062 12.1464 38.1417 9.89566 36.28 7.99708C34.4183 6.0985 32.1966 4.59023 29.745 3.56051C27.2934 2.53078 24.6611 2.00027 22.002 2C19.3429 1.99973 16.7105 2.52972 14.2587 3.55896C11.8069 4.5882 9.58492 6.09602 7.7228 7.99423C5.86068 9.89244 4.39578 12.1429 3.41379 14.614Z"
                  :stroke="(selected)? 'white' : 'rgb(var(--v-theme-primary))'"
                  stroke-opacity="0.2"
                  stroke-width="2.05128"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
        </svg>
        <svg
            v-if="fill === 1" 
            width="9"
            height="15"
            viewBox="0 0 9 15"
            fill="white"
            class="fill"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3.87412 12.4175C3.98588 8.93779 5.09729 5.56302 7.0751 2.69785C7.36763 2.27407 7.29341 1.68992 6.88522 1.37601V1.37601C6.47703 1.06209 5.88966 1.13736 5.5951 1.55973C3.36145 4.76257 2.11409 8.55014 2.00746 12.4535C1.9934 12.9682 2.42109 13.3778 2.93593 13.3678V13.3678C3.45078 13.3579 3.85759 12.9322 3.87412 12.4175Z"
                  :stroke="(selected)? 'white' : 'rgb(var(--v-theme-primary))'"
                  stroke-width="2.05128"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
        </svg>
        <svg 
            v-if="fill === 2"
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            class="fill"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M4.80832 11.7801C3.06228 14.7173 2.10053 18.0481 2.00746 21.4535C1.9934 21.9682 2.42109 22.3778 2.93593 22.3678V22.3678C3.45078 22.3579 3.85759 21.9323 3.87412 21.4176C3.97235 18.36 4.84286 15.3713 6.41125 12.733C8.13872 9.82708 10.6441 7.46185 13.6445 5.90429C16.6449 4.34672 20.021 3.65883 23.3916 3.91825C26.4519 4.15377 29.397 5.16165 31.9542 6.84082C32.3846 7.12347 32.9668 7.03572 33.2712 6.62037V6.62037C33.5756 6.20503 33.4868 5.61957 33.0577 5.33487C30.2191 3.45136 26.9416 2.32118 23.5347 2.05897C19.8175 1.77288 16.0943 2.53151 12.7853 4.24923C9.47638 5.96695 6.71342 8.57538 4.80832 11.7801Z"
                  :stroke="(selected)? 'white' : 'rgb(var(--v-theme-primary))'"
                  stroke-width="2.05128"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
        </svg>
        <svg 
            v-if="fill === 3"
            width="44"
            height="24"
            viewBox="0 0 44 24"
            fill="none"
            class="fill"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3.41431 14.6127C2.54689 16.795 2.0715 19.1108 2.00747 21.4535C1.9934 21.9683 2.42109 22.3778 2.93593 22.3678V22.3678C3.45078 22.3579 3.85759 21.9323 3.87413 21.4176C3.94143 19.3229 4.3714 17.2534 5.14722 15.3015C6.03797 13.0604 7.36676 11.0195 9.05581 9.29825C10.7449 7.57696 12.7603 6.20983 14.9841 5.27687C17.2079 4.3439 19.5954 3.86384 22.007 3.86477C24.4186 3.86571 26.8058 4.34761 29.0289 5.2823C31.2519 6.21698 33.2663 7.58567 34.954 9.30826C36.6417 11.0309 37.9689 13.0728 38.858 15.3145C39.6323 17.267 40.0606 19.3369 40.1263 21.4316C40.1425 21.9463 40.5489 22.3723 41.0638 22.3826V22.3826C41.5786 22.3929 42.0066 21.9837 41.993 21.469C41.9307 19.1262 41.4571 16.8101 40.5914 14.6271C39.611 12.1548 38.1473 9.90295 36.286 8.00322C34.4248 6.1035 32.2033 4.59408 29.7516 3.56328C27.2999 2.53249 24.6673 2.00103 22.0077 2C19.3482 1.99897 16.7151 2.5284 14.2626 3.5573C11.8102 4.58619 9.58754 6.0939 7.72481 7.99218C5.86208 9.89047 4.39666 12.1412 3.41431 14.6127Z"
                  :stroke="(selected)? 'white' : 'rgb(var(--v-theme-primary))'"
                  stroke-width="2.05128"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
        </svg>
    </div>
</template>
