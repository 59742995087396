// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        sessions: [],
        pagination: {
            itemsLength: 0,
            itemsPerPage: 10,
            pageCount: null,
            page: 1,
            pageStart: null,
            pageStop: null
        }
    }
}

export const state = getDefaultState()