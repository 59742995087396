// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        sessionsFilters: {
            roaming: [
                {name: 'YES', key: true},
                {name: 'NO', key: false}
            ],
            chargerTypeOptions: [
                {
                    name: 'Company',
                    key: 'company'
                },
                {
                    name: 'Home charger',
                    key: 'home'
                }
            ],
            location: []
        },
        titles: null,
        countries: null,
        languages: null
    }
}

export const state = getDefaultState()