<script setup>
import moment from 'moment'
import { useStore } from 'vuex'
import { computed, reactive } from 'vue'

// components
import Btn from '@/components/inputs/Btn.vue'
import Modal from '@/components/modals/Modal.vue'
import PricePrograms from '@/components/PricePrograms.vue'
import PriceProgramConfirmation from '@/pages/drivers/components/PriceProgramConfirmation.vue'

// utils
import isDriver from '@/utils/isDriver'
import formatDecimal from '@/utils/formatDecimal'

// Helpers
import showMessage from '@/utils/errorMessage.js'
import MeterWithInfo from './helpers/MeterWithInfo.vue'

// translations
import { useI18n } from 'vue-i18n'
import { DATE_FORMAT } from '../../../configs/index.js'

const { t } = useI18n()

const store = useStore()
const { getters, dispatch } = store
const emit = defineEmits(['refetch'])

const props = defineProps({
    driver: {
        type: Object,
        default: null
    }
})

const state = reactive({
    isDialogActive: false,
    showConfirmationDialog: false,
    step: 1,
    tempPriceChange: {
        tariffId: null,
        price: null,
        subscriptionMonthlyPrice: null
    }
})

const locale = computed(() => {
    return getters['account/locale']
})

const getCurrency = computed(() => {
    return (
        import.meta.env[`VITE_APP_I18N_CURRENCY_${locale.value.toUpperCase()}`] ||
        import.meta.env.VITE_APP_I18N_CURRENCY
    )
})

const setTariff = (data) => {
    state.tempPriceChange.tariffId = data.id
    state.tempPriceChange.price = data.price
    state.tempPriceChange.subscriptionMonthlyPrice = data.subscriptionMonthlyPrice
    state.step = 2
}

const resetTemp = () => {
    state.tempPriceChange.tariffId = null
    state.tempPriceChange.price = null
    state.tempPriceChange.subscriptionMonthlyPrice = null
}

// This exists because of PriceProgramConfirmation
const refetch = () => {
    emit('refetch')
}

const cancel = () => {
    state.isDialogActive = false
    state.step = 1
    state.tempPriceChange.price = null
    state.tempPriceChange.tariffId = null
}

const cancelChange = async () => {
    dispatch('drivers/cancelTariffChange', {
        id: props.driver.id
    }).then((message) => {
        showMessage(message, 'success')
        state.showConfirmationDialog = false
        refetch()
    })
}
</script>

<template>
    <section class="price-program-new">
        <v-row>
            <v-col cols="12" class="pt-0 mb-3">
                <h2 class="home-page-title">
                    {{ t('tableHeaders.Price program') }}
                </h2>
            </v-col>
        </v-row>
        <v-card
            elevation="0"
            class="pa-5 price-program-card"
            :class="[driver.tariff.price_list === null ? 'price-program-special' : '']"
            :data-testid="driver.tariff.Name"
        >
            <v-row v-if="driver.tariff.price_list" class="price-list-box ma-lg-5 ma-2">
                <v-col lg="4" cols="12" class="left-side">
                    <div class="info-wrapper">
                        <h3 class="price-title">
                            {{ driver.tariff.name }}
                        </h3>
                        <div class="price-wrapper pt-2">
                            <span class="price"
                                >{{
                                    driver.tariff.price_list.monthlyPrice
                                        ? formatDecimal(driver.tariff.price_list.monthlyPrice)
                                        : 0
                                }}{{ getCurrency }}</span
                            >
                            / {{ t('driverInfo.monthly') }}
                        </div>
                    </div>
                </v-col>
                <v-col lg="8" cols="12">
                    <v-row class="text-center">
                        <v-col cols="12">
                            {{ t('driverInfo.Charging') }}
                        </v-col>
                    </v-row>
                    <v-row class="price-program-wrapper">
                        <v-col :cols="props.driver.tariff.price_list.DC.length > 1 ? 4 : 6" class="px-0">
                            <MeterWithInfo
                                class="box"
                                :price-list="props.driver.tariff.price_list.AC[0]"
                                :currency="getCurrency"
                                :show-power="props.driver.tariff.price_list.DC.length > 1"
                            />
                        </v-col>
                        <v-col :cols="props.driver.tariff.price_list.DC.length > 1 ? 4 : 6" class="pr-0">
                            <MeterWithInfo
                                class="box"
                                :price-list="props.driver.tariff.price_list.DC[0]"
                                :currency="getCurrency"
                                :show-power="props.driver.tariff.price_list.DC.length > 1"
                            />
                        </v-col>
                        <v-col v-if="props.driver.tariff.price_list.DC.length > 1" cols="4">
                            <MeterWithInfo
                                :price-list="props.driver.tariff.price_list.DC[1]"
                                :currency="getCurrency"
                                :show-power="props.driver.tariff.price_list.DC.length > 1"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-else class="price-list-box ma-5 text-center">
                <v-col cols="12">
                    <span>
                        {{ t('driverInfo.specialPriceApplied') }}
                    </span>
                </v-col>
            </v-row>
            <v-row
                v-if="driver.tariff.price_list && driver.tariff.price_list.extraKeys.DriverDetailTariffDescription"
                class="mx-2"
            >
                <v-col cols="12" class="bg-light-blue-lighten-5 text-light-blue-darken-3">
                    <h4 class="text-center font-weight-regular">
                        {{ t(`tariff.${driver.tariff.price_list.extraKeys.DriverDetailTariffDescription}`) }}
                    </h4>
                </v-col>
            </v-row>

            <v-row v-if="driver.nextTariff">
                <v-col
                    cols="12"
                    sm="6"
                    offset-sm="3"
                    class="d-flex align-items-center justify-center flex-column pt-2 pb-8"
                >
                    <small>Planned price program change</small>
                    <div class="price-change-info">
                        <div class="d-inline">
                            <span class="text-primary font-weight-bold">
                                {{ driver.nextTariff.tariff.name }}
                            </span>
                            <span class="font-weight-bold">
                                from {{ moment(driver.nextTariff.start).format(DATE_FORMAT) }}
                            </span>
                        </div>
                    </div>
                    <v-btn
                        color="primary"
                        class="change-program-button uppercase mt-2"
                        @click="state.showConfirmationDialog = true"
                    >
                        {{ t('driverInfo.cancelChange') }}
                    </v-btn>
	                <div class="pt-5 text-center">
		                <v-divider></v-divider>
		                <small>
			                {{ t('driverInfo.createdBy') }}: {{ driver.nextTariff.created_by}}
		                </small>
		                <br>
		                <small>
			                {{ t('driverInfo.createdAt') }}: {{ moment(driver.nextTariff.created_at).format('DD.MM.YYYY') }}
		                </small>
	                </div>
                </v-col>
            </v-row>
            <v-row v-else-if="driver.tariff.price_list && !isDriver()">
                <v-col cols="12" class="d-flex align-items-center justify-center pt-2 pb-8">
                    <v-btn color="primary" class="change-program-button uppercase" @click="state.isDialogActive = true">
                        {{ t('driverInfo.changePriceProgram') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <Modal :is-active="state.isDialogActive" :max-width="1600" @cancel="state.isDialogActive = false">
            <div class="pa-5 price-program-modal">
                <PricePrograms v-if="state.step === 1" :tariff-id="driver.tariff.id" @setTariff="setTariff" />
                <template v-else-if="state.step === 2">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <Btn class="modal__btn" medium @click=";[(state.step = 1), resetTemp()]">
                                    {{ t('BtnGoBack.Back') }}
                                </Btn>
                            </v-col>
                        </v-row>
                        <PriceProgramConfirmation
                            :temp-price-change="state.tempPriceChange"
                            :driver-id="driver.id"
                            @refetch="refetch"
                            @cancel="cancel"
                        />
                    </v-container>
                </template>
            </div>
        </Modal>

        <!-- confirmation dialog to cancel change tariff -->
        <v-dialog v-model="state.showConfirmationDialog" max-width="400">
            <v-card class="pa-5">
                <div class="text-center">
                    <v-icon color="primary" class="pt-15 pb-5 text-h3"> mdi-message-question </v-icon>
                </div>
                <h2 class="mb-4 pt-4 text-center font-weight-light">
                    {{ t('driverInfo.AreYouSure') }}
                </h2>
                <p class="pb-5 text-center">
                    {{ t('driverInfo.cancelInfo') }}
                </p>
                <v-card-actions class="d-flex justify-center pb-5">
                    <v-btn
                        color="primary"
                        variant="text"
                        data-testid="closeButton"
                        @click="state.showConfirmationDialog = false"
                    >
                        {{ t('BtnGoBack.Back') }}
                    </v-btn>
                    <v-btn
                        class="ml-10"
                        color="primary"
                        variant="flat"
                        large
                        data-testid="cancelButton"
                        @click="cancelChange()"
                    >
                        {{ t('BtnGoBack.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </section>
</template>
