import { ref, reactive, computed, toRefs, onBeforeMount } from 'vue'
import store from '@/store'

const { state, commit, dispatch, getters } = store

const dialog = ref(false)

const globalState = reactive({
    formData: null,
    isPhoneVerified: false,
    isSkipVerificationPhone: false,
    errors: {
        email: '',
        password: '',
        phone: '',
        birth_date: '',
        gender: ''
    }
})

const step = computed({
    get() {
        return getters['account/actionAfterLogin']
    },
    set(value) {
        commit('account/setActionsAfterLogin', value)
    }
})
const steps = computed(() => getters['account/registrationSteps'])
const isLastStep = computed(() => (steps.value.indexOf(step.value) + 1) === steps.value.length)

export default function useRegistration() {

    const goBack = (val) => {
        const index = steps.value.indexOf(val)
        window.scrollTo({ top: 0 })
        if (index > 0) {
            step.value = steps.value[index - 1]
            return
        }
        dialog.value = true
    }

    const nextStep = async () => {
        await dispatch('account/getAccount')
        window.scrollTo({ top: 0 })
    }

    const closeRegistration = async () => {
        window.onbeforeunload = null
        await dispatch('account/logout')
        window.location.href = 'welcome'
    }

    onBeforeMount(() => {
        globalState.formData = { ...state.account }
    })

    return {
        steps,
        step,
        dialog,
        isLastStep,
        ...toRefs(globalState),
        goBack,
        nextStep,
        closeRegistration
    }

}