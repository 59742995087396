import { useI18n } from 'vue-i18n'

/**
 * if there are changes return string with warning message else return null
 * @argument {Object} e event
 * @name beforeWindowUnload
 */

export default function useBeforeWindowUnload() {

    const { t } = useI18n()

    const beforeWindowUnload = (e) => {
        const dialogText = t('error.HasChanges')
        e.returnValue = dialogText
        return dialogText
    }

    return {
        beforeWindowUnload
    }
}