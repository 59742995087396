<script setup>
// composables
import useChargingCards from '@/composables/chargingCards/useChargingCards'

// components
import FilterByToggle from '@/components/inputs/FilterByToggle.vue'

const { isFiltersVisible, tab, tabItems } = useChargingCards()
</script>

<template>
    <div class="px-2 px-sm-8">
        <div class="d-flex justify-space-between align-center">
            <div class="tabs">
                <v-tabs
                    v-model="tab"
                    background-color="transparent"
                >
                    <v-tab
                        v-for="({ title }) in tabItems"
                        :key="title"
                    >
                        {{ title }}
                    </v-tab>
                </v-tabs>
            </div>
            <div class="filter-toggle">
                <FilterByToggle
                    v-if="tab === 0"
                    :active="isFiltersVisible"
                    @change="isFiltersVisible = !isFiltersVisible"
                />
            </div>
        </div>
    </div>
</template>