<script setup>
import {useStore} from 'vuex'

// configs
import { Locale } from '@/configs'

// components
import Metter from '@/components/registrationWizard/Metter.vue'

// utils
import formatDecimal from '@/utils/formatDecimal'

// translations
import {useI18n} from 'vue-i18n'
const { t } = useI18n()

const store = useStore()

const props = defineProps({
    currency: {
        type: String,
        default: import.meta.env.VITE_I18N_CURRENCY
    },
    priceList: {
        type: Object,
        default: null
    },
    isTariffSelected: {
        type: Boolean,
        default: null
    },
    showPower: {
        type: Boolean,
        default: true
    }
})


const getChargingTypeTranslationSuffix = (val) => {
    switch (val) {
    case Locale.pl:
        return Locale.pl.toUpperCase()
    default:
        return ''
    }
}

const getTitle = (power) => {
    switch (power) {
    case 'addDriver.upTo25':
        return t(`addDriver.standard${getChargingTypeTranslationSuffix(store.state.account.locale)}`)
    case 'addDriver.upTo100':
        return t(`addDriver.fast${getChargingTypeTranslationSuffix(store.state.account.locale)}`)
    case 'addDriver.moreThan100':
        return t(`addDriver.ultraFast${getChargingTypeTranslationSuffix(store.state.account.locale)}`)
    }
}

const getFill = (power) => {
    switch (power) {
    case 'addDriver.upTo25':
        return 1
    case 'addDriver.upTo100':
        return 2
    case 'addDriver.moreThan100':
        return 3
    }
}
</script>

<template>
    <div class="meter-with-info d-flex flex-column justify-start align-center">
        <Metter :fill="getFill(props.priceList.power)" :selected="isTariffSelected" />
        <h4 class="text-caption font-weight-bold text-capitalize">
            {{ getTitle(props.priceList.power) }}
        </h4>
        <span v-if="props.showPower"
              class="text-caption text-center"
        >
            {{ t(`${priceList.power}`) }}
        </span>
        <h5 class="text-body-1">
            <strong>{{ formatDecimal(priceList.price) }}</strong>{{ props.currency }}<span class="kwh text-caption">/kWh</span>
        </h5>
    </div>
</template>
