// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        facility: {
            utilizationPercent: null,
            statuses: {
                availableConnectors: null,
                inFaultConnectors: null,
                occupiedConnectors: null,
                outOfCommunicationConnectors: null,
                outOfOperationConnectors: null
            },
            chargingSessions: null,
            chargingTime: null,
            energy: null
        },
        home: {
            utilizationPercent: null,
            statuses: {
                availableConnectors: null,
                inFaultConnectors: null,
                occupiedConnectors: null,
                outOfCommunicationConnectors: null,
                outOfOperationConnectors: null
            },
            chargingSessions: null,
            chargingTime: null,
            energy: null
        },
        all: {
            utilizationPercent: null,
            statuses: {
                availableConnectors: null,
                inFaultConnectors: null,
                occupiedConnectors: null,
                outOfCommunicationConnectors: null,
                outOfOperationConnectors: null
            },
            chargingSessions: null,
            chargingTime: null,
            energy: null
        }
    }
}

export const state = getDefaultState()