<script setup>
import useChargingCards from '@/composables/chargingCards/useChargingCards'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const {
    exportChargingCardsDialogVisible: dialog,
    exportChargingCardsPrompt,
    exportChargingCards,
    selectedCards,
    fetchingData,
    windowWidth
} = useChargingCards()
</script>

<template>
    <div class="text-center export-tokens">
        <v-dialog
            v-model="dialog"
            width="375"
        >
            <template v-slot:activator="{ props }">
                <template v-if="windowWidth > 959">
                    <v-btn
                        :disabled="selectedCards.length === 0"
                        color="primary"
                        elevation="0"
                        variant="outlined"
                        v-bind="props"
                        x-large
                        @click="exportChargingCardsPrompt"
                    >
                        <v-icon left>
                            mdi-download
                        </v-icon>
                        {{ t('chargingCards.exportTokens') }}
                        <div v-if="selectedCards.length === 0" class="tooltip py-1 px-2">
                            <span class="text-caption">{{ t('chargingCards.pleaseSelectChargingCards') }}</span>
                        </div>
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn
                        :disabled="selectedCards.length === 0"
                        :ripple="false"
                        color="primary"
                        icon
                        v-bind="props"
                        @click="exportChargingCardsPrompt"
                    >
                        <v-icon>
                            mdi-download
                        </v-icon>
                    </v-btn>
                </template>
            </template>
            <v-card class="pt-4 px-4 pb-8">
                <v-card-title class="text-h6 text-sm-h5 font-weight-light d-flex justify-center text-center mb-4">
                    {{ t('chargingCards.exportTokens') }}
                </v-card-title>
                <v-card-text class="text-subtitle-1 font-weight-light d-flex flex-column justify-center text-center">
                    {{ t('chargingCards.exportQuestion') }}
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center">
                    <v-btn
                        color="primary"
                        text
                        x-large
                        @click="dialog = false"
                    >
                        {{ t('chargingCards.cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="fetchingData"
                        :loading="fetchingData"
                        class="ml-4 ml-sm-8"
                        color="primary"
                        x-large
                        @click="exportChargingCards"
                    >
                        {{ t('chargingCards.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
