const cloneDeep = (value) => {
    return JSON.parse(JSON.stringify(value))
}

const roundToPrecision = (num, precision) => {
    return +(Math.round(num + `e+${precision}`) + `e-${precision}`)
}

export {
    roundToPrecision,
    cloneDeep
}