<script setup>
import moment from 'moment'
import {useStore} from 'vuex'
import {computed} from 'vue'

// components
import LocationPublicInfoLoader from '@/loaders/LocationPublicInfoLoader.vue'

// utils
import isEmptyObj from '@/utils/isEmptyObj'

// translations
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const store = useStore()

defineProps({
    location: {
        required: true,
        type: Object,
        default: () => {
            return null
        }
    }
})

const isLoading = computed(() => store.state.loader)
const isEmptyObject = (obj) => {
    return isEmptyObj(obj)
}
</script>

<template>
    <v-container v-if="location"
                 :class="isEmptyObject(location) ? 'adjust-padding' : ''"
                 class="location-container location-public-info"
                 fluid
    >
        <LocationPublicInfoLoader v-if="isEmptyObject(location)" :height="140"
                                  class="location__card-body"
        />
        <v-row v-else class="location__card-body pb-5 px-md-4">
            <v-col class="location__card-block" cols="12" md="6">
                <v-row class="location__border-top mt-5">
                    <v-col class="pb-md-1" cols="3" md="12">
                        <h3>{{ t('locationDetails.Address') }}</h3>
                    </v-col>
                    <v-col class="pt-md-0" cols="9" md="12">
                        <div>{{ `${location.street} ${location.house_number}, ${location.city}` }}</div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="location__card-block" cols="12" md="6">
                <v-row class="location__border-md-top mt-md-5">
                    <v-col class="pb-md-1" cols="3" md="12">
                        <h3>{{ t('locationDetails.OpeningHours') }}</h3>
                    </v-col>
                    <v-col class="pt-md-0" cols="9" md="12">
                        <template v-if="location.opening_hours">
                            <ul class="location__opening-hours pa-0">
                                <li v-for="day of location.opening_hours"
                                    :key="day.date"
                                    :class="{'font-weight-medium': day.week_day === 'Sunday' | day.week_day === 'Saturday'}"
                                    class="mb-1"
                                >
                                    <div v-if="day.opening_slots === null" class="d-flex justify-space-between">
                                        <span class="text-capitalize">
                                            {{ day.week_day_localized }} ({{ moment(day.date).format('D.M.YYYY') }})
                                        </span>
                                        <span class="text-capitalize">
                                            {{
                                            (day.is_open) ? t('locationDetails.Open') : t('locationDetails.Closed')
                                            }}
                                        </span>
                                    </div>
                                    <div v-else class="d-flex flex-column">
                                        <span class="text-capitalize">
                                            {{ day.week_day_localized }} ({{ moment(day.date).format('D.M.YYYY') }})
                                        </span>
                                        <ul class="pl-2">
                                            <li v-for="(slot, index) in day.opening_slots" :key="index">
                                                <span>{{ slot.From }} - {{ slot.To }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </template>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="location__card-block border-t mt-4" cols="12">
                <v-row>
                    <v-col class="pb-md-1" cols="3" md="12">
                        <h3>{{ t('locationDetails.Access') }}</h3>
                    </v-col>
                    <v-col class="pt-md-0" cols="9" md="12">
                        <div class="text-gray">
                            {{ location.access_instructions }}
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>