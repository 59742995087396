import store from '@/store'
import { useI18n } from 'vue-i18n'
import { ROLES } from '@/constants/roles'
import { getEnvironment, isFeatureEnabledGitlab } from '@/utils/isFeatureAvailable'

export default function useSidebarMenuLinks() {

    const { commit } = store
    const { t } = useI18n()

    const settingsLink = () => {
        if (store.state.account.roles) {
            const roles = [...store.state.account.roles]
            if (roles) {
                return roles.length === 1 && roles.includes('driver') ? `/drivers/${store.state.account.driver.id}` : '/account'
            }
        }
        return '/account'
    }

    const checkAccess = (scope, action) => {
        const account = store.state.account
        try {
            return !!account.roles?.some(role => ROLES[role][scope] && ROLES[role][scope][action])
        } catch (e) {
            return false
        }
    }

    const checkFlag = (enabledFeatures, flagName) => {
        const email = store.state.account.email
        if (enabledFeatures && Object.keys(enabledFeatures).length) {
            const flag = `${getEnvironment(window.location.origin)}_${flagName}`
            return isFeatureEnabledGitlab(flag, enabledFeatures.features, email) || false
        }
        return false
    }

    const initializeLinks = () => {
        const links = [
            { title: t('sidebar.Dashboard'), id: 'dashboard', link: '/dashboard', icon: 'mdi-view-dashboard', scope: 'dashboard', visible: checkAccess('dashboard', 'read') },
            { title: t('sidebar.Fleet'), id: 'fleet', link: '/fleet-dashboard', icon: 'mdi-view-dashboard-variant', scope: 'fleetDashboard', visible: checkAccess('fleetDashboard', 'read') },
            { title: t('sidebar.News'), id: 'news', link: '/news', icon: 'mdi-newspaper-variant-multiple', scope: 'news', visible: checkAccess('news', 'read') },
            { title: t('sidebar.locationMap'), id: 'location-map', link: '/locationMap', icon: 'mdi-map-marker', scope: 'locationMap', visible: checkAccess('account', 'read') },
            { title: t('sidebar.Sessions'), id: 'sessions', link: '/sessions', icon: 'mdi-lightning-bolt', scope: 'sessions', visible: checkAccess('sessions', 'read') },
            { title: t('sidebar.Invoices'), id: 'invoices', link: '/invoices', icon: 'mdi-currency-eur', scope: 'invoices', visible: checkAccess('invoices', 'read') },
            { title: t('sidebar.Chargers'), id: 'chargers', link: '/charger-management', icon: 'mdi-ev-station', scope: 'chargerManagement', visible: checkAccess('chargerManagement', 'read') },
            { title: t('sidebar.Coupons'), id: 'coupons', link: '/coupons', icon: 'mdi-ticket', scope: 'coupons', visible: checkAccess('coupons', 'read') },
            { title: t('sidebar.chargingCards'), id: 'chargingCards', link: '/charging-cards', icon: 'mdi-credit-card-multiple', scope: 'chargingCards', visible: checkAccess('chargingCards', 'read') },
            { title: t('sidebar.Settings'), id: 'settings', link: settingsLink(), icon: 'mdi-account-settings', scope: 'account', visible: checkAccess('account', 'read') }
            // {title: t('sidebar.Help'), link: '/help', icon: 'mdi-help', scope: 'help', visible: false}, TODO: removed temporary
        ]

        if (store.state.account.drivers_count > 1) {
            links.splice(6, 0, { title: t('sidebar.Drivers'), id: 'drivers', link: '/drivers', icon: 'mdi-account-box', scope: 'driverManagement', visible: checkAccess('driverManagement', 'read') },)
        }

        commit('settings/setFieldValue', {
            field: 'links',
            value: links
        })
    }

    return {
        initializeLinks
    }
}