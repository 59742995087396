import { ref, computed } from 'vue'
import store from '@/store'
import router from '@/router'
import {useRoute} from 'vue-router'

// Utils
import isEmptyObj from '@/utils/isEmptyObj'
import showMessage from '@/utils/errorMessage'
import {getConnectorParamFromQuery} from '@/utils/connectors.js'

// Composables
import useWindowWidth from './windowWidth'
import useFilterableList from '@/composables/filterableList'

// Translations
import { useI18n } from 'vue-i18n'

const { dispatch, getters, state, commit } = store
export default function useConnectors(props) {
    const { t } = useI18n()
    const {
        windowWidth,
        setWindowWidth
    } = useWindowWidth()

    const {
        filtersSelected,
        options,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting
    } = useFilterableList({
        filters: props?.filters || {
            code: [],
            status: [],
            type: [],
            location: {
                name: [],
                code: []
            },
            charger: {
                code: []
            }
        },
        options: {
            sortBy: ['code'],
            sortDesc: true
        },
        action: 'connectors/getConnectors'
    })

    const activePopup = ref('')
    const selected = ref([])

    let activePopupStartCharging = ref(false)
    let activePopupStopCharging = ref(false)
    let selectedConnector = ref(null)

    const actions = ref([
        {
            title: t('chargerManagement.startCharging'),
            action: 'startCharging',
            question: {
                title: t('driverInfo.AreYouSure'),
                type: 'question',
                subtitle: t('chargerManagement.YouWantToStartCharging'),
                icon: 'mdi-help-circle'
            },
            icon: 'mdi-play-circle'
        },
        {
            title: t('chargerManagement.stopCharging'),
            action: 'stopCharging',
            question: {
                title: t('driverInfo.AreYouSure'),
                type: 'question',
                subtitle: t('chargerManagement.YouWantToStopCharging'),
                icon: 'mdi-help-circle'
            },
            icon: 'mdi-stop-circle'
        }
    ])

    const headers = computed(() => {
        return getters['connectors/tableHeaders']
    })

    const primaryDriverId = computed(() => {
        return getters['account/primaryDriverId']
    })

    const isChargingEventTriggered = computed(() => {
        return getters['connectors/isChargingEventTriggered']
    })

    const connectors = computed(() => {
        return getters['connectors/connectors']
    })
    const pagination = computed(() => {
        return state.connectors.pagination
    })
    const isChargeAble = computed(() => {
        return !isEmptyObj(state.account.paymentInfo)
    })
    const countSelectedItems = computed(() => {
        return selected.value.length
    })

    function goToDetail(connector) {
        if (activePopupStartCharging.value || activePopupStopCharging.value) {
            return
        }
        router.push(`/charger/${connector.charger.id}`)
    }

    /**
     * Check state of status and return if its available or not
     * @param item
     * @returns {*[]|UnwrapRefSimple<{question: {subtitle, icon: string, title, type: string}, icon: string, action: string, title}>[]}
     */
    function getActions(item) {
        switch (item.etrel_status) {
            // Start charging
            case 'ConnectorStatus_Available':
            case 'ConnectorStatus_Preparing':
                return [actions.value[0]]
            // Stop charging
            case 'ConnectorStatus_Occupied':
                return [actions.value[1]]
        }
        return []
    }

    function handleActionConnectors(item) {
        const route = useRoute()
        const {param, value} = getConnectorParamFromQuery(route)
        param === 'id' ? router.push({ name: 'charging', query: { id: value } }) : router.push({ name: 'charging', query: { code: value } })
    }

    function handleAction(item) {
        selectedConnector.value = item.id
        switch (item.etrel_status) {
            // Start charging
            case 'ConnectorStatus_Available':
            case 'ConnectorStatus_Preparing':
                activePopupStartCharging.value = true
                break
            // Stop charging
            case 'ConnectorStatus_Occupied':
                activePopupStopCharging.value = true
                break
        }
        return false
    }

    function handleBatchAction(action) {
        activePopup.value = action
    }

    async function batchAction(actionTitle) {
        try {
            const ids = selected.map((item) => item.id)
            await action(actionTitle, ids)
        } catch (e) {
            showMessage(e)
        }
    }

    async function action(action, id) {
        switch (action) {
            case 'startCharging':
                await startCharging(id)
                break
            case 'stopCharging':
                await stopCharging(id)
                break
        }
    }

    async function startCharging(id) {
        try {
            commit('connectors/setIsChargingEventTriggered', true)
            await dispatch('connectors/startCharging', { driver_id: state.account.primaryDriverId, connector_id: id })
            await dispatch('connectors/getConnectors', filtersSelected.value)
            showMessage(t('chargerManagement.ConnectorBeenStarted'), 'success')
        } catch (e) {
            commit('connectors/setIsChargingEventTriggered', false)
            showMessage(e.response.data.msg)
        }
    }

    async function stopCharging(id) {
        try {
            commit('connectors/setIsChargingEventTriggered', true)
            await dispatch('connectors/stopCharging', { connector_id: id })
            await dispatch('connectors/getConnectors', filtersSelected.value)
            showMessage(t('chargerManagement.ConnectorBeenStopped'), 'success')
        } catch (e) {
            commit('connectors/setIsChargingEventTriggered', false)
            showMessage(e.response.data.msg)
        }
    }

    function selectedItems(items) {
        selected.value = items
    }

    function cancel() {
        activePopupStartCharging.value = false
        activePopupStopCharging.value = false
        selectedConnector.value = null
    }

    return {
        activePopup,
        windowWidth,
        selected,
        filtersSelected,
        options,
        actions,
        headers,
        connectors,
        pagination,
        isChargeAble,
        countSelectedItems,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting,
        goToDetail,
        getActions,
        handleActionConnectors,
        handleAction,
        handleBatchAction,
        batchAction,
        action,
        selectedItems,
        setWindowWidth,
        startCharging,
        activePopupStartCharging,
        activePopupStopCharging,
        selectedConnector,
        cancel,
        primaryDriverId,
        isChargingEventTriggered
    }
}