import { ref, reactive, computed, toRefs, onBeforeMount } from 'vue'
import store from '@/store'

// utils
import { apiRequest } from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessageArray'
import { getFullFileName } from '@/utils/getFileName'
import { getEnvironment } from '@/utils/isFeatureAvailable'
import { specificFileDownload } from '@/utils/fileDownload'

// composables
import useWindowWidth from '@/composables/windowWidth'
import useChargingCardsActions from '@/composables/chargingCards/useChargingCardsActions'

// translations
import { useI18n } from 'vue-i18n'

const { getters, dispatch } = store

const globalState = reactive({
    exportChargingCardsDialogVisible: false,
    orderChargingCardDialogVisible: false,
    registerGWCardDialogVisible: false,
    actionPromptVisible: false,
    selectedAction: '',
    selectedItem: null,
    addNewCardModel: '',
    driverDetailId: null,
    driverDetailCard: null,
    selectedCards: [],
    isFiltersVisible: false,
    fetchedDrivers: null, // TODO: Remove this when RFID data will be available on client/drivers endpoint
    availableDrivers: [],
    chargingCardsListItems: [],
    chargingCardsOrdersList: [],
    chargingCardsOrdersLimit: 999,
    chargingCardsOrdersRemaining: null,
    primaryDriverActiveChargingCard: [],
    tab: null,
    fetchingData: false,
    loadingDriversResults: false,
    cardDetail: null,
    detailVisible: false,
    RFIDProductId: null,
    tabItems: []
})

export default function useChargingCards() {
    const {
        assignAction,
        unassignAction,
        blockAction,
        unblockAction,
        deleteAction,
        assignCard,
        unassignCard,
        blockCard,
        unblockCard,
        addNewCard,
        blockDriverCard,
        unblockDriverCard,
        removeCards,
        triggerSnackBar
    } = useChargingCardsActions()

    const PRIMARY_DRIVER_ID = ref(getters['account/primaryDriverId'])
    const PRIMARY_DRIVER_NAME = ref(getters['account/fullName'])

    const { t } = useI18n()
    const { windowWidth } = useWindowWidth()

    globalState.tabItems = [
        {
            content: 'ListOfChargingCards',
            title: t('chargingCards.listOfChargingCards')
        },
        {
            content: 'ListOfOrders',
            title: t('chargingCards.listOfOrders')
        }
    ]

    const selectedDriver = ref(null)
    const numberOfChargingCardsToOrder = ref(null)
    const driversWithCards = ref([]) // TODO: Remove this when RFID data will be available on client/drivers endpoint
    const assignableDrivers = ref([]) // TODO: Remove this when RFID data will be available on client/drivers endpoint
    const registerGWCardRows = ref([{
        rfid_code: '',
        driver_id: null
    }])

    const quantityAsNumber = computed(() => {
        return numberOfChargingCardsToOrder.value ? parseInt(numberOfChargingCardsToOrder.value) : null
    })

    const init = () => {
        onBeforeMount(async () => {
            globalState.fetchingData = true
            fetchChargingCardsList()
            await getRFIDProductId()
            fetchChargingCardsOrdersList()
            globalState.fetchingData = false
        })
    }

    const fetchChargingCardsList = async () => {
        try {
            const { data } = await apiRequest('client/drivers/rfid/list')
            setPrimaryDriverActiveChargingCard(data)
            setActions(data)
        } catch (error) {
            showMessage(error.errors)
        }
    }

    const getRFIDProductId = async () => {
        try {
            const { data } = await apiRequest(`eshop/products?driver_id=${PRIMARY_DRIVER_ID.value}`)
            globalState.RFIDProductId = data.filter(x => x.code === 'RFID').map(x => x.id)[0]
            globalState.chargingCardsOrdersLimit = data[0].driver_limit_quantity
        } catch (error) {
            showMessage(error.errors)
        }
    }

    const getRemainingOrders = () => {
        let remaining = null
        globalState.chargingCardsOrdersList.forEach(item => {
            remaining += item.items[0].quantity
        })
        return globalState.chargingCardsOrdersLimit - remaining
    }

    const fetchChargingCardsOrdersList = async () => {
        globalState.fetchingData = true
        try {
            const { data } = await apiRequest(`client/orders?product_id=${globalState.RFIDProductId}`)
            globalState.chargingCardsOrdersList = data
            globalState.chargingCardsOrdersRemaining = getRemainingOrders()
        } catch (error) {
            showMessage(error.errors)
        } finally {
            globalState.fetchingData = false
        }
    }

    const orderChargingCards = async () => {
        globalState.fetchingData = true
        try {
            const response = await apiRequest.post('eshop/order', { driver_id: PRIMARY_DRIVER_ID.value, items: [{ product_id: globalState.RFIDProductId, quantity: quantityAsNumber.value }] })
            triggerSnackBar(response.data.message)
            await fetchChargingCardsOrdersList()
        } catch (error) {
            showMessage(error.errors)
        } finally {
            globalState.orderChargingCardDialogVisible = false
            numberOfChargingCardsToOrder.value = null
            globalState.fetchingData = false
        }
    }

    const orderChargingCardsDriverDetail = async () => {
        globalState.fetchingData = true
        try {
            await getRFIDProductId()
            await apiRequest.post('eshop/order', { driver_id: PRIMARY_DRIVER_ID.value, items: [{ product_id: globalState.RFIDProductId, quantity: quantityAsNumber.value }] })
        } catch (error) {
            showMessage(error.errors)
        } finally {
            globalState.actionPromptVisible = false
            numberOfChargingCardsToOrder.value = null
            globalState.fetchingData = false
        }
    }

    const registerGWCards = async () => {
        globalState.fetchingData = true
        try {
            const response = await apiRequest.post('client/drivers/rfid/register', cleanEmptyStrings(registerGWCardRows.value))
            triggerSnackBar(response.data.message)
            await fetchChargingCardsList()
            setAvailableDrivers()
        } catch (error) {
            showMessage(error.errors)
        } finally {
            globalState.registerGWCardDialogVisible = false
            globalState.fetchingData = false
        }
    }

    const getDrivers = async (email) => {
        try {
            globalState.loadingDriversResults = true
            const { data } = await apiRequest(`client/drivers?filters[first_name]=${email}`)
            globalState.availableDrivers = []
            globalState.fetchedDrivers = data // TODO: Remove this when RFID data will be available on client/drivers endpoint
            setAvailableDrivers() // TODO: Remove this when RFID data will be available on client/drivers endpoint
        } catch (error) {
            showMessage(error.errors)
        } finally {
            globalState.loadingDriversResults = false
        }
    }

    // TODO: Remove this when RFID data will be available on client/drivers endpoint
    const setAvailableDrivers = () => {
        globalState.availableDrivers = []
        globalState.fetchedDrivers.forEach(item => {
            globalState.availableDrivers.push({
                value: item.id,
                title: item.first_name
            })
        })
    }

    const setPrimaryDriverActiveChargingCard = (data) => {
        globalState.primaryDriverActiveChargingCard = data.filter((item) => {
            if (item.assignee_id === PRIMARY_DRIVER_ID.value && item.is_active) {
                return true
            }
            return false
        })
    }

    const setActions = (data) => {
        for (let i = 0; i < data.length; i++) {
            data[i].actions = []
            if (data[i].assignee_id === PRIMARY_DRIVER_ID.value) {
                if (data[i].is_active === false) {
                    data[i].actions.push(assignAction.value)
                    data[i].actions.push(unblockAction.value)
                } else {
                    data[i].actions.push(unassignAction.value)
                    data[i].actions.push(blockAction.value)
                }
            } else {
                data[i].actions.push(unassignAction.value)
                if (data[i].is_active === false) {
                    data[i].actions.push(unblockAction.value)
                } else {
                    data[i].actions.push(blockAction.value)
                }
            }
            data[i].actions.push(deleteAction.value)
        }
        globalState.chargingCardsListItems = data
    }

    const addNewRow = () => {
        if (registerGWCardRows.value.length < 10) {
            registerGWCardRows.value.push(
                {
                    rfid_code: '',
                    driver_id: null
                }
            )
        }
    }

    const removeRow = (index) => {
        registerGWCardRows.value.splice(index, 1)
    }

    const showDetail = (row) => {
        globalState.detailVisible = true
        globalState.cardDetail = row
    }

    const closeDetail = () => {
        globalState.cardDetail = null
        globalState.detailVisible = false
    }

    const cleanEmptyStrings = (arr) => {
        arr.forEach(item => {
            for (let propName in item) {
                if (item[propName] === '') {
                    delete item[propName]
                }
            }
        })
        return arr
    }

    const exportChargingCardsPrompt = () => {
        globalState.exportChargingCardsDialogVisible = true
    }

    const downloadFileNow = (response) => {
        const fileName = getFullFileName(response)
        specificFileDownload(response.data, fileName)
    }

    const exportChargingCards = async () => {
        globalState.fetchingData = false
        const authTagsToExport = globalState.selectedCards.map(x => x.auth_tag_id)
        try {
            const response = await apiRequest.post('client/drivers/rfid/export', {
                auth_tags: authTagsToExport,
                export_type: 'csv'
            })
            downloadFileNow(response)
        } catch (error) {
            showMessage(error.errors)
        } finally {
            globalState.fetchingData = false
            globalState.exportChargingCardsDialogVisible = false
        }
    }

    const showActionPrompt = (action, item) => {
        globalState.selectedAction = action
        globalState.selectedItem = item
        globalState.actionPromptVisible = true
    }

    const resetValues = () => {
        globalState.selectedAction = ''
        globalState.selectedItem = null
        globalState.selectedCards = []
        globalState.fetchingData = false
        globalState.addNewCard = ''
    }

    const triggerAction = async (action, item) => {
        switch (action) {
            case 'assignCard':
                globalState.fetchingData = true
                if(selectedDriver.value === PRIMARY_DRIVER_ID.value) {
                    await unblockCard(item)
                } else {
                    await assignCard(item, selectedDriver.value)    
                }
                globalState.actionPromptVisible = false
                await fetchChargingCardsList()
                if (!globalState.fetchedDrivers) await getDrivers()
                resetValues()
                setAvailableDrivers() // TODO: Remove this when RFID data will be available on client/drivers endpoint
                break
            case 'unassignCard':
                globalState.fetchingData = true
                await unassignCard(item)
                globalState.actionPromptVisible = false
                await fetchChargingCardsList()
                if (!globalState.fetchedDrivers) await getDrivers()
                resetValues()
                setAvailableDrivers() // TODO: Remove this when RFID data will be available on client/drivers endpoint
                break
            case 'blockCard':
                globalState.fetchingData = true
                await blockCard(item)
                globalState.actionPromptVisible = false
                await fetchChargingCardsList()
                if (!globalState.fetchedDrivers) await getDrivers()
                resetValues()
                setAvailableDrivers() // TODO: Remove this when RFID data will be available on client/drivers endpoint
                break
            case 'unblockCard':
                globalState.fetchingData = true
                await unblockCard(item)
                globalState.actionPromptVisible = false
                await fetchChargingCardsList()
                if (!globalState.fetchedDrivers) await getDrivers()
                resetValues()
                setAvailableDrivers() // TODO: Remove this when RFID data will be available on client/drivers endpoint
                break
            case 'removeCard':
                globalState.fetchingData = true
                await removeCards(item)
                globalState.actionPromptVisible = false
                await fetchChargingCardsList()
                if (!globalState.fetchedDrivers) await getDrivers()
                resetValues()
                setAvailableDrivers() // TODO: Remove this when RFID data will be available on client/drivers endpoint
                break
            case 'removeDriverCard':
                globalState.fetchingData = true
                await removeCards(item)
                globalState.actionPromptVisible = false
                await dispatch('drivers/getDriver', globalState.driverDetailId)
                resetValues()
                break
            case 'removeCards':
                globalState.fetchingData = true
                await removeCards(item)
                globalState.actionPromptVisible = false
                await fetchChargingCardsList()
                resetValues()
                break
            case 'addNewCard':
                globalState.fetchingData = true
                await addNewCard(item, globalState.driverDetailId)
                globalState.actionPromptVisible = false
                await dispatch('drivers/getDriver', globalState.driverDetailId)
                resetValues()
                break
            case 'blockDriverCard':
                globalState.fetchingData = true
                await blockDriverCard(item, globalState.driverDetailId)
                globalState.actionPromptVisible = false
                await dispatch('drivers/getDriver', globalState.driverDetailId)
                resetValues()
                break
            case 'unblockDriverCard':
                globalState.fetchingData = true
                await unblockDriverCard(item, globalState.driverDetailId)
                globalState.actionPromptVisible = false
                await dispatch('drivers/getDriver', globalState.driverDetailId)
                resetValues()
                break
        }
    }

    return {
        ...toRefs(globalState),
        PRIMARY_DRIVER_ID,
        numberOfChargingCardsToOrder,
        registerGWCardRows,
        selectedDriver,
        windowWidth,
        init,
        exportChargingCardsPrompt,
        exportChargingCards,
        addNewRow,
        removeRow,
        orderChargingCards,
        orderChargingCardsDriverDetail,
        getDrivers,
        registerGWCards,
        showDetail,
        closeDetail,
        triggerAction,
        showActionPrompt
    }
}