<script setup>
// composables
import usePriceProgram from '@/composables/registration/usePriceProgram'

// components
import Tariffs from '@/components/registrationWizard/Tariffs.vue'

// utils
import { useI18n } from 'vue-i18n'
import formatDecimal from '@/utils/formatDecimal'
import markdownParser from '@/utils/markdownParser'

const { t } = useI18n()

const { locale, tariffs, getCurrency } = usePriceProgram()
</script>

<template>
    <div class="price-program">
        <div class="heading text-center mb-6">
            <h1 class="text-h5 font-weight-bold mb-2">
                {{ t('registrationWizard.PricePlan') }}
            </h1>
            <span class="text-body-2">{{ t('registrationWizard.CheckPlanForYou') }}</span>
        </div>
        <main>
            <Tariffs />
            <v-row v-if="tariffs.length" no-gutters>
                <v-col>
                    <div class="info-panel text-blue bg-light-blue-lighten-4 mx-auto pa-4">
                        <div class="information mb-4">
                            <div class="d-flex align-center mb-2">
                                <v-icon class="text-blue align-start">
                                    mdi-information-outline
                                </v-icon>
                                <h4 class="text-subtitle-2 ml-1">
                                    {{ t('registrationWizard.overstayFeeTitle') }}
                                </h4>
                            </div>
                            <div class="text-caption ma-0 pl-3" v-html="markdownParser(t(`priceProgram.overstayFeeContent`))"></div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </main>
    </div>
</template>