<script setup>
import { ref, reactive, computed, onBeforeMount, onMounted, nextTick } from 'vue'
import store from '@/store'

// Cookies
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

// configs
import { Lang } from '@/configs/index.js'

// composables
import useLocale from '@/composables/locale'

// components
import Btn from '@/components/inputs/Btn.vue'
import LangSwitcher from '@/components/inputs/LangSwitcher.vue'
import LoginRegistration from '@/components/LoginRegistration.vue'

// utils
import showMessage from '@/utils/errorMessage'
import markdownParser from '@/utils/markdownParser'
import { useI18n } from 'vue-i18n'

// validations
import useValidations from '@/composables/useValidations'
const {
    isNotEmpty,
    isValidEmail,
    isValidPassword,
    isValidPhone,
    isLongPassword
} = useValidations()

const { t } = useI18n()
const { dispatch, getters, commit } = store
const { locale, lang } = useLocale()

const state = reactive({
    isFormValid: true,
    emailModel: '',
    passwordModel: '',
    emailError: false,
    passwordError: false,
    emailFocus: false,
    passwordFocus: false,
    consents: [],
    isTermsAccepted: false,
    formErrorMessage: '',
    isPasswordVisible: false,
    isNotEmpty,
    isValidEmail,
    isValidPassword,
    isValidPhone,
    isLongPassword,
    errors: {
        email: '',
        password: ''
    },
    links: {
        priceList: t('footer.VITE_APP_PRICE_LIST_LINK'),
        terms: t('footer.VITE_APP_TERMS_LINK'),
        dataProtection: import.meta.env.VITE_APP_DATA_PROTECTION_LINK,
        disputeResolution: import.meta.env.VITE_APP_DISPUTE_RESOLUTION_LINK
    },
    recaptchaToken: null
})
const email = ref()
const password = ref()
const form = ref()

const language = computed(() => {
    return store.state.account.lang || import.meta.env.VITE_APP_I18N_LOCALE || Lang.en
})

const siteKey = computed(() => {
    return store.state.settings.recaptchaSiteKey
})

const checkRecaptchaToken = computed(() => {
    if (siteKey.value === null) {
        return false
    }
    return !state.recaptchaToken
})

const link = computed(() => {
    return import.meta.env[`VITE_APP_ONE_TIME_CHARGING_LINK_${locale.value.toUpperCase()}`] + `/${lang.value}`
})

const register = async () => {
    try {
        await dispatch('account/register', {
            email: state.emailModel,
            password: state.passwordModel,
            'g-recaptcha-response': state.recaptchaToken,
            consents: state.consents.filter(consent => consent.checked === true || !consent.visible).map(consent => consent.id)
        })
        window.location.href = '/registration-wizard'
    } catch (errors) {
        if (typeof errors === 'string') {
            showMessage(errors)
        } else {
            for (const [key, value] of Object.entries(errors)) {
                switch (key) {
                    case 'email':
                        state.errors.email = value[0]
                        break
                }
            }
        }
    }
}

const getRecaptchaResponse = async () => {
    const { valid } = await form.value.validate()
    state.isFormValid = valid

    if (!state.isFormValid || !siteKey.value || !state.isTermsAccepted) return
    grecaptcha.enterprise.ready(async () => {
        state.recaptchaToken = await grecaptcha.enterprise.execute(siteKey.value, { action: 'signup' })
        register()
    })
}

const createObservers = () => {
    new MutationObserver(mutations => {
        for (const m of mutations) {
            nextTick(() => {
                onEmailClassChange(m)
            })
        }
    }).observe(email.value.$el, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class']
    })

    new MutationObserver(mutations => {
        for (const m of mutations) {
            nextTick(() => {
                onPasswordClassChange(m)
            })
        }
    }).observe(password.value.$el, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class']
    })
}
const onEmailClassChange = (element) => {
    state.emailError = element.target.classList.contains('error--text')
    state.emailFocus = element.target.classList.contains('v-input--is-focused')
}
const onPasswordClassChange = (element) => {
    state.passwordError = element.target.classList.contains('error--text')
    state.passwordFocus = element.target.classList.contains('v-input--is-focused')
}

onBeforeMount(() => {
    if (getters['account/isAuth']) {
        let link = !store.state.account.actionAfterLogin ? (cookies.get('redirect_after_login') ?? 'news') : 'registration-wizard'
        if (cookies.isKey('redirect_after_login')) {
            cookies.remove('redirect_after_login')
        }
        if (getters['oauth/isOAuth']) {
            link = 'oauth'
        }
        window.location.href = link
    }
})
onMounted(async () => {
    // recaptcha
    const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
    if (recaptchaSiteKey) {
        commit('settings/setRecaptchaSiteKey', { recaptchaSiteKey })
    }
    dispatch('consents/getConsents', {
        registration_step: null
    }).then(consents => {
        state.consents = consents
    })
    createObservers()
})
</script>

<template>
    <LoginRegistration class="login-registration-wrapper">
        <template v-slot:header>
            <lang-switcher />
            <Btn
                text
                :href="link"
                :variant="'text'"
                data-testid="oneTimeCharging"
            >
                {{ t('logIn.OneTimeCharging') }}
            </Btn>
        </template>
        <template v-slot:main>
            <v-form
                ref="form"
                v-model="state.isFormValid"
                class="form"
                align="center"
            >
                <h1 class="text-h4 font-weight-thin text-center mb-4">
                    {{ t('registration.Title') }}
                </h1>
                <p class="mb-8 text-center">
                    {{ t('registration.AlreadyHaveAnAccount?') }}
                    <router-link
                        class="login-link"
                        to="/login"
                    >
                        {{ t('registration.LogIn') }}
                    </router-link>
                </p>
                <div class="d-flex">
                    <v-container fluid>
                        <v-row>
                            <v-col
                                class="py-0 d-flex flex-column"
                                md="12"
                                cols="12"
                            >
                                <label
                                    :class="state.emailError ? 'form__label-error' : state.emailFocus ? 'form__label-active' : 'form__label'"
                                >
                                    <small data-testid="loginLabel">{{ t('logIn.Email') + '*' }}</small>
                                </label>
                                <v-text-field
                                    ref="email"
                                    v-model="state.emailModel"
                                    type="email"
                                    variant="outlined"
                                    data-testid="registerEmail"
                                    :rules="[isNotEmpty, isValidEmail]"
                                    :error-messages="state.errors.email"
                                    @click="state.errors.email = ''"
                                />
                            </v-col>
                            <v-col
                                class="py-0 d-flex flex-column"
                                md="12"
                            >
                                <label
                                    :class="state.passwordError ? 'form__label-error' : state.passwordFocus ? 'form__label-active' : 'form__label'"
                                >
                                    <small data-testid="loginPassword">{{ t('logIn.Password') + '*' }}</small>
                                </label>
                                <v-text-field
                                    ref="password"
                                    v-model="state.passwordModel"
                                    height="35px"
                                    variant="outlined"
                                    data-testid="registerPassword"
                                    :rules="[isNotEmpty, isValidPassword, isLongPassword]"
                                    :type="state.isPasswordVisible ? 'text' : 'password'"
                                >
                                    <template v-slot:append-inner>
                                        <v-icon
                                            :icon="state.isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                                            @click="state.isPasswordVisible = !state.isPasswordVisible"
                                        />
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <div class="form__footer">
                    <div
                        v-for="consent in state.consents"
                        :key="consent.id"
                    >
                        <v-checkbox
                            v-if="consent.visible"
                            v-model="consent.checked"
                            :rules="[consent.checked]"
                            data-testid="iAgree"
                            @update:modelValue="state.isTermsAccepted = consent.checked"
                        >
                            <template v-slot:label>
                                <div class="required_mark">
                                    *
                                </div>
                                <div
                                    class="mt-2"
                                    @click.stop
                                    v-html="markdownParser(consent.title)"
                                />
                            </template>
                        </v-checkbox>
                    </div>
                </div>
                <Btn
                    :disabled="!state.isFormValid || !state.isTermsAccepted"
                    class="buttons__btn form__submit mt-5 w-100"
                    :loading="getters['isLoading']"
                    data-testid="registerSubmit"
                    @click="getRecaptchaResponse"
                >
                    {{ t('registration.Title') }}
                </Btn>
            </v-form>
            <div class="required_fields">
                <span>{{ t('registration.RequiredFields') }}</span>
            </div>
            <div class="payment-methods">
                <div class="payment-methods__header">
                    <div class="payment-methods__title">
                        {{ t('registration.PaymentMethods') }}:
                    </div>
                </div>
                <div class="payment-methods__list">
                    <div class="payment-methods__list-item">
                        <img
                            src="/assets/icons/visa.svg"
                            class="visa"
                            alt="visa"
                        />
                    </div>
                    <div class="payment-methods__list-item">
                        <img
                            src="/assets/icons/masterCard.svg"
                            class="mastercard"
                            alt="mastercard"
                        />
                    </div>
                    <div class="payment-methods__list-item">
                        <img
                            src="/assets/icons/amex.svg"
                            class="amex"
                            alt="amex"
                        />
                    </div>
                    <div class="payment-methods__list-item">
                        <img
                            src="/assets/icons/maestro.svg"
                            class="maestro"
                            alt="maestro"
                        />
                    </div>
                </div>
            </div>
        </template>
    </LoginRegistration>
</template>
