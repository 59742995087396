// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        news: [],
        notifications: [],
        search: '',
        isLoadMore: false,
        page: 1
    }
}

export const state = getDefaultState()