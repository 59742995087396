<script setup>
import { ref, computed, watch } from 'vue'
import store from '@/store'

// Utils
import { useI18n } from 'vue-i18n'
import markdownParser from '@/utils/markdownParser'

// composables
import usePayout from '@/composables/usePayout'

// Components
import InfoMessage from '@/components/InfoMessage.vue'

// Cookies
import { useCookies } from 'vue3-cookies'

const PENDING_CHECKOUT_INTERVAL = import.meta.env.VITE_APP_PENDING_CHECKOUT_INTERVAL

const { t } = useI18n()
const { commit, dispatch, getters } = store
const { cookies } = useCookies()
const { checkStatus, addPaymentCard, checkoutStatus } = usePayout()

const interval = ref(null)

const dialog = computed({
    get() {
        return store.state.account.showExtraPaymentModal
    },
    set(val) {
        cookies.set('skippedExtraChargingCardDialog', true, 60 * 60 * 2, '/', import.meta.env.VITE_APP_COOKIES_DOMAIN)
        commit('account/setShowExtraPaymentModal', val)
    }
})

watch(checkoutStatus, (val) => {
    if (val === 'pending') {
        interval.value = setInterval(() => {
            checkStatus()
            if (getters['account/paymentInfo'].provider) clearInterval(interval.value)
        }, PENDING_CHECKOUT_INTERVAL)
        return
    }
    clearInterval(interval.value)
    dispatch('account/getPaymentInfo')
})
</script>

<template>
    <v-dialog v-model="dialog" width="auto" persistent class="extra-payment-details">
        <v-card max-width="800" :text="t('account.addCardToUseChargingServices')" class="text-center" elevation="16">
            <template v-slot:title>
                <span class="font-weight-black">{{ t('account.actionsNeeded') }}</span>
            </template>
            <v-card-text class="pt-4">
                <v-btn
                    color="primary"
                    class="d-flex mx-auto"
                    elevation="2"
                    data-testid="addPayoutCard"
                    @click="addPaymentCard"
                >
                    <v-icon class="mr-1"> mdi-credit-card </v-icon>
                    {{ t('account.AddPaymentCard') }}
                </v-btn>
                <h4 v-if="checkoutStatus === 'pending'" class="text-center pt-4">
                    {{ t('account.pendingCheckoutMessage') }}
                </h4>
                <InfoMessage class="mt-6 mb-4 text-left">
                    <div class="info-panel blue lighten-4 blue--text" data-testid="paypalDisclaimer">
                        <div class="text-caption">
                            <div v-html="markdownParser(t('managePayment.providingCardDetailsPayout'))" />
                        </div>
                    </div>
                </InfoMessage>
            </v-card-text>
            <template v-slot:actions>
                <v-btn class="ms-auto" :text="t('account.skipForNow')" @click="dialog = false"></v-btn>
            </template>
        </v-card>
    </v-dialog>
</template>
