<script setup>
import { reactive, computed, toRef, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import store from '@/store'

// composables
import useRegistration from '@/composables/registration/useRegistration'
import usePriceProgram from '@/composables/registration/usePriceProgram'

// Components
import Footer from '@/components/Footer.vue'
import RegistrationHeader from './components/RegistrationHeader.vue'
import CloseRegistrationDialog from './components/CloseRegistrationDialog.vue'
import Notify from '@/components/Notify.vue'

import ContactInfo from './components/ContactInfo.vue'
import Confirmation from './components/Confirmation.vue'
import PriceProgram from './components/PriceProgram.vue'
import PaymentMethod from './components/PaymentMethod.vue'
import PhoneVerification from './components/PhoneVerification.vue'

// utils
import { useI18n } from 'vue-i18n'
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'
import { RegistrationView } from '@/utils/registrationView'

const { t } = useI18n()
const { step, goBack, isLastStep } = useRegistration()
const { getTariffs, selectedTariff, userTariffData, tariffs } = usePriceProgram()
const { commit, dispatch } = store
const route = useRoute()
const router = useRouter()

const components = {
    ContactInfo,
    Confirmation,
    PriceProgram,
    PaymentMethod,
    PhoneVerification
}

const component = computed(() => components[step.value])

onBeforeMount(async () => {
    commit('account/setLocale', store.state.account.locale ? store.state.account.locale : import.meta.env.VITE_APP_I18N_LOCALE)
    await dispatch('filters/getTitles')
    await dispatch('filters/getCountries')
    await getTariffs()
    await dispatch('account/getAccount').then(async response => {
        if (response.action_after_login) {
            commit('account/setActionsAfterLogin', RegistrationView.showComponent(response.action_after_login))
        } else {
            router.push('/news')
            return
        }
        if (response.registration_steps.length) {
            const registration_steps = []
            response.registration_steps.forEach(item => {
                registration_steps.push(RegistrationView.showComponent(item))
            })
            commit('account/setRegistrationSteps', registration_steps)
        }
        if (response.registration_data) {
            userTariffData.value = response.registration_data
            selectedTariff.value = tariffs.value.find(x => x.id === userTariffData.value.tariff_id)
        }
    })
    if (route.query.emailtoken) {
        apiRequest.post('email/verify', { code: route.query.emailtoken }).then(() => {
            showMessage(t('logIn.EmailVerified'), 'success')
        }).catch(error => {
            showMessage(error)
        })
    }
})
</script>

<template>
    <div class="registration-wizard">
        <RegistrationHeader @back="goBack(step)" />
        <CloseRegistrationDialog />
        <component
            :is="component"
            class="wizard__view pa-4 pa-sm-16"
        />
        <Footer />
    </div>
</template>