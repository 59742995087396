const PaymentStatus = Object.freeze({
    Unpaid: 'unpaid',
    PartialyPaid: 'partialy_paid',
    isUnpaid: (status) => {
        return PaymentStatus.Unpaid === status
    },
    isPartialyPaid: (status) => {
        return PaymentStatus.PartialyPaid === status
    }
})

export {
    PaymentStatus
}