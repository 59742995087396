import { ref, computed, onMounted } from 'vue'
import store from '@/store'

// utils
import { useI18n } from 'vue-i18n'
import formatDate from '@/utils/formatDate'
import isEmptyObj from '@/utils/isEmptyObj'
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'
import showMessageArray from '@/utils/errorMessageArray'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'

// composables
import useRegistration from '@/composables/registration/useRegistration'

// configs
import { Client } from '@/configs/index.js'

const { dispatch, getters, commit } = store

export default function useContactInfo() {
    const { t } = useI18n()
    const { formData, isPhoneVerified, isSkipVerificationPhone, errors, step } = useRegistration()
    const form = ref()
    const vehicle = ref({
        id: null,
        producer: null,
        producer_code: null,
        model: null,
        model_code: null,
        version: null,
        version_code: null,
        registration: null,
        production: null,
        plate_number: null,
        note: null
    })
    const requiredVatValidation = ref(false)

    const personalTitle = computed(() => {
        return formData.value.isCompany ? 'AuthorizedPerson' : 'PersonalInformation'
    })
    const roles = computed(() => {
        return store.state.account.roles
    })

    const setRequiredVatValidation = (val) => {
        requiredVatValidation.value = val
    }
    const setFormField = ({ field, value }) => {
        formData.value[field] = value
    }
    const setIsPhoneVerified = () => {
        isPhoneVerified.value = true
        errors.value.phone = ''
    }
    const unsetIsPhoneVerified = () => {
        isPhoneVerified.value = false
    }
    const checkIsPhoneVerified = () => {
        if (roles.value.includes('payer')) {
            return apiRequest('client/phone-verified')
                .then((response) => {
                    isPhoneVerified.value = response.data
                    isSkipVerificationPhone.value = false
                })
                .catch((error) => {
                    typeof error.msg !== 'undefined' ? showMessage(error.msg) : showMessage(error.message)
                })
        }
    }
    const skipVerificationPhone = () => {
        isSkipVerificationPhone.value = true
    }
    const setCarFields = (data) => {
        for (const [key, value] of Object.entries(data)) {
            vehicle.value[key] = value
        }
    }
    const dateFormat = (date) => {
        if (!date) return null
        return formatDate(date, 'MM/DD/YYYY')
    }

    const resolveVehicle = async () => {
        if (store.state.drivers.driver.vehicle?.id) {
            dispatch('drivers/putVehicleDetails', {
                car: { ...vehicle.value, type_registration: true },
                id: store.state.account.primaryDriverId
            }).catch((error) => {
                showMessage(error)
            })
        } else {
            dispatch('drivers/addVehicle', {
                car: { ...vehicle.value, type_registration: true },
                id: store.state.account.primaryDriverId
            }).catch((error) => {
                showMessage(error)
            })
        }
    }

    const prepareData = () => {
        const payload = {
            first_name: formData.value.firstName,
            last_name: formData.value.lastName,
            language: formData.value.communicationLanguage,
            email: formData.value.email,
            phone: formData.value.phone,
            birth_date: !formData.value.isCompany
                ? formData.value.birthDate && formData.value.birthDate.length > 10
                    ? dateFormat(formData.value.birthDate.slice(0, 10))
                    : dateFormat(formData.value.birthDate)
                : null,
            delivery_address_object: formData.value.deliveryAddress
                ? {
                      name: formData.value.deliveryAddress.name,
                      street: formData.value.deliveryAddress.street,
                      house_number: formData.value.deliveryAddress.houseNumber,
                      zip_code: formData.value.deliveryAddress.zipCode,
                      city: formData.value.deliveryAddress.city,
                      country_name: formData.value.deliveryAddress.countryName
                  }
                : null,
            billing_address: {
                city: formData.value.city,
                country_name: formData.value.country,
                street: formData.value.street,
                house_number: formData.value.houseNumber,
                zip_code: formData.value.zipCode
            },
            gender: formData.value.gender ? capitalizeFirstLetter(formData.value.gender) : formData.value.gender,
            type_registration: formData.value.isCompany ? Client.isCompany : Client.isIndividual,
            company_information: formData.value.isCompany
                ? {
                      country_name: formData.value.country,
                      self_employed: formData.value.isSelfEmployed,
                      business_id: formData.value.businessId,
                      name: formData.value.companyName,
                      tax_id: formData.value.taxId,
                      vat_id: formData.value.vatId
                  }
                : {}
        }
        return payload
    }

    const next = async () => {
        const { valid } = await form.value.validate()

        if (!valid) {
            showMessage(t('error.FormContainsErrors'))
            return
        }

        if (requiredVatValidation.value && getters['account/isEUMemberState'] && formData.value.vatId) {
            try {
                commit('setLoader', 1, { root: true })
                await apiRequest.post('client/validate-vat', { vat_id: formData.value.vatId })
            } catch (error) {
                if (error.response?.status !== 503) {
                    if (error.response?.status === 419) {
                        showMessageArray(error.response.data.errors)
                        return
                    } else {
                        showMessageArray(error.errors)
                    }
                }
            } finally {
                commit('setLoader', -1, { root: true })
            }
        }

        await dispatch('account/setAccount', prepareData())
            .then(() => {
                if (!isEmptyObj(vehicle.value)) {
                    resolveVehicle()
                }
                window.scrollTo({ top: 0 })
            })
            .catch((error) => {
                for (const [key, value] of Object.entries(error)) {
                    switch (key) {
                        case 'email':
                            errors.value.email = value[0]
                            break
                        case 'phone':
                            errors.value.phone = value[0]
                            break
                        case 'gender':
                            errors.value.gender = value[0]
                            break
                    }
                }
            })
    }

    onMounted(() => {
        // check if phone number is not set that means do not prefill first and last name
        if (!formData.value.phone) {
            formData.value.firstName = ''
            formData.value.lastName = ''
            form.value.resetValidation()
        }
        formData.value.communicationLanguage = getters['account/lang']
        const params = {
            provider: getters['account/consentsLocale']
        }
        if (step.value.toLowerCase() === 'contactinfo') {
            params['registration-step'] = '2-clientData'
        }

        checkIsPhoneVerified().then(() => {
            if (store.state.account.phone && !formData.value.phone) {
                formData.value.phone = store.state.account.phone
            }
        })
    })

    return {
        form,
        personalTitle,
        vehicle,
        setRequiredVatValidation,
        setFormField,
        setIsPhoneVerified,
        setCarFields,
        unsetIsPhoneVerified,
        skipVerificationPhone,
        next
    }
}
