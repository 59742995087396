export const mutations = {
    setConnectors: (state, connectors) => {
        state.connectors = connectors
    },
    setConnector: (state, connector) => {
        state.connector = connector
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination
    },
    toggleConnectorEdit: (state, value) => {
        state.isEditView = value
    },
    setPage: (state, page) => {
        state.pagination.page = page
    },
    setItemPerPage: (state, itemsPerPage) => {
        state.pagination.itemsPerPage = itemsPerPage
    },
    setFilters: (state, filters) => {
        state.filters = filters
    },
    setFieldValue: (state, { field, value }) => {
        state[field] = value
    },
    setSelectedFiltersFieldValue: (state, {field, value}) => {
      state.selectedFilters[field] = value
    },
    setIsChargingEventTriggered: (state, value) => {
        state.isChargingEventTriggered = value
    },
    setConsumptionSession: (state, consumptionSession) => {
        state.consumptionSession = consumptionSession
    },
    setConsumptionsSessions: (state, consumptionsSessions) => {
        state.consumptionsSessions = consumptionsSessions
    },
}