export const getters = {
    isLoadMore: state => state.isLoadMore,
    credits: state => state.credits,
    isButtonEnabled: (state) => {
        const couponData = state.addCouponData
        if(couponData) {
            let hasEmptyValue = []
            Object.keys(couponData).forEach(key => {
                if(couponData[key] === null || (couponData[key] !== null && couponData[key].toString().trim().length === 0)) {
                    hasEmptyValue.push(true)
                }
            })
            return hasEmptyValue.length === 0
        }
        return false
    }
}