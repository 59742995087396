export default class DeviceDetection {
    constructor() {
        this.ua = navigator.userAgent
        this.checks = {
            iphone: Boolean(this.ua.match(/iPhone/)),
            ipod: Boolean(this.ua.match(/iPod/)),
            ipad: Boolean(this.ua.match(/iPad/)),
            blackberry: Boolean(this.ua.match(/BlackBerry/)),
            playbook: Boolean(this.ua.match(/PlayBook/)),
            android: Boolean(this.ua.match(/Android/)),
            macOS: Boolean(this.ua.match(/Mac OS X/)),
            win: Boolean(this.ua.match(/Windows/)),
            mac: Boolean(this.ua.match(/Macintosh/)),
            wphone: Boolean(this.ua.match(/(Windows Phone OS|Windows CE|Windows Mobile)/)),
            mobile: Boolean(this.ua.match(/Mobile/)),
            androidTablet: Boolean( this.ua.match(/(GT-P1000|SGH-T849|SHW-M180S)/) ),
            tabletPc: Boolean(this.ua.match(/Tablet PC/)),
            palmDevice: Boolean(this.ua.match(/(PalmOS|PalmSource| Pre\/)/)),
            kindle: Boolean(this.ua.match(/(Kindle)/)),
            otherMobileHints: Boolean(this.ua.match(/(Opera Mini|IEMobile|SonyEricsson|smartphone)/))
        }
    }

    isTouchDevice = () => {
        return this.checks.iphone || this.checks.ipod || this.checks.ipad
    }

    isApple = () => {
        return this.checks.iphone || this.checks.ipod || this.checks.ipad || this.checks.macOS  || this.checks.mac
    }

    isBlackberry = () => {
        return this.checks.blackberry
    }

    isAndroid = () => {
        return this.checks.android
    }

    isTablet = () => {
        return this.checks.ipad || this.checks.tabletPc || this.checks.playbook || this.checks.androidTablet || this.checks.kindle
    }
    isDesktop = () => {
        return !this.isTouchDevice() && !this.isSmartPhone() && !this.isTablet()
    }
    isSmartPhone = () => {
        return (this.checks.mobile || this.checks.blackberry || this.checks.palmDevice || this.checks.otherMobileHints) && !this.isTablet() && !this.checks.ipod
    }
    isDevice = () => {
        return this.isTablet() || this.isSmartPhone()
    }
}
