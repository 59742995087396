// Utils
import {apiRequest} from '@/utils/apiRequest'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }

        commit('account/resetStore', state)
        cookies.remove('mobile-token')
    },
    orderCard: ({commit, dispatch}, {driver_id, items}) => {
        commit('setLoader', 1, {root: true})
        return apiRequest.post('eshop/order', {
            driver_id,
            items
        }).then(() => {
            commit('setFieldValue', {
                field: 'requestCard',
                value: false
            })
            return true
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
    },
    getProducts: ({commit, dispatch}, {driver_id}) => {
        commit('setLoader', 1, {root: true})
        return apiRequest('eshop/products', {
            params: {
                driver_id: parseInt(driver_id)
            }
        }).then(({data}) => {
            commit('setFieldValue', {
                field: 'products',
                value: data
            })
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
    }
}