<script setup>
import { computed } from 'vue'
import moment from 'moment'
import store from '@/store'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const tariffChanges = computed(() => store.state.drivers.driver.tariffChanges)
</script>

<template>
    <v-row v-if="tariffChanges && tariffChanges.length">
        <v-col>
            <h2 class="home-page-title">
                {{ $t('drivers.tariffChangesTitle') }}
            </h2>
            <v-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                ID
                            </th>
                            <th class="text-left">
                                {{ t('drivers.tariffName') }}
                            </th>
                            <th class="text-left">
                                {{ t('drivers.start') }}
                            </th>
                            <th class="text-left">
                                {{ t('drivers.end') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in tariffChanges"
                            :key="item.id"
                        >
                            <td>{{ item.tariff.id }}</td>
                            <td>{{ item.tariff.name }}</td>
                            <td>{{ moment(item.start).format('DD.MM.YYYY, HH:mm') }}</td>
                            <td>{{ (item.end) ? moment(item.end).format('DD.MM.YYYY, HH:mm') : '-' }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-table>
        </v-col>
    </v-row>
</template>