<script setup>
import {useStore} from 'vuex'
import {onMounted, reactive} from 'vue'

// translations
import {useI18n} from 'vue-i18n'
const { t } = useI18n()

const store = useStore()

const localState = reactive({
    show: false,
    location: 'top',
    message: '',
    color: 'info',
    timeout: 10000
})

onMounted(() => {
    store.subscribe((mutation, state) => {
        if (mutation.type === 'setNotify') {
            localState.message = state.notify.content
            localState.color = state.notify.color
            localState.show = true
        }
    })
})

</script>

<template>
    <v-snackbar 
        v-model="localState.show"
        :color="localState.color"
        :timeout="localState.timeout"
        :location="localState.location"
    >
        <div class="d-flex justify-space-between align-center">
            <template v-if="typeof localState.message === 'string'">
                {{ t(localState.message) }}
            </template>
            <template v-else>
                <div v-html="localState.message?.outerHTML" />
            </template>
            <v-btn class="mb-auto text-white" variant="plain" text @click="localState.show = false">
                <v-icon class="active-icon">
                    mdi-close
                </v-icon>
            </v-btn>
        </div>
    </v-snackbar>
</template>