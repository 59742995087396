import formatChargerCode from '@/utils/formatChargerCode'
import en from '@/translations/EN.json'

export const getters = {
    getStatus: (state, getters, rootState) => (etrel_status) => {
        // 0 => 'ConnectorStatus_Out_Of_Communication'
        // 1 => 'ConnectorStatus_Unavailable'
        // 2 => 'ConnectorStatus_In_Fault'
        // 3 => 'ConnectorStatus_Available'
        // 4 => 'ConnectorStatus_Reserved'
        // 5 => 'ConnectorStatus_Occupied'
        // 6 => 'ConnectorStatus_Finishing'
        // 7 => 'ConnectorStatus_Out_Of_Working_Time'
        // 8 => 'ConnectorStatus_Newly_Inserted'
        // 9 => 'ConnectorStatus_Preparing'
        // 10 => 'ConnectorStatus_Suspended_EVSE'
        // 11 => 'ConnectorStatus_Suspended_EV'

        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        switch (etrel_status) {
            case 'ConnectorStatus_Out_Of_Communication':
                return translation['etrelstatus.offline']
            case 'ConnectorStatus_Unavailable':
                return translation['etrelstatus.evse']
            case 'ConnectorStatus_In_Fault':
                return translation['etrelstatus.fault']
            case 'ConnectorStatus_Available':
                return translation['etrelstatus.available']
            case 'ConnectorStatus_Reserved':
                return translation['etrelstatus.reserved']
            case 'ConnectorStatus_Occupied':
                return translation['etrelstatus.charging']
            case 'ConnectorStatus_Finishing':
                return translation['etrelstatus.finishing']
            case 'ConnectorStatus_Out_Of_Working_Time':
                return translation['etrelstatus.outoforder']
            case 'ConnectorStatus_Newly_Inserted':
                return translation['etrelstatus.inserted']
            case 'ConnectorStatus_Preparing':
                return translation['etrelstatus.connected']
            case 'ConnectorStatus_Suspended_EVSE':
                return translation['etrelstatus.suspendedevse']
            case 'ConnectorStatus_Suspended_EV':
                return translation['etrelstatus.suspendedev']
            default:
                return translation['etrelstatus.unknown']
        }
    },
    connectors: (state, getters) => state.connectors.map(connector => {
        return {
            'id': connector.id,
            'connector_code': connector.code,
            'label': `${formatChargerCode(connector.charger.code)}`,
            'type': connector.type,
            'etrel_status': connector.status.Status,
            'etrel_status_transformed': connector.status ? getters.getStatus(typeof connector.status === 'object' ? connector.status.Status : connector.status) : getters.getStatus('notDefined'),
            'status': connector.status ? getters.getStatus(typeof connector.status === 'object' ? connector.status.Status : connector.status) : getters.getStatus('notDefined'),
            'charger': connector.charger,
            'location': connector.location
        }
    }),
    connectorInfo: state => state.connectorInfo,
    filters: (state) => {
        return state.filters
    },
    isChargingEventTriggered: state => state.isChargingEventTriggered,
    tableHeaders: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                width: '50px',
                align: 'center',
                sortable: false,
                value: 'detail'
            },
            {
                title: translation['tableHeaders.Connector type'],
                align: 'start',
                sortable: false,
                value: 'type',
                width: '150px'
            },
            {
                title: translation['chargerDetails.ConnectorStatus'],
                align: 'start',
                sortable: false,
                value: 'etrel_status_transformed',
                width: '160px'
            },
            {
                title: translation['tableHeaders.ChargerLabel'],
                align: 'start',
                sortable: false,
                value: 'label',
                width: '130px'
            },
            {
                title: translation['facilitySessions.ChargerManufacture'],
                align: 'start',
                sortable: true,
                value: 'charger.manufacture',
                width: '175px'
            },
            {
                title: translation['facilitySessions.ChargerModel'],
                align: 'start',
                sortable: true,
                value: 'charger.model',
                width: '150px'
            },
            {
                title: translation['tableHeaders.Location'],
                align: 'start',
                sortable: true,
                value: 'location.name',
                width: 'auto'
            },
            {
                title: translation['chargerDetails.ConnectorCode'],
                align: 'start',
                sortable: false,
                value: 'connector_code',
                width: 'auto'
            },
            {
                title: translation['chargers.ChargerCode'],
                align: 'start',
                sortable: true,
                value: 'charger.code',
                width: '150px'
            },
            {
                title: translation['locations.LocationCode'],
                align: 'start',
                sortable: true,
                value: 'location.code',
                width: '150px'
            },
            {
                title: translation['tableHeaders.Actions'],
                align: 'center',
                sortable: false,
                value: 'actions'
            }
        ]
    }
}