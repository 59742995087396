<script setup>
// composables
import useWindowWidth from '@/composables/windowWidth'

// utils
import formatDecimal from '@/utils/formatDecimal'
import formatDate from '@/utils/formatDate'

// configs
import { DATE_FORMAT } from '@/configs/index.js'

// translations
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const {
  windowWidth,
  setWindowWidth
} = useWindowWidth()

defineProps({
  freeKwh: {
    required: true,
    type: Array,
    default: () => {
      return null
    }
  }
})
</script>

<template>
  <v-card
      v-if="freeKwh"
      class="freeKwhCard mt-5"
      elevation="0"
  >
    <v-card-title class="pb-0">
      <v-row>
        <v-col>
          <span class="freeKwhCard__title">{{ t('freeKWhpanel.amountPerDriver') }}</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-list
          class="overflow-y-auto"
          max-height="220"
      >
        <v-list-item
            v-for="({ driver_name, valid_to, freekwh_amount, used_amount }, index) in freeKwh"
            :key="index"
        >
          <v-list-item-title>
            <v-row>
              <v-col
                  class="whitespace-initial break-words"
                  sm="4"
              >
                {{ driver_name }}
              </v-col>

              <v-col sm="4">
                <div
                    :class="windowWidth < 600 ? 'text-right' : 'pr-3'"
                    class="freeKwhCard__valid_to"
                >
                  <small class="text pr-1">
                    {{ t('freeKWhpanel.validTo') }}
                  </small>
                  <strong class="text">
                    {{ formatDate(valid_to, DATE_FORMAT) }}
                  </strong>
                </div>
              </v-col>
              <v-col
                  class="text-right"
                  sm="4"
              >
                <v-avatar
                    color="primary"
                    size="20"
                >
                  <v-icon color="white"
                          dark
                          size="15"
                          small
                  >
                    mdi-lightning-bolt
                  </v-icon>
                </v-avatar>
                <span class="freeKwhCard__used_amount pl-2">
                                    <strong>{{ formatDecimal(freekwh_amount - used_amount) }}</strong>
                                    <span class="text text-small"> / {{ formatDecimal(freekwh_amount) }} kWh</span>
                                </span>
              </v-col>
            </v-row>
          </v-list-item-title>
          <v-progress-linear
              :model-value="formatDecimal((1 - (used_amount / freekwh_amount)) * 100)"
              bg-color="grey lighten-2"
              color="primary"
              class="freeKwhCard__bar mt-2"
              height="7"
              rounded
          />
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
