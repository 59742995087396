// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        consumptionsSessions: [],
        consumptionSession: {},
        isEditView: false,
        pagination: {
            itemsLength: 0,
            itemsPerPage: 10,
            pageCount: null,
            page: 1,
            pageStart: null,
            pageStop: null
        },
        filters: null,
        selectedFilters: null,
        key: 0
    }
}

export const state = getDefaultState()