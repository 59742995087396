import { ref, reactive, computed, toRefs, onMounted, onUnmounted } from 'vue'
import store from '@/store'

// utils
import { useI18n } from 'vue-i18n'
import dropIn from 'braintree-web-drop-in'
import formatDate from '@/utils/formatDate'
import showMessage from '@/utils/errorMessage'
import showMessageArray from '@/utils/errorMessageArray'
import { apiRequest } from '@/utils/apiRequest'
import { PaymentProviders } from '@/utils/paymentProviders'

// composables
import useBeforeWindowUnload from '@/composables/useBeforeWindowUnload'

const { dispatch, getters, commit } = store

export default function useAccountManagePayment() {
    const beforeWindowUnload = useBeforeWindowUnload()
    const { t } = useI18n()

    const state = reactive({
        currency: ref(import.meta.env.VITE_APP_I18N_CURRENCY),
        instance: null,
        paymentMethods: null,
        isPopupAskDelete: false,
        popupData: {
            questionDelete: {
                title: t('managePayment.doYouReallyWantTo'),
                type: 'question',
                subtitle: t('managePayment.removingOfThePaymentCard'),
                icon: 'mdi-help-circle'
            }
        },
        addCardUrl: null,
        recaptchaSiteKey: import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null || null
    })

    const paymentInfo = computed(() => getters['account/paymentInfo'])
    const lang = computed(() => getters['account/lang'])
    const language = computed(() => store.state.account.lang || import.meta.env.VITE_APP_I18N_LOCALE || 'en')
    const siteKey = computed(() => store.state.settings.recaptchaSiteKey)
    const paymentGatewayProvider = computed(() => getters['account/paymentGatewayProvider'])

    const loadPaymentMethods = async () => {
        grecaptcha.enterprise.ready(async () => {
            const recaptchaToken = await grecaptcha.enterprise.execute(state.recaptchaSiteKey, { action: 'payment_add' })

            const { data } = await apiRequest('client/braintree/generate-token', {
                params: {
                    'g-recaptcha-response': recaptchaToken
                }
            }).catch(e => {
                if (typeof e.errors.error !== 'undefined') {
                    showMessage(e.errors.error)
                } else if (Array.isArray(e.errors)) {
                    showMessageArray(e.errors)
                }
            })
            state.paymentMethods = data
            if (state.instance) {
                state.instance.teardown()
            }
            createDropIn()
        })
    }

    const createDropIn = () => {
        dropIn.create({
            authorization: state.paymentMethods.token,
            container: '#dropin-container',
            locale: `${lang.value}_${lang.value.toUpperCase()}`,
            dataCollector: true,
            threeDSecure: true
        }, (createErr, instance) => {
            if (createErr) {
                return
            }
            state.instance = instance

            const labelSmall = document.querySelector('.braintree-method__label--small')
            if (labelSmall) {
                document.querySelector('.braintree-method__label').innerHTML = `${i18n.t('payment.ExpireAt')} ${formatDate(paymentInfo.value.expire, 'MM/YYYY')}<br><div class="braintree-method__label--small">${labelSmall.innerHTML}</div>`
            }
        })
    }

    const handleDelete = () => {
        state.isPopupAskDelete = !state.isPopupAskDelete
    }
    const removeCard = () => {
        dispatch('account/removeCard', {}).then(() => {
            dispatch('account/getPaymentInfo').then(() => {
                loadPaymentMethods()
            })
            if (paymentInfo.value.provider === 'braintree') commit('account/setBraintreePayload', null)
            showMessage(t('managePayment.CardBeenDeletedSuccessfully'), 'success')
        }).catch(error => {
            showMessage(error)
        })
    }

    const threeDSecureParameters = () => {
        return {
            amount: Number(import.meta.env.VITE_APP_BRAINTREE_VERIFY_AMOUNT).toFixed(2).toString(),
            email: store.state.account.email,
            billingAddress: {
                givenName: store.state.account.firstName,
                surname: store.state.account.lastName,
                phoneNumber: store.state.account.phone,
                streetAddress: store.state.account.street,
                extendedAddress: store.state.account.houseNumber,
                locality: store.state.account.city
            }
        }
    }

    const saveBrainTreeCard = async () => {
        // if (!state.instance.isPaymentMethodRequestable()) return
        commit('setLoader', 1, { root: true })
        state.instance.requestPaymentMethod({ threeDSecure: threeDSecureParameters() }).then(async response => {
            commit('account/setBraintreePayload', response)
            let payload = {
                'payment_method_nonce': response.nonce,
                'device_data': response.deviceData
            }
            try {
                await dispatch('account/braintreeSaveCard', payload)
            } catch (e) {
                state.instance.clearSelectedPaymentMethod()
                // it returns array of error messages
                const element = document.createElement('div')
                if (typeof e === 'string') {
                    showMessage(i18n.t('error.500'))
                    return
                }
                e.forEach(error => {
                    const messageElement = document.createElement('span')
                    messageElement.append(`${error}`)
                    messageElement.append(document.createElement('br'))
                    element.append(messageElement)
                })
                showMessage(element)
            }
        }).catch(error => {
            showMessage(error.message)
        }).finally(() => {
            commit('setLoader', -1, { root: true })
        })
    }

    onMounted(async () => {
        if (paymentGatewayProvider.value === PaymentProviders.Braintree) {
            await loadPaymentMethods()
        }
        if (paymentInfo.value.provider === null) {
            window.onbeforeunload = beforeWindowUnload
        }
    })
    onUnmounted(() => {
        window.onbeforeunload = null
    })

    return {
        ...toRefs(state),
        paymentGatewayProvider,
        paymentInfo,
        language,
        siteKey,
        saveBrainTreeCard,
        handleDelete,
        removeCard
    }
}