<script setup>
import { reactive, ref, computed, onMounted } from 'vue'
import store from '@/store'

const { dispatch, commit, getters } = store

const props = defineProps({
    isEditView: {
        type: Boolean
    },
    revocableConsents: {
        type: Array
    }
})

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const currentConsents = ref([])

const state = reactive({
    isLoading: true
})

const displayConsents = computed(() => {
    return getters['account/revocableConsents']
})

const wasChecked = (id) => {
    return currentConsents.value.some(e => e.id === id) || false
}
const getConsent = (id) => {
    return props.revocableConsents?.some(e => e.id === id) || false
}
const updateRevocableConsent = (consentId, value) => {
    commit('account/updateRevocableConsent', { consentId, value })
}

onMounted(async () => {
    const params = {
        provider: getters['account/consentsLocale'],
        'filters[revocable]': 1
    }
    await dispatch('consents/getConsents', params).then(data => {
        commit('account/setRevocableConsents', data)
    }).finally(() => {
        state.isLoading = false
    })

	if(typeof store.state.account.payerConsents !== 'undefined') {
		currentConsents.value = [...store.state.account.payerConsents]
		getters['account/revocableConsents'].forEach(item => {
			if (store.state.account.payerConsents.includes(item.id)) {
				updateRevocableConsent(item.id, true)
			}
		})
	} else {
		currentConsents.value = null
	}

})

</script>

<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col cols="12">
                    <h2 class="text-h6">
                        {{ t('personalData.Consents') }}
                    </h2>
                </v-col>
                <template v-if="state.isLoading">
                    <v-row class="ma-4 mb-5 text-center">
                        <v-col cols="12">
                            <v-progress-circular
                                :size="50"
                                class="m-auto"
                                color="primary"
                                indeterminate
                            />
                        </v-col>
                    </v-row>
                </template>
                <v-col
                    v-else
                    v-for="consent in displayConsents"
                    :key="consent.id"
                    cols="12"
                    :class="!consent.visible || !consent.title ? 'd-none' : ''"
                >
                    <v-checkbox
                        v-if="consent.visible && consent.title"
                        :model-value="consent.checked"
                        :disabled="!isEditView || wasChecked(consent.id)"
                        @update:modelValue="updateRevocableConsent(consent.id, $event)"
                    >
                        <template v-slot:label>
                            <div v-html="consent.title" />
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
