<script setup>
import store from '@/store'

// components
import SocialBar from '@/components/SocialBar.vue'

// utils
import { useI18n } from 'vue-i18n'

// composables
import useLocale from '@/composables/locale'

const { locale, lang } = useLocale()
const { t } = useI18n()

const VERSION = store.state.version
const CURRENT_YEAR = new Date().getFullYear()

const clickPrivacyButton = () => {
  UC_UI.showSecondLayer()
}
</script>

<template>
  <div class="footer-login-root">
    <footer class="footer-login">
      <div class="footer-login__main">
        <div class="footer-login__text">
          <a target="_blank" :href="t(`footer.VITE_APP_CONTACTS`)">{{ t('registration.Contacts') }}</a> •
          <a target="_blank" :href="t(`footer.VITE_APP_TERMS_LINK`)">{{ t('registration.GeneralTermsAndConditions') }}</a> •
          <a target="_blank" :href="t('footer.VITE_APP_PRICE_LIST_LINK')">{{ t('registration.PriceList') }}</a> •
          <a target="_blank" href="/legal-information">{{ t('registration.LegalInformation') }}</a> •
          <v-btn variant="plain"
                 @click="clickPrivacyButton()"
                 density="compact"
                 :ripple="false"
                 class="privacy-text"
                 type="button"
          >
            {{ t('registration.privacySettings') }}
          </v-btn>
        </div>
        <div class="footer-login__copyright">
	        © 2018 - {{ CURRENT_YEAR }}
	        <span class="footer-login__copyright-primary">
	            {{t(`registration.GreenWaInfrastructure`) }}
            </span>
          <template v-if="VERSION">
            <br>
            <span>{{ `${t('registration.version')} ${VERSION}` }}</span>
          </template>
        </div>
      </div>
    </footer>
    <footer class="footer-mobile">
      <div class="footer-mobile__main">
        <SocialBar :secondary="true" class="mt-4" />
        <div class="footer-mobile__text pa-1 mt-9">
          <a target="_blank" :href="t(`footer.VITE_APP_CONTACTS`)">{{ t('registration.Contacts') }}</a> •
          <a target="_blank" :href="t(`footer.VITE_APP_TERMS_LINK`)">{{ t('registration.GeneralTermsAndConditions') }}</a> •
          <a target="_blank" :href="t('footer.VITE_APP_PRICE_LIST_LINK')">{{ t('registration.PriceList') }}</a> •
          <a target="_blank" href="/legal-information">{{ t('registration.LegalInformation') }}</a>
        </div>
        <div class="footer-mobile__copyright mt-9">
          © 2018 - {{ CURRENT_YEAR }} <span class="footer-mobile__copyright-primary">{{
            t(`registration.GreenWaInfrastructure${locale.toUpperCase()}`) }}</span>
        </div>
      </div>
    </footer>
  </div>
</template>
