<script setup>
import { reactive, computed, watch, onBeforeMount, onBeforeUnmount } from 'vue'
import store from '@/store'

// components
import Consents from '@/pages/account/components/Consents.vue'

// utils
import showMessage from '@/utils/errorMessage'
import { getEnvironment } from '@/utils/isFeatureAvailable'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const { getters, commit, dispatch } = store

const state = reactive({
    isLoading: true,
    personalDataIsEditView: false,
    actionsChange: [
        { title: t('driverInfo.cancel'), action: 'cancel', icon: 'mdi-close' },
        { title: t('driverInfo.apply'), action: 'apply', icon: 'mdi-check' }
    ]
})

const isNotifyFeatureEnabled = computed(() => getters['featureFlags/getFeature'](`${getEnvironment(window.location.origin)}_notify_after_session`))
const getSessionNotificationValue = computed({
    get() {
        return getters['account/notifyAfterSession']
    },
    set(val) {
        commit('account/setNotifyAfterSession', val)
    }
})
const revocableConsents = computed(() => getters['account/revocableConsents'])

watch(isNotifyFeatureEnabled, async (val) => {
    if (val) {
        dispatch('account/getNotifyAfterSession')
    }
})

const handleActionDetails = (title) => {
    state.isLoading = true
    switch (title) {
        case 'cancel':
            state.personalDataIsEditView = false
            Promise.all([dispatch('account/getNotifyAfterSession'), dispatch('account/getAccount')]).finally(() => {
                state.isLoading = false
            })
            break
        case 'apply':
            updateSettings()
    }
}

const prepareData = () => {
    const consents = []
    revocableConsents.value.forEach(item => {
        if (item.checked) consents.push(item.id)
    })
    return {
        consents
    }
}

const updateSettings = async () => {
    try {
        if (isNotifyFeatureEnabled.value) await dispatch('account/setNotifyAfterSession', getSessionNotificationValue.value)
        await dispatch('consents/setConsents', prepareData())
        await dispatch('account/getAccount')
        state.isLoading = false
        showMessage(t('driverInfo.DataBeenChangedSuccessfully'), 'success')
    } catch (error) {
        showMessage(error[0])
    } finally {
        state.personalDataIsEditView = false
    }
}

onBeforeMount(async () => {
    if (isNotifyFeatureEnabled.value) {
        await dispatch('account/getNotifyAfterSession').finally(() => {
            state.isLoading = false
        })
    }
})

onBeforeUnmount(() => {
    state.personalDataIsEditView = false
})
</script>

<template>
    <template v-if="state.isLoading">
        <v-row class="ma-4 mb-5 text-center">
            <v-col cols="12">
                <v-progress-circular
                    :size="50"
                    class="m-auto"
                    color="primary"
                    indeterminate
                />
            </v-col>
        </v-row>
    </template>
    <v-row
        v-else
        class="notifications-tab"
    >
        <v-col>
            <v-row>
                <v-col cols="6" />
                <v-col
                    cols="6"
                    class="d-flex justify-end"
                >
                    <div v-if="!state.personalDataIsEditView">
                        <v-btn
                            class="text-white"
                            color="primary"
                            @click="state.personalDataIsEditView = true"
                        >
                            {{ t('driverInfo.edit') }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <Consents
                        :is-edit-view="state.personalDataIsEditView"
                        :revocable-consents="revocableConsents"
                    />
                </v-col>
            </v-row>
            <v-row v-if="isNotifyFeatureEnabled">
                <v-col>
                    <h2 class="text-h6 mb-2">
                        {{ t('account.SessionsTitle') }}
                    </h2>
                    <div class="d-flex flex-column">
                        <v-checkbox
                            v-model="getSessionNotificationValue"
                            :disabled="!state.personalDataIsEditView"
                            :label="t('account.SessionSummaryNotifications')"
                        />
                    </div>
                </v-col>
            </v-row>
            <v-row class="mt-10">
                <v-col
                    v-if="state.personalDataIsEditView"
                    cols="12"
                    class="d-flex flex-row justify-center"
                >
                    <div
                        v-for="{ title, action } in state.actionsChange"
                        :key="title"
                    >
                        <v-btn
                            class="mx-10 px-10 text-white"
                            :outlined="(action !== 'apply')"
                            :loading="store.getters['isLoading']"
                            color="primary"
                            @click="handleActionDetails(action)"
                        >
                            {{ title }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>