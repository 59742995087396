<script setup>
import { ref, computed, watch } from 'vue'

// composables
import useChargingCards from '@/composables/chargingCards/useChargingCards'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const {
    registerGWCardDialogVisible: dialog,
    registerGWCardRows,
    addNewRow,
    removeRow,
    registerGWCards,
    fetchingData,
    getDrivers,
    availableDrivers,
    windowWidth
} = useChargingCards()

const timeout = ref(null)

watch(dialog, () => {
    if (dialog.value === false) {
        registerGWCardRows.value = [
            {
                rfid_code: '',
                driver_id: null
            }
        ]
    }
})

const isValid = computed(() => {
    if (containsEmptyValueOrNotValidNumber(registerGWCardRows.value) || containsDuplicates(registerGWCardRows.value)) {
        return false
    }
    return true
})

const containsEmptyValueOrNotValidNumber = (arr) => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].rfid_code === '' || arr[i].rfid_code.length !== 9) {
            return true
        }
    }
    return false
}

const containsDuplicates = (arr) => {
    const printed = arr.map((item) => {
        return item.rfid_code
    })
    const isDuplicatePrinted = printed.some((item, idx) => {
        return printed.indexOf(item) !== idx
    })
    if (isDuplicatePrinted) {
        return true
    }
    return false
}
const search = (val) => {
    if (val) {
        clearTimeout(timeout.value)
        timeout.value = setTimeout(async () => {
            await getDrivers(val)
        }, 500)
    }
}
</script>

<template>
    <div class="text-center register-gw-cards">
        <v-dialog v-model="dialog" min-width="375" max-width="500">
            <template v-slot:activator="{ props }">
                <template v-if="windowWidth > 959">
                    <v-btn
                        class="white--text w-100"
                        color="var(--v-menu-background)"
                        elevation="2"
                        v-bind="props"
                        x-large
                    >
                        {{ t('chargingCards.registerCard') }}
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn :ripple="false" color="var(--v-theme-menu-background)" icon v-bind="props">
                        <v-icon> mdi-plus </v-icon>
                    </v-btn>
                </template>
            </template>
            <v-card class="register-card pt-4 px-4 pb-8">
                <v-card-title
                    class="dialog-title text-h6 text-sm-h5 font-weight-light text-center d-flex justify-center mb-8"
                >
                    {{ t('chargingCards.registerCardTitle') }}
                </v-card-title>
                <div class="rows mb-16">
                    <v-row v-for="(row, index) in registerGWCardRows" :key="index" class="row mx-auto mb-2 mt-0">
                        <v-col class="col d-flex align-center" cols="6">
                            <v-text-field
                                v-model="row.rfid_code"
                                :label="t('chargingCards.printedId')"
                                hide-details
                                oninput="if(this.value < 0) this.value = 0"
                                type="number"
                                density="compact"
                            />
                        </v-col>
                        <v-col class="col d-flex align-center" cols="6">
                            <v-autocomplete
                                v-model="row.driver_id"
                                :item-title="availableDrivers.title"
                                :item-value="availableDrivers.value"
                                :items="availableDrivers"
                                :label="t('chargingCards.name')"
                                :placeholder="t('chargingCards.startTypingName')"
                                density="compact"
                                clearable
                                hide-details
                                @update:search="search"
                            />
                        </v-col>
                        <v-btn
                            v-if="registerGWCardRows.length > 1"
                            class="remove-row"
                            color="error"
                            icon
                            size="x-small"
                            @click="removeRow(index)"
                        >
                            <v-icon color="rgb(var(--v-theme-white))"> mdi-close </v-icon>
                        </v-btn>
                    </v-row>
                    <div v-if="registerGWCardRows.length < 5" class="add-row d-flex flex-column align-center">
                        <v-btn
                            class="add-row__button mb-2"
                            color="primary"
                            elevation="2"
                            icon
                            size="x-small"
                            @click="addNewRow"
                        >
                            <v-icon color="rgb(var(--v-theme-white))"> mdi-plus </v-icon>
                        </v-btn>
                        <span class="body-2">{{ t('chargingCards.addAnotherCard') }}</span>
                    </div>
                </div>
                <v-card-actions class="d-flex align-center justify-center pa-0">
                    <v-btn color="primary" variant="text" size="x-large" @click="dialog = false">
                        {{ t('chargingCards.cancel') }}
                    </v-btn>
                    <v-btn
                        :disabled="!isValid || fetchingData"
                        :loading="fetchingData"
                        class="ml-4 ml-sm-8"
                        variant="flat"
                        color="primary"
                        size="x-large"
                        @click="registerGWCards()"
                    >
                        {{ t('chargingCards.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
