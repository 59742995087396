import qs from 'qs'
import axios from 'axios'

// Utils
import isEmptyObj from '@/utils/isEmptyObj'
import {apiRequest} from '@/utils/apiRequest'
import {mapPagination} from '@/utils/mapPagination'

// Helpers
import {getDefaultState} from './helper'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

let CancelToken = axios.CancelToken.source()

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if (error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if (typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if (typeof error.errors !== 'undefined') {
                throw error.errors
            } else if (typeof error.message !== 'undefined') {
                throw error.message
            } else if (typeof error.msg !== 'undefined') {
                throw error.msg
            } else if (typeof error.error !== 'undefined') {
                throw error.error
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({commit, state, dispatch}, data = {}) => {
        if (data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }

        commit('account/resetStore', state)
        cookies.remove('mobile-token')

    },
    getDriverTariffs: async ({commit, dispatch}) => {
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest('client/drivers/filters')
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })

        commit('setDriversTariffs', data)

    },
    cancelRequests: () => {
        CancelToken.cancel('Cancelled request')
    },
    getDrivers: async ({commit, dispatch}, options) => {
        commit('setLoader', 1, {root: true})
        CancelToken = axios.CancelToken.source()
        const {data, headers} = await apiRequest('client/drivers', {
            cancelToken: CancelToken.token,
            params: options,
            paramsSerializer: params => qs.stringify(params, {skipNulls: true})
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })

        const pagination = await mapPagination(headers)
        commit('setDrivers', data)
        commit('setPagination', pagination)
    },
    getDriver: async ({commit, dispatch}, id) => {
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest(`client/drivers/${id}`)
            .catch(error => {
                if (error.response.status === 404) {
                    window.location.href = `${window.location.protocol}//${window.location.host}/drivers`
                }
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })

        // no data from driver
        if (data.length === 0) {
            window.location.href = `${window.location.protocol}//${window.location.host}/drivers`
        }

        const driver = {
            id: data.id,
            payerId: data.payer_id,
            name: data.first_name,
            status: data.status === 'Unblocked' || data.status === 'OK' ? 'Active' : data.status,
            email: data.email,
            phone: data.phone,
            cards: data.cards,
            blockedChargingAt: data.blocked_charging_at,
            vehicle: !isEmptyObj(data.vehicle) ? data.vehicle : getDefaultState().driver.vehicle,
            priceProgram: data.tariff_name,
            tariffId: data.tariff_id,
            activeTariffId: data.tariff_id,
            nextTariff: data.next_tariff,
            lang: data.lang ? data.lang.slice(-2) : '',
            note: data.note,
            deletedAt: data.deleted_at,
            running_session: data.running_session,
            isProtected: data.tariff.IsProtected,
            freeKWh: data.freeKWh,
            tariff: data.tariff,
            tariffChanges: data.tariff_changes
        }
        commit('setDriver', driver)
    },
    addDriver: async ({commit, dispatch}, addDriver) => {
        commit('setLoader', 1, {root: true})
        return apiRequest.post('client/drivers', addDriver).then(({data}) => {
            return data
        }).catch(error => {
            if (typeof error.errors !== 'undefined') {
                throw error.errors
            } else {
                dispatch('checkStatus', error)
            }
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
    },
    getVehicleDetails: async ({commit, dispatch, rootState}, id) => {
        const vehicleId = rootState.drivers.driver.vehicle.id
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest.get(`client/drivers/${id}/vehicles/${vehicleId}`)
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
        commit('setVehicle', data)
    },
    putVehicleDetails: async ({commit, dispatch, rootState}, {id, car}) => {
        const vehicleId = rootState.drivers.driver.vehicle.id
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest.put(`client/drivers/${id}/vehicles/${vehicleId}`, car)
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
        commit('setVehicle', data)
    },
    addVehicle: async ({commit, dispatch}, {id, car}) => {
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest.post(`client/drivers/${id}/vehicles`, {...car})
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
        commit('setVehicle', data)
    },
    deleteVehicle: async ({commit, dispatch, rootState}, id) => {
        const vehicleId = rootState.drivers.driver.vehicle.id
        commit('setLoader', 1, {root: true})
        await apiRequest.delete(`client/drivers/${id}/vehicles/${vehicleId}`)
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
        commit('setVehicle', getDefaultState().driver.vehicle)
    },
    changeDriverTariff: async ({commit, dispatch}, params) => {
        commit('setLoader', 1, {root: true})
        return await apiRequest.put('client/driver-tariff', params)
            .catch(error => {
                return dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
    },
    changeDriverDetails: async ({commit, dispatch}, params) => {
        commit('setLoader', 1, {root: true})
        await apiRequest.put(`client/drivers/${params.id}`, params)
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
    },
    deleteDriver: async ({commit, dispatch}, id) => {
        commit('setLoader', 1, {root: true})
        return apiRequest.delete(`client/drivers/${id}`)
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
    },
    deleteDrivers: async ({commit, dispatch}, ids) => {
        commit('setLoader', 1, {root: true})
        return apiRequest.post('client/drivers/delete-multiple', {ids})
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
    },
    cancelTariffChange: async ({commit, dispatch}, {id}) => {
        return apiRequest.post(`client/cancel-driver-tariff?driver_id=${id}`)
            .then(({ data }) => {
                return data.message
            })
            .catch((error) => {
                dispatch('checkStatus', error)
            })
            .finally(() => {
                commit('setLoader', -1, { root: true })
            })
    }
}
