<script setup>
import moment from 'moment'
import {computed, onMounted, reactive} from 'vue'

// Components
import Chart from 'chart.js'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const props = defineProps({
    name: String,
    data: Array,
    acDataField: String,
    dcDataField: String,
    totalDataField: String
})

const summary = computed(() => {
    let summary = {
        sumAC: 0,
        sumDC: 0,
        sumAll: 0
    }

    props.data.forEach(r => {
        summary.sumAC += r[props.acDataField] ? Number(r[props.acDataField]) : 0
        summary.sumDC += r[props.dcDataField] ? Number(r[props.dcDataField]) : 0
        summary.sumAll += r[props.totalDataField] ? Number(r[props.totalDataField]) : 0
    })
    return summary
})


const formatNumber = (number) => {
    return Number.isInteger(number) ? Math.trunc(number) : number.toFixed(2)
}

onMounted(() => {
    const acData = []
    const dcData = []
    const labels = []
    props.data.forEach(r => {
        if (r.date) {
            acData.push(r[props.acDataField])
            dcData.push(r[props.dcDataField])
            labels.push(moment(r['date']).format('DD.MM'))
        }
    })
    const ctx = document.getElementById(props.name).getContext('2d')
    new Chart(ctx, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: [
                {
                    label: 'AC',
                    data: acData,
                    backgroundColor: '#009F3A',
                    borderWidth: 0,
                    weight: 1
                },
                {
                    label: 'DC',
                    data: dcData,
                    backgroundColor: '#8DC641',
                    borderWidth: 0,
                    weight: 1
                }
            ]
        },
        options: {
            maintainAspectRatio: false,
            legend: {
                align: 'start'
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    label: (tooltipItem, data) => {
                        const type = data.datasets[tooltipItem.datasetIndex].label
                        const value = Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])
                        let total = 0
                        for (let i = 0; i < data.datasets.length; i++)
                            total += Number(data.datasets[i].data[tooltipItem.index])
                        if (tooltipItem.datasetIndex != data.datasets.length - 1) {
                            return type + ' : ' + formatNumber(value)
                        } else {
                            return [
                                type + ' : ' + formatNumber(value),
                                t('dashboard.Total') + ' : ' + formatNumber(total)
                            ]
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    gridLines: false,
                    ticks: {
                        min: 0,
                        beginAtZero: true
                    }
                }],
                yAxes: [{
                    display: false,
                    stacked: true,
                    gridLines: false
                }]
            }
        }
    })
})

</script>

<template>
    <div class="bar-graph-wrapper">
        <div class="graph">
            <div class="graph__bars">
                <canvas :id="name"/>
            </div>
            <div class="graph__info">
                <div class="info__text font-weight-bold">
                    {{ t('dashboard.7days') }}
                </div>
                <div class="info__block">
                    <span class="info__text info__text--title">{{ t('dashboard.AC') }}</span>
                    <span class="font-weight-bold info__text">{{ formatNumber(summary.sumAC) }}</span>
                </div>
                <div class="info__block">
                    <span class="info__text info__text--title">{{ t('dashboard.DC') }}</span>
                    <span class="font-weight-bold info__text">{{ formatNumber(summary.sumDC) }}</span>
                </div>
                <div class="info__block">
                    <span class="info__text info__text--title">{{ t('dashboard.Total') }}</span>
                    <span class="font-weight-bold info__text">{{ formatNumber(summary.sumAll) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
