<script setup>
import { ref, computed } from 'vue'
import store from '@/store'

import useInvoices from '@/composables/invoices'
import useChargers from '@/composables/chargers'
import useConnectors from '@/composables/connectors'

const emit = defineEmits(['action'])

const { getters } = store

const componentProps = defineProps({
    actionsFor: {
        type: String,
        default: 'invoices'
    },
    isStyled: {
        type: Boolean,
        default: false
    },
    isDisabled: {
        type: Boolean,
        required: false,
        default: false
    },
    text: {
        type: String,
        default: ''
    },
    id: {
        required: true,
        default: null
    },
    showDots: {
        required: false,
        type: Boolean,
        default: () => {
            return true
        }
    },
    showChevron: {
        required: false,
        type: Boolean,
        default: () => {
            return false
        }
    }
})

const toggle = ref(false)
const emitAction = (title) => {
    emit('action', title, componentProps.id, null)
}

const { actions: chargerActions } = useChargers()
const { actions: connectorActions } = useConnectors()
const { getActions, filteredInvoices } = useInvoices()

const resolveActions = computed(() => {
    switch (componentProps.actionsFor) {
        case 'chargers':
            return chargerActions.value
        case 'connectors':
            return connectorActions.value
        default:
            return getActions(filteredInvoices.value?.find(el => el.invoiceId === componentProps.id) || null)
    }
})
</script>

<template>
    <v-menu
        class="menu-action-mobile"
        offset-y
    >
        <template v-slot:activator="{ props }">
            <v-btn
                :class="componentProps.isStyled ? 'actions-toggle' : 'invoice__header-btn'"
                :disabled="componentProps.isDisabled"
                class="active-button"
                color="var(--v-color-gray-darken1)"
                variant="plain"
                size="small"
                v-bind="props"
                @click="toggle = !toggle"
            >
                <v-icon v-if="componentProps.showDots">
                    mdi-dots-vertical
                </v-icon>
                {{ componentProps.text }}
                <v-icon v-if="componentProps.showChevron">
                    {{ toggle ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </v-btn>
        </template>
        <v-list class="py-0">
            <v-list-item
                v-for="{ icon, title, action } in resolveActions"
                :key="title"
                @click="emitAction(action)"
                @click-outside="[toggle = false]"
            >
                <v-btn
                    :loading="getters['isLoading']"
                    class="w-100 d-flex justify-space-between"
                    variant="plain"
                >
                    <div>{{ title }}</div>
                    <v-icon
                        class="btn pl-8"
                        color="rgb(var(--v-theme-color-gray-darken2))"
                    >
                        {{ icon }}
                    </v-icon>
                </v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
