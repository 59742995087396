import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import { apiRequest } from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessage'
import showMessageArray from '@/utils/errorMessageArray'
import { fileDownload } from '@/utils/fileDownload'
import qs from 'qs'
import useWindowWidth from './windowWidth'
import useFilterableList from './filterableList'
import useDetailsCard from './detailsCard'
import { useI18n } from 'vue-i18n'

const { dispatch, getters, state } = store

export default function useFacilitySessions(props) {
    const {
        isCardView,
        isTable,
        isFiltersVisible,
        filtersSelected,
        exportFilters,
        options,
        getItems,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting
    } = useFilterableList({
        filters: {
            ac_dc_categories: null,
            connector_type_code: null,
            invoice_status: null,
            running_session: null,
            charger_id: props?.filters ? props.filters.charger_id : null,
            location_id: props?.filters ? [props.filters.location_id] : null,
            driver_id: null,
            drivers: [],
            invoice_id: null,
            price: {
                min: null,
                max: null
            },
            is_roaming: null,
            status_session: null,
            total_minutes: {
                min: null,
                max: null
            },
            total_kwh: {
                min: null,
                max: null
            },
            max_power: {
                min: null,
                max: null
            },
            connector_max_power: {
                min: null,
                max: null
            },
            average_power: {
                min: null,
                max: null
            },
            date: {
                from: null,
                to: null
            },
            charger_type: null
        },
        options: {
            sortBy: ['connected_from'],
            sortDesc: true
        },
        action: 'facilitySession/getFacilitySessions'
    })

    const { t } = useI18n()
    const { windowWidth, setWindowWidth } = useWindowWidth()

    const { isPickedItem, pickedItem, selectedRow, showDetails, closeDetails, rowSelect, highlightElement } =
        useDetailsCard()

    const isCustomVisible = ref(false)
    const facilityExportLoading = ref(false)
    const selected = ref([])

    const actions = ref([
        {
            title: t('invoices.DownloadInvoice'),
            action: 'Download invoice',
            icon: 'mdi-download'
        },
        {
            title: t('invoices.DownloadListOfSessions'),
            action: 'Download list of sessions',
            icon: 'mdi-file-download-outline'
        }
    ])
    const headers = computed(() => {
        return getters['facilitySession/tableHeaders']
    })
    const sessions = computed(() => {
        return getters['facilitySession/facilitySessions']
    })
    const pagination = computed(() => {
        return state.facilitySession.pagination
    })
    const countSelectedItems = computed(() => {
        return selected.value.length
    })

    function checkChargingToRowClass(item) {
        if (typeof item !== 'undefined' && !item.charging_to) {
            return 'highlight-row'
        }
    }

    async function handleFacilityDownload(type) {
        facilityExportLoading.value = true
        await apiRequest('facility/sessions/export', {
            params: { filters: exportFilters.value, type },
            paramsSerializer: (params) => qs.stringify(params, { skipNulls: true }),
            responseType: 'blob'
        })
            .then((response) => {
                if (response.data.error) {
                    showMessage(response.data.error)
                } else {
                    fileDownload(response.data, 'Sessions', type)
                }
            })
            .catch((err) => {
                showMessageArray(err.response.data.errors)
            })
        facilityExportLoading.value = false
    }

    onMounted(() => {
        dispatch('facilitySession/getFilters')
    })

    return {
        isPickedItem,
        isTable,
        isFiltersVisible,
        isCustomVisible,
        isCardView,
        facilityExportLoading,
        windowWidth,
        selected,
        selectedRow,
        pickedItem,
        filtersSelected,
        options,
        actions,
        headers,
        sessions,
        pagination,
        countSelectedItems,
        checkChargingToRowClass,
        getItems,
        handleFacilityDownload,
        getDefaultFilters,
        setFilter,
        clearFilters,
        sorting,
        showDetails,
        closeDetails,
        rowSelect,
        highlightElement,
        setWindowWidth
    }
}
