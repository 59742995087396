import { ref, computed } from 'vue'

// utils
import { apiRequest } from '@/utils/apiRequest'
import { getFullFileName } from '@/utils/getFileName'
import errorMessageArray from '@/utils/errorMessageArray'
import { specificFileDownload } from '@/utils/fileDownload'

const receiptEmail = ref('')
const pdfReceiptAvailable = ref(false)

export default function useSessionSummary() {

    const checkForReceiptAvailability = async () => {
        try {
            const { data } = await apiRequest('guest/download-ready')
            if (data.ready) pdfReceiptAvailable.value = true
        } catch (error) {
            errorMessageArray(error.errors)
        }
    }
    const downloadFileNow = (response) => {
        const fileName = getFullFileName(response)
        specificFileDownload(response.data, fileName)
    }
    const downloadReceipt = async () => {
        try {
            const response = await apiRequest('guest/download', {
                responseType: 'blob'
            })
            downloadFileNow(response)
        } catch (error) {
            errorMessageArray(error.response.data.errors)
        }
    }

    return {
        receiptEmail,
        pdfReceiptAvailable,
        checkForReceiptAvailability,
        downloadReceipt
    }
}