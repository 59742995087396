<script setup>
import useWindowWidth from '@/composables/windowWidth'

const {
    setWindowWidth
} = useWindowWidth()

const props = defineProps({
    classItem: {
        type: String,
        required: false,
        default: () => {
            return ''
        }
    },
    isActive: {
        type: Boolean,
        default: false
    },
    maxWidth: {
        type: Number,
        default: 300
    }
})

const emit = defineEmits(['cancel'])

const checkKeyDown = ($event) => {
    if ($event.key === 'Escape') {
        emit('cancel')
    }
}

const closeModal = () => {
    emit('cancel')
}
</script>

<template>
    <v-dialog
        class="modal"
        :model-value="isActive"
        :max-width="maxWidth"
        persistent
        @click:outside="closeModal"
        @keydown="checkKeyDown"
    >
        <v-card :class="classItem">
            <slot />
        </v-card>
    </v-dialog>
</template>