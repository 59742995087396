const PaymentProviders = Object.freeze({
    Braintree: 'braintree',
    Payout: 'payout',
    BankTransfer: 'BankTransfer',
    tPay: 'tpay',
    canPay: (status) => {
        return (PaymentProviders.Braintree === status || PaymentProviders.Payout === status || PaymentProviders.tPay === status) ? true : false
    }
})

export {
    PaymentProviders
}