<script setup>
import { computed, reactive } from 'vue'
import {useStore} from 'vuex'

// components
import Btn from '@/components/inputs/Btn.vue'
import DatePicker from '@/components/inputs/DatePicker.vue'

// translations
import {useI18n} from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const {getters} = store

const emit = defineEmits(['clearFilters', 'setFilter', 'getItems'])

const props = defineProps({
    chargerId: {
        required: false
    },
    filtersSelected: {
        required: false
    }
})

const state = reactive({
    searchInputCode: '',
    isClear: false
})

const filters = computed(() => {
    return getters['facilitySession/filters']
})

const showClear = () => {
    for (let key in props.filtersSelected) {
        if (typeof props.filtersSelected[key] === 'object' && key !== 'charger_id') {
            for (let nestedKey in props.filtersSelected[key]) {
                if (props.filtersSelected[key][nestedKey]) {
                    return true
                }
            }
        } else if (props.filtersSelected[key] && key !== 'charger_id') {
            return true
        }
    }
    return false
}

const debounce = (func, delay) => {
    let timerId
    return function(...args) {
        clearTimeout(timerId)
        timerId = setTimeout(() => {
            func.apply(this, args) // Call the debounced function with arguments
        }, delay)
    }
}

const setFilter = debounce(function(field, value)  {
    emit('setFilter', { field, value })
    emit('getItems')
}, 1500)

const clearFilters = () => {
    emit('clearFilters')
}

const dateFromTo = ({dateFrom: from, dateTo: to, isClear}) => {
    setFilter('date', {
        from: from || null,
        to:  to || null
    })
    state.isClear = isClear
}


</script>

<template>
    <v-container fluid class="chargers-list-filter-wrapper chargers__filters">
        <v-row class="pt-10 px-4 pb-4">
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-select 
                    density="compact"
                    variant="outlined"
                    clearable
                    :items="filters.roaming"
                    item-value="key"
                    item-title="name"
                    v-model="filtersSelected.is_roaming" 
                    :placeholder="t('sessions.Roaming')"
                    @update:model-value="setFilter('is_roaming', $event)"
                />
            </v-col>
            <v-col 
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <v-select 
                    density="compact"
                    variant="outlined"
                    clearable
                    :items="filters.status_session"
                    item-value="key"
                    item-title="name"
                    v-model="filtersSelected.status_session"
                    :placeholder="t('sessions.RunningSession')"
                    @update:model-value="setFilter('status_session', $event)"
                />
            </v-col>
            <v-col class="py-0 ma-0 px-0">
                <DatePicker
                    class="py-3 ma-0"
                    :label1="t('invoices.DateFrom')"
                    :label2="t('invoices.DateTo')"
                    :is-clear="state.isClear"
                    @date="dateFromTo"
                />
            </v-col>
            <v-col 
                v-if="showClear()"
                class="py-0"
                md="3"
                sm="6"
                cols="12"
            >
                <Btn text color="primary" @click="[emit('clearFilters'), state.isClear = true]">
                    <v-icon class="clear-icon pr-1">
                        mdi-delete
                    </v-icon>{{ t('filter.ClearAll') }}
                </Btn>
            </v-col>
        </v-row>
    </v-container>
</template>