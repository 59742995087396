import { createStore } from 'vuex'

import account from './modules/account/'
import cars from './modules/cars/'
import chargers from './modules/chargers/'
import connectors from './modules/connectors/'
import consumptions from './modules/consumptions/'
import consents from './modules/consents/'
import coupons from './modules/coupons/'
import dashboard from './modules/dashboard/'
import drivers from './modules/drivers/'
import eshop from './modules/eshop/'
import facilitySession from './modules/facilitySession/'
import featureFlags from './modules/featureFlags/'
import filters from './modules/filters/'
import fleetDashboard from './modules/fleetDashboard/'
import freeKwhPackages from './modules/freeKwhPackages/'
import invoices from './modules/invoices/'
import locations from './modules/locations/'
import news from './modules/news/'
import oauth from './modules/oauth/'
import resetPassword from './modules/resetPassword/'
import sessions from './modules/sessions/'
import settings from './modules/settings/'
import tariffs from './modules/tariffs/'
import themes from './modules/themes/'
import translation from './modules/translation/'

export const store = createStore({
  strict: import.meta.env.NODE_ENV !== 'production',
  modules: {
    account,
    cars,
    chargers,
    connectors,
    consumptions,
    consents,
    coupons,
    dashboard,
    drivers,
    eshop,
    facilitySession,
    featureFlags,
    filters,
    fleetDashboard,
    freeKwhPackages,
    invoices,
    locations,
    news,
    oauth,
    resetPassword,
    sessions,
    settings,
    tariffs,
    themes,
    translation,
  },
  state: {
    sidebarExpanded: false,
    version: null,
    loader: 0,
    token: null,
    notify: {
      content: '',
      color: 'info',
    }
  },
  mutations: {
    setNotify: (state, notify) => {
      state.notify.content = notify.content
      state.notify.color = notify.color
    },
    setLoader: (state, loaderCount) => {
      if (loaderCount === -1) {
        state.loader = 0
      } else {
        state.loader += loaderCount
      }
    },
    setToken: (state, token) => {
      state.token = token
    },
    setVersion: (state, version) => {
      state.version = version
    },
    setFieldValue: (state, { field, value }) => {
      state[field] = value
    }
  },
  actions: {},
  getters: {
    isLoading: state => !!state.loader
  }
})

export default store