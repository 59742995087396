<script setup>
import store from '@/store'

// components
import SocialBar from '@/components/SocialBar.vue'

// composables
import useLocale from '@/composables/locale'
import useWindowWidth from '@/composables/windowWidth.js'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const {
	windowWidth
} = useWindowWidth()

const { locale } = useLocale()

const VERSION = store.state.version
const CURRENT_YEAR = new Date().getFullYear()

const clickPrivacyButton = () => {
  UC_UI.showSecondLayer()
}
</script>

<template>
    <footer
        class="site-footer"
        :class="{'site-footer--registration': $route.name === 'registration-wizard'}"
    >
        <div class="site-footer__main">
            <div class="site-footer__copyright">
                © 2018 - {{ CURRENT_YEAR }} <span class="site-footer__copyright-primary">{{ t(`registration.GreenWaInfrastructure${locale.toUpperCase()}`) }}</span>
                <template v-if="VERSION">
                    <br>
                    <span>{{ `${t('registration.version')} ${VERSION}/B` }}</span>
                </template>
            </div>
            <div class="site-footer__text"
                 :class="windowWidth < 1180 ? 'site-footer__text_mobile' : ''"
            >
                <a target="_blank" :href="t('footer.VITE_APP_CONTACTS')">{{ t('registration.Contacts') }}</a> •
                <a target="_blank" :href="t('footer.VITE_APP_TERMS_LINK')">{{ t('registration.GeneralTermsAndConditions') }}</a> •
                <a target="_blank" :href="t('footer.VITE_APP_PRICE_LIST_LINK')">{{ t('registration.PriceList') }}</a> •
                <a target="_blank" href="/legal-information">{{ t('registration.LegalInformation') }}</a> •
                <span 
                    @click="clickPrivacyButton()"
                    class="privacy-text"
                >
                    {{ t('registration.privacySettings') }}
                </span>
            </div>
            <SocialBar
                :secondary="true"
                class="site-footer__social-bar"
            />
        </div>
    </footer>
</template>
