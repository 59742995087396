<script setup>
import qs from 'qs'
import {useStore} from 'vuex'
import router from '@/router'
import { reactive, computed } from 'vue'

// configs
import {ChargerCategory, Lang, Roles} from '@/configs/index.js'

// components
import ExportBtn from '@/components/ExportBtn.vue'
import ChargePointLoader from '@/loaders/ChargePointLoader.vue'
import FilterByToggle from '@/components/inputs/FilterByToggle.vue'
import ConnectorInfo from '@/pages/chargerManagement/components/ConnectorInfo.vue'
import ChargerSessions from '@/pages/chargerManagement/components/ChargerSessions.vue'
import ConsumptionsList from '@/pages/chargerManagement/components/ConsumptionsList.vue'

// utils
import showMessage from '@/utils/errorMessage'
import {apiRequest} from '@/utils/apiRequest.js'
import {fileDownload} from '@/utils/fileDownload.js'
import showMessageArray from '@/utils/errorMessageArray.js'
import {isAvailableCharger, getHintCharger, getIconCharger, getConnectorParamFromQuery} from '@/utils/connectors'

// composables
import useWindowWidth from '@/composables/windowWidth'

// translations
const { t } = useI18n()
import {useI18n} from 'vue-i18n'

const props = defineProps({
    id: {
        required: true,
        type: String
    }
})

const store = useStore()
const { getters } = store

const { windowWidth } = useWindowWidth()

const state = reactive({
    activeConnector: null,
    showConnectorInfo: false,
    isConsumptionsFilterVisible: false
})

const isLoading = computed(() => getters['isLoading'])
const charger = computed(() => getters['chargers/charger'])
const connectors = computed(() => charger.value?.connectors)

const getButtonColor = (hint) => {
    switch (hint) {
    case t('chargerManagement.stopCharging'):
        return 'grey'
    default:
        return 'primary'
    }
}
const getConnector = (id) => {
    return connectors.value.find(el => el.id === id) || null
}
const toggleConnectorInfo = (id) => {
    state.activeConnector = getConnector(id)
    state.showConnectorInfo = true
}
const getSvgIcon = (type) => {
    switch (type) {
    case 'AC Type 2 Cable':
    case 'AC Type 2 kábel':
    case 'przewód AC Typ 2':
    case 'AC Type 2 Socket':
    case 'AC Type 2 zásuvka':
    case 'AC typ 2 gniazdo':
        return 'connectorType2Cable'
    case 'CCS':
        return 'connectorCCS'
    case 'CHAdeMO':
        return 'connectorCHAdeMO'
    }
}
const getSvgText = (type) => {
    switch (type) {
    case 'AC Type 2 Cable':
    case 'AC Type 2 kábel':
    case 'przewód AC Typ 2':
        return t('chargerDetails.IconConnectorType2Cable')
    case 'AC Type 2 Socket':
    case 'AC Type 2 zásuvka':
    case 'AC typ 2 gniazdo':
        return t('chargerDetails.IconConnectorType2Socket')
    default:
        return ''
    }
}
const isAvailable = (connector) => {
    return isAvailableCharger(connector)
}
const getHint = (connector) => {
    return getHintCharger(connector)
}
const getIcon = (connector) => {
    return getIconCharger(connector)
}
const handleAction = (connector) => {
  router.push({name: 'charging', query: { id: connector.connector_id }})
}
const eipaVisible = () => {
    const locale = getters['account/locale']
    return locale.toLowerCase() === Lang.pl
}
const hasHomeChargers = computed(() => {
    return (store.state.account.homeChargers && store.state.account.homeChargers.length) || store.state.account.roles.includes(Roles.fleetHomeChargingManager)
})
const getStatus = (item) => {
    // 0 => 'ConnectorStatus_Out_Of_Communication'
    // 1 => 'ConnectorStatus_Unavailable'
    // 2 => 'ConnectorStatus_In_Fault'
    // 3 => 'ConnectorStatus_Available'
    // 4 => 'ConnectorStatus_Reserved'
    // 5 => 'ConnectorStatus_Occupied'
    // 6 => 'ConnectorStatus_Finishing'
    // 7 => 'ConnectorStatus_Out_Of_Working_Time'
    // 8 => 'ConnectorStatus_Newly_Inserted'
    // 9 => 'ConnectorStatus_Preparing'
    // 10 => 'ConnectorStatus_Suspended_EVSE'
    // 11 => 'ConnectorStatus_Suspended_EV'

    let itemStatus = item.status
    // check if it's object
    if(typeof item.status === 'object') {
        itemStatus = item.status.Status
    }

    switch(itemStatus) {
    case 'ConnectorStatus_Out_Of_Communication':
        return t('etrelstatus.offline')
    case 'ConnectorStatus_Unavailable':
        return t('etrelstatus.evse')
    case 'ConnectorStatus_In_Fault':
        return t('etrelstatus.fault')
    case 'ConnectorStatus_Available':
        return t('etrelstatus.available')
    case 'ConnectorStatus_Reserved':
        return t('etrelstatus.reserved')
    case 'ConnectorStatus_Occupied':
        return t('etrelstatus.charging')
    case 'ConnectorStatus_Finishing':
        return t('etrelstatus.finishing')
    case 'ConnectorStatus_Out_Of_Working_Time':
        return t('etrelstatus.outoforder')
    case 'ConnectorStatus_Newly_Inserted':
        return t('etrelstatus.inserted')
    case 'ConnectorStatus_Preparing':
        return t('etrelstatus.connected')
    case 'ConnectorStatus_Suspended_EVSE':
        return t('etrelstatus.suspendedevse')
    case 'ConnectorStatus_Suspended_EV':
        return t('etrelstatus.suspendedev')
    default:
        return t('etrelstatus.unknown')
    }
}

const isHomeChargersFleetManager = computed(() => {
    return store.state.account.roles.includes(Roles.fleetHomeChargingManager)
})

const handleExportDownload = async(url, getter, type) => {
    state.isLoading = true
    const selectedFilters = store.getters[getter]
    await apiRequest(url, {
        params: { filters: selectedFilters, type },
        paramsSerializer: params => qs.stringify(params, { skipNulls: true }),
        responseType: 'blob'
    }).then((response) => {
        if (response.data.error) {
            showMessage(response.data.error)
        } else {
            fileDownload(response.data, 'Consumptions', type)
        }
    }).catch(err => {
        if(typeof err.message !== 'undefined' && typeof err.message === 'string') {
            showMessage(err.message)
        } else {
            showMessageArray(err.response.data.errors)
        }
    }).finally(() => {
        state.isLoading = false
    })
}

const reset = () => {
	state.showConnectorInfo = false
	state.activeConnector = null
}

</script>

<template>
    <div class="charger-status">
        <v-container class="charger-status__container pa-0" fluid>
            <v-row>
                <v-col v-if="isLoading || !connectors" cols="12">
                    <ChargePointLoader />
                </v-col>
                <v-col
                    v-for="(info, index) in connectors"
                    v-else
                    :key="index"
                    class="charger-status__card border"
                    cols="12"
                    sm="4"
                >
                    <v-row class="charger-status__card-body ma-1">
                        <v-col class="charger-status__block px-5 charger-status__first d-flex justify-center pt-5" cols="12">
                            <div class="icon d-flex align-center">
                                <img :src="`/assets/icons/${getSvgIcon(info.type)}.svg`" alt=""/>
                                <span class="icon-text pl-2">{{ getSvgText(info.type) }}</span>
                            </div>
                            <v-btn
                                variant="plain"
                                class="info-button ml-auto px-2 active-button active-icon"
                                @click="toggleConnectorInfo(info.id)"
                            >
                                <span>{{ t('chargerDetails.Information') }}</span>
                                <v-icon class="ml-3">
                                    mdi-information-outline
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="charger-status__block px-5" cols="12">
                            <h2 class="home-page-title charger__title">
                                {{ info.type }}
                            </h2>
                        </v-col>
                        <v-col class="charger-status__block px-5" cols="12">
                            <h3>{{ t('chargerDetails.ConnectorCode') }}</h3>
                            <div>{{ info.code }}</div>
                        </v-col>
                        <v-col class="charger-status__block px-5" cols="12">
                            <h3>{{ t('chargerDetails.ConnectorStatus') }}</h3>
                            <div :class="{'active font-weight-medium d-flex align-center':getStatus(info) === t('etrelstatus.ConnectorStatus_Available')}">
                                <v-icon v-if="getStatus(info) === t('etrelstatus.ConnectorStatus_Occupied')"
                                        class="active-icon"
                                >
                                    mdi-flash
                                </v-icon>
                                <v-icon v-if="getStatus(info) === t('etrelstatus.ConnectorStatus_Available')"
                                        class="active-icon"
                                >
                                    mdi-check
                                </v-icon>
                                <span class="text">{{ getStatus(info) }}</span>
                            </div>
                        </v-col>
                        <v-col class="charger-status__block px-5" cols="12">
                            <h3>{{ t('chargerDetails.ConnectorType') }}</h3>
                            <div>{{ info.type }}</div>
                        </v-col>
                        <v-col class="charger-status__block px-5" cols="12">
                            <h3>{{ t('chargerDetails.EVSE ID') }}</h3>
                            <div>{{ info.evse_id }}</div>
                        </v-col>
                        <v-col class="charger-status__block px-5 py-5" cols="12">
                            <v-btn
                                v-if="isAvailable(info)"
                                block
                                large
                                depressed
                                class="ml-auto"
                                :color="getButtonColor(getHint(info))"
                                :loading="isLoading"
                                @click="handleAction(info)"
                            >
                                <v-icon class="pr-3">
                                    {{ getIcon(info) }}
                                </v-icon>
                                <span>{{ getHint(info) }}</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <ChargerSessions :charger-id="props.id" />

        <template v-if="charger && charger.category === ChargerCategory.home">
            <v-container fluid class="charger-sessions pa-0">
                <main class="charger-sessions__main mt-5">
                    <v-row class="new-line pt-3">
                        <v-col cols="6" class="left-size">
                            <h1 class="section__title float-left pl-5">
                                {{ t('chargerManagement.Consumption') }}
                            </h1>
                        </v-col>
                        <v-col cols="6" class="charger-sessions__options">
                            <div class="options-wrapper">
                                <ExportBtn @handleDownload="handleExportDownload('client/consumption/export', 'consumptions/selectedFilters' , $event)" />
                                <FilterByToggle :active="state.isConsumptionsFilterVisible"
                                                @change="state.isConsumptionsFilterVisible = !state.isConsumptionsFilterVisible"
                                />
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <ConsumptionsList :is-filters-visible="state.isConsumptionsFilterVisible"
                                              :pre-select-filter="{field: 'charger', value: parseInt(props.id)}"
                            />
                        </v-col>
                    </v-row>
                </main>
            </v-container>
        </template>


        <v-navigation-drawer
	        v-model="state.showConnectorInfo"
	        @update:model-value="reset"
	        :temporary="true"
	        location="right"
	        class="charger-status__drawer"
	        :width="windowWidth < 960 ? '100%' : 550"
        >
            <div class="charger-status__side"
                 :class="{'pa-5 mt-10':state.showConnectorInfo}"
            >
                <v-row>
                    <ConnectorInfo :info="state.activeConnector" @close="reset" />
                </v-row>
            </div>
        </v-navigation-drawer>
    </div>
</template>