<script setup>
import store from '@/store'
import {onBeforeMount} from 'vue'
const { dispatch } = store

onBeforeMount(async () => {
    await dispatch('account/logout')
    window.location = '/'
})

</script>

<template>
    <div />
</template>