import {useI18n} from 'vue-i18n'
import store from '@/store'
const {dispatch} = store
import { i18n } from '@/modules/i18n'

// utils
import showMessage from '@/utils/errorMessage'

const getEshopProducts = async (driver_id) => {
    await dispatch('eshop/getProducts', {
        driver_id
    }).catch(message => {
        showMessage(message)
    })
}

const orderCard = async (rfid, driver_id, items) => {
    // check if RFID card in eshop exists first
    if(rfid) {
        await dispatch('eshop/orderCard', {
            driver_id,
            items
        }).then(result => {
            if(result) {
                showMessage(i18n.global.t('orderCard.success'), 'success')
            }
        }).catch(error => {
            showMessage(error)
            throw error
        })
    } else {
        showMessage(i18n.global.t('orderCard.unableToOrder'))
    }
}

export {
    getEshopProducts,
    orderCard
}