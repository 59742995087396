function fileDownload(file, name, type) {
    const link = document.createElement('a')
    link.href = URL.createObjectURL(new Blob([file]))
    link.setAttribute('download', `${name}.${type}`)
    link.click()
    URL.revokeObjectURL(file)
}

function specificFileDownload(file, fileName) {
    const link = document.createElement('a')
    link.href = URL.createObjectURL(new Blob([file]))
    link.setAttribute('download', fileName)
    link.click()
    URL.revokeObjectURL(file)
}

export {
    fileDownload,
    specificFileDownload
}
