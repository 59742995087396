<script setup>
defineProps({
    secondary: {
        type: Boolean,
        default: false
    },
    width: {
        type: String,
        default: '220px'
    },
    height: {
        type: String,
        default: '45px'
    }
})
</script>

<template>
    <div class="logo" :style="`width: ${width}; height: ${height}`">
        <svg v-if="secondary"
             :width="width"
             :height="height"
             viewBox="0 0 220 46"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M29.6139 32.4206V17.1438C29.6139 14.0885 30.0109 11.4575 30.8842 9.33572C31.7576 7.21395 32.8691 5.6014 34.3775 4.32834C35.8066 3.14014 37.3945 2.29144 39.2999 1.69734C41.2054 1.10325 43.1108 0.848633 45.0957 0.848633H45.6514H46.2072V9.25085H43.6666C41.523 9.25085 39.9351 9.84494 38.903 10.9483C37.8709 12.0516 37.3151 13.8339 37.3151 16.1254V32.3357H29.6139V32.4206Z" fill="#99C45A" />
            <path d="M54.3053 13.4096H65.5792C65.4204 11.2878 64.944 9.67528 63.9913 8.65683C63.0386 7.55351 61.6889 6.95941 59.8628 6.95941C58.1956 6.95941 56.9253 7.55351 55.8932 8.65683C54.861 9.84502 54.3053 11.3727 54.3053 13.4096ZM67.961 22.6605H73.1216C72.3277 25.9705 70.7398 28.5166 68.4374 30.214C66.1349 31.9963 63.2768 32.9299 59.7834 32.9299C55.5756 32.9299 52.241 31.4871 49.9386 28.5166C47.5568 25.631 46.3659 21.6421 46.3659 16.2952C46.3659 11.203 47.4774 7.2989 49.7798 4.41329C52.1616 1.52768 55.4168 0 59.6247 0C64.0707 0 67.4846 1.4428 69.9458 4.24354C72.3277 7.12915 73.5186 11.203 73.5186 16.4649V17.3137V17.8229C73.5186 18.2472 73.4392 18.5018 73.4392 18.6716H54.2259C54.3053 21.1328 54.861 22.9151 55.8932 24.1033C56.8459 25.3764 58.275 25.9705 60.1804 25.9705C61.4507 25.9705 62.4828 25.7159 63.4356 25.1218C64.0707 24.6974 65.4204 23.5092 65.8968 23.0849C66.3731 22.9151 67.0877 22.6605 67.961 22.6605Z" fill="#99C45A" />
            <path d="M82.3314 13.4096H93.6847C93.5259 11.2878 93.0495 9.67528 92.0968 8.65683C91.1441 7.55351 89.7944 6.95941 87.9683 6.95941C86.301 6.95941 85.0308 7.55351 83.9986 8.65683C82.9665 9.84502 82.4108 11.3727 82.3314 13.4096ZM96.0665 22.6605H101.227C100.433 25.9705 98.8452 28.5166 96.5428 30.214C94.2404 31.9963 91.3822 32.9299 87.8889 32.9299C83.6811 32.9299 80.3465 31.4871 78.0441 28.5166C75.6623 25.631 74.4714 21.6421 74.4714 16.2952C74.4714 11.203 75.5829 7.2989 77.8853 4.41329C80.2671 1.52768 83.5223 0 87.7301 0C92.1762 0 95.5901 1.4428 98.0513 4.24354C100.433 7.12915 101.624 11.203 101.624 16.4649V17.3137V17.8229C101.624 18.2472 101.624 18.5018 101.545 18.6716H82.3314C82.4108 21.1328 82.9665 22.9151 83.9986 24.1033C84.9514 25.3764 86.3804 25.9705 88.2859 25.9705C89.5562 25.9705 90.5883 25.7159 91.541 25.1218C92.1762 24.6974 93.5259 23.5092 94.0022 23.0849C94.4786 22.9151 95.1137 22.6605 96.0665 22.6605Z" fill="#99C45A" />
            <path d="M128.697 32.4208H124.807C123.695 32.4208 122.822 32.0814 122.108 31.3175C121.393 30.5537 121.075 29.6201 121.075 28.4319V12.3064C121.075 10.4393 120.52 8.99648 119.488 8.14778C118.376 7.29907 117.185 6.87471 115.915 6.87471C114.565 6.87471 113.374 7.29907 112.342 8.14778C111.31 8.99648 110.754 10.4393 110.754 12.3064V32.5057H102.974V13.919C102.974 8.57213 104.244 4.92269 106.943 3.1404C109.563 1.35811 112.501 0.339664 115.677 0.169922C118.932 0.169922 121.949 1.1035 124.648 2.88579C127.348 4.66807 128.777 8.40239 128.777 13.919V32.4208H128.697Z" fill="#99C45A" />
            <path d="M160.217 32.4205H152.436L147.911 9.84485L143.385 32.4205H135.604L127.506 0.848541H135.049L139.733 22.6604L143.941 0.848541H151.801L155.77 22.6604L159.899 3.64928C160.375 1.78212 161.487 0.763672 163.472 0.763672H168.315L160.217 32.4205Z" fill="white" />
            <path d="M184.59 16.5496C183.558 17.1437 182.129 17.6529 180.303 18.0773L177.763 18.6714C176.413 19.0957 175.54 19.3503 174.904 20.199C174.349 20.9629 174.11 21.8965 174.11 22.83C174.11 24.0182 174.428 24.9518 175.143 25.7156C175.778 26.3097 176.73 26.6492 178.001 26.6492C180.065 26.6492 181.732 26.0551 182.844 24.7821C184.035 23.509 184.59 21.8116 184.59 19.6049V16.5496ZM186.416 31.1474C184.829 31.9961 183.241 32.5902 181.494 32.7599C179.747 32.9297 178.239 33.0145 176.889 33.0145C174.19 33.0145 171.887 32.2507 169.903 30.723C167.918 29.1954 166.886 26.6492 166.886 23.0847C166.886 19.7747 167.759 17.3983 169.585 15.9555C171.411 14.5127 173.713 13.5791 176.492 13.0699C177.127 12.985 177.366 12.9001 177.921 12.8153C178.557 12.7304 178.953 12.6455 179.589 12.5607C182.844 12.1363 184.432 11.033 184.432 9.50532C184.432 8.31713 183.876 7.55328 182.685 7.2138C181.573 6.87432 180.462 6.70458 179.509 6.70458C178.477 6.70458 177.524 6.87432 176.73 7.2138C175.937 7.55328 175.301 8.14738 174.984 8.99609H167.521C167.838 6.53483 168.87 4.49793 170.617 2.97026C172.602 1.18797 175.46 0.254395 179.192 0.254395C183.32 0.254395 186.416 1.01823 188.481 2.5459C190.624 4.15845 191.736 6.36509 191.736 9.16583V19.8596C191.736 22.9998 191.18 25.461 190.148 27.2433C189.116 29.0256 187.846 30.2987 186.416 31.1474Z" fill="white" />
            <path d="M195.547 43.5387V37.0036H197.69C199.199 37.0036 200.31 36.6641 201.184 35.9003C202.057 35.1364 202.216 33.9483 201.66 32.2509L191.339 0.848633H199.913L205.868 23.1697L211.108 3.73424C211.584 1.86708 212.775 0.848633 214.681 0.848633H220L208.647 36.8339C207.773 39.4649 206.662 41.332 205.312 42.4354C203.963 43.3689 201.819 43.8782 198.802 43.8782H198.008H197.214C196.976 43.7933 196.738 43.7084 196.42 43.7084C196.023 43.6235 195.785 43.5387 195.547 43.5387Z" fill="white" />
            <path d="M7.5424 16.8895C7.5424 14.3433 8.01877 12.1367 8.8921 10.0998C9.76543 8.14778 11.5121 7.12933 13.9733 7.12933C15.7993 7.12933 17.2284 7.80829 18.1812 9.2511C19.1339 10.6939 19.769 12.3064 20.0072 14.0887C20.2454 15.871 20.2454 17.7382 19.9278 19.6053C19.6102 21.4725 19.1339 22.8304 18.4987 23.6791C17.0697 25.716 14.926 26.4799 11.9885 25.8858C9.05088 25.3766 7.5424 22.3212 7.5424 16.8895ZM27.7084 14.7677V0.169922H23.8181C22.7066 0.169922 21.8333 0.509401 21.1187 1.27324C20.8012 1.61272 20.563 1.95221 20.4042 2.37656C19.0545 1.1035 17.3872 0.169922 13.3381 0.169922C8.65391 0.169922 5.31938 1.69759 3.17575 4.75294C1.03212 7.80829 0 11.7972 0 16.6349C0 20.7935 0.793937 24.1884 2.38181 26.7345C3.96969 29.2806 5.87514 31.0629 8.09816 31.9965C9.52725 32.5906 10.8769 32.9301 12.306 32.9301C12.3854 32.9301 12.4648 32.9301 12.5442 32.9301C16.4345 32.9301 18.8957 31.657 20.166 30.6385C20.0072 32.6754 19.8484 34.288 19.2133 35.5611C18.8163 36.3249 17.8636 38.7862 14.0527 38.7862C12.7824 38.7862 11.7503 38.5315 10.7975 37.9374C10.1624 37.598 8.8127 36.3249 8.33634 35.9854C7.85998 35.5611 7.14544 35.3913 6.2721 35.3913H1.11151V35.4762C2.77878 42.181 7.38362 46.0002 14.1321 46.0002C17.7048 46.0002 20.4836 44.9817 23.0242 42.9448C25.8824 40.5684 27.5496 36.8341 27.5496 31.2326V16.2105C27.7084 15.7862 27.7084 15.2769 27.7084 14.7677Z" fill="#99C45A" />
        </svg>
        <svg v-if="!secondary"
             :width="width"
             :height="height"
             viewBox="0 0 278 55"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M37.3 38.2V20.2C37.3 16.6 37.8 13.5 38.9 11C40 8.5 41.4 6.6 43.3 5.1C45.1 3.7 47.1 2.7 49.5 2C51.9 1.3 54.3 1 56.8 1H57.5H58.2V10.9H55C52.3 10.9 50.3 11.6 49 12.9C47.7 14.2 47 16.3 47 19V38.1H37.3V38.2Z" fill="#99C45A" />
            <path d="M68.4 15.8H82.6C82.4 13.3 81.8 11.4 80.6 10.2C79.4 8.9 77.7 8.2 75.4 8.2C73.3 8.2 71.7 8.9 70.4 10.2C69.1 11.6 68.4 13.4 68.4 15.8ZM85.6 26.7H92.1C91.1 30.6 89.1 33.6 86.2 35.6C83.3 37.7 79.7 38.8 75.3 38.8C70 38.8 65.8 37.1 62.9 33.6C59.9 30.2 58.4 25.5 58.4 19.2C58.4 13.2 59.8 8.6 62.7 5.2C65.7 1.8 69.8 0 75.1 0C80.7 0 85 1.7 88.1 5C91.1 8.4 92.6 13.2 92.6 19.4V20.4V21C92.6 21.5 92.5 21.8 92.5 22H68.3C68.4 24.9 69.1 27 70.4 28.4C71.6 29.9 73.4 30.6 75.8 30.6C77.4 30.6 78.7 30.3 79.9 29.6C80.7 29.1 82.4 27.7 83 27.2C83.6 27 84.5 26.7 85.6 26.7Z" fill="#99C45A" />
            <path d="M103.7 15.8H118C117.8 13.3 117.2 11.4 116 10.2C114.8 8.9 113.1 8.2 110.8 8.2C108.7 8.2 107.1 8.9 105.8 10.2C104.5 11.6 103.8 13.4 103.7 15.8ZM121 26.7H127.5C126.5 30.6 124.5 33.6 121.6 35.6C118.7 37.7 115.1 38.8 110.7 38.8C105.4 38.8 101.2 37.1 98.3 33.6C95.3 30.2 93.8 25.5 93.8 19.2C93.8 13.2 95.2 8.6 98.1 5.2C101.1 1.8 105.2 0 110.5 0C116.1 0 120.4 1.7 123.5 5C126.5 8.4 128 13.2 128 19.4V20.4V21C128 21.5 128 21.8 127.9 22H103.7C103.8 24.9 104.5 27 105.8 28.4C107 29.9 108.8 30.6 111.2 30.6C112.8 30.6 114.1 30.3 115.3 29.6C116.1 29.1 117.8 27.7 118.4 27.2C119 27 119.8 26.7 121 26.7Z" fill="#99C45A" />
            <path d="M162.1 38.2H157.2C155.8 38.2 154.7 37.7999 153.8 36.8999C152.9 35.9999 152.5 34.8999 152.5 33.4999V14.4999C152.5 12.2999 151.8 10.5999 150.5 9.59995C149.1 8.59995 147.6 8.09995 146 8.09995C144.3 8.09995 142.8 8.59995 141.5 9.59995C140.2 10.5999 139.5 12.2999 139.5 14.4999V38.2999H129.7V16.3999C129.7 10.0999 131.3 5.79995 134.7 3.69995C138 1.59995 141.7 0.399951 145.7 0.199951C149.8 0.199951 153.6 1.29995 157 3.39995C160.4 5.49995 162.2 9.89995 162.2 16.3999V38.2H162.1Z" fill="#99C45A" />
            <path d="M201.8 38.2H192L186.3 11.6L180.6 38.2H170.8L160.6 1.00002H170.1L176 26.7L181.3 1.00002H191.2L196.2 26.7L201.4 4.30003C202 2.10003 203.4 0.900024 205.9 0.900024H212L201.8 38.2Z" fill="black" />
            <path d="M232.5 19.5C231.2 20.2 229.4 20.8 227.1 21.3L223.9 22C222.2 22.5 221.1 22.8 220.3 23.8C219.6 24.7 219.3 25.8 219.3 26.9C219.3 28.3 219.7 29.4 220.6 30.3C221.4 31 222.6 31.4 224.2 31.4C226.8 31.4 228.9 30.7001 230.3 29.2001C231.8 27.7001 232.5 25.7 232.5 23.1V19.5ZM234.8 36.7001C232.8 37.7001 230.8 38.4 228.6 38.6C226.4 38.8 224.5 38.9 222.8 38.9C219.4 38.9 216.5 38 214 36.2001C211.5 34.4001 210.2 31.4001 210.2 27.2001C210.2 23.3001 211.3 20.5 213.6 18.8C215.9 17.1 218.8 16 222.3 15.4C223.1 15.3 223.4 15.2 224.1 15.1C224.9 15 225.4 14.9 226.2 14.8C230.3 14.3 232.3 13.0001 232.3 11.2001C232.3 9.80005 231.6 8.90005 230.1 8.50005C228.7 8.10005 227.3 7.90005 226.1 7.90005C224.8 7.90005 223.6 8.10005 222.6 8.50005C221.6 8.90005 220.8 9.60004 220.4 10.6H211C211.4 7.70004 212.7 5.30005 214.9 3.50005C217.4 1.40005 221 0.300049 225.7 0.300049C230.9 0.300049 234.8 1.20005 237.4 3.00005C240.1 4.90005 241.5 7.50005 241.5 10.8V23.4C241.5 27.1 240.8 30 239.5 32.1C238.2 34.2 236.6 35.7001 234.8 36.7001Z" fill="black" />
            <path d="M246.3 51.3V43.6H249C250.9 43.6 252.3 43.2 253.4 42.3C254.5 41.4 254.7 40 254 38L241 1H251.8L259.3 27.3L265.9 4.4C266.5 2.2 268 1 270.4 1H277.1L262.8 43.4C261.7 46.5 260.3 48.7 258.6 50C256.9 51.1 254.2 51.7 250.4 51.7H249.4H248.4C248.1 51.6 247.8 51.5 247.4 51.5C246.9 51.4 246.6 51.3 246.3 51.3Z" fill="black" />
            <path d="M9.5 19.8999C9.5 16.8999 10.1 14.2999 11.2 11.8999C12.3 9.59995 14.5 8.39995 17.6 8.39995C19.9 8.39995 21.7 9.19995 22.9 10.8999C24.1 12.5999 24.9 14.4999 25.2 16.5999C25.5 18.6999 25.5 20.8999 25.1 23.0999C24.7 25.2999 24.1 26.8999 23.3 27.8999C21.5 30.2999 18.8 31.1999 15.1 30.4999C11.4 29.8999 9.5 26.2999 9.5 19.8999ZM34.9 17.3999V0.199951H30C28.6 0.199951 27.5 0.599947 26.6 1.49995C26.2 1.89995 25.9 2.29995 25.7 2.79995C24 1.29995 21.9 0.199951 16.8 0.199951C10.9 0.199951 6.7 1.99995 4 5.59995C1.3 9.19995 0 13.8999 0 19.5999C0 24.4999 1 28.4999 3 31.4999C5 34.4999 7.4 36.6 10.2 37.7C12 38.4 13.7 38.7999 15.5 38.7999C15.6 38.7999 15.7 38.7999 15.8 38.7999C20.7 38.7999 23.8 37.2999 25.4 36.0999C25.2 38.4999 25 40.3999 24.2 41.8999C23.7 42.7999 22.5 45.7 17.7 45.7C16.1 45.7 14.8 45.4 13.6 44.7C12.8 44.2999 11.1 42.7999 10.5 42.3999C9.9 41.8999 9 41.7 7.9 41.7H1.4V41.7999C3.5 49.6999 9.3 54.2 17.8 54.2C22.3 54.2 25.8 52.9999 29 50.5999C32.6 47.7999 34.7 43.3999 34.7 36.7999V19.0999C34.9 18.5999 34.9 17.9999 34.9 17.3999Z" fill="#99C45A" />
        </svg>
    </div>
</template>


