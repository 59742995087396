import { marked } from 'marked'

export default function markdownParser(val) {
    if(val) {
        marked.setOptions({
            headerIds: false,
            mangle: false
        })

        // Documentation: https://marked.js.org/using_pro#renderer
        const renderer = {
            paragraph(text) {
                return `<p class="markedParagraph">${text}</p>`
            },
            list(body, ordered) {
                return `<${ordered ? 'ol' : 'ul'} class="markedList">${body}</${ordered ? 'ol' : 'ul'}>`
            },
            heading(text, level) {
                return `<h${level} class="markedH${level}">${text}</h${level}>`
            },
            link(href, title, text) {
                return `<a href="${href}" title="${ (title) ? title : '' }" class="markedLink" target="_blank">${text}</a>`
            }
        }
        marked.use({ renderer })
        
        return marked.parse(val)
    }
    return false
}