
export async function mapPagination(headers) {
    return {
        page: +headers['x-pagination-current_page'],
        itemsPerPage: +headers['x-pagination-per_page'],
        itemsLength: +headers['x-pagination-total'],
        pageCount: +headers['x-pagination-last_page'],
        pageStart: +headers['x-pagination-from']-1,
        pageStop: +headers['x-pagination-to']
    }
}