export const mutations = {
    setInvoices: (state, invoices) => {
        state.invoices = invoices
    },
    setInvoice: (state, invoice) => {
        state.invoice = invoice
    },
    setInvoiceStatus: (state, status) => {
        state.invoice.status_key = status
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination
    },
    setPage: (state, page) => {
        state.pagination.page = page
    },
    setItemPerPage: (state, limit) => {
        state.limit = limit
    }
}