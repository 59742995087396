<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'

// components
import LocationDetailsLoader from '@/loaders/LocationDetailsLoader.vue'

// utils
import isEmptyObj from '@/utils/isEmptyObj'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const store = useStore()

defineProps({
    location: {
        required: true,
        type: Object,
        default: () => {
            return null
        }
    }
})

const isLoading = computed(() => store.state.loader)

const isEmptyObject = (obj) => {
    return isEmptyObj(obj)
}
</script>


<template>
    <v-container fluid
                 class="location-container location-details"
                 :class="isEmptyObject(location) ? 'adjust-padding' : ''"
    >
        <LocationDetailsLoader v-if="isEmptyObject(location)" class="location__card-body" :height="140" />
        <v-row v-else class="location__card-body px-md-4 py-5">
            <v-col class="location__card-block location__border-md-right" md="4" cols="12">
                <v-row>
                    <v-col cols="3" md="12" class="pb-md-1">
                        <h3>{{ t('locationDetails.LocationCode') }}</h3>
                    </v-col>
                    <v-col cols="9" md="12" class="pt-md-0">
                        <div>{{ location.code }}</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" md="12" class="pb-md-1">
                        <h3>{{ t('locationDetails.LocationName') }}</h3>
                    </v-col>
                    <v-col cols="9" md="12" class="pt-md-0">
                        <div>{{ location.name }}</div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="location"
                   class="location__card-block location__border-md-right"
                   md="4"
                   cols="12"
            >
                <v-row class="pl-md-5">
                    <v-col cols="3" md="12" class="pb-md-1">
                        <h3>{{ t('locationDetails.LocationStatus') }}</h3>
                    </v-col>
                    <v-col v-if="location.status" 
                           cols="9"
                           md="12"
                           class="pt-md-0 d-flex align-center"
                           :class="{'location__available-location': location.status.toLowerCase() === t('etrelstatus.available').toLocaleLowerCase()}"
                    >
                        <v-icon v-if="location.status.toLowerCase() === t('etrelstatus.available').toLocaleLowerCase()"
                                class="active-icon"
                        >
                            mdi-check
                        </v-icon>
                        <span>{{ location.status }}</span>
                    </v-col>
                    <v-col v-else
                           cols="9"
                           md="12"
                           class="pt-md-0 d-flex align-center"
                    >
                        <span>{{ t('etrelstatus.unknown') }}</span>
                    </v-col>
                </v-row>
                <v-row class="pl-md-5">
                    <v-col cols="3" md="12" class="pb-md-1">
                        <h3>{{ t('locationDetails.AccessType') }}</h3>
                    </v-col>
                    <v-col cols="9" md="12" class="pt-md-0">
                        <div>{{ location.access_type }}</div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="location__card-block" md="4" cols="12">
                <v-row class="pl-md-5">
                    <v-col cols="3" md="12" class="pb-md-1">
                        <h3>{{ t('locationDetails.GPSLatitude') }}</h3>
                    </v-col>
                    <v-col cols="9" md="12" class="pt-md-0">
                        <div>{{ location.latitude }}</div>
                    </v-col>
                </v-row>
                <v-row class="pl-md-5">
                    <v-col cols="3" md="12" class="pb-md-1">
                        <h3>{{ t('locationDetails.GPSLongitude') }}</h3>
                    </v-col>
                    <v-col cols="9" md="12" class="pt-md-0">
                        <div>{{ location.longitude }}</div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>