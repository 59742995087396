import { ref } from 'vue'
import { apiRequest } from '@/utils/apiRequest'
import showMessage from '@/utils/errorMessageArray'
import {useI18n} from 'vue-i18n'

const snackbar = ref(false)
const snackbarText = ref('')

export default function useChargingCardsAction() {
    const { t } = useI18n()

    const assignAction = ref({
        title: t('chargingCards.assignCard'),
        action: 'assignCard',
        icon: 'mdi-account-plus'
    })
    
    const unassignAction = ref({
        title: t('chargingCards.unassignCard'),
        action: 'unassignCard',
        icon: 'mdi-account-minus'
    })
    
    const blockAction = ref({
        title: t('chargingCards.blockCard'),
        action: 'blockCard',
        icon: 'mdi-lock'
    })
    
    const unblockAction = ref({
        title: t('chargingCards.unblockCard'),
        action: 'unblockCard',
        icon: 'mdi-lock-open'
    })
    
    const deleteAction = ref({
        title: t('chargingCards.removeCard'),
        action: 'removeCard',
        icon: 'mdi-delete'
    })

    const assignCard = async (card, driverId) => {
        try {
            const response = await apiRequest.post('client/drivers/rfid/assign', { driver_id: driverId, auth_tag_id: card.auth_tag_id })
            triggerSnackBar(response.data.message)
        } catch(error) {
            showMessage(error.errors)
        }
    }

    const unassignCard = async (card) => {
        try {
            const response = await apiRequest.post('client/drivers/rfid/unassign', { driver_id: card.assignee_id, auth_tag_id: card.auth_tag_id })
            triggerSnackBar(response.data.message)
        } catch(error) {
            showMessage(error.errors)
        }
    }

    const blockCard = async (card) => {
        try {
            const response = await apiRequest.post('client/drivers/rfid/block', { auth_tag_id: card.auth_tag_id, driver_id: card.assignee_id })
            triggerSnackBar(response.data.message)
        } catch(error) {
            showMessage(error.errors)
        }
    }

    const unblockCard = async (card) => {
        try {
            const response = await apiRequest.post('client/drivers/rfid/unblock', { auth_tag_id: card.auth_tag_id, driver_id: card.assignee_id })
            triggerSnackBar(response.data.message)
        } catch(error) {
            showMessage(error.errors)
        }
    }

    const blockDriverCard = async (auth_tag_id, driverId) => {
        try {
            await apiRequest.post('client/drivers/rfid/block', { auth_tag_id: auth_tag_id, driver_id: driverId })
        } catch(error) {
            showMessage(error.errors)
        }
    }

    const unblockDriverCard = async (auth_tag_id, driverId) => {
        try {
            await apiRequest.post('client/drivers/rfid/unblock', { auth_tag_id: auth_tag_id, driver_id: driverId })
        } catch(error) {
            showMessage(error.errors)
        }
    }

    const addNewCard = async (auth_tag_id, driverId) => {
        try {
            await apiRequest.post('client/drivers/rfid/register', [{rfid_code: auth_tag_id, driver_id: driverId}])
        } catch(error) {
            showMessage(error.errors)
        }
    }

    const removeCards = async (cards) => {
        try {
            const response = await apiRequest.delete('client/drivers/rfid/delete', { data: getDeleteRequestBody(cards) })
            triggerSnackBar(response.data.message)
        } catch(error) {
            showMessage(error.errors)
        }
    }

    const getDeleteRequestBody = (items) => {
        const authTagsToRemove = {
            auth_tags: []
        }
        if(Array.isArray(items)) {
            items.forEach(item => {
                authTagsToRemove.auth_tags.push(item.auth_tag_id)
            })
            return authTagsToRemove
        }
        authTagsToRemove.auth_tags.push(items.auth_tag_id)
        
        return authTagsToRemove
    }

    const triggerSnackBar = (text) => {
        snackbarText.value = text
        snackbar.value = true
    }

    return {
        assignAction,
        unassignAction,
        blockAction,
        unblockAction,
        deleteAction,
        snackbar,
        snackbarText,
        triggerSnackBar,
        assignCard,
        unassignCard,
        blockCard,
        unblockCard,
        addNewCard,
        blockDriverCard,
        unblockDriverCard,
        removeCards
    }
}