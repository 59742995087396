<script setup>
import router from '@/router'

// composables
import useCharging from '@/composables/charging/useCharging'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const {isGuest, isLoggedIn} = useCharging()

const goBack = () => {
    if (isGuest.value || !isLoggedIn.value) {
        window.location.href = import.meta.env.VITE_APP_LOCATION_MAP_IFRAME_URL
        return
    }
    router.push('locationMap')
}
</script>

<template>
    <v-card :elevation="3" class="start-charging mr-auto ml-auto mt-md-8 mt-2 pa-4">
        <div class="text-center">
            <v-avatar
                class="mb-4"
                color="primary"
                size="100"
            >
                <v-icon color="white" size="80">
                    mdi-emoticon-sad-outline
                </v-icon>
            </v-avatar>
        </div>
        <h2 class="text-h6 text-center mb-6">
            {{ t('startCharging.connectorNotFound') }}
        </h2>
        <v-row>
            <v-col cols="12">
                <v-btn
                    variant="outlined"
                    rounded="xl"
                    @click="goBack()"
                >
                    {{ t('startCharging.close') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
