import { formatLanguage } from '@/utils/formatLanguage'
import en from '@/translations/EN.json'

import { DriverStatus } from '@/configs/'

export const getters = {
    drivers: state => state.drivers.map(driver => ({
        id: driver.id,
        name: driver.first_name,
        cards: driver.cards,
        status: driver.status === DriverStatus.ok ? 'Active' : driver.status,
        email: driver.email,
        phone: driver.phone,
        vehicle: driver.vehicle?.producer || null,
        priceProgram: driver.tariff_name,
        language: formatLanguage(driver.lang),
        tariff: driver.tariff
    })),
    isEditView: state => state.isEditView,
    driver: state => state.driver,
    tariffId: state => state.driver.tariffId,
    tableHeaders: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                title: translation['tableHeaders.Name'],
                align: 'start',
                sortable: true,
                value: 'name',
                width: '200px'
            },
            {
                title: translation['tableHeaders.Status'],
                align: 'start',
                sortable: true,
                value: 'status'
            },
            {
                title: translation['tableHeaders.Email'],
                align: 'start',
                sortable: true,
                value: 'email'
            },
            {
                title: translation['tableHeaders.Phone'],
                align: 'start',
                sortable: true,
                value: 'phone'
            },
            {
                title: translation['tableHeaders.Vehicle'],
                align: 'start',
                sortable: true,
                value: 'vehicle'
            },
            {
                title: translation['tableHeaders.Price program'],
                align: 'start',
                sortable: true,
                value: 'priceProgram'
            },
            {
                title: translation['tableHeaders.FreekWh'],
                align: 'start',
                sortable: false,
                value: 'freeKWh',
                width: '150px'
            },
            {
                title: '',
                align: 'end',
                sortable: false,
                value: 'driver_detail'
            }
        ]
    },
    chargingCardsTableHeaders: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                title: translation['tableHeaders.PrintedId'],
                align: 'start',
                sortable: false,
                value: 'card_number',
                width: '200px'
            },
            {
                title: translation['tableHeaders.Status'],
                align: 'start',
                sortable: false,
                value: 'status',
                width: '200px'
            },
            {
                title: translation['tableHeaders.AssigneeId'],
                align: 'start',
                sortable: false,
                value: 'assignee_id',
                width: '200px'
            },
            {
                title: translation['tableHeaders.AssigneeName'],
                align: 'start',
                sortable: false,
                value: 'assignee_name'
            },
            {
                title: '',
                align: 'end',
                sortable: false,
                value: 'actions',
                width: '150px'
            }
        ]
    },
    chargingCardsOrdersTableHeaders: (state, getters, rootState) => {
        const translation = rootState.translation[rootState.account.lang] ? rootState.translation[rootState.account.lang] : en
        return [
            {
                title: translation['tableHeaders.NumberOfCards'],
                align: 'start',
                sortable: true,
                value: 'items[0].quantity',
                width: '200px'
            },
            {
                title: translation['tableHeaders.RequestedOn'],
                align: 'start',
                sortable: true,
                value: 'created_at'
            }
        ]
    }
}