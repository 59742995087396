<script setup>
import useCharging from '@/composables/charging/useCharging'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const {
    noCommunication,
    visibleOfflineDetails,
    backToMap
} = useCharging()

</script>

<template>
    <v-card
        :elevation="3"
        class="charger-offline-details mr-auto ml-auto mt-md-8 mt-2 d-flex flex-column align-center justify-center text-center"
    >
        <div class="top-bar d-flex justify-center align-center">
            <h6
                v-if="noCommunication"
                class="text-subtitle-1 font-weight-medium text-no-wrap text-truncate"
            >
                {{ t('startCharging.temporaryOffline') }}
            </h6>
            <v-icon
                class="close"
                @click="visibleOfflineDetails = false"
            >
                mdi-close
            </v-icon>
        </div>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <div class="stage d-flex align-center justify-center my-10 mx-auto">
                        <v-icon
                            color="var(--v-sc-blue)"
                            size="64"
                        >
                            mdi-information-outline
                        </v-icon>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h2 class="text-h6 font-weight-medium text-center mb-4">
                        {{ t('startCharging.temporaryOffline') }}
                    </h2>
                    <p class="text-body-2 mb-0">
                        {{ t('startCharging.temporaryOfflineText') }}
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn
                        variant="outlined"
                        rounded="xl"
                        @click="visibleOfflineDetails = false"
                    >
                        {{ t('startCharging.close') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>
