export const mutations = {
    setDiscounts: (state, data) => {
        state.discounts = data
    },
    setCoupons: (state, data) => {
        state.coupons = data
    },
    setCredits: (state, value) => {
        state.credits = value
    },
    setCouponsHistory: (state, data) => {
        state.couponsHistory = data
    },
    pushCoupons: (state, data) => {
        state.coupons.push(...data)
    },
    setPage: (state, data) => {
        state.page = data
    },
    setIsLoadMore: (state, data) => {
        state.isLoadMore = data
    },
    clearCoupons: (state) => {
        state.coupons = []
    },
    setTab: (state, {tabIndex}) => {
        state.tabIndex = tabIndex
    },
    updateFieldValue: (state, {field, value}) => {
        state[field] = value
    },
    updateAddCouponFieldValue: (state, {field, value}) => {
        state.addCouponData[field] = value
    }
}