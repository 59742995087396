<script setup>
// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

const emit = defineEmits(['change'])

defineProps({
    list: {
        type: Array,
        default: () => {
            return []
        }
    },
    id: {
        type: String,
        default: null
    },
    label: {
        type: String,
        default: String
    },
    additionalLabel: {
        type: String,
        default: null
    },
    field: {
        type: String,
        default: null
    },
    formType: {
        required: false,
        type: String,
        default: null
    }
})

// 0 => 'ConnectorStatus_Out_Of_Communication'
// 1 => 'ConnectorStatus_Unavailable'
// 2 => 'ConnectorStatus_In_Fault'
// 3 => 'ConnectorStatus_Available'
// 4 => 'ConnectorStatus_Reserved'
// 5 => 'ConnectorStatus_Occupied'
// 6 => 'ConnectorStatus_Finishing'
// 7 => 'ConnectorStatus_Out_Of_Working_Time'
// 8 => 'ConnectorStatus_Newly_Inserted'
// 9 => 'ConnectorStatus_Preparing'
// 10 => 'ConnectorStatus_Suspended_EVSE'
// 11 => 'ConnectorStatus_Suspended_EV'
const getStatus = (item) => {
    let itemStatus = item.status
    // check if it's object
    if (typeof item.status === 'object') {
        itemStatus = item.status.Status
    }

    switch (itemStatus) {
    case 'ConnectorStatus_Out_Of_Communication':
        return t('etrelstatus.offline')
    case 'ConnectorStatus_Unavailable':
        return t('etrelstatus.evse')
    case 'ConnectorStatus_In_Fault':
        return t('etrelstatus.fault')
    case 'ConnectorStatus_Available':
        return t('etrelstatus.available')
    case 'ConnectorStatus_Reserved':
        return t('etrelstatus.reserved')
    case 'ConnectorStatus_Occupied':
        return t('etrelstatus.charging')
    case 'ConnectorStatus_Finishing':
        return t('etrelstatus.finishing')
    case 'ConnectorStatus_Out_Of_Working_Time':
        return t('etrelstatus.outoforder')
    case 'ConnectorStatus_Newly_Inserted':
        return t('etrelstatus.inserted')
    case 'ConnectorStatus_Preparing':
        return t('etrelstatus.connected')
    case 'ConnectorStatus_Suspended_EVSE':
        return t('etrelstatus.suspendedevse')
    case 'ConnectorStatus_Suspended_EV':
        return t('etrelstatus.suspendedev')
    default:
        return t('etrelstatus.unknown')
    }
}

const checkDisabled = (field, item) => {
    if (formType !== 'stopCharging') {
        if (field === 'connector' && isConnectorAvailable(item.status)) {
            return true
        }
    }
    return false
}

const isConnectorAvailable = (status) => {
    return !(status === 'ConnectorStatus_Available' || status === 'ConnectorStatus_Preparing')
}
</script>

<template>
    <v-radio-group v-if="list && list.length">
        <v-radio
            v-for="(item, index) in list"
            :key="item.id"
            :disabled="checkDisabled(field, item)"
            :label="`${item[label]}` + (field === 'connector' ? `| ${item.type} | ${getStatus(item)}` : '')"
            :value="item.id"
            @change="emit('change', { value: list[index], field })"
        />
    </v-radio-group>
    <div v-else>
        {{ t('error.NoDataAvailable') }}
    </div>
</template>