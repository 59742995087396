<script setup>
    import Logo from '@/components/icons/Logo.vue'
    import SocialBar from '@/components/SocialBar.vue'
    import {useI18n} from 'vue-i18n'
    const { t } = useI18n()
</script>
<template>
    <aside class="aside">
        <header class="aside__header">
            <Logo
                class="aside__logo"
                width="250px"
                height="52px"
                secondary
            />
            <p class="aside__sub-title">
                {{ t('aside.EverythingForCharging') }} <br>{{ t('aside.yourElectricVehicle') }}
            </p>
        </header>
        <SocialBar :is-top="true" />
        <svg class="aside__img"
             width="369"
             height="694"
             viewBox="0 0 369 694"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M184 458C184 492.133 184 603 184 603"
                  stroke="#99C45A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
            <path d="M184 665C184 671.827 184 694 184 694"
                  stroke="#99C45A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
            <path d="M184 461V449C184 448.448 183.552 448 183 448H178C174.134 448 171 444.866 171 441V425.5H197V441.429C197 442.755 196.473 444.027 195.536 444.964L194 446.5"
                  stroke="#8DC641"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
            <path d="M190.5 415V425.5"
                  stroke="#8DC641"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
            <path d="M177 415V425.5"
                  stroke="#8DC641"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
            <path d="M184 430L180 437L187.5 435L183 442"
                  stroke="#8DC641"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
            />
            <circle cx="190"
                    cy="447.5"
                    r="1"
                    fill="#8DC641"
            />
            <path d="M140 320C139.5 323.5 139.988 327.5 143.588 330.5C147.188 333.5 163.088 338 186.588 338" stroke="#5A6873" />
            <path d="M229.588 320C230.088 323.5 229.6 327.5 226 330.5C222.4 333.5 206.5 338 183 338" stroke="#5A6873" />
            <path d="M159.942 39.5C147.442 39.5002 135.352 48.4996 130.442 60.9998C125.531 73.4999 129 81.5 129 81.5" stroke="#5A6873" />
            <path d="M184.942 34.5C174.275 33.8334 148.249 38.3852 137.442 60C124.942 85 124.442 107.5 123.442 123.5C122.642 136.3 123.109 215.167 123.442 253C123.609 262.5 124 276.525 124.442 280.5C125.053 286 127.013 288 128.442 290C130.942 293.5 132.442 295 134.442 295C138.082 295 138.669 289.044 138.942 287C140.942 272 142.442 189 132.942 157.5C132.942 157.5 128.442 140.5 123.281 127.5" stroke="#5A6873" />
            <path d="M123.942 273C124.445 277.58 126.265 281.334 127.442 283C129.5 285.915 130.795 288 132.441 288C135.438 288 135.441 277.5 135.441 277.5C135.441 277.5 138.942 171 123.441 130" stroke="#5A6873" />
            <path d="M116.522 150.5V158.5" stroke="#5A6873" />
            <path d="M116.522 153.5C115.022 153.5 113.794 153.364 112.522 154C110.522 155 109.207 155.972 107.522 158.5C105.522 161.5 103.522 161.5 103.022 161.5" stroke="#5A6873" />
            <path d="M117.022 150C118.523 149 120.022 149 120.022 151L120.022 158.5C120.022 160.5 119.022 161 118.022 160C117.022 159 117.522 158.5 115.522 158.5C113.522 158.5 110.386 164.068 107.522 165.5C106.522 166 103.495 166.278 103.022 162.5C102.523 158.5 110.521 151 112.522 150.5C114.522 150 115.358 151.109 117.022 150Z" stroke="#5A6873" />
            <path d="M183 181.5C193 181.5 205.071 181.857 212.5 183C219 184 223.333 185 225 185.5C223.333 195.167 221.1 220 221.5 242C221.9 264 222.667 274.667 223 277.5C210.5 281.5 194.2 282 185 282" stroke="#5A6873" />
            <path d="M187 181.5C177 181.5 164.929 181.857 157.5 183C151 184 146.667 185 145 185.5C146.667 195.167 148.9 220 148.5 242C148.1 264 147.333 274.667 147 277.5C159.5 281.5 176.8 282 186 282" stroke="#5A6873" />
            <path d="M183 287C194.5 287 207 285.95 211.5 285.5C215.5 285.1 222.167 284 223.5 283.5C225.5 295.333 228 311 229.5 319C229.776 320.474 230 324 228.5 325C225.5 327 207.5 333 184 333" stroke="#5A6873" />
            <path d="M186.701 287C175.201 287 162.701 285.95 158.201 285.5C154.201 285.1 147.534 284 146.201 283.5C144.201 295.333 141.701 311 140.201 319C139.924 320.474 139.701 324 141.201 325C144.201 327 162.201 333 185.701 333" stroke="#5A6873" />
            <path d="M184.5 107C197.827 107 233.184 111.217 236 126C237.6 134.4 235.157 143 233.5 149.5C231.333 158 226.3 176 225.5 178C224.5 180.5 222.082 180.583 218 180C211 179 196.5 177.5 184 177.5" stroke="#5A6873" />
            <path d="M185.021 107C171.694 107 136.337 111.217 133.521 126C131.921 134.4 134.364 143 136.021 149.5C138.188 158 143.221 176 144.021 178C145.021 180.5 147.439 180.583 151.521 180C158.521 179 173.021 177.5 185.521 177.5" stroke="#5A6873" />
            <path d="M133 133C132.5 128 132.216 124.634 133 120.5C135.903 105.182 171.04 100.524 185 100.524" stroke="#5A6873" />
            <path d="M180.5 373.5C204.5 372.5 223 375.5 239 352C241.124 348.881 244.5 343.5 245.5 337.5C246.996 346.474 244.5 359.5 237 364.5C232.535 367.477 225.833 367.104 223.5 367.104" stroke="#5A6873" />
            <path d="M187.414 373.5C163.414 372.5 144.914 375.5 128.914 352C126.79 348.881 123.414 343.5 122.414 337.5C120.918 346.474 123.414 359.5 130.914 364.5C135.379 367.477 142.081 367.104 144.414 367.104" stroke="#5A6873" />
            <path d="M245.5 338C245.5 354 237.5 360.423 233.5 363.5C229.5 366.577 225 366.5 225 366.5" stroke="#5A6873" />
            <path d="M122.414 338C122.414 354 130.414 360.423 134.414 363.5C138.414 366.577 142.914 366.5 142.914 366.5" stroke="#5A6873" />
            <path d="M181 367C194.5 367 217.261 366.226 221 364.5C227.5 361.5 233.691 354.281 236.273 337.5C238.273 324.5 231 308.833 226.5 301.5" stroke="#5A6873" />
            <path d="M188.613 367C175.113 367 152.352 366.226 148.613 364.5C142.113 361.5 135.922 354.281 133.341 337.5C131.341 324.5 138.613 308.833 143.113 301.5" stroke="#5A6873" />
            <path d="M209 39.5C221.5 39.5002 233.589 48.4996 238.5 60.9998C243.411 73.4999 240 81.5 240 81.5" stroke="#5A6873" />
            <path d="M184 34.5C194.667 33.8334 220.693 38.3852 231.5 60C244 85 244.5 107.5 245.5 123.5C246.3 136.3 245.833 215.167 245.5 253C245.333 262.5 244.942 276.525 244.5 280.5C243.889 286 241.929 288 240.5 290C238 293.5 236.5 295 234.5 295C230.86 295 230.272 289.044 230 287C228 272 226.5 189 236 157.5C236 157.5 240.5 140.5 245.661 127.5" stroke="#5A6873" />
            <path d="M245 273C244.497 277.58 242.676 281.334 241.5 283C239.441 285.915 238.147 288 236.5 288C233.503 288 233.5 277.5 233.5 277.5C233.5 277.5 230 170 245.5 129" stroke="#5A6873" />
            <path d="M236.5 132.5C236.752 130 237.536 124.634 236.752 120.5C233.864 105.264 198.946 100.53 185 100.53" stroke="#5A6873" />
            <path d="M251.5 150.5V158.5" stroke="#5A6873" />
            <path d="M251.5 153.5C253 153.5 254.229 153.364 255.5 154C257.5 155 258.815 155.972 260.5 158.5C262.5 161.5 264.5 161.5 265 161.5" stroke="#5A6873" />
            <path d="M251 150C249.5 149 248 149 248 151L248 158.5C248 160.5 249 161 250 160C251 159 250.5 158.5 252.5 158.5C254.5 158.5 257.636 164.068 260.5 165.5C261.5 166 264.528 166.278 265 162.5C265.5 158.5 257.502 151 255.5 150.5C253.5 150 252.664 151.109 251 150Z" stroke="#5A6873" />
            <path d="M183 28H199.377C201.8 28 203.913 29.6492 204.5 32V32C215 33.5 228.5 40 235.5 49.5C251.711 71.5 252.498 105.06 251.5 133C250.678 156.034 250 192 250 192C250 192 248.784 265.574 252.5 301.5C254 316 253 341.5 247 356C242.8 366.15 234 374.5 221 376C209.817 377.29 195.828 378.886 185 379.044M185 379.044C179.759 379.121 179.783 378.998 185 379.044ZM185 379.044H183" stroke="#5A6873" />
            <path d="M185.119 28H168.742C166.319 28 164.207 29.6492 163.619 32V32C153.119 33.5 139.619 40 132.619 49.5C116.409 71.5 115.621 105.06 116.619 133C117.442 156.034 118.119 192 118.119 192C118.119 192 119.336 265.574 115.619 301.5C114.119 316 115.119 341.5 121.119 356C125.319 366.15 134.119 374.5 147.119 376C158.262 377.286 172.189 378.875 183 379.043" stroke="#5A6873" />
        </svg>
    </aside>
</template>