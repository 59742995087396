// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        facilitySessions: [],
        facilitySession: {},
        isEditView: false,
        pagination: {
            itemsLength: 0,
            itemsPerPage: 10,
            pageCount: null,
            page: 1,
            pageStart: null,
            pageStop: null
        },
        filters: {
            chargerTypeOptions: [
                {
                    name: 'Company',
                    key: 'company'
                },
                {
                    name: 'Home charger',
                    key: 'home'
                }
            ],
        }
    }
}

export const state = getDefaultState()