<script setup>
import {computed, onBeforeMount} from 'vue'
import moment from 'moment'
import store from '@/store'

// composables
import useSecurity from '@/composables/account/useSecurity'

// utils
import {getBrowserName, getOperatingSystemName} from '@/utils/parseUserAgent'
import {useI18n} from 'vue-i18n'

const { t } = useI18n()
const {getters} = store
const {
    computedListOfDevices,
    fetchListOfDevices,
    logout,
    logoutEverywhere
} = useSecurity()

onBeforeMount(async () => {
    fetchListOfDevices()
})

const tableHeaders = computed(() => {
    return getters['account/tableHeaders']
})

const isLoading = computed(() => {
    return getters['isLoading']
})

</script>

<template>
    <v-container fluid
                 class="pt-5"
    >
        <v-row>
            <v-col cols="12"
                   class="d-flex justify-space-between align-center pt-0"
            >
                <h2 class="home-page-title">
                    {{ t('account.devices') }}
                </h2>
                <v-btn color="primary" elevation="0" @click="logoutEverywhere">
                    {{ t('account.logOutEverywhere') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <div class="account-security white py-4">
                    <div v-if="computedListOfDevices?.length" class="content">
                        <v-data-table :items="computedListOfDevices"
                                      :headers="tableHeaders"
                                      :loading="isLoading"
                                      hide-default-footer
                                      disable-pagination
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>
                                        <v-icon v-if="item.type">
                                            {{ item.type }}
                                        </v-icon>
                                        <v-icon v-else>
                                            mdi-cellphone-link
                                        </v-icon>
                                    </td>
                                    <td>
                                        {{ (item.user_agent) ? getBrowserName(item.user_agent) : t('account.unknown') }}
                                    </td>
                                    <td>
                                        {{
                                            (item.user_agent) ? getOperatingSystemName(item.user_agent) : t('account.unknown')
                                        }}
                                    </td>
                                    <td>
                                        {{ moment(item.updated_at).format('D.M.YYYY, HH:mm') }}
                                    </td>
                                    <td>
                                        {{ (item.current_device) ? t('account.currentDevice') : '' }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>