<script setup>
    import { ContentLoader } from 'vue-content-loader'
</script>

<template>
    <content-loader
        :height="150"
        :width="700"
        :speed="2"
        primary-color="rgb(var(--v-theme-white))"
        secondary-color="rgb(var(--v-theme-back))"
    >
        <rect x="0"
              y="0"
              rx="0"
              ry="0"
              width="700"
              height="150"
        />
    </content-loader>
</template>