import { ref, computed } from 'vue'

// configs
import { Status } from '@/configs'

export default function useFilteredCards(items, id) {

    const printedIdFilterValue = ref(null)
    const selectedStateFilterValue = ref(null)
    const assigneeNameFilterValue = ref(null)
    const conditions = ref([])

    const filterPrintedId = (item) => {
        if (item.card_number !== null) {
            return item.card_number.replace(/\s/g, '').includes(printedIdFilterValue.value.replace(/\s/g, ''))
        }
    }

    const filterState = (item) => {
        if (selectedStateFilterValue.value === Status.Active) {
            return item.is_active === true
        }
        return item.assignee_id === id.value && item.is_active === false
    }

    const filterAssigneeName = (item) => {
        if (item.assignee_name !== null) {
            return item.assignee_name.toLowerCase().includes(assigneeNameFilterValue.value.toLowerCase())
        }
    }

    const filteredCards = computed(() => {
        conditions.value = []
        if (printedIdFilterValue.value !== null) {
            conditions.value.push(filterPrintedId)
        }
        if (selectedStateFilterValue.value !== null) {
            conditions.value.push(filterState)
        }
        if (assigneeNameFilterValue.value !== null) {
            conditions.value.push(filterAssigneeName)
        }
        if (conditions.value.length > 0) {
            return items.value.filter((item) => {
                return conditions.value.every((condition) => {
                    return condition(item)
                })
            })
        }
        return items.value
    })

    return {
        filteredCards,
        printedIdFilterValue,
        selectedStateFilterValue,
        assigneeNameFilterValue
    }
}