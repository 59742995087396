// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        chargers: [],
        charger: null,
        isEditView: false,
        pagination: {
            itemsLength: 0,
            itemsPerPage: 10,
            pageCount: null,
            page: 1,
            pageStart: null,
            pageStop: null
        },
        filters: null
    }
}

export const state = getDefaultState()