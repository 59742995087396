<script setup>
import { useStore } from 'vuex'
import { computed, onMounted, watch, reactive } from 'vue'

// configs
import { Lang } from '@/configs/index.js'

// utils
import { apiRequest } from '@/utils/apiRequest'

// translations
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const store = useStore()
const { getters } = store

const state = reactive({
  faqs: [],
  search: '',
  isLoading: false,
  mailHelp: import.meta.env.VITE_APP_EMAIL_SUPPORT
})

const locale = computed(() => {
  return getters['account/locale'] || import.meta.env.VITE_APP_I18N_LOCALE
})

watch(state.search, async (newValue) => {
  if (newValue && newValue.length >= 3) {
    state.faqs = await searchFaqs(newValue)
  } else if (!newValue || newValue.length === 0) {
    state.faqs = await getFaqs()
  }
})


const getFaqs = async () => {
  state.isLoading = true
  try {
    const { data } = await apiRequest('faq')
    return data
  } catch (e) {
    return []
  } finally {
    state.isLoading = false
  }
}

const searchFaqs = async (search) => {
  state.isLoading = true

  try {
    const { data } = await apiRequest(`faq/search/${search}`)
    return data
  } catch (e) {
    return []
  } finally {
    state.isLoading = false
  }
}

onMounted(async () => {
  state.faqs = await getFaqs()
})
</script>

<template>
  <div class="help-page">
    <header class="header">
      <h1 class="home-page-title">
        {{ t('help.Title') }}
      </h1>
      <div class="help-page__search ml-auto">
        <v-text-field
          v-model="state.search"
          prepend-inner-icon="mdi-magnify"
          full-width
          density="compact"
          clearable
          hide-details
          variant="outlined"
          :loading="store.getters['isLoading']"
          :placeholder="t('news.SearchNews')"
        />
      </div>
    </header>
    <main :class="!state.isLoading && state.faqs.length !== 0 ? 'main' : 'main__loading'">
      <v-expansion-panels
        v-if="!state.isLoading && state.faqs.length !== 0"
        class="panels"
      >
        <v-expansion-panel
          v-for="{ group, questions } in state.faqs"
          :key="group.priority"
          class="panel-root"
        >
          <v-expansion-panel-header class="text-h6">
            <template v-slot:actions>
              <v-icon class="panel-icon">
                $expand
              </v-icon>
            </template>
            <span class="panel-header-root"> {{ group.name }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="{ question, answer, priority } in questions"
                :key="`${group.priority}_${priority}`"
              >
                <v-expansion-panel-header>
                  <template v-slot:actions>
                    <v-icon class="panel-icon">
                      $expand
                    </v-icon>
                  </template>
                  <span class="panel-header">{{ question }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>{{ answer }}</v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-progress-circular
        v-else-if="isLoading"
        class="help-page__loader"
        :size="35"
        indeterminate
        color="var(--v-primary)"
      />
      <div
        v-else-if="!state.faqs.length"
        class="help-page__empty"
      >
        <span>{{ t('help.Empty') }}</span>
      </div>
      <div class="help-page__bottom">
        <div class="help-page__bottom-main">
          <div class="d-flex flex-row">
            <div class="title mb-4">
              <span>{{ t('NoAnswer.Title') }}</span>
            </div>
            <svg
              class="pl-4 mr-n12"
              width="86"
              height="24"
              viewBox="0 0 64 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 17C17.7624 7.70434 42.9996 6.50001 61.4993 13.4999"
                stroke="#787878"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M60.5 7.99976L62.1298 13.883L57 16.5316"
                stroke="#787878"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            v-if="locale === Lang.sk"
            class="d-flex flex-column align-center"
          >
            <div>
              {{ t('NoAnswer.Email') }}
              <span class="text-no-wrap">
                <a :href="`mailto:${state.mailHelp}`">{{ state.mailHelp }}</a>
              </span>
            </div>
          </div>
          <div
            v-else-if="locale === Lang.pl"
            class="d-flex flex-column align-center"
          >
            <div>
              {{ t('NoAnswer.Phone') }}
              <span class="text-no-wrap">
                <a :href="`tel:${t('phoneNumber.help')}`">{{ t('phoneNumber.help') }}</a>
              </span>
            </div>
            <div class="grey--text">
              <span class="text-no-wrap">{{ t('NoAnswer.Schedule') }} 9.00 - 17.00</span>
            </div>
          </div>
          <div class="mt-6 text-center">
            {{ t('NoAnswer.PhoneTechnical') }}
            <span class="text-no-wrap"><a :href="`tel:${t('phoneNumber.technical')}`">{{ t('phoneNumber.technical')
            }}</a></span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
