import qs from 'qs'

// Utils
import {apiRequest} from '@/utils/apiRequest'
import {mapPagination} from '@/utils/mapPagination'

// Cookies
import {useCookies} from 'vue3-cookies'
const {cookies} = useCookies()

// Translations
import translations from '@/translations/EN.json'

export const actions = {
    checkStatus: ({dispatch}, error) => {
        if(error.response && typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
            throw error.response.data.errors
        }
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            dispatch('logout')
        } else {
            if(typeof error.message !== 'undefined') {
                throw error.message
            } else if(typeof error.msg !== 'undefined') {
                throw error.msg
            } else {
                throw translations.error['SomethingGoesWrong']
            }
        }
    },
    logout: async ({ commit, state, dispatch }, data = {}) => {
        if(data) {
            await apiRequest.post('auth/logout', data)
                .catch(error => {
                    dispatch('checkStatus', error)
                })
        }
        commit('account/resetStore', state)
        cookies.remove('mobile-token')
    },
    getLocations: async ({ commit, dispatch }, options) => {
        commit('setLoader', 1, {root: true})
        const {data, headers} = await apiRequest('facility/charger-management/locations', {
            params: options,
            paramsSerializer: params => qs.stringify(params, { skipNulls: true })
        }).catch(error => {
            dispatch('checkStatus', error)
        }).finally(() => {
            commit('setLoader', -1, {root: true})
        })
            
        const pagination = await mapPagination(headers)
        commit('setLocations', data)
        commit('setPagination', pagination)
    },
    getLocation: async ({commit, dispatch}, id) => {
        commit('setLoader', 1, { root: true })
        try {
            const { data } = await apiRequest(`facility/charger-management/locations/${id}`)
            commit('setLocation', data)
            await dispatch('getOpeningHours', data.id)
        } catch(error) {
            dispatch('checkStatus', error)
            commit('setLoader', -1, { root: true })
        }
    },
    getOpeningHours: async({ commit, dispatch }, payload) => {
        commit('setLoader', 1, { root: true })
        try{
            const { data } = await apiRequest(`location/opening-hours?LocationID=${payload}`)
            commit('setOpeningHours', data)
        } catch(error) {
            dispatch('checkStatus', error.message)
        } finally {
            commit('setLoader', -1, { root: true })
        }
    },
    getFilters: async ({commit, dispatch}) => {
        commit('setLoader', 1, {root: true})
        const {data} = await apiRequest('facility/charger-management/locations/filters')
            .catch(error => {
                dispatch('checkStatus', error)
            }).finally(() => {
                commit('setLoader', -1, {root: true})
            })
        commit('setFilters', data)
    }
}