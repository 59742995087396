export const mutations = {
    setLocations: (state, locations) => {
        state.locations = locations
    },
    setLocation: (state, location) => {
        state.location = location
    },
    setOpeningHours: (state, hours) => {
        state.location.opening_hours = hours
    },
    setPagination: (state, pagination) => {
        state.pagination = pagination
    },
    setPage: (state, page) => {
        state.pagination.page = page
    },
    setItemPerPage: (state, itemsPerPage) => {
        state.pagination.itemsPerPage = itemsPerPage
    },
    setFilters: (state, filters) => {
        state.filters = filters
    }
}