import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import store from '@/store'

// utils
import showMessageArray from '@/utils/errorMessageArray'
import showMessage from '@/utils/errorMessage'
import { apiRequest } from '@/utils/apiRequest'

const { commit } = store

export default function usePayout() {
    const route = useRoute()

    const state = reactive({
        payoutUrl: null,
        checkoutStatus: null,
        backUrl: `${window.location.origin}${
            route.path === '/registration-wizard' ? route.path : '/account?tab=payment'
        }`
    })

    const redirectToPayout = () => {
        window.location = state.payoutUrl
    }

    const addPaymentCard = () => {
        if (state.payoutUrl) {
            redirectToPayout()
            return
        }
        toPayout()
    }

    const toPayout = async () => {
        commit('setLoader', 1, { root: true })
        try {
            // eslint-disable-next-line
            await grecaptcha.enterprise.ready(async () => {
                const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
                // eslint-disable-next-line
                const recaptchaToken = await grecaptcha.enterprise.execute(recaptchaSiteKey, { action: 'payment_add' })
                const { data } = await apiRequest.post('client/payment-card/add', {
                    redirect_url: state.backUrl,
                    'g-recaptcha-response': recaptchaToken
                })
                state.payoutUrl = data.redirect_url
                redirectToPayout()
            })
        } catch (error) {
            if (Array.isArray(error)) {
                showMessageArray(error)
            } else {
                showMessage(error)
            }
        } finally {
            commit('setLoader', -1, { root: true })
        }
    }

    const checkStatus = async () => {
        try {
            // eslint-disable-next-line
            grecaptcha.enterprise.ready(async () => {
                const recaptchaSiteKey = import.meta.env.VITE_APP_CAPTCHA_SITE_KEY || null
                // eslint-disable-next-line
                const recaptchaToken = await grecaptcha.enterprise.execute(recaptchaSiteKey, { action: 'payment_add' })
                const { data } = await apiRequest('client/payment-card/status', {
                    params: {
                        'g-recaptcha-response': recaptchaToken
                    }
                })

                state.checkoutStatus = data.status
                if (state.checkoutStatus === 'pending') {
                    state.payoutUrl = data.data.checkout_url
                    return
                }
                state.checkoutStatus = null
                state.payoutUrl = null
            })
        } catch (e) {
            if (typeof e.errors.error !== 'undefined') {
                showMessage(e.errors.error)
            } else if (Array.isArray(e.errors)) {
                showMessageArray(e.errors)
            }
        }
    }

    return {
        ...toRefs(state),
        addPaymentCard,
        checkStatus
    }
}
