import moment from 'moment'

function getFileName(response){
    const disposition = response.headers['content-disposition']
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, '')
            return filename.split('.')[0]
        }
    }
}

function getFullFileName(response){
    const disposition = response.headers['content-disposition']
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, '')
            return filename
        }
    } else if (disposition && disposition.indexOf('filename')) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, '')
            return filename
        }
    }
    const type = response.headers['content-type'].split('/')[1]
    return `file_${moment(new Date).format('DD_MM_YYYY-HH:mm:ss')}.${type}`
}

export {
    getFileName,
    getFullFileName
}
