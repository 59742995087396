<script setup>
import {reactive} from 'vue'

// translations
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

defineProps({
    data: {
        type: Array,
        required: true,
        default: () => {
            return []
        }
    },
    isLoading: {
        type: Boolean,
        required: true,
        default: () => {
            return true
        }
    }
})

const state = reactive({
    headers: [
        {
            title: t('coupons.name'),
            align: 'start',
            sortable: false,
            value: 'campaignName'
        },
        {
            title: t('coupons.description'),
            align: 'start',
            sortable: false,
            value: 'campaignDescription',
            width: 550
        },
        {
            title: t('coupons.registeredAt'),
            align: 'start',
            sortable: false,
            value: 'registredAt'
        },
        {
            title: t('coupons.usedAt'),
            align: 'start',
            sortable: false,
            value: 'usedAt'
        },
        {
            title: t('coupons.validTo'),
            align: 'start',
            sortable: false,
            value: 'validTo'
        }
    ]
})
</script>

<template>
    <v-data-table
        :headers="state.headers"
        :items="data"
        :loading="isLoading"
        class="coupons-history"
    >
        <template v-slot:[`item.usedAt`]="{item}">
            <span class="coupons__used-at">
                {{ item.usedAt }}
            </span>
        </template>
        <template v-slot:[`item.validTo`]="{item}">
            <span class="coupons__valid-to">
                {{ item.validTo }}
            </span>
        </template>
    </v-data-table>
</template>