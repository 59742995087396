export default function formatChargerCode(code) {
    const regex = /(?:\-\d+)/

    // get everything after dash
    let result = regex.exec(code)[0]

    // cut out dash
    result = result.substr(1)

    // if there is 0 at the start cut it out
    if(result.indexOf(0) === 0) {
        result = result.substr(1)
    }

    return `#${result}`
}