// We defined function, because of reset state possibility
const getDefaultState = () => {
    return {
        accountTemp: null,
        cookiesExpiration: '30d',
        wizardHasChanges: true,
        recaptchaSiteKey: null,
        chargerManagement: {
            tab: null
        },
        sessions: {
            tab: null
        },
        links: null
    }
}

export const state = getDefaultState()